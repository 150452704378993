import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
@Module({ namespaced: true, name: '' })

class Security extends VuexModule {
    public CSRFToken!: string | null

    @Mutation
    public setCSRFToken(token: string): void {
        this.CSRFToken = token
    }

    @Mutation
    public removeCSRFToken(): void {
        this.CSRFToken = null
    }

    get getCSRFToken(): string | null {
        return this.CSRFToken
    }
}

export default Security