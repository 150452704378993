
import { Options, Vue } from 'vue-class-component'
import CardBase from '@/components/common/layout/CardBase.vue'
import formattersMixin from '@/mixins/formatters'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import exportFromJSON from 'export-from-json'
import { namespace } from 'vuex-class'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import { useToast } from 'vue-toastification'
const adminReports = namespace('adminReports')
const currenciesSatStreet = namespace('currenciesSatStreet')
const toast = useToast()
@Options({
  components: {
    CardBase,
    DataTable,
    Column
  },
  mixins: [formattersMixin]
})
export default class AllBalances extends Vue {
  isLoading = true
  @adminReports.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchAllCustomerBalances!: () => Promise<any>
  @adminReports.Getter
  getAllCustomerBalances!: any[]
  mainSearch = ''
  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem[]>

  async getAllUsers() {
    try {
      this.isLoading = true
      await this.fetchAllCustomerBalances()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  //MIXINS

  public formatPrice!: (amount: number, decimals?: number) => number
  public formatZeros!: (amount: number, exact?: number) => string

  async downloadXls(type: any) {
    const fileName = `TradeTracker-${new Date()}`
    const exportType = type
    try {
      this.isLoading = true
      const currencies = await this.fetchCurrencies()
      const header = { ID: '', Customer: '' }
      for (const currency of currencies) {
        header[currency.id] = ''
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rows: any = [header]

      for (const user of this.getAllCustomerBalances) {
        const row = {
          ID: user.customerId,
          Customer: user.customer.nickname || user.customer.email
        }
        for (const balance of user.balances) {
          row[balance.id] =
            balance.currencyType === 1
              ? this.formatPrice(balance.balance)
              : this.formatZeros(balance.balance, balance.exact)
        }
        rows.push(row)
      }
      exportFromJSON({ data: rows, fileName, exportType })
    } catch (e) {
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      this.isLoading = false
    }
  }
  mounted() {
    this.getAllUsers()
  }

  get getFilteredUsers(): any[] {
    if (!this.mainSearch) {
      return this.getAllCustomerBalances
    }
    return this.getAllCustomerBalances.filter(item =>
      JSON.stringify(item)
        .toLowerCase()
        .includes(this.mainSearch.toLowerCase())
    )
  }
}
