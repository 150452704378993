import { RouteRecordRaw } from 'vue-router'
import AuthBase from '@/views/customer/Auth.vue'
import Login from '@/components/customer/auth/Login.vue'
import ForgotPassword from '@/components/customer/auth/ForgotPassword.vue'
import ResetPassword from '@/components/customer/auth/ResetPassword.vue'
import Register from '@/components/customer/auth/Register.vue'
import BusinessRegister from '@/components/customer/auth/BusinessRegister.vue'
import Confirm from '@/components/customer/auth/Confirm.vue'
import ReSend from '@/components/customer/auth/ReSend.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthBase,
    children: [
      {
        path: "login",
        component: Login,
        name: "auth.login",
        meta: { layout: "auth" }
      },
      {
        path: "register",
        component: Register,
        name: "auth.register",
        meta: { layout: "auth" }
      },
      {
        path: "register-business",
        component: BusinessRegister,
        name: "auth.register.business",
        meta: { layout: "auth" }
      },
      {
        path: "confirm/:code",
        component: Confirm,
        props: true,
        name: "auth.confirm",
        meta: { layout: "auth" }
      },
      {
        path: "forgot",
        component: ForgotPassword,
        name: "auth.forgot",
        meta: { layout: "auth" }
      },
      {
        path: "reset/:token",
        component: ResetPassword,
        name: "auth.reset",
        props: true,
        meta: { layout: "auth" }
      },
      {
        path: "resend/:email",
        component: ReSend,
        props: true,
        name: "auth.resend",
        meta: { layout: "auth" }
      },
    ]
  }
]
export default routes