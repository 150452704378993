import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import currencies from "@/mixins/currencies"
import { useToast } from "vue-toastification"
import { BACKEND_WS_INTERNAL_URL, BACKEND_WS_URL } from '@/config/config'
const toast = useToast()

//search a type of currency in balance
// eslint-disable-next-line
const search = (currencies: any, balances: any) => {
    // eslint-disable-next-line
    const colector: any = []
    // eslint-disable-next-line
    currencies.forEach((element: any) => {
        // eslint-disable-next-line
        const result = balances.find((balance: any) => balance.currencyId == element.symbol)
        if (result) {
            colector.push(result)
        }
    })
    return colector
}
@Module({ namespaced: true, name: '' })
class Balance extends VuexModule {
    public status!: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public data!: any[]
    public error!: object
    public connection!: any

    @Mutation
    public onBalance(): void {
        this.status = "fetching"
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onBalanceSuccess(data: any): void {
        this.status = "success"
        this.error = {}
        this.data = data
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onBalanceUpdate(data: any): void {
        this.status = "success"
        this.error = {}
        this.data = data
    }

    @Mutation
    public onBalanceError(err: object): void {
        this.status = "error"
        this.error = err
        toast.error("Ups! we have a problems.")
    }
    @Mutation
    public mountedWs(connection: object): void {
        this.connection = connection
    }
    @Mutation
    public unmountedWs(): void {
        this.connection.close()
        this.connection = null
    }

    //connect to websocket to get event when balance is updated
    @Action({ rawError: true })
    public getEvent(): void {
        const connection = new WebSocket(BACKEND_WS_URL)
        this.context.commit("mountedWs", connection)
        const token = localStorage.getItem("access-token")
        connection.addEventListener('open', (event: any) => {
            connection.send(JSON.stringify({
                "event": "events",
                "data": { "token": token }
            }))
        })
        connection.addEventListener('message', (event: any) => {
            if ((typeof event.data) == 'string' && event.data == 'activity0') {
                this.context.dispatch("getBalance")
            }
        })
    }

    @Action({ rawError: true })
    public getBalance(currency?: string) {
        return new Promise((resolve, reject) => {
            this.context.commit("onBalance")
            http
                .get(`customer-balance${currency ? '/' + currency : ''}`)
                .then(response => {
                    this.context.commit("onBalanceSuccess", response.data)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onBalanceError")
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public unmountConnection() {
        this.context.commit("unmountedWs")
    }
    public get balances(): object {
        return this.data
    }

    public get fiats(): object {
        if (this.status == "success") {
            return search(currencies.data().localCurrencies, this.data)
        } else {
            return []
        }
    }

    public get cryptos(): object {
        if (this.status == "success") {
            //   return search(currencies.data().criptoCurrencies, this.data)
            return this.data.filter(c => !['CAD', 'USD'].includes(c.currencyId))
        } else {
            return []
        }
    }
}
export default Balance