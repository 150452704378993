import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import {TransactionType} from '@/types'

@Module({ namespaced: true, name: '' })
class Transactions extends VuexModule {
    public status!: string
    public data!: object
    public quote!: object
    public transaction!: TransactionType
    public error!: object
    
    @Mutation
    public onSetTransaction (data: TransactionType): void {
        this.transaction = data
    }
    @Mutation
    public onTransaction(): void {
        this.status = "fetching"
    }
    @Mutation
    public onCreateQuote (quote: object): void {
        this.status = "creating quote"
        this.error = {}
        this.quote = quote
    }
    @Mutation
    public onTransactionSuccess (data: object): void {
        this.status = "success"
        this.error = {}
        this.data = data
    }
    @Mutation // eslint-disable-next-line
    public onTransactionError (err: any): void {
        this.status = "error"
        this.error = err
    }
    @Action({rawError: true})
    public setTransaction (params: object){
        this.context.commit("onSetTransaction", params)
    }
    @Action({rawError: true})
    public getQuote (params: object){
        return new Promise((resolve, reject) => {
            this.context.commit("onTransaction")
            http
                .post('customer-order/get-quote', params)
                .then(response => {
                    this.context.commit("onCreateQuote", response.data)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onTransactionError",error)
                    reject(error)
                })
          })
    }
    @Action({rawError: true})
    public createQuote (params: object){
        return new Promise((resolve, reject) => {
            this.context.commit("onTransaction")
            http
                .post('customer-order/create', params)
                .then(response => {
                    this.context.commit("onTransactionSuccess", response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    this.context.commit("onTransactionError", error)
                    reject(error)
                })
          })
    }
    public get statusTransaction(): string {
        return this.status
    }
}
export default Transactions