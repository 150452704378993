import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
//import {Response} from '@/types'
//import CoingeckoItem from '@/types/currencies/CoingeckoItem'
import http from '@/http-common-coingecko'
import moment from 'moment'
import { BACKEND_WS_INTERNAL_URL, BACKEND_WS_URL } from '@/config/config'
@Module({ namespaced: true, name: '' })
class Prices extends VuexModule {
  /* eslint-disable */
  public connection!: any
  public status!: string
  public data: object = {}
  public error!: object
  public wsAssets: object = {}
  public retry: number = 0

  @Mutation
  public onPrices(): void {
    this.status = "fetching"
    this.error = {}
  }
  @Mutation
  public onPricesSuccess(data: object): void {
    this.error = {}
    if (this.data as object) {
      Object.keys(data).forEach(key => {
        this.data[key] = data[key]
      });
    }
    this.status = "success"
  }
  @Mutation
  public onPricesError(err: object): void {
    this.status = "error"
    this.error = err
    this.retry += 1
  }
  @Mutation
  public mountedWs(connection: object): void {
    this.connection = connection
  }
  @Mutation
  public unmountedWs(): void {
    this.connection.close()
    this.connection = null
  }

  @Mutation
  public onWsAsset(data: object): void {
    if (Object.keys(this.wsAssets).length === 0) {
      this.wsAssets = data
    }
  }

  @Action({ rawError: true })
  public async getPricesCoingecko(assets: any) {
    let payload = Object({})
    let ids = ''
    let vs_currencies = 'btc,'
    // let cryptoIds = ''
    // let fiatIds = 'btc,'

    assets.crypto.forEach((element: any) => {
      ids += element.coingeckoId + ','
    });
    assets.fiat.forEach((element: any) => {
      ids += element.coingeckoId + ','
      vs_currencies += element.currencyId.toLowerCase() + ','
    });
    // assets.crypto.forEach((element: any) => {
    //   cryptoIds += element.coingeckoId + ','
    // });
    // assets.fiat.forEach((element: any) => {
    //   fiatIds += element.currencyId.toLowerCase() + ','
    // });
    try {
      const result = await http.get('simple/price', {
        params: {
          'ids': ids,
          'vs_currencies': vs_currencies,
        }
      })
      Object.entries(Object(result.data)).forEach((element: any) => {
        let baseAsset = assets.fiat.find((fiat: any) => fiat.coingeckoId == element[0]) ? assets.fiat.find((fiat: any) => fiat.coingeckoId == element[0]) : assets.crypto.find((crypto: any) => crypto.coingeckoId == element[0])
        assets.fiat.forEach((fiat: any) => {
          if ((Object.keys(this.wsAssets).length === 0 || !this.wsAssets[baseAsset.currencyId + fiat.currencyId]) && baseAsset.currencyId != fiat.currencyId && element[1][fiat.currencyId.toLowerCase()]) {
            payload[baseAsset.currencyId + fiat.currencyId] = {
              lower: {
                buy: element[1][fiat.currencyId.toLowerCase()],
                sell: element[1][fiat.currencyId.toLowerCase()],
                spot: element[1][fiat.currencyId.toLowerCase()],
              },
              higher: {
                buy: element[1][fiat.currencyId.toLowerCase()],
                sell: element[1][fiat.currencyId.toLowerCase()],
                spot: element[1][fiat.currencyId.toLowerCase()],
              }
            }
          }
        });
        assets.crypto.forEach((crypto: any) => {
          if ((Object.keys(this.wsAssets).length === 0 || !this.wsAssets[baseAsset.currencyId + crypto.currencyId]) && baseAsset.currencyId != crypto.currencyId && element[1][crypto.currencyId.toLowerCase()]) {
            payload[baseAsset.currencyId + crypto.currencyId] = {
              lower: {
                buy: element[1][crypto.currencyId.toLowerCase()],
                sell: element[1][crypto.currencyId.toLowerCase()],
                spot: element[1][crypto.currencyId.toLowerCase()],
              },
              higher: {
                buy: element[1][crypto.currencyId.toLowerCase()],
                sell: element[1][crypto.currencyId.toLowerCase()],
                spot: element[1][crypto.currencyId.toLowerCase()],
              }
            }
          }
        });
      });
      // console.log(payload)
      this.context.commit("onPricesSuccess", payload)
    } catch (error) {
      setTimeout(() => { this.retry <= 3 ? this.context.dispatch("getPricesCoingecko", assets) : null }, (this.retry * 1000));
      this.context.commit("onPricesError", error)
    }
  }

  @Action({ rawError: true })
  public getPrices() {
    try {
      const connection = new WebSocket(BACKEND_WS_URL)
      const token = localStorage.getItem("access-token")
      // eslint-disable-next-line
      connection.addEventListener('open', (event: any) => {
        connection.send(JSON.stringify({
          "event": "getPrices",
          "data": { "token": token }
        }))
        this.context.commit("onPrices")
      })
      // eslint-disable-next-line
      connection.addEventListener('message', (event: any) => {
        if (Object.keys(event.data).length > 0) {
          this.context.commit("onWsAsset", JSON.parse(event.data))
          this.context.commit("onPricesSuccess", JSON.parse(event.data))
        }
      })
      connection.addEventListener('error', (event: any) => {
        setTimeout(() => { this.context.dispatch("getPrices") }, 600000);
      })
      this.context.commit("mountedWs", connection)
    } catch (error) {
    }


  }
  @Action({ rawError: true })
  public getPricesOnBehalfOf(customerId: number) {
    const connection = new WebSocket(BACKEND_WS_INTERNAL_URL)
    const token = localStorage.getItem("access-token")
    // eslint-disable-next-line
    connection.addEventListener('open', (event: any) => {
      connection.send(JSON.stringify({
        "event": "getPrices",
        "data": { "token": token, "customerId": customerId }
      }))
      this.context.commit("onPrices")
    })
    // eslint-disable-next-line
    connection.addEventListener('message', (event: any) => {
      if (Object.keys(event.data).length > 0) {
        this.context.commit("onWsAsset", JSON.parse(event.data))
        this.context.commit("onPricesSuccess", JSON.parse(event.data))
      }
    })
    connection.addEventListener('error', (event: any) => {
      setTimeout(() => { this.context.dispatch("getPricesOnBehalfOf", customerId) }, 1200000);
    })
    this.context.commit("mountedWs", connection)
  }

  @Action({ rawError: true })
  public getAssetPriceByDate(payload: { asset: string, date: string }): Promise<number> {
    payload.asset = payload.asset.toLowerCase()
    const formatedDate = moment(payload.date).format('DD-MM-YYYY')
    return http.get(`coins/${payload.asset}/history`, {
      params: {
        date: formatedDate,
        localization: false
      }
    })
  }

  @Action({ rawError: true })
  public unmountConnection() {
    this.context.commit("unmountedWs")
  }

  public get prices(): object {
    return this.data
  }
  public get pricesStatus(): string {
    switch (this.status) {
      case "success":
        return 'success'
      case "fetching":
        return 'fetching'
      case "error":
        return 'error'
      default:
        return 'fetching'
    }
  }
}
export default Prices

