
import { adminStore } from '@/store/admin'
import formatters from '@/mixins/formatters'
import RequestEnableUser from '@/types/users/RequestEnableUser'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'
import StatusLog from '@/types/users/StatusLog'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AuthorizedSigner from '@/types/users/AuthorizedSigner'
import BeneficialOwner from '@/types/users/BeneficialOwner'
import Dialog from 'primevue/dialog'
import Knob from 'primevue/knob'
import ConfirmPopup from 'primevue/confirmpopup'
import FileUser from '@/types/users/FileUser'
const downloads = namespace('downloads')
const users = namespace('users')
const toast = useToast()
Vue.registerHooks(['beforeRouteEnter'])
@Options({
  data() {
    return {
      approveAccount: false,
      comments: '',
      addressURL: null,
      identificationURL: null,
      identificationBackURL: null,
      addressError: false,
      identificationError: false,
      identificationBackError: false,
      beneficialDetails: null,
      signerDetails: null
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    Dialog,
    Knob,
    ConfirmPopup
  }
})
export default class ValidateCompany extends Vue {
  approveAccount = false
  banAccountModal = false
  banComments = ''
  beneficialModal = false
  signerModal = false
  comments!: string
  addressURL = ''
  identificationURL = ''
  identificationBackURL = ''
  addressError = false
  identificationError = false
  identificationBackError = false
  signerDetails!: AuthorizedSigner
  beneficialDetails!: BeneficialOwner

  adminUpdates: StatusLog[] = []

  @users.Getter
  public selectedUser!: UserItem

  @users.Action
  public enableUser!: (req: RequestEnableUser) => Promise<object>

  @users.Action
  public getSpecificUser!: (id: number) => Promise<UserItem>

  @users.Action
  public getAccountStatusLogs!: (customerId: number) => Promise<StatusLog[]>

  @users.Action
  public reset2Fact!: (customerId: number) => Promise<void>

  @users.Action
  public banUser!: (req: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    message: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    status: boolean
  }) => Promise<void>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  mounted() {
    const userId = Number(this.$route.params.id)
    this.getSpecificUser(userId)
    this.getAccountStatusLogs(userId).then(logs => {
      this.adminUpdates = logs
    })
    document.addEventListener('contextmenu', e => e.preventDefault())
    setTimeout(() => {
      this.comments = this.selectedUser.comments
    }, 400)
  }

  openModalBanAccount(): void {
    this.banAccountModal = true
    this.banComments = ''
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filterFiles(): any[] {
    return this.selectedUser.files.filter(
      file =>
        !file.typeOfDocument.includes('signer') &&
        !file.typeOfDocument.includes('beneficial')
    )
  }

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  async confirmBanUser(): Promise<void> {
    try {
      await this.banUser({
        customerId: this.selectedUser.id,
        message: this.banComments,
        status: !this.selectedUser.isSuspended
      })
      this.banAccountModal = false
      await this.getSpecificUser(this.selectedUser.id)
      toast.success('Account updated')
    } catch (e) {
      toast.error('An error has occurred \nPlease try again later')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetTwoFactorAuth(e: any): void {
    this.$confirm.require({
      target: e.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          await this.reset2Fact(this.selectedUser.id)
          await this.getSpecificUser(this.selectedUser.id)
          toast.success('Account updated')
        } catch (error) {
          toast.error('An error has occurred \nPlease try again later')
        }
      }
    })
  }

  showSignerDatails(signer: AuthorizedSigner): void {
    this.signerModal = true
    this.signerDetails = signer
  }

  showBeneficialDatails(owner: BeneficialOwner): void {
    this.beneficialModal = true
    this.beneficialDetails = owner
  }

  public async approveUserAccount(): Promise<void> {
    if (!this.approveAccount && this.comments.length < 4) return
    const req = new RequestEnableUser()
    req.customerEmail = this.selectedUser.email
    req.customerStatus = this.approveAccount ? 1 : 2
    this.comments && (req.comments = this.comments)
    try {
      adminStore.dispatch('loader/show')
      await this.enableUser(req)
      toast.success(this.approveAccount ? 'Account approved' : 'Comments sent')
      this.$router.go(-1)
    } catch (error) {
      toast.error('An error has occurred \nPlease try again later')
    }
  }
}
