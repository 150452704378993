
import { Options, Vue } from 'vue-class-component'
@Options({
  name: 'CardBase',
  props: {
    title: String,
    subtitle: String,
    allowBack: Boolean
  }
})
export default class CardBase extends Vue {
  title!: string
  subtitle!: string
}
