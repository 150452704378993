
import InputNumber from 'primevue/inputnumber'
import Calendar from 'primevue/calendar'
import { Options, Vue } from 'vue-class-component'
import WithdrawalsForm from '@/types/pending-deposit/WithdrawalsForm'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import { namespace } from 'vuex-class'
import { customerStore } from '@/store/customer'
import formatersMixin from '@/mixins/formatters'
import { useToast } from 'vue-toastification'
import BankAccountItem from '@/types/bank-account/BankAccountItem'
import amountInput from '@/components/common/inputs/amountInput.vue'
import CopyToClipboard from '@/components/common/buttons/CopyToClipboard.vue'
import moment from 'moment'
import CurrencyItem from '@/types/currencies/CurrencyItem'
const withdrawals = namespace('withdrawals')
const onBehalfOf = namespace('onBehalfOfUser')
const currenciesSatStreet = namespace('currenciesSatStreet')
const toast = useToast()

@Options({
  name: 'PendingDepositsForm',
  components: {
    Calendar,
    amountInput,
    CopyToClipboard,
    InputNumber
  },
  mixins: [formatersMixin],
  data() {
    return {
      currentAccount: null,
      amount: 0.1,
      currentCurrencyAccount: null
    }
  }
})
export default class PendingWithdrawalsForm extends Vue {
  isAdjustment = false
  currentCurrencyAccount!: CurrencyItem | undefined
  public withdrawalsForm!: IFormGroup<WithdrawalsForm>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userAccounts: any[] = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public currentAccount!: any

  public reductor!: (string: string) => number

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem>

  @withdrawals.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchAccountsByUser!: (customerId: string | number) => Promise<any>

  @withdrawals.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public postWithdrawal!: (withdrawal: WithdrawalsForm) => Promise<any>

  @onBehalfOf.State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user!: any

  @onBehalfOf.Action
  getUser!: (userId: number) => Promise<void>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.withdrawalsForm = this.createNewForm()
  }

  mounted() {
    const userId = Number(this.$route.params.customerId)
    this.getUser(userId).then(() => {
      this.selectCustomer()
    })
    this.fetchCurrencies()
  }

  onSelectCurrencyId(): void {
    this.currentCurrencyAccount = this.getCurrencies.find(
      c => c.id === this.withdrawalsForm.props.currencyId
    )
  }

  onSelectBankOrWalletId(e): void {
    const id = Number(e.target.value)
    // Adjustment
    if (id === 0) {
      this.isAdjustment = true
      this.currentAccount = null
      this.currentCurrencyAccount = undefined
      return
    }
    this.isAdjustment = false
    this.currentAccount = this.userAccounts.find(account => account.id === id)
    this.currentCurrencyAccount = this.getCurrencies.find(
      c => c.id === this.currentAccount.currencyId
    )
  }

  selectCustomer(): void {
    const userId = Number(this.$route.params.customerId)
    this.withdrawalsForm.props.customerId = userId
    this.getAccounts()
  }

  async getAccounts(): Promise<void> {
    customerStore.dispatch('loader/show')
    try {
      const { data } = await this.fetchAccountsByUser(
        this.withdrawalsForm.props.customerId
      )
      this.userAccounts = data.filter(
        (account: BankAccountItem) => account.isActive
      )
    } catch {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  async submitForm(): Promise<void> {
    // if (this.withdrawalsForm.invalid) return
    const withdrawal = new WithdrawalsForm()
    withdrawal.amount = parseFloat(this.withdrawalsForm.props.amount)

    withdrawal.createdAt = moment(this.withdrawalsForm.props.createdAt).format(
      'YYYY-MM-DD HH:mm:ss'
    )

    withdrawal.customerId = this.withdrawalsForm.props.customerId
    withdrawal.bankOrWalletId = this.withdrawalsForm.props.bankOrWalletId + ''
    withdrawal.transactionAuthCode = this.withdrawalsForm.props.transactionAuthCode
    if (withdrawal.bankOrWalletId == '0') {
      withdrawal.currencyId = this.withdrawalsForm.props.currencyId
    } else {
      withdrawal.currencyId = this.currentAccount.currencyId
    }
    customerStore.dispatch('loader/show')
    try {
      customerStore.dispatch('loader/show')
      await this.postWithdrawal(withdrawal)
      toast.success('Withdrawal registed!')
      this.clearForm()
      this.selectCustomer()
    } catch (error) {
      console.log({ error: error })
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  createNewForm(): IFormGroup<WithdrawalsForm> {
    return new RxFormBuilder().formGroup(WithdrawalsForm) as IFormGroup<
      WithdrawalsForm
    >
  }

  clearForm(): void {
    this.withdrawalsForm.props.bankOrWalletId = null
    this.withdrawalsForm.props.amount = null
    this.withdrawalsForm.props.currencyId = null
    this.withdrawalsForm.props.createdAt = null
    this.withdrawalsForm.props.transactionAuthCode = null
    this.withdrawalsForm = this.createNewForm()
  }
}
