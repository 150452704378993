
import { adminStore } from '@/store/admin'
import CommercialGroup from '@/types/commercial-group/CommercialGroup'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import CustomerSelect from '@/components/admin/dashboard/customerSelect.vue'
import formatters from '@/mixins/formatters'
import { useToast } from 'vue-toastification'
import UserItem from '@/types/users/UserItem'
import Dropdown from 'primevue/dropdown'
import AutoComplete from 'primevue/autocomplete'
const toast = useToast()
const commercialGroups = namespace('commercialGroups')
const users = namespace('users')
@Options({
  name: 'MangeCustomers',
  components: {
    DataTable,
    Column,
    CustomerSelect,
    Dropdown,
    AutoComplete
  },
  mixins: [formatters],
  data() {
    return {
      currentCustomer: null,
      customerSelectKey: 0,
      customerForDetails: null,
      users: []
    }
  }
})
export default class MangeCustomers extends Vue {
  searchText = ''
  resultsSearch = []
  @commercialGroups.Getter
  public selectedCommercialGroup!: CommercialGroup
  public currentCustomer!: UserItem | null
  public customerSelectKey = 0
  public customerForDetails!: UserItem
  public users!: UserItem[]

  @commercialGroups.Getter
  commercialGroups!: CommercialGroup[]
  @commercialGroups.Action
  public list!: () => Promise<CommercialGroup[]>

  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getUsers!: () => Promise<any>

  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getTopBarUsersFilter!: (keyWord) => Promise<any>

  @commercialGroups.Action
  public getSpecificCommercialGroup!: (id: number) => Promise<CommercialGroup>

  @commercialGroups.Action
  public fetchUsersFromCommercialGroup!: (planId: number) => Promise<UserItem[]>

  @commercialGroups.Action
  public assingCustomerToCommercialGroup!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    planId: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId: number
  }) => Promise<void>

  @commercialGroups.Getter
  public getUsersFromCommercialGroup!: UserItem[]

  async getFilteredUsers() {
    await new Promise(resolve => setTimeout(resolve, 1))
    if (this.searchText.length < 3) return
    try {
      const keyWord = this.searchText.split(' ')[0]
      const { data } = await this.getTopBarUsersFilter({ keyWord })
      this.resultsSearch = data
    } catch (error) {
      console.log(error)
    }
  }

  onSelectCustomer(e) {
    this.currentCustomer = e.value
  }

  clearSearch() {
    this.searchText = ''
    this.resultsSearch = []
    this.currentCustomer = null
  }

  async getCommercialGroup() {
    adminStore.dispatch('loader/show')
    try {
      await this.getSpecificCommercialGroup(Number(this.$route.params.id))
    } catch (error) {
      console.log(error)
      toast.error('An error has occurred \nPlease try again')
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async getListUsersFromCommercialGroup() {
    adminStore.dispatch('loader/show')
    try {
      await this.fetchUsersFromCommercialGroup(Number(this.$route.params.id))
    } catch (error) {
      toast.error('An error has occurred \nPlease try again')
      console.log(error)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  mounted() {
    if (!this.commercialGroups) {
      this.list()
    }
    this.getCommercialGroup().then(() => {
      this.getListUsersFromCommercialGroup()
    })
  }

  selectCustomer(customer: UserItem): void {
    this.currentCustomer = customer
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeCommercialGroup(e: any) {
    this.addCustomer(e.value, this.customerForDetails.id)
    this.customerForDetails.id = 0
  }

  async addCustomer(planId: number, customerId: number) {
    try {
      await this.assingCustomerToCommercialGroup({
        planId,
        customerId
      })
      this.customerSelectKey += 1
      toast.success(`Customer changed of group`)
      this.getListUsersFromCommercialGroup()
      this.clearSearch()
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }
}
