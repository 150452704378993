
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import TwoFactorAuthenticator from '@/components/customer/modals/twoFactorAuthenticator.vue'
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
const profile = namespace('profile')
@Options({
  data() {
    return {
      links: [],
      adminLinks: [],
      customerLinks: []
    }
  },
  components: {
    TwoFactorAuthenticator
  }
})
export default class SiteNavbar extends Vue {
  public adminLinks = [{ text: 'Users', routeName: 'user.list' }]
  public customerLinks = [
    { text: 'Dashboard', routeName: 'Home' },
    { text: 'Trade', routeName: 'trade' },
    { text: 'Account Statement', routeName: 'account.statement.report' }
  ]
  public modalShow = false
  @profile.State
  public status!: object

  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: any

  @profile.Getter
  public emailProfile!: string

  @profile.Action
  public logout!: () => void

  async signOut() {
    await this.logout()
  }

  get profileLinks(): { text: string; routeName: string }[] {
    switch (this.userProfile?.role) {
      case 'admin':
        return this.adminLinks
      case 'customer':
        return this.customerLinks
      default:
        return []
    }
  }
  enable2fa() {
    this.modalShow = !this.modalShow
  }
}
