import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
//import {Response} from '@/types'
import { useToast } from "vue-toastification"
const toast = useToast()
@Module({ namespaced: true, name: '' })
class Assets extends VuexModule {
    public status!: string
    public data!: object
    public error!: object

  @Mutation
  public onAssets(): void {
    this.status = "fetching"
    this.error = {}
    this.data = {}
  }
  @Mutation
  public onAssetsSuccess (data: object): void {
    this.status = "success"
    this.error = {}
    this.data = data
  }
  @Mutation
  public onAssetsError (err: object): void {
    this.status = "error"
    this.error = err
  }
  @Action({rawError: true})
  public getAssets(){
    return new Promise((resolve, reject) => {
      this.context.commit("onAssets")
      http
      .get('currency/list')
      .then(response => {
        this.context.commit("onAssetsSuccess", response.data)
        resolve(response)
      })
      .catch(error => {
        this.context.commit("onAssetsError", error)
        toast.error("Ups! we have a problems.")
        reject(error)
      })
    })
  }
  public get fiatsAssets(): object {
    return this.status == "success" ?  this.data["FIAT"] : null
  }
  public get cryptosAssets(): object {
    return this.status == "success" ? this.data["CRYPTO"] : null
  }
  public get getAllAssets(): object {
    return [...this.data["CRYPTO"], ...this.data["FIAT"]]
  }
  public get statusAssets(): string {
    return this.status
  }
}
export default Assets