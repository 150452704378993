<template>
  <div class="default-layout">
    <SiteNavbar />
    <div style="position:relative; height:90%; overflow-y:auto" class="mt-5">
      <div>
        <p class="font-weight-bold h4 mb-3">
          <i class="fas fa-cog fa-spin mr-3"></i>Under Maintenance
        </p>
        <p>
          We are currently working on improving the platform, adding features,
          and doing maintenance. We'll be back online shortly!
        </p>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <SiteFooter />
    </div>
  </div>
</template>

<script>
import SiteNavbar from '@/components/common/layout/siteNavbar.vue'
import SiteFooter from '@/components/common/layout/siteFooter.vue'
export default {
  name: 'UnderMaintenance',
  components: {
    SiteNavbar,
    SiteFooter
  }
}
</script>
<style lang="scss" scoped>
.default-layout {
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 0;
  top: 0;
}
</style>
