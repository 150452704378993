import { maxLength, minLength, required, maxNumber, minNumber, email } from "@rxweb/reactive-forms";


export default class BeneficialOwnerForm {
    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    name!: string;

    @maxNumber({ value: 100, message: 'Max value 100' })
    @minNumber({ value: 1, message: 'Min value 1' })
    @required()
    percentage!: number;

    @email({ message: 'Invalid email' })

    @required()
    @maxLength({ value: 65, message: "Max length 65 caracters" })
    email!: string;

    @required()
    identification!: File;

    identificationBack!: File;

    @required()
    withGovernment!: boolean;

    @required()
    accredited?: boolean;

    @maxLength({ value: 15, message: 'Max length 15' })
    @minNumber({ value: 1, message: 'Min length 1' })
    @required()
    phoneNumber?: string;
}