import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import http from "@/http-common-admin";
import CurrencyItem from "@/types/currencies/CurrencyItem";
import CoingeckoItem from "@/types/currencies/CoingeckoItem";

@Module({ namespaced: true, name: "" })
export default class CurrenciesSatStreet extends VuexModule {
    public status!: string;
    public currencies: CurrencyItem[] = [];
    public error!: object;
    public coingecko: CoingeckoItem[] = []

    @Mutation
    public onFetching(): void {
        this.status = "fetching"
        this.error = {}
    }

    @Mutation
    public onSuccess(data: CurrencyItem[]): void {
        this.status = "success"
        this.error = {}
        this.currencies = data
    }

    @Mutation
    public onError(err: object): void {
        this.status = "error"
        this.error = err
    }

    @Mutation
    public onSuccessCoingecko(data: CoingeckoItem[]): void {
        this.status = "success"
        this.error = {}
        this.coingecko = data
    }

    @Mutation
    public onErrorCoingecko(err: object): void {
        this.status = "error"
        this.error = err
        this.coingecko = []
    }

    @Action({ rawError: true })
    public fetchCurrencies(): Promise<CurrencyItem[]> {
        return new Promise((resolve, reject) => {
            this.context.commit("onFetching")
            http
                .get("currency/admin/list-all")
                .then((response) => {
                    this.context.commit("onSuccess", response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    this.context.commit("onError", error)
                    reject(error)
                });
        });
    }

    @Action({ rawError: true })
    public fetchCoingeckoCurrencies(id: string): Promise<CoingeckoItem[]> {
        return new Promise((resolve, reject) => {
            http
                .get(`currency/listCoingeckoAssert/${id}`)
                .then((response) => {
                    this.context.commit("onSuccessCoingecko", response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    this.context.commit("onErrorCoingecko", error)
                    reject(error)
                });
        });
    }

    @Action({ rawError: true })
    public addCurrencies(currency: CurrencyItem): Promise<void> {
        return new Promise((resolve, reject) => {
            http
                .post("currency/admin/create", currency)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    @Action({ rawError: true })
    public updateCurrencies(currency: CurrencyItem): Promise<void> {
        return new Promise((resolve, reject) => {
            http
                .put("currency/admin/update", currency)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    @Action({ rawError: true })
    public deleteCurrency(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            http
                .delete("currency/admin/delete", { data: { id } })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    public get getCurrencies(): CurrencyItem[] {
        return this.currencies
    }

    public get getFiatCurrencies(): CurrencyItem[] {
        return this.currencies.filter(c => c.currencyType === 1)
    }

    public get getCryptoCurrencies(): CurrencyItem[] {
        return this.currencies.filter(c => c.currencyType === 0)
    }

    public get getStatus(): string {
        return this.status
    }

    public get getStatusCurrenciesSatstreet(): string {
        return this.status
    }

    public get coingeckoCurrencies(): CoingeckoItem[] {
        return this.coingecko
    }
}