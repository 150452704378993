import { RouteRecordRaw } from 'vue-router'
import { ifUserAuthenticated } from '../ guards'
import Profile from '@/views/customer/Profile.vue'
import MyProfile from "@/components/customer/profile/MyProfile.vue"
import CompanyProfile from "@/components/customer/profile/CompanyProfile.vue"
import BeneficialForm from "@/components/customer/profile/BeneficialForm.vue"
import SignerForm from "@/components/customer/profile/SignerForm.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/account',
        name: 'profile',
        beforeEnter: ifUserAuthenticated,
        component: Profile,
        children: [
            {
                path: "profile",
                component: MyProfile,
                name: "profile.my.profile",
                meta: { layout: "default" }
            },
            {
                path: "profile-company",
                component: CompanyProfile,
                name: "profile.company",
                meta: { layout: "default" }
            },
            {
                path: "create-beneficial",
                component: BeneficialForm,
                name: "create.beneficial",
                meta: { layout: "default" }
            },
            {
                path: "edit-beneficial/:beneficialId",
                component: BeneficialForm,
                name: "edit.beneficial",
                meta: { layout: "default" }
            },
            {
                path: "create-signer",
                component: SignerForm,
                name: "create.signer",
                meta: { layout: "default" }
            },
            {
                path: "edit-signer/:signerId",
                component: SignerForm,
                name: "edit.signer",
                meta: { layout: "default" }
            }
        ]
    }
]

export default routes;