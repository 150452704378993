
import ProgressSpinner from 'primevue/progressspinner'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import OrderItem from '@/types/account-statement/OrderItem'
import { customerStore } from '@/store/customer'
import formatters from '@/mixins/formatters'
import Dialog from 'primevue/dialog'
import { useToast } from 'vue-toastification'
const accountStatement = namespace('accountStatement')
const orders = namespace('orders')
const toast = useToast()
@Options({
  name: 'OrderHistoryDetail',
  components: {
    IconsCurrency,
    ProgressSpinner,
    Dialog
  },
  mixins: [formatters]
})
export default class OrderHistoryDetail extends Vue {
  isLoading = true
  showReverseModal = false
  reverseConfirmationCode = ''
  reverseInputCode = ''
  @accountStatement.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchSpecificOrder!: (orderId: string) => Promise<any>

  @orders.Action
  reverseOrder!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    orderUuid: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>

  @accountStatement.Getter
  public getSelectedOrder!: OrderItem

  async getData() {
    customerStore.dispatch('loader/show')
    try {
      const id = this.$route.params.id as string
      await this.fetchSpecificOrder(id)
    } catch {
      this.$router.back()
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  get calculateTotal(): number {
    let total = 0
    for (const item of this.getSelectedOrder.providerOrders) {
      total += item.totalCost
    }
    return total
  }

  generateReverseConfirmationCode(): string {
    return (Math.random() + 1).toString(36).substring(2)
  }
  openModalReverse(): void {
    this.reverseConfirmationCode = this.generateReverseConfirmationCode()
    this.showReverseModal = true
  }

  async confirmReverseOrder() {
    if (this.getSelectedOrder.automaticExecution) {
      return
    }
    if (
      !this.reverseConfirmationCode ||
      !this.reverseInputCode ||
      this.reverseConfirmationCode !== this.reverseInputCode.trim()
    ) {
      toast.warning('Invalid Code.')
      return
    }
    try {
      this.isLoading = true
      await this.reverseOrder({
        customerId: Number(this.$route.params.customerId),
        orderUuid: this.getSelectedOrder.uuid
      })
      toast.success('Order reversed successfully.')
      this.getData()
      this.showReverseModal = false
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.getData()
  }

  updateOder(): void {
    this.getData()
  }
}
