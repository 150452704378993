import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import http from "@/http-common-admin";
import { useToast } from "vue-toastification";
import UserItem from "@/types/users/UserItem";
import CommercialGroup from "@/types/commercial-group/CommercialGroup";
const toast = useToast();
@Module({ namespaced: true, name: "" })
class CommercialGroups extends VuexModule {
  public status!: string;
  // eslint-disable-next-line 
  public commercialGroupsData!: any;
  // eslint-disable-next-line 
  public data!: any;
  public error!: object;
  public commercialGroup!: CommercialGroup;

  public usersFromCommercialGroup: UserItem[] = []
  // eslint-disable-next-line 
  public createCommercialGroupData!: any
  public createCommercialGroupError!: object
  public createCommercialGroupStatus!: string

  public saveCommercialGroupError!: object
  public saveCommercialGroupStatus!: string

  @Mutation
  public onSavingCommercialGroup(): void {
    this.saveCommercialGroupStatus = "fetching"
    this.saveCommercialGroupError = {}
  }

  @Mutation// eslint-disable-next-line 
  public onSavingCommercialGroupSuccess(data: object): void {
    this.saveCommercialGroupStatus = "success"
    this.saveCommercialGroupError = {}
  }

  @Mutation
  public onSavingCommercialGroupError(err: object): void {
    this.saveCommercialGroupStatus = "error"
    this.saveCommercialGroupError = err
  }

  @Mutation
  public onCreatingCommercialGroup(): void {
    this.createCommercialGroupStatus = "fetching";
    this.createCommercialGroupError = {};
    this.createCommercialGroupData = [];
  }

  @Mutation
  public onCreatingCommercialGroupSuccess(data: object): void {
    this.createCommercialGroupStatus = "success";
    this.createCommercialGroupError = {};
    this.createCommercialGroupData = data;
  }

  @Mutation
  public onCreatingCommercialGroupError(err: object): void {
    this.createCommercialGroupStatus = "error";
    this.createCommercialGroupError = err;
  }

  @Mutation
  public onCommercialGroups(): void {
    this.status = "fetching";
    this.error = {};
    this.data = [];
  }

  @Mutation
  public onCommercialGroupsSuccess(data: object): void {
    this.status = "success";
    this.error = {};
    this.data = data;
  }

  @Mutation
  public onCommercialGroupsError(err: object): void {
    this.status = "error";
    this.error = err;
  }

  @Mutation
  public addPlanPairToCommercialGroup(pairId: string) {
    const planPair = this.commercialGroup.planPairs.find((pp) => pp.pairId == pairId);
    if (planPair) {
      planPair.id = 1;
      planPair.spread = Number(this.commercialGroup.defaultSpread);
    }
  }

  @Mutation
  public removePlanPairFromCommercialGroup(pairId: string) {
    const planPair = this.commercialGroup.planPairs.find((pp) => pp.pairId == pairId);
    // eslint-disable-next-line
    if (planPair) planPair.id = <any>null;
  }

  @Mutation
  public editPlanPairSpreadForCommercialGroup({ pairId, spread }) {
    const planPair = this.commercialGroup.planPairs.find((pp) => pp.pairId == pairId);
    if (planPair) planPair.spread = spread;
  }

  @Mutation
  public onGetUsersFromCommercialGroupSuccess(users: UserItem[]) {
    this.usersFromCommercialGroup = users;
  }

  @Mutation
  public onGetUsersFromCommercialGroupError() {
    this.usersFromCommercialGroup = [];
  }

  @Action({ rawError: true })
  public list() {
    return new Promise((resolve, reject) => {
      this.context.commit("onCommercialGroups")
      http
        .get("plan/admin/list")
        .then((response) => {
          this.context.commit("onCommercialGroupsSuccess", response.data)
          resolve(response)
        })
        .catch((error) => {
          this.context.commit("onCommercialGroupsError", error)
          reject(error)
        });
    });
  }

  @Action({ rawError: true })
  public fetchUsersFromCommercialGroup(planId: number) {
    return new Promise((resolve, reject) => {
      http
        .get(`plan/admin/customers/${planId}`)
        .then((response) => {
          this.context.commit("onGetUsersFromCommercialGroupSuccess", response.data);
          resolve(response)
        })
        .catch((error) => {
          this.context.commit("onGetUsersFromCommercialGroupError");
          reject(error)
        });
    });
  }

  @Action({ rawError: true })
  public assingCustomerToCommercialGroup(payload: { planId: number; customerId: number }) {
    return new Promise((resolve, reject) => {
      http
        .put('customer/admin/updatePlan', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  @Action({ rawError: true })
  public createCommercialGroup(payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onCreatingCommercialGroup");
      http
        .post("plan/admin/create", payload)
        .then((response) => {
          this.context.commit("onCreatingCommercialGroupSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onCreatingCommercialGroupError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public saveCommercialGroup() {
    return new Promise((resolve, reject) => {
      this.context.commit("onSavingCommercialGroup");
      // eslint-disable-next-line 
      const commercialGroupData: CommercialGroup = { ...(this.context.state as any).commercialGroup };
      commercialGroupData.planPairs = [...commercialGroupData.planPairs.map(e => ({ ...e }))].filter(e => e.id != null)
      // eslint-disable-next-line 
      commercialGroupData.planPairs.forEach(planPair => delete (planPair as any).id)
      console.log("commercialGroupData.planPairs", commercialGroupData.planPairs)
      http
        .put("plan/admin/update", { ...commercialGroupData, planId: commercialGroupData.id, defaultSpread: Number(commercialGroupData.defaultSpread) })
        .then((response) => {
          this.context.commit("onSavingCommercialGroupSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onSavingCommercialGroupError", error);
          reject(error);
        });
    });
  }

  public get commercialGroups(): object {
    return this.data;
  }

  @Mutation
  public setCommercialGroup(commercialGroup: CommercialGroup): void {
    this.commercialGroup = commercialGroup;
  }

  public get selectedCommercialGroup(): CommercialGroup {
    return this.commercialGroup;
  }

  public get currentStatus(): string {
    return this.status;
  }

  public get currentCreateCommercialGroupStatus(): string {
    return this.createCommercialGroupStatus;
  }

  public get getUsersFromCommercialGroup(): UserItem[] {
    return this.usersFromCommercialGroup;
  }

  @Action({ rawError: true })
  public getSpecificCommercialGroup(id: number | string): Promise<CommercialGroup> {
    return new Promise((resolve, reject) => {
      http
        .get(`plan/admin/details/${id}`)
        .then((response) => {
          resolve(response.data);
          this.context.commit("setCommercialGroup", response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default CommercialGroups;
