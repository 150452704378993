import { RouteRecordRaw } from "vue-router";
import Base from "@/views/admin/ReportsAdminView.vue";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import CurrencyReport from "@/components/admin/reports-admin/CurrencyReport.vue";
import RevenueReport from "@/components/admin/reports-admin/RevenueReport.vue";
import AllBalances from "@/components/admin/reports-admin/AllCustomerBalances.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/reports",
        name: "reports",
        component: Base,
        beforeEnter: ifAdminAuthenticated,
        redirect: "",
        children: [
            {
                path: "all-balances",
                name: "report.all.balances",
                component: AllBalances,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin"
                },
            },
            {
                path: "currencies",
                name: "report.currency",
                component: CurrencyReport,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                },
            },
            {
                path: "revenues",
                name: "report.revenues",
                component: RevenueReport,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                },
            },
        ],
    },
];
export default routes;