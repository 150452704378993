
import { customerStore } from '@/store/customer'
import UserItem from '@/types/users/UserItem'
import { Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'

const profile = namespace('profile')
export default class StepZero extends Vue {
  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: UserItem

  get showMessage() {
    return this.userProfile.manualVerificationStep === 3
  }

  mounted() {
    customerStore.dispatch('profile/checkToken').then(() => {
      if (this.userProfile.isActive) {
        this.$router.push({ name: 'Home' })
      }
    })
  }
}
