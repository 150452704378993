import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-admin'
import currencies from "@/mixins/currencies"
import { useToast } from "vue-toastification"
const toast = useToast()

//search a type of currency in balance
// eslint-disable-next-line
const search = (currencies: any, balances: any) => {
    // eslint-disable-next-line
    const colector: any = []
    // eslint-disable-next-line
    currencies.forEach((element: any) => {
        // eslint-disable-next-line
        const result = balances.find((balance: any) => balance.currencyId == element.symbol)
        if (result) {
            colector.push(result)
        }
    })
    return colector
}
@Module({ namespaced: true, name: '' })
class Balance extends VuexModule {
    public status!: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public data!: any[]
    public error!: object
    
    @Mutation
    public onBalance(): void {
        this.status = "fetching"
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onBalanceSuccess (data: any): void {
        this.status = "success"
        this.error = {}
        this.data = data
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onBalanceUpdate (data: any): void {
        this.status = "success"
        this.error = {}
        this.data = data
    }
    
    @Mutation
    public onBalanceError (err: object): void {
        this.status = "error"
        this.error = err
        toast.error("Ups! we have a problems.")
    }
    @Action({rawError: true})
    public getBalance (customerId: number, currency?: string){
        return new Promise((resolve, reject) => {
            this.context.commit("onBalance")
            http
                .get(`customer-balance/internal/${customerId}${currency ? '/'+currency : ''}`)
                .then(response => {
                    this.context.commit("onBalanceSuccess", response.data)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onBalanceError")
                    reject(error)
                })
          })
    }

    public get balances(): object {
        return this.data
    }

    public get fiats(): object {
        if(this.status == "success"){
            return search(currencies.data().localCurrencies, this.data)
        } else {
            return []
        }
    }

    public get cryptos(): object {
        if(this.status == "success"){
            return this.data.filter(c => !['CAD', 'USD'].includes(c.currencyId))
        } else {
            return []
        }
    }
}
export default Balance