
import { Options, Vue } from "vue-class-component";
import SearchClient from "@/components/admin/dashboard/searchClient.vue";
import LastTrades from "@/components/admin/dashboard/lastTrades.vue";
import AdminSubNavbar from "@/components/admin/layout/AdminSubNavbar.vue";
import { namespace } from "vuex-class";
const dashboard = namespace("dashboard");

@Options({
  components: {
    SearchClient,
    LastTrades,
    AdminSubNavbar,
  },
})
export default class DashboardBase extends Vue {
  @dashboard.Getter
  cards!: any;

  @dashboard.Action
  public getCards!: () => Promise<any>;

  async mounted() {
    try {
      (this as any).$store.dispatch("loader/show");
      await this.getCards();
    } catch (error) {
      //toast.error(error);
    }
  }

  moveTo(path: string) {
    // eslint-disable-next-line
    (window as any).router = this.$router;
    if (this.$route.path == path) {
      this.$router.push({ path: "/dashboard" });
    } else {
      this.$router.push({ path });
    }
  }
}
