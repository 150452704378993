import { required, maxLength, minLength, minNumber } from "@rxweb/reactive-forms";

export default class DespositForm {
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    @maxLength({ value: 75, message: 'Max length 75 characters.' })
    transactionAuthCode!: string

    @required()
    currencyId!: string

    @required()
    @minNumber({ value: 0.000000000005, message: 'Min value 0.000000000005.' })
    amount!: number;

    @minNumber({ value: 0.0000, message: "Invalid value" })
    cadExchangeValue!: number;

    @minNumber({ value: 0.0000, message: "Invalid value" })
    usdExchangeValue!: number;

    @required()
    createdAt!: string

    file!: File;

    customerId!: number | string;
}