import { required, maxLength, minLength, minDate, maxDate, email } from "@rxweb/reactive-forms";

export default class StepOneForm {

    @required()
    @email({ message: 'Please enter valid email address' })
    @maxLength({ value: 70, message: 'Email cannot be more than 50 characters' })
    email!: string;

    @required()
    @maxLength({ value: 45, message: 'First name cannot be more than 45 characters' })
    firstName!: string;

    @required()
    @maxLength({ value: 45, message: 'Last name cannot be more than 45 characters' })
    lastName!: string;

    @required()
    @minDate({ value: '01/01/1920', message: 'Invalid Date' })
    @maxDate({ value: '31/12/' + (new Date().getFullYear() - 18), message: 'Invalid Date' })
    birthdate!: string

    @maxLength({ value: 20, message: 'Max length 20 characters.' })
    phoneNumber!: string

    @required()
    @maxLength({ value: 10, message: 'Max length 10 characters.' })
    postalCode!: string

    @required()
    @maxLength({ value: 60, message: 'Max length 60 characters.' })
    @minLength({ value: 3, message: 'Min length 3 characters.' })
    city!: string

    @required()
    @maxLength({ value: 60, message: 'Max length 60 characters.' })
    @minLength({ value: 2, message: 'Min length 2 characters.' })
    province!: string

    @required()
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    @maxLength({ value: 10, message: 'Max length 10 characters.' })
    streetNumber!: string

    @required()
    @minLength({ value: 3, message: 'Min length 3 characters.' })
    @maxLength({ value: 60, message: 'Max length 60 characters.' })
    street!: string

    @maxLength({ value: 10, message: 'Max length 10 characters.' })
    interiorNumber!: string

    @maxLength({ value: 100, message: 'Max length 100 characters.' })
    @minLength({ value: 12, message: 'Min length 12 characters,' })
    addressLine2!: string

    @maxLength({ value: 100, message: 'Max length 100 characters.' })
    @minLength({ value: 2, message: 'Min length 2 characters,' })
    occupation!: string

    @required()
    government = false

    @maxLength({ value: 35, message: 'Max length 35 characters.' })
    @minLength({ value: 2, message: 'Min length 2 characters,' })
    nickname!: string

    phoneNumberCode!: string

    phoneCountry!: string
}