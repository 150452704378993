import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    // {
    //     path: '/deposits-withdrawal',
    //     name: 'deposits.withdrawal',
    //     beforeEnter: ifUserAuthenticated,
    //     component: DepositAndWithdrawalsView,
    // }
]

export default routes;