
import { Options, Vue } from 'vue-class-component'
import SearchClient from '@/components/admin/dashboard/searchClient.vue'
import LastTrades from '@/components/admin/dashboard/lastTrades.vue'
import AdminSubNavbar from '@/components/admin/layout/AdminSubNavbar.vue'
import { namespace } from 'vuex-class'
import { adminStore } from '@/store/admin'
const dashboard = namespace('dashboard')

@Options({
  components: {
    SearchClient,
    LastTrades,
    AdminSubNavbar
  }
})
export default class PairsBaseView extends Vue {
  @dashboard.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cards!: any

  @dashboard.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getCards!: () => Promise<any>

  async mounted() {
    try {
      adminStore.dispatch('loader/show')
      await this.getCards()
    } catch (error) {
      //toast.error(error);
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  moveTo(path: string) {
    // eslint-disable-next-line
    ;(window as any).router = this.$router
    if (this.$route.path == path) {
      this.$router.push({ path: '/dashboard' })
    } else {
      this.$router.push({ path })
    }
  }
}
