
import { Options, Vue } from 'vue-class-component'
import TabMenu from 'primevue/tabmenu'
@Options({
  name: 'OnBehalfOfOptions',
  components: { TabMenu }
})
export default class OnBehalfOfOptions extends Vue {
  $permissions!: any
  public options!: any[]
  public load = false
  created(){
    this.options = []
    if (this.$permissions['generals']['customer_management'] && this.$permissions['on_behalf_of']['create_automatic_order']) {
      this.options.push({
        to: { name: 'on-behalf-of.home.automatic.order' },
        label: 'Create Automatic Order',
        icon: 'pi pi-fw pi-clock'
      })
    }
    if (this.$permissions['generals']['customer_management'] && this.$permissions['on_behalf_of']['create_manual_order']) {
      this.options.push({
        to: { name: 'on-behalf-of.home.manual.order' },
        label: 'Create Manual Order',
        icon: 'pi pi-fw pi-pencil'
      })
    }
    if (this.$permissions['generals']['customer_management'] && this.$permissions['on_behalf_of']['register_deposit']) {
      this.options.push({
        to: { name: 'on-behalf-of.home.register.deposit' },
        label: 'Register Deposit',
        icon: 'pi pi-fw pi-folder-open'
      })
    }
    if (this.$permissions['generals']['customer_management'] && this.$permissions['on_behalf_of']['register_withdrawal']) {
      this.options.push({
        to: { name: 'on-behalf-of.home.register.withdrawal' },
        label: 'Register Withdrawal',
        icon: 'pi pi-fw pi-arrow-down'
      })
    }
    if (this.$permissions['generals']['customer_management'] && this.$permissions['on_behalf_of']['bank_and_wallets']) {
      this.options.push({
        to: { name: 'on-behalf-of.home.validate.account' },
        label: 'Bank & Wallets',
        icon: 'pi pi-fw pi-credit-card'
      })
    }
    this.load = true
  }
}
