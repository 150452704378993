
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import OrderHistory from '@/components/customer/account-statement/OrderHistory.vue'
import Column from 'primevue/column'
import AccountStatementItem from '@/types/account-statement/AccountStatementItem'
import { namespace } from 'vuex-class'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import Calendar from 'primevue/calendar'
import Datepicker from 'vue3-datepicker'
import currenciesMixin from '@/mixins/currencies'
import formatters from '@/mixins/formatters'
import * as moment from 'moment'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import exportFromJSON from 'export-from-json'
import UserItem from '@/types/users/UserItem'
const accountStatement = namespace('accountStatement')
const transactions = namespace('transactions')
const balance = namespace('balance')
const onBehalfOf = namespace('onBehalfOfUser')

@Options({
  name: 'AccountStatementReport',
  mixins: [currenciesMixin, formatters],
  components: {
    DataTable,
    Column,
    IconsCurrency,
    Datepicker,
    OrderHistory,
    Calendar
  },
  watch: {
    // from() {
    //   this.changeFilters()
    // },
    // to() {
    //   this.changeFilters()
    // },
    currencyId() {
      this.changeFilters()
    },
    statusTransaction() {
      this.statusTransaction == 'success' ? this.getRecords() : null
    }
  }
})
export default class AccountStatementReport extends Vue {
  isWrittingDate = false
  public from = moment
    .default()
    .subtract(3, 'months')
    .format('yyyy-MM-DDThh:mm')
  public to = moment.default().format('yyyy-MM-DDThh:mm')
  public max = moment.default().format('yyyy-MM-DDThh:mm')
  public currencyId = ''
  public period = '3M'
  public $isAdmin!: boolean

  @balance.State
  status!: string

  @accountStatement.Getter
  public getStatements!: AccountStatementItem[]

  @accountStatement.Getter
  public getStatus!: string

  @transactions.Getter
  public statusTransaction!: string

  @onBehalfOf.Getter
  public getUserOnBehalfOf!: UserItem

  @balance.Getter
  // eslint-disable-next-line
  fiats!: any

  @balance.Getter
  // eslint-disable-next-line
  cryptos!: any

  @accountStatement.Action
  public fetchStatements!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId?: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    fromDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    toDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    currencyId: string
  }) => Promise<void>
  @accountStatement.Action
  public adminFetchStatements!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    fromDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    toDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    currencyId: string
  }) => Promise<void>

  @accountStatement.Action
  public fetchStatementsOnBehalfOf!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    fromDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    toDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    currencyId: string
  }) => Promise<void>

  @balance.Action
  // eslint-disable-next-line
  public getBalance!: (customerId?: number) => Promise<any>

  //MIXINS

  public formatPrice!: (amount: number, decimals?: number) => number

  public formatDate!: (date: string) => string

  public formatZeros!: (amount: number, exact?: number) => string

  public format!: (amount: number | string, decimalCount?: number) => string

  changeFilters() {
    if (
      !moment.default(this.from).isValid() ||
      !moment.default(this.to).isValid()
    )
      return
    this.getRecords()
  }

  getRecords(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      fromDate: moment.default(this.from).format('yyyy-MM-DDThh:mm'),
      toDate: moment.default(this.to).format('yyyy-MM-DDThh:mm'),
      currencyId: this.currencyId
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((this as any).$isAdmin)
      payload.customerId = Number(this.$route.params.customerId)
    this.fetchStatements(payload)
  }
  ifCrypto(currency: CurrencyItem) {
    if (this.status == 'success') {
      return currency.currencyType === 0
    }
  }
  ifLocal(currency: CurrencyItem) {
    if (this.status == 'success') {
      return currency.currencyType === 1
    }
  }
  splitPair(pair: string) {
    const fiat = { first: '', second: '' }
    const crypto = { first: '', second: '' }
    let pairType = ''
    this.cryptos.forEach(element => {
      if (pair.search(element.currencyId) != -1) {
        crypto.first == ''
          ? (crypto.first = element.currencyId)
          : (crypto.second = element.currencyId)
      }
    })
    this.fiats.forEach(element => {
      if (pair.search(element.currencyId) != -1) {
        fiat.first == ''
          ? (fiat.first = element.currencyId)
          : (fiat.second = element.currencyId)
      }
    })
    if (fiat.first && fiat.second) {
      pairType = 'fiat'
      let pv: string
      if (pair != fiat.first + fiat.second) {
        pv = fiat.first
        fiat.first = fiat.second
        fiat.second = pv
      }
    }
    if (crypto.first && crypto.second) {
      pairType = 'crypto'
      let pv: string
      if (pair != crypto.first + crypto.second) {
        pv = crypto.first
        crypto.first = crypto.second
        crypto.second = pv
      }
    }
    if (pairType == '') {
      pairType = 'mix'
    }
    return { fiat: fiat, crypto: crypto, pairType: pairType }
  }
  onChangeInputDate() {
    if (
      !moment.default(this.from).isValid() &&
      !moment.default(this.to).isValid()
    ) {
      return
    }
    this.isWrittingDate = true
    setTimeout(() => {
      if (this.isWrittingDate) {
        this.isWrittingDate = false
        this.changeFilters()
      }
    }, 3000)
  }
  interval(period: string) {
    const year = moment.default().format('YYYY')
    this.period = period
    switch (period) {
      case '1D':
        this.from = moment
          .default()
          .subtract(1, 'days')
          .format('yyyy-MM-DDThh:mm')
        this.to = moment.default().format('yyyy-MM-DDThh:mm')
        break
      case '1W':
        this.from = moment
          .default()
          .subtract(7, 'days')
          .format('yyyy-MM-DDThh:mm')
        this.to = moment.default().format('yyyy-MM-DDThh:mm')
        break
      case '1M':
        this.from = moment
          .default()
          .subtract(1, 'months')
          .format('yyyy-MM-DDThh:mm')
        this.to = moment.default().format('yyyy-MM-DDThh:mm')
        break
      case '3M':
        this.from = moment
          .default()
          .subtract(3, 'months')
          .format('yyyy-MM-DDThh:mm')
        this.to = moment.default().format('yyyy-MM-DDThh:mm')
        break
      case 'Q1':
        this.from = moment
          .default(`${year}-01-01T00:00`)
          .format('yyyy-MM-DDThh:mm')

        this.to = moment
          .default(`${year}-03-31T00:00`)
          .format('yyyy-MM-DDThh:mm')
        break
      case 'Q2':
        this.from = moment
          .default(`${year}-04-01T00:00`)
          .format('yyyy-MM-DDThh:mm')

        this.to = moment
          .default(`${year}-06-30T00:00`)
          .format('yyyy-MM-DDThh:mm')
        break
      case 'Q3':
        this.from = moment
          .default(`${year}-07-01T00:00`)
          .format('yyyy-MM-DDThh:mm')

        this.to = moment
          .default(`${year}-09-30T00:00`)
          .format('yyyy-MM-DDThh:mm')
        break
      case 'Q4':
        this.from = moment
          .default(`${year}-10-01T00:00`)
          .format('yyyy-MM-DDThh:mm')

        this.to = moment
          .default(`${year}-12-31T00:00`)
          .format('yyyy-MM-DDThh:mm')
        break
    }
    this.changeFilters()
  }
  downloadXls() {
    const data: {Date: string; Type: string; Pair: string; 'Previous_Balance': string; Amount: string;'Final_Balance': string }[] = []
    let type  = ''
    this.getStatements.forEach(element => {
      switch (element.type) {
        case 1:
          type = 'Order Base'
          break;
        case 2:
          type = 'Order'
          break;
        case 3:
          type = 'Deposit'
          break;
        case 4:
          type = 'Deposit Canceled'
          break;
        case 5:
          type = 'Withdrawal'
          break;
        case 6:
          type = 'Withdrawal Canceled'
          break;
      }
      
      data.push({
        Date: this.formatDate(element.createdAt),
        ...(this.$isAdmin) && {"Accounting_Hour": element.accountingHourDate},
        Type: type,
        Pair: element.order ? element.order.pairId : element.currencyId,
        'Previous_Balance': element.currency?.currencyType === 1
          ? '$ ' + this.format(element.previousBalance)
          : this.format(element.previousBalance),
        Amount: element.currency?.currencyType === 1
          ? '$ ' + this.format(element.amount)
          : this.format(element.amount),
        'Final_Balance': element.currency?.currencyType === 1
          ? '$ ' + this.format(element.finalBalance)
          : this.format(element.finalBalance),
      })
    });
    let fileName = `Satstreet-Report-${new Date()}`
    if (this.$isAdmin) {
      fileName += `-${this.getUserOnBehalfOf.nickname}`
    }
    const exportType = 'csv'
    exportFromJSON({ data, fileName, exportType })
  }
  mounted() {
    this.getRecords()
    this.getBalance(
      this.$isAdmin ? Number(this.$route.params.customerId) : undefined
    )
  }
  beforeUnmount() {
    close()
  }
}
