import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    _createVNode("li", _hoisted_2, [
      _createVNode("a", {
        class: ["nav-link text-dark", {act: _ctx.type == 'market'}],
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeType('market'))),
        href: "#"
      }, "Market", 2)
    ]),
    _createVNode("li", _hoisted_3, [
      _createVNode("a", {
        class: ["nav-link text-dark", {act: _ctx.type == 'fok'}],
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeType('fok'))),
        href: "#"
      }, "Fill or Kill", 2)
    ])
  ]))
}