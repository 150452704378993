import { RouteRecordRaw } from 'vue-router'
import { ifUserAuthenticated } from '../ guards'
import BankAccountView from '@/views/customer/BankAccountView.vue'
import ListBackAccounts from '@/components/customer/bank-accounts/ListBackAccounts.vue'
import BankAccountForm from '@/components/customer/bank-accounts/BankAccountForm.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/back-account',
        name: 'bank.account',
        beforeEnter: ifUserAuthenticated,
        component: BankAccountView,
        children: [
            {
                name: 'bank.account.list',
                path: '/back-account/list',
                component: ListBackAccounts
            },
            {
                name: 'bank.account.form',
                path: '/back-account/form',
                component: BankAccountForm
            },
            {
                name: 'bank.account.detail',
                path: '/back-account/:id',
                component: BankAccountForm
            },
        ]
    }
]

export default routes;