import { required, maxLength, minLength } from "@rxweb/reactive-forms";

export default class CryptoAccountForm {
    @required()
    currencyId!: string;

    @required()
    customerId!: number;

    @required()
    @maxLength({ value: 50, message: 'Max length 50 characters.' })
    @minLength({ value: 10, message: 'Min length 10 characters.' })
    cryptoAddress!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    accountName!: string;

    type!: number;
}