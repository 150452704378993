
	import { adminStore } from '@/store/admin'
	import formatters from '@/mixins/formatters'
	import RequestEnableUser from '@/types/users/RequestEnableUser'
	import UserItem from '@/types/users/UserItem'
	import { Options, Vue } from 'vue-class-component'
	import { RouteLocation } from 'vue-router'
	import { useToast } from 'vue-toastification'
	import { namespace } from 'vuex-class'
	import StatusLog from '@/types/users/StatusLog'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Dialog from 'primevue/dialog'
	import ConfirmPopup from 'primevue/confirmpopup'
	import FileUser from '@/types/users/FileUser'
	const users = namespace('users')
	const downloads = namespace('downloads')
	const toast = useToast()
	Vue.registerHooks(['beforeRouteEnter'])
	@Options({
		data() {
			return {
				approveAccount: false,
				comments: '',
				addressURL: null,
				identificationURL: null,
				identificationBackURL: null,
				addressError: false,
				identificationError: false,
				identificationBackError: false,
			}
		},
		mixins: [formatters],
		components: {
			DataTable,
			Column,
			Dialog,
			ConfirmPopup,
		},
	})
	export default class SiteNavbar extends Vue {
		public approveAccount = false
		public banAccountModal = false
		public comments!: string
		public banComments = ''
		public addressURL = ''
		public identificationURL = ''
		public identificationBackURL = ''
		public addressError = false
		public identificationError = false
		public identificationBackError = false
		filterFiles: FileUser[] = []
		public APItoken = ''

		adminUpdates: StatusLog[] = []

		@users.Getter
		public selectedUser!: UserItem

		@users.Action
		public enableUser!: (req: RequestEnableUser) => Promise<object>

		@users.Action
		public getSpecificUser!: (id: number) => Promise<UserItem>

		@users.Action
		public getAccountStatusLogs!: (customerId: number) => Promise<StatusLog[]>

		@users.Action
		public reset2Fact!: (customerId: number) => Promise<void>

		@users.Action
		public setApiToken!: (customerId: number) => Promise<string>

		@users.Action
		public viewApiToken!: (customerId: number) => Promise<string>

		@users.Action
		public removeApiToken!: (customerId: number) => Promise<void>

		@users.Action
		public banUser!: (req: {
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			customerId: number
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			message: string
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			status: boolean
		}) => Promise<void>

		@downloads.Action
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		public downloadFile!: (file: FileUser) => Promise<any>

		mounted() {
			document.addEventListener('contextmenu', (e) => e.preventDefault())
		}

		openModalBanAccount(): void {
			this.banAccountModal = true
			this.banComments = ''
		}
		async downloadDocument(file: FileUser): Promise<void> {
			try {
				const { data } = await this.downloadFile(file)
				window.location.href = data
			} catch {
				toast.error('Download error, please try later.')
			}
		}
		async confirmBanUser(): Promise<void> {
			try {
				await this.banUser({
					customerId: this.selectedUser.id,
					message: this.banComments,
					status: !this.selectedUser.isSuspended,
				})
				this.banAccountModal = false
				await this.getSpecificUser(this.selectedUser.id)
				this.adminUpdates = await this.getAccountStatusLogs(
					this.selectedUser.id
				)
				toast.success('Account updated')
			} catch (e) {
				toast.error('An error has occurred \nPlease try again later')
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		resetTwoFactorAuth(e: any): void {
			this.$confirm.require({
				target: e.currentTarget,
				message: 'Are you sure you want to proceed?',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					try {
						await this.reset2Fact(this.selectedUser.id)
						await this.getSpecificUser(this.selectedUser.id)
						toast.success('Account updated')
					} catch (error) {
						toast.error('An error has occurred \nPlease try again later')
					}
				},
			})
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		enableAPItoken(e: any): void {
			this.$confirm.require({
				target: e.currentTarget,
				message: 'Are you sure you want to proceed?',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					try {
						if (!this.selectedUser.isApiTradingEnabled) {
							this.APItoken = await this.setApiToken(this.selectedUser.id)
						} else {
							await this.removeApiToken(this.selectedUser.id)
							this.APItoken = ''
						}
						await this.getSpecificUser(this.selectedUser.id)
						toast.success('Account updated')
					} catch (error) {
						toast.error('An error has occurred \nPlease try again later')
					}
				},
			})
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async getAPItoken() {
			try {
				if (this.selectedUser.isApiTradingEnabled) {
					this.APItoken = await this.viewApiToken(this.selectedUser.id)
				}
			} catch (error) {
				toast.error('An error has occurred \nPlease try again later')
			}
		}

		beforeRouteEnter(to: RouteLocation, from: RouteLocation, next: Function) {
			next(async (vm: SiteNavbar) => {
				//adminStore.dispatch("loader/show");
				const user = await vm.getSpecificUser(Number(to.params.id))
				vm.comments = user.comments
				vm.filterFiles = [...user.files]
				vm.adminUpdates = await vm.getAccountStatusLogs(user.id)
			})
		}

		public async approveUserAccount(): Promise<void> {
			if (!this.approveAccount && this.comments.length < 4) return
			const req = new RequestEnableUser()
			req.customerEmail = this.selectedUser.email
			req.customerStatus = this.approveAccount ? 1 : 2
			this.comments && (req.comments = this.comments)
			try {
				adminStore.dispatch('loader/show')
				await this.enableUser(req)
				toast.success(
					this.approveAccount ? 'Account approved' : 'Comments sent'
				)
				this.$router.go(-1)
			} catch (error) {
				toast.error('An error has occurred \nPlease try again later')
			}
		}
	}
