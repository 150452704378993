
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import Dialog from 'primevue/dialog'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import BusinessRegisterForm from '@/types/auth/BusinessRegisterForm'
import BeneficialOwnerForm from '@/types/auth/BeneficialOwnerForm'
import AuthorizedSignerForm from '@/types/auth/AuthorizedSignerForm'
import MultiSelect from 'primevue/multiselect'
import Toolbar from 'primevue/toolbar'
import Slider from 'primevue/slider'
import { customerStore } from '@/store/customer'
import UserItem from '@/types/users/UserItem'
import InputNumber from 'primevue/inputnumber'
import Password from 'primevue/password'
import { FILE_SIZE_LIMIT_MB } from '@/config/config'
import { GOOGLE_RECAPTCHA_SITE_KEY } from '@/config/api-keys'
import FileUser from '@/types/users/FileUser'

const toast = useToast()
const register = namespace('register')
const downloads = namespace('downloads')
const profile = namespace('profile')

@Options({
  components: {
    Dialog,
    MultiSelect,
    Toolbar,
    Slider,
    InputNumber,
    Password
  },
  data() {
    return {
      terms: false
    }
  }
})
export default class BusinessRegister extends Vue {
  disableForm = false
  showBeneficialModal = false
  showSignerModal = false
  terms!: boolean
  retryForm = false
  registerBusinessForm!: IFormGroup<BusinessRegisterForm>
  signerForm!: IFormGroup<AuthorizedSignerForm>
  signerList: AuthorizedSignerForm[] = []
  beneficialForm!: IFormGroup<BeneficialOwnerForm>
  beneficialList: BeneficialOwnerForm[] = []
  aviablePercentage = 100
  percentageShow = 100
  partners = [
    { name: 'Our team' },
    { name: 'Web (Google Twitter)' },
    { name: 'Other' }
  ]
  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: UserItem

  @register.Action
  public registerCorporate!: (data: FormData) => Promise<object>

  @register.Action
  public updateCorporate!: (data: FormData) => Promise<object>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  openBeneficialModal() {
    this.showBeneficialModal = true
    this.percentageShow =
      100 -
      this.beneficialList.reduce(
        (total: number, ben) => total + ben.percentage,
        0
      )
    this.beneficialForm = new RxFormBuilder().formGroup(
      BeneficialOwnerForm
    ) as IFormGroup<BeneficialOwnerForm>
  }

  openSignerModal() {
    this.showSignerModal = true
    this.signerForm = new RxFormBuilder().formGroup(
      AuthorizedSignerForm
    ) as IFormGroup<AuthorizedSignerForm>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.registerBusinessForm = new RxFormBuilder().formGroup(
      BusinessRegisterForm
    ) as IFormGroup<BusinessRegisterForm>
    this.beneficialForm = new RxFormBuilder().formGroup(
      BeneficialOwnerForm
    ) as IFormGroup<BeneficialOwnerForm>
    this.signerForm = new RxFormBuilder().formGroup(
      AuthorizedSignerForm
    ) as IFormGroup<AuthorizedSignerForm>
  }

  mounted() {
    if (this.$route.name === 'verify.company') {
      customerStore.dispatch('profile/checkToken').then(() => {
        this.retryForm = true
        this.fillRetryForm()
      })
    }
  }

  fillRetryForm(): void {
    this.registerBusinessForm.props.fullLegalName = this.userProfile.firstName
    this.registerBusinessForm.props.lastName = this.userProfile.lastName
    this.registerBusinessForm.props.email = this.userProfile.email
    this.registerBusinessForm.props.password = 'SetcretPa555word*'
    this.registerBusinessForm.props.confirmPassword = 'SetcretPa555word*'
    this.registerBusinessForm.props.entityName = this.userProfile.corporate.entityName
    this.registerBusinessForm.props.entityType = this.userProfile.corporate.entityType
    this.registerBusinessForm.props.corporateAdress = this.userProfile.addressLine1
    this.registerBusinessForm.props.companyWebsite = this.userProfile.corporate.website
    this.registerBusinessForm.props.natureBusiness = this.userProfile.corporate.businessNature
    this.registerBusinessForm.props.hasFintracOrMsb = this.userProfile.corporate.MSB
    this.registerBusinessForm.props.hasIndividualEntity =
      this.userProfile.corporate.beneficialOwners.length > 0

    this.registerBusinessForm.props.miningBitcoin = this.userProfile.corporate.minesBitcoin
    if (this.userProfile.corporate.minesBitcoin) {
      this.registerBusinessForm.props.walletAddress = this.userProfile.corporate.walletAddress
    }

    // Search Files
    for (const file of this.userProfile.files) {
      if (file.typeOfDocument === 'addressProof')
        this.registerBusinessForm.props.addressProof = file
      else if (file.typeOfDocument === 'energyBill')
        this.registerBusinessForm.props.energyBill = file
      else if (file.typeOfDocument === 'articlesIncorporation')
        this.registerBusinessForm.props.articlesIncorporation = file
    }
  }

  checkFileName(file: File): boolean {
    const extension = file.name.split('.').pop() || ''
    return ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(extension)
  }

  checkFileType(type): boolean {
    return [
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'application/pdf'
    ].includes(type)
  }

  checkFileSize(file: File): boolean {
    const size = parseFloat((file.size / 1024 / 1024).toFixed(4))
    return size > FILE_SIZE_LIMIT_MB
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEntryFile(e: any, field: string): void {
    const file = e.target.files[0]
    if (!file) return

    if (!this.checkFileType(file.type)) {
      toast.warning('Invalid file type.')
      return
    }

    if (!this.checkFileName(file)) {
      toast.warning('Invalid file type.')
      return
    }

    if (!this.checkFileName(file)) {
      toast.warning('Invalid file type.')
      return
    }

    if (this.checkFileSize(file)) {
      toast.warning('File oversize. Limit 10MB.')
      return
    }

    switch (field) {
      case 'articlesIncorporation':
        this.registerBusinessForm.props.articlesIncorporation = file
        break
      case 'addressProof':
        this.registerBusinessForm.props.addressProof = file
        break
      case 'idBeneficial':
        this.beneficialForm.props.identification = file
        break
      case 'idBeneficialBack':
        this.beneficialForm.props.identificationBack = file
        break
      case 'idSigner':
        this.signerForm.props.identification = file
        break
      case 'idSignerBack':
        this.signerForm.props.identificationBack = file
        break
      case 'energyBill':
        this.registerBusinessForm.props.energyBill = file
        break
      default:
        break
    }
  }

  addBeneficialOwner(): void {
    if (this.beneficialForm.invalid) {
      return this.touchAllBeneficialFormControls()
    }
    const beneficial = new BeneficialOwnerForm()
    beneficial.name = this.beneficialForm.props.name
    beneficial.email = this.beneficialForm.props.email
    beneficial.accredited = this.beneficialForm.props.accredited
    beneficial.percentage = this.beneficialForm.props.percentage
    beneficial.phoneNumber = this.beneficialForm.props.phoneNumber
    beneficial.identification = this.beneficialForm.props.identification
    beneficial.withGovernment = this.beneficialForm.props.withGovernment
    beneficial.identificationBack = this.beneficialForm.props.identificationBack
    this.beneficialList.push(beneficial)
    // Check percentage
    this.aviablePercentage -= this.beneficialForm.props.percentage
    this.clearBeneficialOwnerForm()
  }

  checkRange(e) {
    if (this.aviablePercentage < 0 || this.aviablePercentage > 100) return
    if (e.value > this.aviablePercentage) {
      e.value = this.aviablePercentage
    }
    this.percentageShow = this.aviablePercentage
    this.percentageShow -= e.value
    this.beneficialForm.props.percentage = e.value
  }

  removeBeneficial(email: string): void {
    const index = this.beneficialList.findIndex(b => b.email === email)
    // Add Percentage
    this.aviablePercentage += this.beneficialList[index].percentage
    this.percentageShow += this.beneficialList[index].percentage
    this.beneficialList.splice(index, 1)
  }

  get getPercentage(): number {
    let counter = 0
    this.beneficialList.forEach(b => {
      counter += b.percentage
    })
    return counter - 100
  }

  addAuthorizedSigner(): void {
    if (this.signerForm.invalid) {
      return this.touchAllSignerFormControls()
    }
    const signer = new AuthorizedSignerForm()
    signer.name = this.signerForm.props.name
    signer.email = this.signerForm.props.email
    signer.identification = this.signerForm.props.identification
    signer.identificationBack = this.signerForm.props.identificationBack
    signer.withGovernment = this.signerForm.props.withGovernment
    this.signerList.push(signer)
    this.clearSignerForm()
  }

  removeSigner(email: string): void {
    const index = this.signerList.findIndex(s => s.email === email)
    this.signerList.splice(index, 1)
  }

  clearBeneficialOwnerForm(): void {
    this.showBeneficialModal = false
    this.beneficialForm = new RxFormBuilder().formGroup(
      BeneficialOwnerForm
    ) as IFormGroup<BeneficialOwnerForm>
  }

  clearSignerForm(): void {
    this.showSignerModal = false
    this.signerForm = new RxFormBuilder().formGroup(
      AuthorizedSignerForm
    ) as IFormGroup<AuthorizedSignerForm>
  }

  touchAllSignerFormControls(): void {
    Object.keys(this.signerForm.controls).forEach(crl => {
      this.signerForm.get(crl).markAsDirty()
    })
  }

  touchAllBeneficialFormControls(): void {
    Object.keys(this.beneficialForm.controls).forEach(crl => {
      this.beneficialForm.get(crl).markAsDirty()
    })
  }

  touchAllRegistrationFormControls(): void {
    Object.keys(this.registerBusinessForm.controls).forEach(crl => {
      this.registerBusinessForm.get(crl).markAsDirty()
    })
  }

  buildFormData(): FormData {
    const form = new FormData()
    if (this.retryForm) form.set('customerId', this.userProfile.id.toString())
    form.set('email', this.registerBusinessForm.props.email)
    form.set('password', this.registerBusinessForm.props.password)
    form.set('entityName', this.registerBusinessForm.props.entityName)
    form.set('entityType', this.registerBusinessForm.props.entityType)
    form.set(
      'miningBitcoin',
      this.registerBusinessForm.props.miningBitcoin.toString()
    )
    form.set('firstName', this.registerBusinessForm.props.fullLegalName)
    form.set('lastName', this.registerBusinessForm.props.lastName)
    if (this.registerBusinessForm.props.companyWebsite) {
      form.set('companyWebsite', this.registerBusinessForm.props.companyWebsite)
    }
    form.set('natureBusiness', this.registerBusinessForm.props.natureBusiness)
    form.set('corporateAdress', this.registerBusinessForm.props.corporateAdress)
    form.set('hasFintracOrMsb', this.registerBusinessForm.props.hasFintracOrMsb)

    //Validating files and then add them
    this.registerBusinessForm.props.articlesIncorporation &&
      form.append(
        'articlesIncorporation',
        this.registerBusinessForm.props.articlesIncorporation
      )

    this.registerBusinessForm.props.addressProof &&
      form.append('addressProof', this.registerBusinessForm.props.addressProof)

    this.registerBusinessForm.props.energyBill &&
      form.append('energyBill', this.registerBusinessForm.props.energyBill)

    form.set(
      'hasIndividualEntity',
      this.registerBusinessForm.props.hasIndividualEntity
    )
    form.set(
      'accreditedInvestorOwner',
      this.registerBusinessForm.props.hasBeneficialOwner
    )
    if (this.registerBusinessForm.props.walletAddress) {
      form.set('walletAddress', this.registerBusinessForm.props.walletAddress)
    }

    form.set('beneficialArray', JSON.stringify(this.beneficialList))
    form.set('signerArray', JSON.stringify(this.signerList))

    if (
      this.registerBusinessForm.props.partnerAssociate &&
      this.registerBusinessForm.props.partnerAssociate.length > 0
    ) {
      form.set(
        'partnerAssociate',
        this.registerBusinessForm.props.partnerAssociate
          .map(p => p.name)
          .toString()
      )
    }

    // Beneficial Owners
    if (this.beneficialList.length > 0 && !this.retryForm) {
      form.set('beneficialOwnerTotal', this.beneficialList.length.toString())
      let count = 1
      for (const beneficial of this.beneficialList) {
        form.set(`beneficialName${count}`, beneficial.name)
        form.set(
          `beneficialPersentage${count}`,
          beneficial.percentage.toString()
        )
        form.set(`beneficialEmail${count}`, beneficial.email)
        form.append(`beneficialId${count}`, beneficial.identification)
        form.append(`beneficialIdBack${count}`, beneficial.identificationBack)
        form.set(
          `beneficialWithGovernment${count}`,
          beneficial.withGovernment + ''
        )
        form.set(`beneficialAccredited${count}`, beneficial.accredited + '')
        form.set(`beneficialPhone${count}`, beneficial.phoneNumber + '')
        count++
      }
    }

    // Authorized Signer
    if (this.signerList.length > 0 && !this.retryForm) {
      form.set('signerTotal', this.signerList.length.toString())
      let count = 1
      for (const signer of this.signerList) {
        form.set(`signerName${count}`, signer.name)
        form.set(`signerEmail${count}`, signer.email)
        form.set(`signerWithGovernment${count}`, signer.withGovernment + '')
        form.append(`signerId${count}`, signer.identification)
        form.append(`signerIdBack${count}`, signer.identificationBack)
        count++
      }
    }
    return form
  }

  async updateForm(): Promise<void> {
    if (this.registerBusinessForm.invalid) {
      return this.touchAllRegistrationFormControls()
    }
    if (this.registerBusinessForm.props.miningBitcoin) {
      if (
        !this.registerBusinessForm.props.walletAddress ||
        !this.registerBusinessForm.props.energyBill
      ) {
        toast.warning('Please provide an Energy Bill and Wallet Address.')
        return
      }
    }
    try {
      customerStore.dispatch('loader/show')
      await this.updateCorporate(this.buildFormData())
      this.$router.push({ name: 'verify.zero' })
      toast.success('Congratulation! \nPlease check your email to continue')
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  async submit() {
    if (this.registerBusinessForm.invalid) {
      return this.touchAllRegistrationFormControls()
    }
    if (this.registerBusinessForm.props.miningBitcoin) {
      if (
        !this.registerBusinessForm.props.walletAddress ||
        !this.registerBusinessForm.props.energyBill
      ) {
        return toast.warning(
          'Please provide an Energy Bill and Wallet Address.'
        )
      }
    }
    if (this.signerList.length === 0) {
      return toast.warning("Your 'Authorized Signer List' is empty.")
    }

    //eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
      const key = GOOGLE_RECAPTCHA_SITE_KEY
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute(key, {
          action: 'register'
        })
        .then(async (token: string) => {
          customerStore.dispatch('loader/show')
          const form = this.buildFormData()
          form.set('token', token)
          await this.registerCorporate(form)
          this.$router.push({ name: 'auth.login' })
          customerStore.dispatch('loader/hidden')
          toast.success(
            'Congratulations! \nPlease check your email to continue'
          )
        })
        .catch(e => {
          customerStore.dispatch('loader/hidden')
          console.log(e)
          if (e?.response?.data?.message) {
            toast.error(e.response.data.message)
          } else {
            toast.error('An error has occurred \nPlease try again')
          }
        })
    })
  }
}
