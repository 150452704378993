
import CardBase from '@/components/common/layout/CardBase.vue'
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import formatters from '@/mixins/formatters'
import User from '@/components/admin/dashboard/user.vue'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import UserItem from '@/types/users/UserItem'
const toast = useToast()
const users = namespace('users')
@Options({
  data() {
    return {
      filters: null
    }
  },
  mixins: [formatters],
  components: {
    CardBase,
    User,
    DataTable,
    Column
  }
})
export default class Users extends Vue {
  // eslint-disable-next-line
  filters!: any
  radios = ''
  radioOptions = [
    {
      value: 'ENABLED',
      text: 'Enabled',
      icon: 'fas fa-user-check'
    },
    {
      value: 'DISABLED',
      text: 'Disabled',
      icon: 'fas fa-user-minus'
    },
    {
      value: 'PRE-AUTHORIZED',
      text: 'Pre-Authorized',
      icon: 'fas fa-user-clock'
    },
    {
      value: 'REJECTED',
      text: 'Rejected',
      icon: 'fas fa-user-alt-slash'
    },
    {
      value: 'ALL',
      text: 'All',
      icon: 'fas fa-users'
    }
  ]

  @users.State
  status!: string

  @users.Getter
  users!: UserItem[]

  @users.Action
  public getUsers!: () => Promise<UserItem[]>

  @users.Action
  public getUsersFilter!: (filter: { keyWord: string }) => Promise<object>

  @users.Action
  public enableUser!: (email: string) => Promise<object>

  @users.Action
  // eslint-disable-next-line
  public clearUsers!: () => Promise<any>

  async mounted() {
    try {
      await this.getUsers()
      this.radios = 'ENABLED'
    } catch (error) {
      toast.error(error)
    }
  }

  created() {
    this.initFilters()
  }

  clearFilter() {
    this.initFilters()
  }

  initFilters() {
    this.filters = {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS }
    }
  }

  beforeUnmount() {
    this.clearUsers()
  }

  get getFilteredUsers(): UserItem[] {
    switch (this.radios) {
      case 'ALL':
        return this.users
      case 'ENABLED':
        return this.users.filter(user => user.manualVerificationStep === 2)
      case 'PRE-AUTHORIZED':
        return this.users.filter(user => user.manualVerificationStep === 4)
      case 'DISABLED':
        return this.users.filter(
          user =>
            user.manualVerificationStep !== 3 &&
            user.manualVerificationStep !== 2
        )
      default:
        return this.users.filter(user => user.manualVerificationStep === 3)
    }
  }
}
