import CoingeckoItem from "./CoingeckoItem";

export default class CurrencyItem {
    id!: string;
    description!: string;
    currencyType!: number;
    vault!: boolean;
    stake!: boolean;
    lend!: boolean;
    coingeckoId!: string;
    coingeckoObject!: CoingeckoItem
    exact!: number
}