
import { Vue, Options } from 'vue-class-component'
import httpAdmin from '@/http-common-admin'
import httpCustomer from '@/http-common-customer'

@Options({
  name: 'CsrfToken'
})
export default class CsrfToken extends Vue {
  $isAdmin!: boolean
  token = ''
  mounted() {
    // const instance = this.$isAdmin ? httpAdmin : httpCustomer
    // instance.get('auth/csrfToken').then(res => {
    //   //   const value = res.cookie['_csrf']
    //   //   document.cookie = 'X-XSRF-Token' + '=' + value
    //   console.log(res)
    // })
  }
}
