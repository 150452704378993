import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = { class: "container px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminSubNavbar = _resolveComponent("AdminSubNavbar")
  const _component_TabMenu = _resolveComponent("TabMenu")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_AdminSubNavbar),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_TabMenu, {
        model: _ctx.links,
        activeIndex: "0"
      }, null, 8, ["model"]),
      _createVNode(_component_router_view, { class: "mt-3" })
    ])
  ]))
}