
import { Options, Vue } from 'vue-class-component'
import VueApexCharts from "vue3-apexcharts"
import { namespace } from 'vuex-class'
const tradeChart = namespace('tradeChart')
const assets = namespace('assets')
let quotePrecision = 2
@Options({
    data(){
        return {
            series: [{
                data: [
                ]
            }],
            seriesBar: [{
                data: [
                ]
            }],
            chartOptions: {
                chart: {
                    type: 'candlestick',
                    id: 'candles',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: false,
                        easing: 'easeinout',
                        speed: 0,
                        animateGradually: {
                            enabled: false,
                            delay: 0
                        },
                        dynamicAnimation: {
                            enabled: false,
                            speed: 0
                        }
                    },
                    sparkline: {
                        enabled: false,
                    },
                },
                noData: {
                text: 'Loading...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                grid: {
                    show: false,
                },
                stroke: {
                    width: 1,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false
                    },
                    tooltip: {
                        formatter: (value: number) => {
                            return `
                                ${new Date(value).getDate()< 10 ? "0"+new Date(value).getDate(): new Date(value).getDate()}-${new Date(value).getMonth()< 10 ? "0"+new Date(value).getMonth(): new Date(value).getMonth()}
                                ${new Date(value).getHours()< 10 ? "0"+new Date(value).getHours(): new Date(value).getHours()}:${new Date(value).getMinutes()< 10 ? "0"+new Date(value).getMinutes(): new Date(value).getMinutes()}
                            `
                        }
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        minWidth: 50,
                        maxWidth: 50,
                        show: true,
                        formatter: (value: number) => { return value.toFixed(quotePrecision) }
                    }
                },
                tooltip: {
                    // eslint-disable-next-line
                    custom: function(object: {seriesIndex: any; dataPointIndex: any; w: any}) {
                        // eslint-disable-next-line
                        const formater = (amount: any, decimalCount = quotePrecision, decimal = ".", thousands = ",") => {
                            try {
                            decimalCount = Math.abs(decimalCount);
                            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
                        
                            const negativeSign = amount < 0 ? "-" : "";
                        
                            const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                            const j = (i.length > 3) ? i.length % 3 : 0;
                        
                            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : "");
                            } catch (e) {
                            console.log(e)
                            }
                        }
                        const o = object.w.globals.seriesCandleO[object.seriesIndex][object.dataPointIndex]
                        const h = object.w.globals.seriesCandleH[object.seriesIndex][object.dataPointIndex]
                        const l = object.w.globals.seriesCandleL[object.seriesIndex][object.dataPointIndex]
                        const c = object.w.globals.seriesCandleC[object.seriesIndex][object.dataPointIndex]
                        return (
                        '<div class="apexcharts-tooltip-candlestick">' +
                        '<div><b>Open: <span class="value">' +
                        formater(o) +
                        '</span></b></div>' +
                        '<div><b class="text-success">High: <span class="value">' +
                        formater(h) +
                        '</span></b></div>' +
                        '<div><b class="text-danger">Low: <span class="value">' +
                        formater(l) +
                        '</span></b></div>' +
                        '<div><b>Close: <span class="value">' +
                        formater(c) +
                        '</span></b></div>' +
                        '</div>'
                        )
                    }
                }
            },
            chartOptionsBar: {
                chart: {
                    type: 'bar',
                    brush: {
                        enabled: true,
                        target: 'candles'
                    },
                    selection: {
                        enabled: false,
                        xaxis: {
                            type: 'datetime',
                        },
                        fill: {
                            color: '#ccc',
                            opacity: 0.4
                        },
                        stroke: {
                            color: '#0D47A1',
                        }
                    },
                    animations: {
                        enabled: false,
                        easing: 'easeinout',
                        speed: 0,
                        animateGradually: {
                            enabled: false,
                            delay: 0
                        },
                        dynamicAnimation: {
                            enabled: false,
                            speed: 0
                        }
                    }
                },
                grid: {
                    show: false,
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false
                    },
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        minWidth: 50,
                        maxWidth: 50,
                        show: true,
                        formatter: (value: number) => { return value.toFixed(quotePrecision) }
                    }
                }
            },
        }
    },
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        parity: Object,
        interval: String,
        minInterval: Number
    },
    watch: {
        parity(){
            this.refresh()
            quotePrecision = !this.isFiat(this.parity.quoteCurrency) ? this.cryptosAssets.find((currency) => currency.id == this.parity.quoteCurrency)?.exact : 2
        },
        interval(){
            this.getTradeChart({quoteCurrencyId: this.parity.quoteCurrency, baseCurrencyId: this.parity.baseCurrency, period: this.interval}).then(() => { 
                this.updateChart()
            })
        },
        // getSeriesUpdate(){
        //     this.updateChart()
        // }
    }
})
export default class TradeChart extends Vue {
    interval!: string
    minInterval!: number
    updateChartIntervalId!: number
    // eslint-disable-next-line
    series!: any
    // eslint-disable-next-line
    seriesBar!: any

    parity!: {baseCurrency: string; quoteCurrency: string; pairId: string; allowBuy: boolean; allowSell: boolean}

    @assets.Getter
    statusAssets!: string

    @assets.Getter
    // eslint-disable-next-line
    fiatsAssets!: any

    @assets.Getter
    // eslint-disable-next-line
    cryptosAssets!: any

    @assets.Action
    // eslint-disable-next-line
    getAssets!: () => Promise<any>
    
    @tradeChart.Getter
    // eslint-disable-next-line
    getSeries!: any

    @tradeChart.Getter
    // eslint-disable-next-line
    getSeriesBar!: any

    @tradeChart.Getter
    TradeChartStatus!: string

    @tradeChart.Getter
    getSeriesUpdate!: number

    @tradeChart.Action // eslint-disable-next-line
    public getTradeChart!: (params: object) => Promise<any>
    
    @tradeChart.Action // eslint-disable-next-line
    public connectWs!: (params: object) => void

    @tradeChart.Action // eslint-disable-next-line
    public unmountConnection!: () => void
    
    updateChart(){
        this.series = [...this.getSeries]
        this.seriesBar = [...this.getSeriesBar] 
    }
    isFiat(asset: string): boolean {
        if(this.statusAssets == "success") {
            return this.fiatsAssets.find((currency) => currency.id == asset) ? true : false
        } else {
            return true
        }
    }
    beforeMount() {
        if (this.statusAssets != 'success'){
            this.getAssets()
        } else {
            quotePrecision = !this.isFiat(this.parity.quoteCurrency) ? this.cryptosAssets.find((currency) => currency.id == this.parity.quoteCurrency)?.exact : 2
        }
        this.connectWs({fiat: this.parity.quoteCurrency, crypto: this.parity.baseCurrency})
        this.getTradeChart({quoteCurrencyId: this.parity.quoteCurrency, baseCurrencyId: this.parity.baseCurrency, period: this.interval}).then(() => {
            this.updateChartIntervalId = setInterval(this.updateChart, 5000)
        })
    }
    beforeUnmount () {
        clearInterval(this.updateChartIntervalId)
        this.unmountConnection()
    }
    refresh() {
        this.unmountConnection()
        this.connectWs({fiat: this.parity.quoteCurrency, crypto: this.parity.baseCurrency})
        this.getTradeChart({quoteCurrencyId: this.parity.quoteCurrency, baseCurrencyId: this.parity.baseCurrency, period: this.interval}).then(() => {
            this.updateChart()
        })
    }
}

