import { RouteRecordRaw } from "vue-router";
import ProvidersBase from "@/views/admin/ProvidersBase.vue";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import SitePermissionsEnum from "@/types/users/SitePermissionsEnum";
// import ProviderList from "@/components/admin/providers/ProviderList.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/providers",
        name: "providers",
        component: ProvidersBase,
        beforeEnter: ifAdminAuthenticated,
        redirect: "",
        children: [
            {
                path: "list",
                name: "providers.list",
                component: () => import("@/components/admin/providers/ProviderList.vue"),
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                    permission: SitePermissionsEnum.manageAdmins
                },
            },
            {
                path: "details/:providerId",
                props: true,
                name: "providers.details",
                component: () => import("@/components/admin/providers/ProviderDetails.vue"),
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                    permission: SitePermissionsEnum.manageAdmins
                },
            },

        ],
    },
];
export default routes;