
import formatters from '@/mixins/formatters'
import { useToast } from 'vue-toastification'
import { Options, Vue } from 'vue-class-component'
import CardBase from '@/components/common/layout/CardBase.vue'
import { API_KEY_GOOGLE } from '@/config/api-keys'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import StepOneForm from '@/types/verify/StepOneFormAdmin'
import UserItem from '@/types/users/UserItem'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import RequestStepOne from '@/types/verify/RequestStepOne'
import Two from '@/components/customer/verify/stepTwo.vue'
import { namespace } from 'vuex-class'
const users = namespace('users')
import moment from 'moment'
import { adminStore } from '@/store/admin'

const toast = useToast()
@Options({
  name: 'CreateNewCustomer',
  components: {
    CardBase,
    TabView,
    TabPanel,
    Two
  },
  mixins: [formatters],
  data() {
    return {
      customerId: null,
      isLoading: false,
      isResultAddress: false,
      autocomplete: ''
    }
  }
})
export default class CreateNewCustomer extends Vue {
  apiKeyGoogle = API_KEY_GOOGLE
  stepOneForm!: IFormGroup<StepOneForm>
  phoneNumber!: any
  isResultAddress!: boolean
  autocomplete!: any
  isDisabledSubmitButton!: boolean
  isLoading!: boolean
  customerId!: number
  activeTab = 0

  actions = [
    {
      template: 'PRE-AUTHORIZED',
      text: 'Send pre registration email to customer'
    }
  ]

  @users.Action
  getSpecificUser!: (id: number) => Promise<UserItem>

  @users.Action
  createNewCustomerAdmin!: (req: RequestStepOne) => Promise<number>

  @users.Action
  sendEmailToCustomer!: (data: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customer: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    template: string
  }) => Promise<any>

  mounted() {
    if (this.$route.params['customerId']) {
      this.customerId = Number(this.$route.params['customerId'])
      this.fillForms()
    }
    if (this.$route?.query['tab']) {
      this.activeTab = 1
    }
  }

  async sendEmail(template: string) {
    try {
      adminStore.dispatch('loader/show')
      await this.sendEmailToCustomer({ template, customer: this.customerId })
      toast.success('Email sent')
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.stepOneForm = new RxFormBuilder().formGroup(StepOneForm) as IFormGroup<
      StepOneForm
    >
  }

  async fillForms() {
    if (!this.customerId) return
    adminStore.dispatch('loader/show')
    this.isLoading = true
    try {
      const user = await this.getSpecificUser(this.customerId)
      this.stepOneForm.props.email = user?.email
      this.stepOneForm.props.firstName = user?.firstName
      this.stepOneForm.props.lastName = user?.lastName
      this.stepOneForm.props.nickname = user?.nickname
      this.stepOneForm.props.birthdate = user?.dateOfBirth
      this.stepOneForm.props.occupation = user?.occupation
      this.stepOneForm.props.phoneCountry = user?.phoneCountry
      this.stepOneForm.props.phoneNumberCode = user?.phoneNumberCode
      this.stepOneForm.props.phoneNumber = user?.phoneNumber
      this.isResultAddress = user?.addressLine1 ? true : false
      this.autocomplete = user?.addressLine1
      this.stepOneForm.props.street = user?.street
      this.stepOneForm.props.streetNumber = user?.streetNumber
      this.stepOneForm.props.interiorNumber = user?.interiorNumber
      this.stepOneForm.props.city = user?.city
      this.stepOneForm.props.province = user?.province
      this.stepOneForm.props.postalCode = user?.postalCode
      this.stepOneForm.props.addressLine2 = user?.addressLine2
      this.stepOneForm.props.government = user?.relationWithGovernment
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
      adminStore.dispatch('loader/hidden')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAddress(place: any, response: any): void {
    this.isResultAddress = true
    this.autocomplete = response.formatted_address
    this.setAddressComponents(response.address_components)
  }

  setAddressComponents(components: any[]): void {
    components.forEach(c => {
      if (c.types.includes('street_number')) {
        this.stepOneForm.props.streetNumber = c.long_name
      } else if (
        c.types.includes('locality') &&
        c.types.includes('political')
      ) {
        this.stepOneForm.props.city = c.long_name
      } else if (
        c.types.includes('sublocality') ||
        c.types.includes('neighborhood') ||
        c.types.includes('administrative_area_level_1')
      ) {
        this.stepOneForm.props.province = c.long_name
      } else if (c.types.includes('postal_code')) {
        this.stepOneForm.props.postalCode = c.long_name
      } else if (c.types.includes('route')) {
        this.stepOneForm.props.street = c.long_name
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changePhone(val: any): void {
    this.phoneNumber = val?.target?.value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeCountryInputTel(val: any): void {
    this.stepOneForm.props.phoneNumberCode = val.dialCode
    this.stepOneForm.props.phoneCountry = val.iso2
  }

  resetAutocomplete(): void {
    this.isResultAddress = false
    this.autocomplete = ''
  }

  handleFormWithErrors(): void {
    this.isDisabledSubmitButton = true
    this.touchAllFormControls()
    toast.error('Check all fields!')
    setTimeout(() => (this.isDisabledSubmitButton = false), 3000)
  }

  touchAllFormControls(): void {
    Object.keys(this.stepOneForm.controls).forEach(crl => {
      this.stepOneForm.get(crl).markAsDirty()
    })
  }

  async submitStepOneForm() {
    if (this.stepOneForm.invalid) {
      return this.handleFormWithErrors()
    }
    const req = new RequestStepOne()
    if (this.customerId) {
      req.customerId = this.customerId
    }
    req.dateOfBirth = moment(this.stepOneForm.props.birthdate).format(
      'yyyy-MM-DD hh:mm:ss'
    )
    req.email = this.stepOneForm.props.email
    req.firstName = this.stepOneForm.props.firstName
    req.lastName = this.stepOneForm.props.lastName
    req.phoneCountry = this.stepOneForm.props.phoneCountry
    req.occupation = this.stepOneForm.props.occupation
    req.street = this.stepOneForm.props.street
    req.streetNumber = this.stepOneForm.props.streetNumber
    req.relationWithGovernment = this.stepOneForm.props.government
    req.postalCode = this.stepOneForm.props.postalCode
    req.addressLine2 = this.stepOneForm.props.addressLine2
    req.addressLine1 = this.autocomplete
    req.city = this.stepOneForm.props.city
    req.interiorNumber = this.stepOneForm.props.interiorNumber
    req.province = this.stepOneForm.props.province
    req.phoneNumber = this.phoneNumber
    req.phoneNumberCode = this.stepOneForm.props.phoneNumberCode
    req.nickname = this.stepOneForm.props.nickname
    try {
      adminStore.dispatch('loader/show')
      this.isLoading = true
      this.customerId = await this.createNewCustomerAdmin(req)
      this.$router.push({
        name: 'dashboard.add.user',
        params: { customerId: this.customerId },
        query: { tab: 'documents' }
      })
      this.activeTab = 1
      console.log(this.stepOneForm)
    } catch (error) {
      console.log(error)
      toast.error('An error has occurred \nPlease try again')
    } finally {
      adminStore.dispatch('loader/hidden')
      this.isLoading = false
    }
  }
}
