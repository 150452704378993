
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
const toast = useToast()
//DECORATORS
const profile = namespace('profile')
const twofactorauthentication = namespace('twofactorauthentication')

//OPTION API
@Options({
  data() {
    return {
      code: '',
      blockButton: false
    }
  },
  props: {
    show: Boolean,
    email: String,
    returnCode: Boolean
  },
  emits: ['close', 'success']
})
export default class Verify2fa extends Vue {
  isLoading = false
  public show!: boolean
  public code!: string
  public email!: string
  public returnCode!: boolean
  public blockButton!: boolean

  @profile.Action
  // eslint-disable-next-line
  public verify!: (params: object) => Promise<any>

  async setTextFromClipBoard() {
    try {
      const text = await navigator.clipboard.readText()
      this.code = text
    } catch (error) {
      toast.info('Please enable site permission for Clipboard')
    }
  }

  @twofactorauthentication.Getter
  getTemporalToken!: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterKeyPress(event: any) {
    const keyCode = event.keyCode ? event.keyCode : event.which
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault()
    }
  }
  autoSend() {
    if (this.code.length === 6) {
      this.verifyCode()
    }
  }
  async verifyCode() {
    if (this.returnCode) {
      this.$emit('success', this.code)
      this.$emit('close')
    } else {
      this.blockButton = true
      if (this.isLoading || !this.code || this.code.toString().length < 6) {
        return
      }
      this.isLoading = true
      try {
        const tokenTwoFactor = this.getTemporalToken
        const response = await this.verify({
          twoFactorAuthenticationCode: this.code,
          email: this.email,
          method: 'verify',
          tokenTwoFactor
        })
        this.$emit('success', response)
        this.$emit('close')
      } catch (e) {
        toast.clear()
        if (e?.response?.data?.message) {
          if (e?.response?.data?.message === 'Invalid token') {
            toast.error('The time for session has ended, try login again.')
            this.$emit('close')
          } else {
            toast.error(e.response.data.message)
          }
        } else {
          toast.error('An error has occurred \nPlease try again')
        }
      } finally {
        this.isLoading = false
      }
    }
    this.code = ''
    this.blockButton = false
  }
}
