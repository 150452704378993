import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://restcountries.eu/rest/v2/',
})

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
