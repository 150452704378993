import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "animated fadeIn" }
const _hoisted_2 = {
  key: 0,
  id: "chart-candlestick"
}
const _hoisted_3 = {
  key: 1,
  id: "chart-bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.TradeChartStatus)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_apexchart, {
            type: "candlestick",
            height: "148%",
            options: _ctx.chartOptions,
            series: _ctx.series
          }, null, 8, ["options", "series"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.TradeChartStatus)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_apexchart, {
            type: "bar",
            height: "70%",
            options: _ctx.chartOptionsBar,
            series: _ctx.seriesBar
          }, null, 8, ["options", "series"])
        ]))
      : _createCommentVNode("", true)
  ]))
}