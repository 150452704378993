
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import TwoFactorAuthenticator from '@/components/customer/modals/twoFactorAuthenticator.vue'
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
const profile = namespace('profile')
@Options({
  data() {
    return {
      links: []
    }
  },
  components: {
    TwoFactorAuthenticator
  }
})
export default class SiteAdminNavbar extends Vue {
  public links = [
    /*{ text: 'Users', routeName: 'user.list' }*/
  ]
  public modalShow = false
  @profile.State
  public status!: object

  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: any

  @profile.Getter
  public emailProfile!: string

  @profile.Action
  public logout!: () => void

  async signOut() {
    await this.logout()
  }
  enable2fa() {
    this.modalShow = !this.modalShow
  }
}
