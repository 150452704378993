<template>
  <div
    v-if="!config.maintenance"
    class="default-layout"
    style="overflow-y: hidden"
  >
    <div class="text-right bg-primary">
      <a
        target="_blank"
        href="https://deskv2.satstreet.com/auth/login"
        class="text-white mx-2"
      >
        Try the new version
      </a>
    </div>
    <SiteNavbar />
    <div style="position: relative; height: 90%; overflow-y: auto">
      <SiteContent />
      <div class="separator"></div>
    </div>
    <Sidebar
      v-model:visible="visibleFull"
      :baseZIndex="1000"
      position="full"
      :showCloseIcon="false"
    >
      <div class="row justify-content-center">
        <div class="col">
          <img
            src="@/assets/svg/logo-white.svg"
            class="img-fluid logo"
            alt="Satstreet"
          />
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center text-center mt-5"
      >
        <h3 class="font-weight-bold main-message">Still there?</h3>
        <br />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <p class="font-weight-bold text-muted">
          Your session will be closed due to inactivity.
          {{ formatTime(min, sec) }}
        </p>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <button class="btn btn-lg btn-success btn-block continue-button">
          <!-- <i class="fas fa-play"></i> -->
          <img class="logo-button" src="@/assets/logo_blancoo.png" alt="logo" />
        </button>
      </div>
      <div
        class="d-flex justify-content-center align-items-center text-center mt-3"
      >
        <h2><i class="fas fa-ghost fa-lg"></i></h2>
      </div>
    </Sidebar>
    <div class="row justify-content-md-center">
      <SiteFooter />
    </div>
  </div>
  <div v-else>
    <UnderMaintenance />
  </div>
</template>

<script>
import SiteContent from '@/components/common/layout/siteContent.vue'
import SiteNavbar from '@/components/common/layout/siteNavbar.vue'
import SiteFooter from '@/components/common/layout/siteFooter.vue'
import UnderMaintenance from '@/components/customer/errors/UnderMaintenance.vue'
import { mapState } from 'vuex'
import { useToast } from 'vue-toastification'
import { TERMINTATE_SESSION_MIN } from '@/config/config'
import { customerStore } from '@/store/customer'
import Sidebar from 'primevue/sidebar'
const toast = useToast()
export default {
  name: 'DefaultLayout',
  components: {
    SiteContent,
    SiteNavbar,
    SiteFooter,
    UnderMaintenance,
    Sidebar
  },
  data() {
    return {
      intervalTerminateSession: null,
      min: 0,
      sec: 0,
      timeLeft: TERMINTATE_SESSION_MIN * 60,
      visibleFull: false,
      excludedRoutes: [
        'auth.login',
        'auth.register',
        'auth.register.business',
        'auth.confirm',
        'auth.forgot',
        'auth.reset',
        'auth.resend'
      ]
    }
  },
  computed: {
    ...mapState({
      config: state => state.profile.config
    })
  },
  methods: {
    formatTime(min, sec) {
      return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
    },
    updateCountDown() {
      if (this.excludedRoutes.includes(this.$route.name)) {
        return
      }
      this.checkTimer()
      this.min = Math.floor(this.timeLeft / 60)
      this.sec = this.timeLeft % 60
      this.timeLeft--
    },
    resetCountDown() {
      this.timeLeft = TERMINTATE_SESSION_MIN * 60
      this.min = Math.floor(this.timeLeft / 60)
      this.sec = this.timeLeft % 60
      clearInterval(this.intervalTerminateSession)
      this.intervalTerminateSession = setInterval(this.updateCountDown, 1000)
      this.visibleFull = false
    },
    async checkTimer() {
      if (this.min < 2 && !this.visibleFull) {
        this.visibleFull = true
      }
      if (this.timeLeft !== 0) return
      clearInterval(this.intervalTerminateSession)
      toast.clear()
      toast.warning('Your session was closed due to inactivity.', {
        timeout: false
      })
      await customerStore.dispatch('profile/logout')
    }
  },
  mounted() {
    this.min = Math.floor(this.timeLeft / 60)
    this.sec = this.timeLeft % 60
    this.intervalTerminateSession = setInterval(this.updateCountDown, 1000)

    //Adding event
    window.addEventListener('click', this.resetCountDown)
    window.addEventListener('focus', this.resetCountDown)
    window.addEventListener('wheel', this.resetCountDown)
    window.addEventListener('keydown', this.resetCountDown)
  }
}
</script>
<style lang="scss" scoped>
.default-layout {
  background-image: url(../assets/fondo_blanco.png);
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 0;
  top: 0;
}
.separator {
  margin-top: 0px;
}
.main-message {
  margin-top: 5rem;
}
.logo-button {
  max-height: 30px;
}
.continue-button {
  max-width: 400px;
}
</style>
