<template>
  <div class="default-layout">
    <OnBehalfOfNavbar />
    <div class="row justify-content-md-center">
      <SiteContent/>
    </div>
    <div class="row justify-content-md-center">
      <SiteFooter/>
    </div>
  </div>
</template>

<script>
import SiteContent from "@/components/common/layout/siteContent.vue";
import OnBehalfOfNavbar from "@/components/admin/layout/onBehalfOfNavbar.vue";
import SiteFooter from "@/components/common/layout/siteFooter.vue";
export default {
  name: "DefaultAdminLayout",
  components: {
    SiteContent,
    OnBehalfOfNavbar,
    SiteFooter,
  },
};
</script>
<style lang="scss" scoped>
.default-layout {
  background-image: url(../assets/fondo_blanco.png);
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 0;
  top: 0;
}
</style>
