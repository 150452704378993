import { vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("input", {
    id: "__CSRFToken",
    type: "hidden",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.token = $event))
  }, null, 512)), [
    [_vModelText, _ctx.token]
  ])
}