
import InputNumber from 'primevue/inputnumber'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import { Options, Vue } from 'vue-class-component'
import DepositForm from '@/types/pending-deposit/DepositForm'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import currenciesMixin from '@/mixins/currencies'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import amountInput from '@/components/common/inputs/amountInput.vue'
import moment from 'moment'
import { Money3Component } from 'v-money3'
import { FILE_SIZE_LIMIT_MB } from '@/config/config'
import { adminStore } from '@/store/admin'
const onBehalfOf = namespace('onBehalfOfUser')
const deposits = namespace('deposits')
const currenciesSatStreet = namespace('currenciesSatStreet')
const prices = namespace('prices')
const toast = useToast()

@Options({
  name: 'PendingDepositsForm',
  mixins: [currenciesMixin],
  components: {
    money3: Money3Component,
    amountInput,
    InputNumber
  },
  data() {
    return {
      currentCurrency: null
    }
  }
})
export default class PendingDepositsForm extends Vue {
  currencyPrice = { usd: 0, cad: 0 }
  public depositForm!: IFormGroup<DepositForm>
  public isDisabledSubmitButton = true
  public currentCurrency!: CurrencyItem | undefined

  config = {
    decimal: '.',
    thousands: ',',
    precision: 12,
    disableNegative: true,
    prefix: ''
  }

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem>

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  @onBehalfOf.State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user!: any

  @onBehalfOf.Action
  getUser!: (userId: number) => Promise<void>

  @prices.Getter
  // eslint-disable-next-line
  prices!: any

  @prices.Action
  getAssetPriceByDate!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    asset: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    date: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.createNewForm()
  }

  mounted() {
    const userId = Number(this.$route.params.customerId)
    this.getUser(userId).then(() => {
      this.selectCustomer()
      this.fetchCurrencies()
    })
  }

  @deposits.Action
  public postDeposit!: (deposit: DepositForm) => Promise<void>

  checkFileName(file: File): boolean {
    const extension = file.name.split('.').pop() || ''
    return ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(extension)
  }

  checkFileType(type) {
    return [
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'application/pdf'
    ].includes(type)
  }

  checkFileSize(file: File): boolean {
    const size = parseFloat((file.size / 1024 / 1024).toFixed(4))
    return size > FILE_SIZE_LIMIT_MB
  }

  onEntryFile(event): void {
    const file = event.target.files[0]
    if (!file) return

    if (!this.checkFileType(file.type)) {
      toast.warning('Invalid file type.')
      return
    }

    if (!this.checkFileName(file)) {
      toast.warning('Invalid file type.')
      return
    }

    if (this.checkFileSize(file)) {
      toast.warning('File oversize. Limit 10MB.')
      return
    }
    this.depositForm.props.file = event.target.files[0]
  }

  createNewForm(): void {
    this.depositForm = new RxFormBuilder().formGroup(DepositForm) as IFormGroup<
      DepositForm
    >
    this.depositForm.props.createdAt = moment(new Date()).format(
      'yyyy-MM-DDTHH:mm'
    )
  }

  async getFiatPrices() {
    const { data } = await this.getAssetPriceByDate({
      date: this.depositForm.props.createdAt,
      asset: '' + this.currentCurrency?.coingeckoId
    })
    this.currencyPrice.usd = data.market_data.current_price['usd']
    this.currencyPrice.cad = data.market_data.current_price['cad']
    if (this.depositForm.props.amount) {
      this.inputAmount()
    }
  }

  async inputAmount(e?) {
    if (e.target.value) {
      this.depositForm.props.amount = parseFloat(
        e.target.value.replace(/,/g, '')
      )
    }
    try {
      this.depositForm.props.usdExchangeValue =
        this.depositForm.props.amount * this.currencyPrice.usd

      this.depositForm.props.cadExchangeValue =
        this.depositForm.props.amount * this.currencyPrice.cad
    } catch (error) {
      this.depositForm.props.usdExchangeValue = undefined
      this.depositForm.props.cadExchangeValue = undefined
    }
  }

  getExchangeValue(crypto: string, local: string): number {
    return this.prices[crypto + local]
      ? this.prices[crypto + local].higher.spot * this.depositForm.props.amount
      : Number(0)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectCurrency(e: any): void {
    const value = e.target.value
    this.currentCurrency = this.getCurrencies.find(c => c.id === value)
    this.getFiatPrices()
  }

  selectCustomer(): void {
    const userId = Number(this.$route.params.customerId)
    this.depositForm.props.customerId = userId
  }

  async submitForm(): Promise<void> {
    if (this.depositForm.controls.amount.invalid) {
      return this.handleFormWithErrors()
    }
    if (this.depositForm.invalid) {
      if (this.currentCurrency?.currencyType === 1) {
        if (
          !this.depositForm.controls.cadExchangeValue.invalid ||
          !this.depositForm.controls.usdExchangeValue.invalid
        ) {
          return this.handleFormWithErrors()
        }
      } else {
        return this.handleFormWithErrors()
      }
    }
    const deposit = new DepositForm()
    deposit.file = this.depositForm.props.file
    deposit.amount = this.depositForm.props.amount
    deposit.createdAt = moment(this.depositForm.props.createdAt).format(
      'YYYY-MM-DD HH:mm:ss'
    )
    deposit.currencyId = this.depositForm.props.currencyId
    deposit.customerId = this.depositForm.props.customerId
    deposit.usdExchangeValue = this.depositForm.props.usdExchangeValue
    deposit.cadExchangeValue = this.depositForm.props.cadExchangeValue
    deposit.transactionAuthCode = this.depositForm.props.transactionAuthCode
    try {
      adminStore.dispatch('loader/show')
      await this.postDeposit(deposit)
      toast.success('Deposit registed!')
      this.cleanForm()
      this.selectCustomer()
    } catch (error) {
      console.log({ error: error })
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  cleanForm(): void {
    this.createNewForm()
  }

  handleFormWithErrors(): void {
    this.isDisabledSubmitButton = true
    this.touchAllFormControls()
    setTimeout(() => (this.isDisabledSubmitButton = false), 3000)
  }

  touchAllFormControls(): void {
    Object.keys(this.depositForm.controls).forEach(crl => {
      this.depositForm.get(crl).markAsDirty()
    })
  }
}
