import { email, required } from "@rxweb/reactive-forms";
export default class LoginForm {
    @required()
    @email({ message: "Invalid email" })
    email!: string;

    @required()
    password!: string;

    token!: string;
}
