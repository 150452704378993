import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import { useToast } from "vue-toastification"
import UserItem from '@/types/users/UserItem'
import RequestEnableUser from '@/types/users/RequestEnableUser'
const toast = useToast()
@Module({ namespaced: true, name: '' })
class Users extends VuexModule {
  public status!: string
  public data!: object
  public error!: object
  public user!: UserItem

  @Mutation
  public onUsers(): void {
    this.status = "fetching"
    this.error = {}
    this.data = {}
  }

  @Mutation
  public onUsersSuccess(data: object): void {
    this.status = "success"
    this.error = {}
    this.data = data
  }

  @Mutation
  public onUsersError(err: object): void {
    this.status = "error"
    this.error = err
  }

  @Action({ rawError: true })
  public getUsers() {
    return new Promise((resolve, reject) => {
      this.context.commit("onUsers")
      http
        .get('customer/admin/list')
        .then(response => {
          this.context.commit("onUsersSuccess", response.data)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onUsersError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public getUsersFilter({ keyWord = '' }) {
    return new Promise((resolve, reject) => {
      this.context.commit("onUsers")
      http
        .get(`customer/admin/search/${keyWord}`)
        .then(response => {
          this.context.commit("onUsersSuccess", response.data)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onUsersError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public enableUser(req: RequestEnableUser) {
    return new Promise((resolve, reject) => {
      http
        .post('admin/validateCustomerAccount', req)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  public get users(): object {
    return this.data
  }

  @Mutation
  public setUser(user: UserItem): void {
    this.user = user;
  }

  public get selectedUser(): UserItem {
    return this.user
  }

  @Action({ rawError: true })
  public getSpecificUser(id: number | string): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      http
        .get(`customer/admin/get/${id}`)
        .then(response => {
          resolve(response.data)
          this.context.commit("setUser", response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}
export default Users