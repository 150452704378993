import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import BankAccountItem from "@/types/bank-account/BankAccountItem"
import http from "@/http-common-customer"
import BankAccountForm from "@/types/bank-account/BankAccountForm";
import CryptoAccountForm from "@/types/bank-account/CryptoAccountForm";
@Module({ namespaced: true, name: '' })
export default class BankAccount extends VuexModule {
    public error!: object
    public status!: string;
    public accounts!: BankAccountItem[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public account!: any

    @Mutation
    public onAccountFetching(): void {
        this.status = 'fetching'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onAccountFetchingSuccess(payload: any): void {
        this.accounts = payload.data;
        this.error = {}
        this.status = 'success'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onAccountFetchingError(payload: any): void {
        this.accounts = [];
        this.error = payload
        this.status = 'error'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onAccountFetchingSpecificSuccess(payload: any): void {
        this.account = payload.data;
        this.error = {}
        this.status = 'success'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onAccountFetchingSpecificError(payload: any): void {
        this.error = payload
        this.status = 'error'
    }

    @Action({ rawError: true })
    public fetchBankAccounts() {
        return new Promise((resolve, reject) => {
            this.context.commit("onAccountFetching")
            http
                .get('customer-bank-accounts/list')
                .then(response => {
                    this.context.commit("onAccountFetchingSuccess", response)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onAccountFetchingError", error)
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public postBankAccount(account: BankAccountForm) {
        return new Promise((resolve, reject) => {
            http
                .post('customer-bank-accounts/register', account)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }


    @Action({ rawError: true })
    public postCryptoAccount(account: CryptoAccountForm | any) {
        return new Promise((resolve, reject) => {
            http
                .post('customer-bank-accounts/register', account)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public fetchSpecificAccount(id: number | string) {
        return new Promise((resolve, reject) => {
            http
                .get(`customer-bank-accounts/details/${id}`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    public get getAccounts(): BankAccountItem[] {
        return this.accounts
    }

    public get getAccount() {
        return this.account
    }

    public get getAccountStatus(): string {
        return this.status
    }

}