
import TheBanner from './components/TheBanner.vue'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Payload } from '@/types'
import { useToast } from 'vue-toastification'
import { GOOGLE_RECAPTCHA_SITE_KEY } from '@/config/api-keys'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import LoginForm from '@/types/auth/LoginForm'
import Verify2fa from '@/components/customer/modals/verify2fa.vue'
import Dialog from 'primevue/dialog'
import Password from 'primevue/password'
import { customerStore } from '@/store/customer'
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
const toast = useToast()
const profile = namespace('profile')
@Options({
  data() {
    return {
      showVerify: false,
      blockButton: false
    }
  },
  components: {
    TheBanner,
    Verify2fa,
    Dialog,
    Password
  }
})
export default class Login extends Vue {
  loginForm!: IFormGroup<LoginForm>
  isReadOnly2FA = false
  email!: string
  password!: string
  token!: string
  showVerify!: boolean
  modalVisible = false
  isSmall = false
  blockButton!: boolean
  // eslint-disable-next-line
  $v!: any
  @profile.State
  public status!: string

  @profile.Action
  public login!: (payload: Payload) => Promise<object>

  // eslint-disable-next-line
  constructor(pros: any) {
    super(pros)
    this.loginForm = new RxFormBuilder().formGroup(LoginForm) as IFormGroup<
      LoginForm
    >
  }

  mounted() {
    localStorage.clear()
    this.checkIsMobile()
  }

  checkIsMobile() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    if (vw < 500) {
      this.isSmall = true
    }
  }

  toggleModal(): void {
    this.modalVisible = !this.modalVisible
  }

  handleLoginError(statusCode: number): void {
    switch (statusCode) {
      case 301:
        toast.warning('You have not yet confirmed your email!')
        this.$router.push({
          name: `auth.resend`,
          params: { email: this.email }
        })
        break
      case 500:
        toast.warning('Internal server error')
        break
      default:
        toast.warning('Somthing went wrong, try later')
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSuccessResponse(response: any): void {
    if (response.status == 201) {
      customerStore.commit(
        'twofactorauthentication/setTemporalToken',
        response.data.tokenTwoFactor
      )
      if (response.data.twoFactorAuthenticationEnabled) {
        this.showVerify = true
      } else {
        toast.clear()
        toast.success('Welcome to Satstreet!')
        if (response.data.customer.isActive) {
          this.$router.push({ name: 'Home' })
        } else {
          this.$router.push({ name: 'verify.zero' })
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  success2fa(response: any): void {
    toast.success('Welcome to Satstreet!')
    if (response.customer.isActive) {
      this.$router.push({ name: 'Home' })
    } else {
      this.$router.push({ name: 'verify.zero' })
    }
  }
  onClick(e) {
    customerStore.dispatch('loader/show')
    e.preventDefault()
    localStorage.removeItem('access-token')
    localStorage.removeItem('refresh-token')
    this.blockButton = true
    //eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
      const key = GOOGLE_RECAPTCHA_SITE_KEY
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute(key, {
          action: 'submit'
        })
        .then(async (token: string) => {
          this.loginForm.props.token = token
          await this.submit()
          this.blockButton = false
        })
        .catch(() => {
          customerStore.dispatch('loader/hidden')
        })
    })
  }
  close2fa() {
    this.showVerify = false
  }
  async submit() {
    if (this.loginForm.invalid) {
      customerStore.dispatch('loader/hidden')
      return toast.error('Check all fields!')
    }
    try {
      // eslint-disable-next-line
      const response: any = await this.login({
        email: this.loginForm.props.email.toLowerCase(),
        password: this.loginForm.props.password,
        token: this.loginForm.props.token,
        isReadOnly2FA: this.isReadOnly2FA
      })
      this.handleSuccessResponse(response)
      customerStore.dispatch('loader/hidden')
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }
}
