import { maxLength, maxNumber, minLength, numeric, NumericValueType, required } from "@rxweb/reactive-forms";
import CurrencyItem from "../currencies/CurrencyItem";
import InstrumentItem from "./InstrumentItem";
export default class PairItem {
    @maxLength({ value: 10, message: "Max length 10" })
    id!: string

    @required()
    baseCurrencyId!: string
    baseCurrency!: CurrencyItem

    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: "Invalid number" })
    minBase!: number

    @required()
    quoteCurrencyId!: string
    quoteCurrency!: CurrencyItem

    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: "Invalid number" })
    minQuote


    @required()
    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: "Invalid number" })
    @maxNumber({ value: 1, message: "Invalid number" })
    baseCurrencyRevenuePercentage!: number

    @required()
    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: "Invalid number" })
    @maxNumber({ value: 1, message: "Invalid number" })
    quoteCurrencyRevenuePercentage!: number

    @maxLength({ value: 25, message: "Max length 25." })
    @minLength({ value: 1, message: "Min length 1." })
    automaticExecutionProviderName!: string

    instrumentId!: number


    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: "Invalid number" })
    levelLower!: number

    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, message: "Invalid number" })
    levelHigher!: number

    instrument!: InstrumentItem
    allowSell!: boolean
    allowAutomaticTrade!: boolean
    allowBuy!: boolean
    isActive!: boolean
    createdAt!: Date
}