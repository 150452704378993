import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = { class: "container px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminSubNavbar = _resolveComponent("AdminSubNavbar")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_AdminSubNavbar),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, {
        key: _ctx.$route.path
      }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: "slide-fade" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}