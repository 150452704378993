
import formatters from '@/mixins/formatters'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { customerStore } from '@/store/customer'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useToast } from 'vue-toastification'
import BeneficialOwner from '@/types/users/BeneficialOwner'
import BeneficialOwnerForm from '@/types/auth/BeneficialOwnerForm'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import Slider from 'primevue/slider'
import ConfirmDialog from 'primevue/confirmdialog'
import InputNumber from 'primevue/inputnumber'
import { FILE_SIZE_LIMIT_MB } from '@/config/config'
import FileUser from '@/types/users/FileUser'
const downloads = namespace('downloads')

const ownerSigner = namespace('ownerSigner')
const profile = namespace('profile')
const toast = useToast()

@Options({
  name: 'CompanyProfile',
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    Slider,
    ConfirmDialog,
    InputNumber
  }
})
export default class BeneficialForm extends Vue {
  @profile.Getter
  public userProfile!: UserItem
  beneficialForm!: IFormGroup<BeneficialOwnerForm>
  isEditing = false

  currentBeneficial!: BeneficialOwner | undefined

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkToken!: () => Promise<any>

  @ownerSigner.Action
  public registerOwner!: (form: FormData) => Promise<void>

  @ownerSigner.Action
  public updateOwner!: (form: FormData) => Promise<void>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.beneficialForm = new RxFormBuilder().formGroup(
      BeneficialOwnerForm
    ) as IFormGroup<BeneficialOwnerForm>
  }

  mounted() {
    this.checkToken().then(() => {
      if (this.$route.name === 'edit.beneficial') {
        this.isEditing = true
        const beneficialId = Number(this.$route.params.beneficialId.toString())
        this.findBeneficial(beneficialId)
      }
    })
  }

  findBeneficial(id: number): void {
    this.currentBeneficial = this.userProfile.corporate.beneficialOwners.find(
      b => b.id === id
    )
    this.fillForm()
  }

  fillForm(): void {
    this.beneficialForm.props.name = this.currentBeneficial?.name
    this.beneficialForm.props.email = this.currentBeneficial?.email
    this.beneficialForm.props.accredited = this.currentBeneficial?.accredited
    this.beneficialForm.props.percentage = this.currentBeneficial?.equity
    this.beneficialForm.props.phoneNumber = this.currentBeneficial?.phoneNumber
    this.beneficialForm.props.withGovernment = this.currentBeneficial
      ?.relatedGovernment
      ? true
      : false
    this.beneficialForm.props.identification = this.currentBeneficial?.identificationFile
    this.beneficialForm.props.identificationBack = this.currentBeneficial?.identificationBackFile
  }

  checkFileName(file: File): boolean {
    const extension = file.name.split('.').pop() || ''
    return ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(extension)
  }

  checkFileType(type) {
    return [
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'application/pdf'
    ].includes(type)
  }

  checkFileSize(file: File): boolean {
    const size = parseFloat((file.size / 1024 / 1024).toFixed(4))
    return size > FILE_SIZE_LIMIT_MB
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEntryFile(e: any, field: string): void {
    const file = e.target.files[0]
    if (!file) return

    if (!this.checkFileType(file.type)) {
      toast.warning('Invalid file type.')
      return
    }

    if (!this.checkFileName(file)) {
      toast.warning('Invalid file type.')
      return
    }

    if (this.checkFileSize(file)) {
      toast.warning('File oversize. Limit 10MB.')
      return
    }
    switch (field) {
      case 'idBeneficial':
        this.beneficialForm.props.identification = file
        break
      case 'idBeneficialBack':
        this.beneficialForm.props.identificationBack = file
        break
      default:
        break
    }
  }

  submitForm(): void {
    this.$confirm.require({
      message:
        'Are you sure?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.saveBeneficialOwner()
      }
    })
  }

  async saveBeneficialOwner(): Promise<void> {
    if (this.beneficialForm.invalid) {
      return this.touchAllBeneficialFormControls()
    }
    try {
      customerStore.dispatch('loader/show')
      const form = new FormData()
      if (this.isEditing)
        form.set(
          'beneficialOwnerId',
          this.currentBeneficial?.id.toString() || ''
        )

      form.set('name', this.beneficialForm.props.name)
      form.set('percentage', this.beneficialForm.props.percentage.toString())
      form.set('email', this.beneficialForm.props.email)
      form.set('beneficialId', this.beneficialForm.props.identification)
      form.set('beneficialIdBack', this.beneficialForm.props.identificationBack)
      form.set(
        'relatedWithGovernment',
        this.beneficialForm.props.withGovernment
      )
      form.set('accreditedInvestor', this.beneficialForm.props.accredited)
      form.set('phone', this.beneficialForm.props.phoneNumber)
      form.set('customerId', this.userProfile.id.toString())
      this.isEditing
        ? await this.updateOwner(form)
        : await this.registerOwner(form)
      toast.success('Beneficial Saved!')
      this.$router.push({ name: 'Home' })
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  clearBeneficialOwnerForm(): void {
    this.beneficialForm = new RxFormBuilder().formGroup(
      BeneficialOwnerForm
    ) as IFormGroup<BeneficialOwnerForm>
  }

  touchAllBeneficialFormControls(): void {
    Object.keys(this.beneficialForm.controls).forEach(crl => {
      this.beneficialForm.get(crl).markAsDirty()
    })
  }
}
