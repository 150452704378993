
import { Options, Vue } from 'vue-class-component'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import Dialog from 'primevue/dialog'
import AdminItem from '@/types/users/AdminItem'
import SitePermissionsEnum from '@/types/users/SitePermissionsEnum'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import { useToast } from 'vue-toastification'
import Password from 'primevue/password'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { adminStore } from '@/store/admin'
import CardBase from '@/components/common/layout/CardBase.vue'
const toast = useToast()
const users = namespace('users')

@Options({
  name: 'GrandPermissionsToAdmins',
  components: {
    Dialog,
    Password,
    DataTable,
    Column,
    CardBase
  },
  mixins: [formatters],
  data() {
    return {
      SitePermissionsEnum: SitePermissionsEnum
    }
  }
})
export default class GrandPermissionsToAdmins extends Vue {
  showModal = false
  isLoading = false
  mainSearch = ''

  adminForm!: IFormGroup<AdminItem>

  @users.Getter
  getAdminList!: AdminItem[]

  @users.Action
  getAdmins!: () => Promise<AdminItem[]>

  @users.Action
  addNewAdmin!: (admin: AdminItem) => Promise<AdminItem>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.adminForm = new RxFormBuilder().formGroup(AdminItem) as IFormGroup<
      AdminItem
    >
  }

  mounted() {
    this.getAdmins()
  }

  get getFilteredItems(): AdminItem[] {
    return this.getAdminList
  }

  async submitForm(): Promise<void> {
    this.isLoading = true
    try {
      adminStore.dispatch('loader/show')
      const admin = new AdminItem()
      admin.email = this.adminForm.props.email
      admin.password = this.adminForm.props.password
      admin.lastName = this.adminForm.props.lastName
      admin.firstName = this.adminForm.props.firstName
      const data = await this.addNewAdmin(admin)
      this.resetForm()
      this.showModal = false
      this.$router.push({
        name: 'permissions.admin.details',
        params: { id: data.id }
      })
      toast.success('Admin saved!')
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
      this.isLoading = false
    }
  }

  resetForm(): void {
    this.adminForm.props.firstName = ''
    this.adminForm.props.lastName = ''
    this.adminForm.props.email = ''
    this.adminForm.props.password = ''
  }
}
