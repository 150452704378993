import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-admin'


@Module({ namespaced: true, name: '' })
class Verify extends VuexModule {

    @Action({ rawError: true })
    public stepTwo(file: File) {
        return new Promise((resolve, reject) => {
            const params = new FormData()
            params.append("file", file)
            params.append("typeOfDocument", "documentId")
            params.append("customerId", file['customerId']);
            http
                .post(`auth/admin/uploadFile`, params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public stepTwoBack(file: File) {
        return new Promise((resolve, reject) => {
            const params = new FormData()
            params.append("file", file)
            params.append("typeOfDocument", "documentIdBack")
            params.append("customerId", file['customerId']);
            http
                .post(`auth/admin/uploadFile`, params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public stepThree(file: File) {
        return new Promise((resolve, reject) => {
            const params = new FormData()
            params.append("file", file)
            params.append("typeOfDocument", "documentAddress")
            params.append("customerId", file['customerId']);
            http
                .post(`auth/admin/uploadFile`, params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default Verify