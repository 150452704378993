
import { Money3Component } from 'v-money3'
import { ref } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import Calendar from 'primevue/calendar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ProgressBar from 'primevue/progressbar'
import UserItem from '@/types/users/UserItem'
import CustomerSelect from './customerSelect.vue'
import formatters from '@/mixins/formatters'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import amountInput from '@/components/common/inputs/amountInput.vue'
import { useToast } from 'vue-toastification'
import { adminStore } from '@/store/admin'
import exactMath from 'exact-math'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import InputNumber from 'primevue/inputnumber'
import moment from 'moment'
const toast = useToast()
const users = namespace('users')
const pairs = namespace('pairs')
const orders = namespace('orders')
const providers = namespace('providers')
const balance = namespace('balance')
const prices = namespace('prices')
const currenciesSatStreet = namespace('currenciesSatStreet')
@Options({
  data() {
    return {
      selectedCustomer: ref(null),
      pairId: '',
      sideId: '',
      amount: null,
      unitPrice: null,
      total: null,
      totalCostCad: 0,
      providerOrders: [],
      currentBaseCurrency: null,
      currentQuoteCurrency: null,
      fillingOrder: {
        providerId: null,
        amount: null,
        unitCost: null,
        externalProviderOrderId: '',
        settlementDatetime: new Date()
      }
    }
  },
  mixins: [formatters],
  components: {
    InputNumber,
    CustomerSelect,
    Calendar,
    DataTable,
    Column,
    IconsCurrency,
    ProgressBar,
    money3: Money3Component,
    amountInput
  },
  watch: {
    pairId: function(pairId) {
      this.providerOrders = []
      this.getProvidersForPairId(pairId)
    },
    // eslint-disable-next-line
    sideId: function(pairId) {
      this.providerOrders = []
    }
  }
})
export default class CreateOrder extends Vue {
  createAt = moment(new Date()).format('yyyy-MM-DDTHH:mm')
  hasOrderCreateAtError = false
  notifyByEmail = false
  selectedCustomer!: UserItem
  pairId!: string
  sideId!: string
  amount!: number
  total!: number
  unitPrice!: number
  totalCostCad!: number
  // eslint-disable-next-line
  providerOrders!: any[]
  // eslint-disable-next-line
  fillingOrder: any
  // eslint-disable-next-line
  dataPrice: any = undefined

  currentBaseCurrency!: CurrencyItem
  currentQuoteCurrency!: CurrencyItem

  config = {
    decimal: '.',
    thousands: ',',
    precision: 12,
    disableNegative: true,
    prefix: ''
  }

  @users.Getter
  public selectedUser!: UserItem
  @users.Action
  public getSpecificUser!: (id: number) => Promise<UserItem>

  @pairs.Getter
  // eslint-disable-next-line
  pairs!: any[]
  @pairs.Action
  // eslint-disable-next-line
  getPairs!: () => Promise<any[]>

  @orders.Getter
  public currentCreateOrderStatus!: string
  @orders.Action
  // eslint-disable-next-line
  createOrder!: (payload) => any

  @providers.Getter
  // eslint-disable-next-line
  providers!: any[]
  @providers.Action
  // eslint-disable-next-line
  getProvidersForPairId!: (pairId: string) => Promise<any[]>

  @balance.Action
  // eslint-disable-next-line
  public getBalance!: (customerId: number, currency?: string) => Promise<any>

  @prices.Action
  getAssetPriceByDate!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    asset: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    date: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem>

  @currenciesSatStreet.Getter
  getStatusCurrenciesSatstreet!: string

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  exponentialToDecimal!: (n) => number

  mounted() {
    this.fetchCurrencies()
    this.getData()
  }

  changePair(): void {
    const pair = this.pairs.find(p => p.id === this.pairId)
    const base = this.getCurrencies.find(c => c.id === pair.baseCurrencyId)
    const quote = this.getCurrencies.find(c => c.id === pair.quoteCurrencyId)

    this.currentBaseCurrency = base as CurrencyItem
    this.currentQuoteCurrency = quote as CurrencyItem

    this.getCadPrice()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAmount(e): void {
    this.amount = parseFloat(e.target.value.replace(/,/g, ''))
    this.calculateCadReferenceValue()
  }

  async getCadPrice() {
    this.dataPrice = await this.getAssetPriceByDate({
      date: new Date().toString(),
      asset: this.currentBaseCurrency.coingeckoId
    })
    if (this.amount) {
      this.calculateCadReferenceValue()
    }
  }

  get getUnitPrice(): number {
    try {
      if (!this.total && !this.amount && this.amount > 0 && this.total > 0) {
        return 0
      }
      const result = exactMath.div(this.total, this.amount)
      this.unitPrice = result
      return result
    } catch {
      this.unitPrice = 0
      return 0
    }
  }

  async calculateCadReferenceValue(): Promise<void> {
    try {
      if (!this.pairId) return
      if (this.currentBaseCurrency?.currencyType === 0) {
        this.totalCostCad = exactMath.mul(
          this.dataPrice.data.market_data.current_price.cad,
          this.amount
        )
      } else {
        this.totalCostCad = 0
      }
    } catch (error) {
      console.log(error)
      this.totalCostCad = 0
    }
  }

  async getData(): Promise<void> {
    try {
      adminStore.dispatch('loader/show')
      // eslint-disable-next-line
      const promises: Promise<any>[] = []
      promises.push(this.getSpecificUser(Number(this.$route.params.customerId)))
      promises.push(this.getPairs())
      await Promise.all(promises)
    } catch (e) {
      toast.error(e.response.data.message)
      console.log(e)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  addProviderOrder() {
    this.providerOrders.push({ ...this.fillingOrder })
    this.fillingOrder = {
      providerId: null,
      amount: null,
      unitCost: null,
      externalProviderOrderId: '',
      settlementDatetime: new Date()
    }
  }

  validateDate(): boolean {
    const fillingOrderDate = moment(
      this.createAt,
      'yyyy-MM-DDThh:mm:ss'
    ).valueOf()
    const currentDate = moment(new Date(), 'yyyy-MM-DDThh:mm:ss').valueOf()
    if (fillingOrderDate > currentDate) {
      this.hasOrderCreateAtError = true
      return false
    }
    this.hasOrderCreateAtError = false
    return true
  }

  async executeCreateOrder() {
    if (this.createAt && !this.validateDate()) return
    try {
      adminStore.dispatch('loader/show')
      const { data } = await this.createOrder({
        notifyByEmail: this.notifyByEmail,
        createdAt: this.createAt,
        totalCostCad: Number(this.totalCostCad),
        customerId: this.selectedUser.id,
        pairId: this.pairId,
        side: Number(this.sideId),
        amount: Number(this.amount),
        totalAmount: Number(this.total),
        unitPrice: Number(this.unitPrice),
        providerOrdersData: this.providerOrders.map(providerOrder => ({
          providerId: Number(providerOrder.providerId),
          amount: Number(providerOrder.amount),
          unitCost: Number(providerOrder.unitCost),
          externalProviderOrderId: providerOrder.externalProviderOrderId,
          settlementDatetime: moment(providerOrder.settlementDatetime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        }))
      })
      toast.success('Order successfully created')
      this.$router.push({
        name: 'on-behalf-of.home.edit-manual.order',
        params: { orderId: data.uuid, customerId: this.$route.params.customerId }
      })
      setTimeout(() => {
        this.getBalance(this.selectedUser.id)
      }, 2000)
    } catch (e) {
      toast.error(e.response.data.message)
      console.log(e)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  getProviderNameById(providerId) {
    console.log('providerId', providerId)
    console.log('this.providers', this.providers)
    return this.providers.find(e => e.id == providerId).name
  }

  removeRow(index) {
    this.providerOrders.splice(index, 1)
  }

  toMaximum() {
    if (this.progress >= 100 || !this.amount) return
    this.fillingOrder.amount = this.amount - this.totalAmount
  }

  get canAddProviderOrder() {
    return (
      !!this.sideId &&
      !!this.pairId &&
      this.amount &&
      this.unitPrice &&
      !!this.fillingOrder.providerId &&
      !!this.fillingOrder.amount &&
      !!this.fillingOrder.unitCost &&
      !!this.fillingOrder.settlementDatetime
    )
  }

  get currentPair() {
    if (this.pairId == '') return null
    return this.pairs.find(e => e.id == this.pairId)
  }

  get totalAmount() {
    return this.providerOrders.reduce((p, c) => p + c.amount, 0)
  }

  get totalCost() {
    return this.providerOrders.reduce((p, c) => p + c.unitCost * c.amount, 0)
  }

  get progress() {
    if (!this.providerOrders.length) return 0
    if (this.sideId == '1')
      return Math.floor((this.totalAmount / this.amount) * 100)
    if (this.sideId == '2')
      return Math.floor((this.totalCost / (this.amount * this.unitPrice)) * 100)
    return 0
  }

  get baseCurrencyRevenue() {
    try {
      if (this.sideId == '1')
        return exactMath.sub(this.totalAmount, this.amount)
      if (this.sideId == '2')
        return exactMath.sub(this.amount, this.totalAmount)
    } catch (error) {
      return 0
    }
    return 0
  }

  get quoteCurrencyRevenue() {
    try {
      if (this.sideId == '1')
        return exactMath.mul(this.amount, this.unitPrice) - this.totalCost
      if (this.sideId == '2')
        return this.totalCost - exactMath.mul(this.amount, this.unitPrice)
    } catch (error) {
      return 0
    }
    return 0
  }

  get revenueBalance() {
    if (this.amount && this.unitPrice) {
      const baseRevenuePrice =
        this.baseCurrencyRevenue * this.baseCurrencyAveragePrice
      const totalRevenuePrice = baseRevenuePrice + this.quoteCurrencyRevenue
      const basePercent =
        Math.round((baseRevenuePrice * 1000) / totalRevenuePrice) / 10
      const quotePercent = Math.round((100 - basePercent) * 10) / 10
      return [basePercent, quotePercent]
    }
    return null
  }

  get revenueBalanceForBar() {
    if (this.revenueBalance == null) return null
    let value = this.revenueBalance[0]
    if (value < 0) value = 0
    if (value > 100) value = 100
    return value
  }

  get baseCurrencyAveragePrice(): number {
    return this.totalCost / this.totalAmount
  }

  get canCreateOrder(): boolean {
    return (
      !!this.selectedUser &&
      !!this.pairId &&
      !!this.sideId &&
      !!this.amount &&
      !!this.unitPrice
    )
  }
  ifCrypto(currencyId: string) {
    if (this.getStatusCurrenciesSatstreet == 'success') {
      // eslint-disable-next-line
      const result = this.getCurrencies.find((element: any) => {
        return element.id == currencyId && element.currencyType == 0
      })
      return result ? currencyId : undefined
    }
  }
  ifLocal(currencyId: string) {
    // eslint-disable-next-line
    if (this.getStatusCurrenciesSatstreet == 'success') {
      // eslint-disable-next-line
      const result = this.getCurrencies.find((element: any) => {
        return element.id == currencyId && element.currencyType == 1
      })
      return result ? currencyId : undefined
    }
  }
}
