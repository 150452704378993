import { RouteLocation, RouteRecordRaw } from 'vue-router'
import VerifyBase from '@/views/customer/Verify.vue'
import Zero from '@/components/customer/verify/stepZero.vue'
import One from '@/components/customer/verify/stepOne.vue'
import Two from '@/components/customer/verify/stepTwo.vue'
import BusinessRegister from '@/components/customer/auth/BusinessRegister.vue'
import Flinks from "@/components/customer/verify/flinks.vue";
import { ifUserAuthenticated } from '../ guards'
import { customerStore } from '@/store/customer'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/verify',
        name: 'verify',
        beforeEnter: (to: RouteLocation, from: RouteLocation, next: Function) => {
            customerStore.dispatch("profile/checkToken").then(() => {
                const active = customerStore?.state?.profile?.data?.user?.isActive
                if (active) {
                    next({ name: from.name })
                } else {
                    ifUserAuthenticated(to, from, next)
                }
            }).catch(() => next({ name: 'auth.login' }))
        },
        component: VerifyBase,
        children: [
            {
                path: "zero",
                component: Zero,
                name: "verify.zero",
                meta: { layout: "default" }
            },
            {
                path: "flinks",
                component: Flinks,
                name: "verify.flinks",
                meta: { layout: "default" }
            },
            {
                path: "one",
                component: One,
                name: "verify.one",
                meta: { layout: "default" }
            },
            {
                path: "two",
                component: Two,
                name: "verify.two",
                meta: { layout: "default" }
            },
            {
                path: "company",
                component: BusinessRegister,
                name: "verify.company",
                meta: { layout: "default" }
            }
        ]
    }
]
export default routes