import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-admin'
import { ProfileAuth, Payload } from '@/types'
import { useToast } from "vue-toastification"
import { adminRouter } from '@/router/admin'
import { adminStore } from '..'
//import app from '@/main'

const toast = useToast()
@Module({ namespaced: true, name: '' })
class Profile extends VuexModule {
  public status!: string
  public data!: ProfileAuth
  public error!: object

  @Mutation
  public onLogout(): void {
    this.status = "success"
    this.error = {}
    this.data = {} as ProfileAuth;
  }

  @Mutation
  public onLogin(): void {
    this.status = "fetching"
    this.error = {}
    this.data = {
      user: {
        email: '',
        lastName: '',
        firstName: '',
        role: '',
        token: '',
        isActive: false,
        permissions: undefined
      }
    }
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onLoginSuccess(payload: any): void {
    this.status = "success"
    this.error = {}
    this.data.user = payload.admin;
    if (this.data.user) this.data.user.role = payload.type;
    //app.config.globalProperties.$permissions.role = payload.type
  }
  @Mutation
  public onVerifySuccess(): void {
    if (this.data.user) this.data.user.isTwoFactorAuthenticationEnabled = true
  }
  @Mutation
  public onLoginError(err: object): void {
    this.status = "error"
    this.error = err
  }

  @Action({ rawError: true })
  public login(params: Payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onLogin")
      http
        .post(`auth/admin/login`, params)
        .then(response => {
          if (!response.data.twoFactorAuthenticationEnabled) {
            this.context.commit("onLoginSuccess", {
              token: response.data.token,
              type: "admin",
              admin: {
                email: response.data.email,
                lastName: 'Admin',
                firstName: 'Admin',
                role: 'admin',
                isActive: true
              }
            })
            localStorage.setItem("access-token", response.data.token)
            if (response.data.refreshToken) {
              localStorage.setItem("refresh-token", response.data.refreshToken)
            }
            toast.success("Welcome to Satstreet!")
          }
          
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onLoginError", error)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public resend(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/admin/resendConfirmCode`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          toast.error("Invalid account!")
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public checkToken() {
    this.context.commit("onLogin")
    return new Promise((resolve, reject) => {
      http
        .get(`admin/current`)
        .then(response => {
          this.context.commit("onLoginSuccess", {
            type: 'admin',
            admin: {
              lastName: response.data.lastName,
              firstName: response.data.firstName,
              email: response.data.email,
              permissions: response.data.permissions,
              role: 'admin',
              isActive: true,
              isTwoFactorAuthenticationEnabled: response.data.isTwoFactorAuthenticationEnabled,
            }
          })
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onLoginError", error)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.context.commit("onLogin")
      const refreshToken = localStorage.getItem('refresh-token')
      http
        .post(`auth/logout`, { refreshToken })
        .then(() => {
          localStorage.clear();
          adminRouter.push({ name: 'auth.login' })
          adminStore.commit('security/removeCSRFToken')
          resolve()
        })
        .catch(error => {
          localStorage.clear();
          adminRouter.push({ name: 'auth.login' })
          adminStore.commit('security/removeCSRFToken')
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public sendEmailForgotPassword(params: { email: string }) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/admin/requestPasswordReset`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public resetUserPassword(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/admin/resetPassword`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public postRefreshJwtToken() {
    return new Promise((resolve, reject) => {
      const refreshToken = localStorage.getItem('refresh-token')
      http
        .post('auth/refreshToken', { refreshToken })
        .then(response => {
          this.context.commit('onRefreshTokenSuccess', response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit('onRefreshTokenError', error)
          reject(error)
        })
    })
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onRefreshTokenSuccess(payload: any): void {
    const { token, refreshToken } = payload.data
    localStorage.setItem("access-token", token)
    localStorage.setItem("refresh-token", refreshToken)
    this.error = {}
  }
  @Mutation
  public onRefreshTokenError(err: object): void {
    // this.context.dispatch('logout')
    this.error = err
  }
  @Action({ rawError: true })
  public verify(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post('2fa/admin/check', params)
        .then(response => {
          this.context.commit("onLoginSuccess", {
            token: response.data.token,
            type: response.data.type,
            admin: response.data.admin
          })
          localStorage.setItem("2faSuccess", response.data.updatedAt)
          localStorage.setItem("access-token", response.data.token)
          if (response.data.refreshToken) {
            localStorage.setItem("refresh-token", response.data.refreshToken)
          }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public active2fa(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post('2fa/admin/enable', params)
        .then(response => {
          this.context.commit("onVerifySuccess", response.data)
          localStorage.setItem("2faSuccess", response.data.updatedAt)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  get emailProfile() {
    return this.data?.user?.email
  }

  get userProfile() {
    return this.data?.user
  }
}
export default Profile