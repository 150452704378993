import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../../views/customer/Home.vue'
import Terms from '../../views/customer/Terms.vue'
import { ifUserAuthenticated } from './ guards'
import Auth from './modules/auth'
import Verify from './modules/verify'
import Trade from './modules/trade'
import BankAccount from './modules/bankAccount'
import { customerStore } from "@/store/customer"
import AccountStatement from './modules/account-statement'
import Profile from './modules/profile'
import DepositAndWithdrawal from './modules/deposit-and-withdrawals'


const routes: Array<RouteRecordRaw> = [
  ...Auth,
  ...Verify,
  ...Trade,
  ...AccountStatement,
  ...Profile,
  ...BankAccount,
  ...DepositAndWithdrawal,
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: "default" },
    beforeEnter: ifUserAuthenticated
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: { layout: "auth" }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
]

const customerRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

customerRouter.afterEach(() => {
  customerStore.dispatch("loader/hidden")
})

export { customerRouter }
