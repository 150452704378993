
import { adminStore } from '@/store/admin'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import { Options, Vue } from 'vue-class-component'
import Modal from '@/components/common/modal.vue'
import { useToast } from 'vue-toastification'
import CommercialGroup from '@/types/commercial-group/CommercialGroup'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
const commercialGroups = namespace('commercialGroups')
const pairs = namespace('pairs')
const toast = useToast()
Vue.registerHooks(['beforeRouteEnter'])
@Options({
  data() {
    return {
      showModal: false,
      editingPairId: '',
      newSpread: 0
    }
  },
  mixins: [formatters],
  components: {
    IconsCurrency,
    DataTable,
    Column,
    Modal
  }
})
export default class CreateCommercialGroup extends Vue {
  showModal!: boolean
  editingPairId!: string
  newSpread!: number

  // eslint-disable-next-line
  commercialGroup = {
    name: '',
    description: '',
    defaultSpread: 0,
    planPairs: []
  }
  @commercialGroups.State
  public saveCommercialGroupStatus!: string

  @commercialGroups.Action
  public saveCommercialGroup!: () => Promise<CommercialGroup>
  @commercialGroups.Action
  // eslint-disable-next-line
  public createCommercialGroup!: (
    commercialGroup: CommercialGroup
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any>
  @commercialGroups.Mutation
  // eslint-disable-next-line
  public editPlanPairSpreadForCommercialGroup!: ({
    pairId: string,
    spread: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => any

  @pairs.Action
  // eslint-disable-next-line
  getPairs!: () => any[]

  async mounted() {
    adminStore.dispatch('loader/show')
    document.addEventListener('contextmenu', e => e.preventDefault())
    try {
      // eslint-disable-next-line
      const pairs: any = await this.getPairs()
      this.commercialGroup.planPairs = pairs.data.map(pair => ({
        pairId: pair.id,
        spread: 0
        // eslint-disable-next-line
      })) as any
    } catch (e) {
      console.log(e)
    }
    adminStore.dispatch('loader/hidden')
    // eslint-disable-next-line
    ;(document.getElementById('main-scroll') as any).scrollTop = 0
  }

  addPair(pairId: string) {
    // eslint-disable-next-line
    const planPair = this.commercialGroup.planPairs.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pp => (pp as any).pairId == pairId
    )
    if (planPair) {
      // eslint-disable-next-line
      ;(planPair as any).id = 1
      // eslint-disable-next-line
      ;(planPair as any).spread = Number(this.commercialGroup.defaultSpread)
    }
  }

  removePair(pairId: string) {
    // eslint-disable-next-line
    const planPair = this.commercialGroup.planPairs.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pp => (pp as any).pairId == pairId
    )
    // eslint-disable-next-line
    if (planPair) {
      // eslint-disable-next-line
      ;(planPair as any).id = null
    }
  }

  editSpread(pairId: string, spread: number) {
    this.editingPairId = pairId
    this.newSpread = spread
    this.showModal = true
  }

  saveEditSpread() {
    // eslint-disable-next-line
    const planPair = this.commercialGroup.planPairs.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pp => (pp as any).pairId == this.editingPairId
    )
    // eslint-disable-next-line
    if (planPair) {
      planPair
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(planPair as any).spread = this.newSpread
    }
    this.showModal = false
  }

  async saveChanges() {
    try {
      // eslint-disable-next-line
      const commercialGroup = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(this.commercialGroup as any),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        defaultSpread: Number((this.commercialGroup as any).defaultSpread)
      }
      commercialGroup.planPairs = [
        ...commercialGroup.planPairs.map(e => ({ ...e }))
      ].filter(e => e.id != null)
      commercialGroup.planPairs.forEach(planPair => delete planPair.id)
      await this.createCommercialGroup(commercialGroup)
      toast.success('Commercial group created')
      this.$router.back()
    } catch (e) {
      toast.error(e.response.data.message)
      console.log(e)
    }
  }

  rowClass(data) {
    return data.id ? 'row-active' : 'row-inactive'
  }

  get allReady() {
    return !!this.commercialGroup?.name
  }
}
