
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import formatters from '@/mixins/formatters'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import ParityItem from '@/types/pairs/ParityItem'
//DECORATORS
const prices = namespace('prices')
const assets = namespace('assets')

//OPTION API
@Options({
  data() {
    return {
      lastPrice: Number,
      beforePrice: Number
    }
  },
  props: {
    parity: Object,
    type: String,
    side: String
  },
  mixins: [formatters],
  components: {
    IconsCurrency
  }
})
export default class ChartPrice extends Vue {
  //GETTERS
  //PRICES STORE
  @prices.Getter
  // eslint-disable-next-line
  prices!: any

  @prices.Getter
  pricesStatus!: string

  @assets.Getter
  fiatsAssets!: CurrencyItem[]
  @assets.Getter
  cryptosAssets!: CurrencyItem[]

  //MIXINS
  public format!: (amount: number, decimals: number) => number
  //COMPONENT
  balance!: number
  lastPrice!: number
  beforePrice!: number
  // eslint-disable-next-line
  parity!: ParityItem
  type!: string
  side!: string
  updatePriceIntervalId!: number
  //BEFORE MOUNT

  //BEFORE UNMOUNT
  mounted() {
    this.updatePriceIntervalId = setInterval(this.updatePrice, 1000)
  }
  beforeUnmount() {
    clearInterval(this.updatePriceIntervalId)
  }
  //METHODS
  updatePrice() {
    this.beforePrice = this.lastPrice ? this.lastPrice : 0
    if (
      this.parity != null &&
      this.pricesStatus == 'success' &&
      this.prices[this.parity.pair.baseCurrencyId + this.parity.pair.quoteCurrencyId]
    ) {
      this.lastPrice = this.prices[
        this.parity.pair.baseCurrencyId + this.parity.pair.quoteCurrencyId
      ][this.type][this.side]
    }
  }

  public get isQuoteFiat(): boolean {
    if (!this.fiatsAssets) {
      return false
    }
    return this.fiatsAssets.some(a => a.id === this.parity.pair.quoteCurrencyId)
  }

  public get getQuoteCurrency(): CurrencyItem | undefined {
    if (!this.fiatsAssets || !this.cryptosAssets) {
      return undefined
    }
    const result = [...this.fiatsAssets, ...this.cryptosAssets].find(
      a => a.id === this.parity.pair.quoteCurrencyId
    )
    return result
  }
}
