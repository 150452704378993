
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import formatters from '@/mixins/formatters'
import currencies from '@/mixins/currencies'
import CurrenciesChart from '@/components/common/charts/currenciesChart.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import SinglePrice from '@/components/customer/price/singlePrice.vue'
//DECORATORS

const transactions = namespace('transactions')
const balance = namespace('balance')
const prices = namespace('prices')
const assets = namespace('assets')
//OPTION API
@Options({
  data() {
    return {
      cryptoBalances: Array
    }
  },
  components: {
    IconsCurrency,
    CurrenciesChart,
    DataTable,
    Column,
    SinglePrice
  },
  props: {
    initialLocalCurrency: String
  },
  mixins: [formatters, currencies],
  watch: {
    initialLocalCurrency() {
      this.updatePrices()
    },
    statusTransaction() {
      this.statusTransaction == 'success'
        ? setTimeout(() => {
            this.getBalance(
              this.$isAdmin ? Number(this.$route.params.customerId) : undefined
            )
          }, 2000)
        : null
    }
  }
})
export default class Balances extends Vue {
  //GETTERS
  //BALANCE
  @balance.Getter
  // eslint-disable-next-line
  fiats!: any

  @balance.Getter
  // eslint-disable-next-line
  cryptos!: any

  //ASSETS
  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  @assets.Getter
  // eslint-disable-next-line
  cryptosAssets!: any

  @assets.Getter
  statusAssets!: string

  //PRICES
  @prices.Getter
  // eslint-disable-next-line
  prices!: any

  @prices.Getter
  pricesStatus!: string

  @transactions.Getter
  public statusTransaction!: string
  //STATES
  //BALANCE
  @balance.State
  status!: string

  @balance.State
  error!: object

  @balance.Action
  // eslint-disable-next-line
  public getBalance!: (customerId?: number) => Promise<any>
  //MIXINS
  // eslint-disable-next-line
  criptoCurrencies!: any

  // eslint-disable-next-line
  localCurrencies!: any

  public format!: (amount: number, decimals: number) => number

  //PROPS
  // eslint-disable-next-line
  initialLocalCurrency!: string

  //COMPONENT
  // eslint-disable-next-line
  cryptoBalances!: any
  $isAdmin: any
  getCryptoName(id: string) {
    // console.log({id:id,result: this.cryptos.find((element: any) => element.currencyId == id).coingeckoId})
    // eslint-disable-next-line
    try {
      // eslint-disable-next-line
      return this.cryptos.find((element: any) => element.currencyId == id)
        .coingeckoId
    } catch (error) {
      console.log(error)
    }
  }

  updatePrices() {
    return this.cryptos.sort((a: any, b: any) => {
      if (
        this.prices[a.currencyId + this.initialLocalCurrency] == undefined
      ) {
        return 1
      }
      if (
        this.prices[a.currencyId + this.initialLocalCurrency] &&
        !this.prices[b.currencyId + this.initialLocalCurrency]
      ) {
        return -1
      }
      if (
        this.prices[a.currencyId + this.initialLocalCurrency] &&
        this.prices[b.currencyId + this.initialLocalCurrency]
      ) {
        if (
          a.balance *
            this.prices[a.currencyId + this.initialLocalCurrency]['higher']
              .spot >
          b.balance *
            this.prices[b.currencyId + this.initialLocalCurrency]['higher']
              .spot
        ) {
          return -1
        }
        if (
          a.balance *
            this.prices[a.currencyId + this.initialLocalCurrency]['higher']
              .spot <
          b.balance *
            this.prices[b.currencyId + this.initialLocalCurrency]['higher']
              .spot
        ) {
          return 1
        }
        return 0
      }
    })
  }
  beforeMount() {
    if (this.status !== 'success') {
      this.getBalance(
        this.$isAdmin ? Number(this.$route.params.customerId) : undefined
      )
    }
  }
}
