
import { Options, Vue } from 'vue-class-component'
import AccountStatementReport from '@/components/customer/account-statement/AccountStatementReport.vue'
import OrderHistory from '@/components/customer/account-statement/OrderHistory.vue'
@Options({
  components: {
    AccountStatementReport,
    OrderHistory
  }
})
export default class AccountStatement extends Vue {}
