import { RouteRecordRaw } from "vue-router";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import CurrenciesBaseView from "@/views/admin/CurrenciesBaseView.vue";
import ListCurrencies from "@/components/admin/currencies/ListCurrencies.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/currencies",
        name: "currencies",
        component: CurrenciesBaseView,
        beforeEnter: ifAdminAuthenticated,
        redirect: "",
        children: [
            {
                path: "list",
                name: "currencies.list",
                component: ListCurrencies,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                },
            }
        ],
    },
];
export default routes;
