
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import formatters from '@/mixins/formatters'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
//DECORATORS
const prices = namespace('prices')

//OPTION API
@Options({
  data() {
    return {
      lastPrice: Number,
      beforePrice: Number
    }
  },
  props: {
    parity: Object,
    type: String,
    side: String,
    balance: Number,
    upDown: Boolean
  },
  mixins: [formatters],
  components: {
    IconsCurrency
  }
})
export default class SinglePrice extends Vue {
  //GETTERS
  //PRICES STORE
  @prices.Getter
  // eslint-disable-next-line
  prices!: any

  @prices.Getter
  pricesStatus!: string

  //STATES

  //ACTIONS

  //COMPONENT
  balance!: number
  lastPrice!: number
  beforePrice!: number
  // eslint-disable-next-line
  parity!: any
  type!: string
  side!: string
  //BEFORE MOUNT

  //BEFORE UNMOUNT

  //METHODS
}
