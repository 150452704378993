
import { Options, Vue } from "vue-class-component";

@Options({
  
})
export default class DashboardBase extends Vue {
  show = false
  
  moveTo(path: string) {
    // eslint-disable-next-line
    (window as any).router = this.$router;
    if (this.$route.path == path) {
      this.$router.push({ path: "/revenues" });
    } else {
      this.$router.push({ path });
    }
  }
}
