import { RouteRecordRaw } from "vue-router";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import RevenueBase from "@/views/admin/RevenueBase.vue";
import ComponentRevenue from "@/components/admin/revenues/componentRevenue.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/revenues",
        name: "revenues",
        component: RevenueBase,
        beforeEnter: ifAdminAuthenticated,
        redirect: "",
        children: [
            {
                path: "fiat",
                name: "revenues.fiat",
                component: ComponentRevenue,
                beforeEnter: checkPermission,
                meta: { layout: "default-admin" },
            },
            {
              path: "crypto",
              name: "revenues.crypto",
              component: ComponentRevenue,
              beforeEnter: checkPermission,
              meta: { layout: "default-admin" },
            },
            {
              path: "total",
              name: "revenues.total",
              component: ComponentRevenue,
              beforeEnter: checkPermission,
              meta: { layout: "default-admin" },
            }
        ],
    },
];
export default routes;
