
import { Options, Vue } from 'vue-class-component'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import PendingDeposits from '@/components/admin/dashboard/PendingDeposits.vue'
import DepositsMissingValues from '@/components/admin/dashboard/DepositsMissingValues.vue'
@Options({
  name: 'Deposits',
  components: {
    PendingDeposits,
    DepositsMissingValues,
    TabView,
    TabPanel
  }
})
export default class Deposits extends Vue {}
