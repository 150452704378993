const currenciesMixin = {
    data() {
      return {
        criptoCurrencies: [
          {
              id: 'btc',
              name: 'Bitcoin',
              symbol: 'BTC'
          },
          {
              id: 'eth',
              name: 'Ethereum',
              symbol: 'ETH'
          },
          {
              id: 'ltc',
              name: 'Litecoin',
              symbol: 'LTC'
          },
          {
              id: 'bch',
              name: 'Bitcoin Cash',
              symbol: 'BCH'
          },
          {
              id: 'xlm',
              name: 'Stellar',
              symbol: 'XLM'
          },
          {
              id: 'dot',
              name: 'Polkadot',
              symbol: 'DOT'
          },
        ],
        localCurrencies: [
            {
                id: 'cad',
                name: 'CA Dolar',
                symbol: 'CAD'
            },
            {
                id: 'usd',
                name: 'US Dolar',
                symbol: 'USD'
            }
        ]
      }
    }
}
export default currenciesMixin