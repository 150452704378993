import { RouteRecordRaw } from 'vue-router'
import { ifUserAuthenticated } from '../ guards'
import AccountStatement from '@/views/customer/AccountStatement.vue'
import AccountStatementReport from '@/components/customer/account-statement/AccountStatementReport.vue'
import OrderHistoryDetail from '@/components/customer/account-statement/OrderHistoryDetail.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/account-ledger',
        name: 'account.statement',
        beforeEnter: ifUserAuthenticated,
        component: AccountStatement,
        children: [
            {
                name: 'account.statement.report',
                path: '/account-ledger/report',
                component: AccountStatementReport
            },
            {
                name: 'account.statement.order.detail',
                path: '/account-ledger/order/:id',
                component: OrderHistoryDetail
            },
            {
                name: 'account.statement.order.history.detail',
                path: '/account-ledger/order/:id',
                component: OrderHistoryDetail
            }
        ]
    }
]

export default routes;