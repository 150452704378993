import { Action, Module, VuexModule } from "vuex-module-decorators";
import http from '@/http-common-admin'
import BankAccountForm from "@/types/bank-account/BankAccountForm";
import CryptoAccountForm from "@/types/bank-account/CryptoAccountForm";

@Module({ namespaced: true, name: "" })
export default class BankAccount extends VuexModule {

    @Action({ rawError: true })
    public validateBankOrCryptoAccount(customerBankAccountId: number) {
        return new Promise((resolve, reject) => {
            http
                .put(`customer-bank-accounts/internal/authorize/`, {
                    customerBankAccountId
                })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public postBankAccount(account: BankAccountForm) {
        return new Promise((resolve, reject) => {
            http
                .post('customer-bank-accounts/internal/create', account)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public postCryptoAccount(account: CryptoAccountForm) {
        return new Promise((resolve, reject) => {
            http
                .post('customer-bank-accounts/internal/create/', account)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}