
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'

const toast = useToast()
const profile = namespace('profile')
@Options({
  props: {
    email: String
  }
})
export default class Resend extends Vue {
  email!: string

  @profile.State
  public status!: string

  @profile.Action
  public resend!: (payload: object) => Promise<object>

  async submit() {
    try {
      await this.resend({ email: this.email })
      toast.success('Please check your email to continue')
    } catch (error) {
      return toast.error('Something went wrong')
    }
  }
}
