import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import http from "@/http-common-admin";
import UserItem from "@/types/users/UserItem";
import RequestEnableUser from "@/types/users/RequestEnableUser";
import AdminItem from "@/types/users/AdminItem";
import StatusLog from "@/types/users/StatusLog";
import RequestStepOne from "@/types/verify/RequestStepOne";
@Module({ namespaced: true, name: "" })
class Users extends VuexModule {
  public status!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public data: any[] = [];
  public error!: object;
  public user!: UserItem;
  public admins: AdminItem[] = []

  public topBarUsersStatus!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public topBarUsersData: any[] = [];
  public topBarUsersError!: object;

  @Mutation
  public onUsers(): void {
    this.status = "fetching";
    this.error = {};
    this.data = [];
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onUsersSuccess(data: any[]): void {
    this.status = "success";
    this.error = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any[]).forEach((e) => {
      e.fullName = e.firstName + " " + e.lastName;
    });
    this.data = data;
  }

  @Mutation
  public onUsersError(err: object): void {
    this.status = "error";
    this.error = err;
  }

  @Mutation
  public onTopBarUsers(): void {
    this.topBarUsersStatus = "fetching";
    this.topBarUsersError = {};
    this.topBarUsersData = [];
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onTopBarUsersSuccess(data: any[]): void {
    this.topBarUsersStatus = "success";
    this.topBarUsersError = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any[]).forEach((e) => {
      e.fullName = e.firstName + " " + e.lastName;
    });
    this.topBarUsersData = data;
  }

  @Mutation
  public onTopBarUsersError(err: object): void {
    this.topBarUsersStatus = "error"
    this.topBarUsersError = err
  }

  @Mutation
  public onAdminsSuccess(data: object): void {
    this.admins = data as AdminItem[]
  }
  @Mutation
  public onAdminsError(err: object): void {
    this.error = err
    this.admins = []
  }

  @Action({ rawError: true })
  public clearTopBarUsers() {
    this.context.commit("onTopBarUsersSuccess", []);
  }

  @Action({ rawError: true })
  public clearUsers() {
    this.context.commit("onUsersSuccess", []);
  }

  @Action({ rawError: true })
  public getUsers() {
    return new Promise((resolve, reject) => {
      this.context.commit("onUsers");
      http
        .get("customer/admin/list")
        .then((response) => {
          this.context.commit("onUsersSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onUsersError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getAdmins() {
    return new Promise((resolve, reject) => {
      http
        .get("admin/list")
        .then((response) => {
          this.context.commit("onAdminsSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onAdminsError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getAdminById(id: number) {
    return new Promise((resolve, reject) => {
      http
        .get(`admin/get/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getUsersFilter({ keyWord = "" }) {
    return new Promise((resolve, reject) => {
      this.context.commit("onUsers");
      http
        .get(`customer/admin/search/${keyWord}`)
        .then((response) => {
          this.context.commit("onUsersSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onUsersError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getTopBarUsersFilter({ keyWord = "" }) {
    return new Promise((resolve, reject) => {
      this.context.commit("onTopBarUsers");
      http
        .get(`customer/admin/search/${keyWord}`)
        .then((response) => {
          this.context.commit("onTopBarUsersSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onTopBarUsersError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public enableUser(req: RequestEnableUser) {
    return new Promise((resolve, reject) => {
      http
        .post("admin/validateCustomerAccount", req)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public banUser(req: { customerId: number; message: string; status: boolean }) {
    return new Promise((resolve, reject) => {
      http
        .post('customer/admin/suspension', req)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public reset2Fact(customerId: number) {
    return new Promise((resolve, reject) => {
      http
        .put('customer/admin/reset2fa', { customerId })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public getAccountStatusLogs(customerId: number): Promise<StatusLog[]> {
    return new Promise((resolve, reject) => {
      http
        .get(`admin/getAccountStatusLog/${customerId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public listConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      http
        .get(`configuration`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public listAdminLoginLogs(): Promise<any> {
    return new Promise((resolve, reject) => {
      http
        .get('auth/admin/loginLogs')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public updateConfig(config: { configurationId: number; isActive: boolean }): Promise<void> {
    return new Promise((resolve, reject) => {
      http
        .put(`configuration`, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public get users(): object {
    return this.data;
  }

  public get topBarUsers(): object {
    return this.topBarUsersData;
  }

  @Mutation
  public setUser(user: UserItem): void {
    this.user = user;
  }

  public get selectedUser(): UserItem {
    return this.user;
  }

  public get getAdminList(): AdminItem[] {
    return this.admins
  }

  @Action({ rawError: true })
  public getSpecificUser(id: number | string): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      http
        .get(`customer/admin/get/${id}`)
        .then((response) => {
          resolve(response.data);
          this.context.commit("setUser", response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public reset2FA(adminId: number): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      http
        .put(`2fa/admin/reset2fa`, { adminId })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public assingPermissionToAdmin(payload: any) {
    return new Promise((resolve, reject) => {
      http
        .post('admin/assignPermissions', {
          adminId: payload.adminId,
          permissionConcept: payload.concept
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  @Action({ rawError: true })
  public addNewAdmin(admin: AdminItem): Promise<AdminItem> {
    return new Promise((resolve, reject) => {
      http
        .post('admin/create', {
          ...admin
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  @Action({ rawError: true })
  public createNewCustomerAdmin(req: RequestStepOne): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/admin/registerCustomer`, { ...req })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public sendEmailToCustomer(data: { customer: number; template: string }): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      http
        .post(`admin/sendEmail`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public setApiToken(customerId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      http
        .get(`auth/admin/tokenAPI/`+customerId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public removeApiToken(customerId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      http
        .delete(`auth/admin/tokenAPI/`+customerId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public viewApiToken(customerId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      http
        .get(`auth/admin/tokenAPI/view/`+customerId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  @Action({ rawError: true })
  public getFileHistoryForCustomer(customerId: number): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      http
        .get('customer/admin/getAllFiles/' + customerId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default Users;
