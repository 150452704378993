import { RouteRecordRaw } from "vue-router";
import PermissionsBaseView from "@/views/admin/PermissionsBaseView.vue";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import GrandPermissionsToAdmins from "@/components/admin/permissions/GrandPermissionsToAdmins.vue"
import AdminDetails from "@/components/admin/permissions/AdminDetails.vue"
import SecurityOptions from "@/components/admin/permissions/SecurityOptions.vue"
import SitePermissionsEnum from "@/types/users/SitePermissionsEnum";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/permissions",
        name: "permissions",
        component: PermissionsBaseView,
        beforeEnter: ifAdminAuthenticated,
        redirect: "",
        children: [
            {
                path: "grand",
                name: "permissions.grand",
                component: GrandPermissionsToAdmins,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                    permission: SitePermissionsEnum.manageAdmins
                },
            },
            {
                path: "details-admin/:id",
                name: "permissions.admin.details",
                component: AdminDetails,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                    // permission: SitePermissionsEnum.manageAdmins
                },
            },
            {
                path: "security",
                name: "permissions.security",
                component: SecurityOptions,
                beforeEnter: checkPermission,
                meta: {
                    layout: "default-admin",
                    permission: SitePermissionsEnum.manageAdmins
                },
            },
        ],
    },
];
export default routes;