/* eslint-disable */
import { customerStore } from "@/store/customer"
import { RouteLocation } from "vue-router"
import { useToast } from "vue-toastification"
const toast = useToast()

export async function ifUserAuthenticated(to: RouteLocation, from: RouteLocation, next: Function) {
  customerStore.dispatch("loader/show")
  const accessToken = localStorage.getItem("access-token")
  const tfaSuccess = localStorage.getItem("2faSuccess")
  if (!accessToken) {
    customerStore.dispatch("profile/logout")
    next({ name: "auth.login" })
  } else {
    try {
      const { data } = await customerStore.dispatch("profile/checkToken")

    } catch (error) {
      customerStore.dispatch("profile/logout")
      next({ name: "auth.login", query: { redirect: to.fullPath } })
    }
    if (!customerStore.state.profile.data.user.isActive) {
      if (from.name == "auth.login" && to.name == "verify.zero") {
        next()
      } else if ((from.name == "verify.zero" || from.name == "verify.two") && to.name == "verify.one") {
        next()
      } else if ((from.name == "verify.one" || from.name == "verify.three") && to.name == "verify.two") {
        next()
      } else if (from.name == "verify.two" && to.name == "verify.three") {
        next()
      } else if (to.name == "verify.zero") {
        next()
      } else if (to.name == "verify.flinks") {
        next()
      } else {
        next({ name: "verify.zero" })
      }
    } else if (customerStore.state.profile.data.user.isTwoFactorAuthenticationEnabled) {
      if (tfaSuccess) {
        next()
      } else {
        customerStore.dispatch("profile/logout")
        next({ name: "auth.login" })
      }
    } else {
      next()
    }
  }

}
