
import Panel from 'primevue/panel'
import formatters from '@/mixins/formatters'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { customerStore } from '@/store/customer'
import { useToast } from 'vue-toastification'
import Password from 'primevue/password'
import Verify2fa from '@/components/customer/modals/verify2fa.vue'
import TwoFactorAuthenticator from '@/components/customer/modals/twoFactorAuthenticator.vue'
import ResquestUpdateCustomerProfile from '@/types/auth/ResquestUpdateCustomerProfile'
import { required, sameAs, minLength } from '@vuelidate/validators'
import Dialog from 'primevue/dialog'
import Terms from '@/views/customer/Terms.vue'
const profile = namespace('profile')
const toast = useToast()

@Options({
  name: 'MyProfile',
  mixins: [formatters],
  data() {
    return {
      occupationsData: [],
      password: '',
      newPassword: '',
      confirm: '',
      showVerify: false,
      modalShow: false,
      showTermsModal: false
    }
  },
  components: {
    Panel,
    Password,
    Verify2fa,
    TwoFactorAuthenticator,
    Dialog,
    Terms
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8)
      },
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: (value: string) => /[A-Z]/.test(value),
        containsLowercase: (value: string) => /[a-z]/.test(value),
        containsNumber: (value: string) => /[0-9]/.test(value),
        containsSpecial: (value: string) => /[#?!@$%^&*-.;':]/.test(value)
      },
      confirm: {
        sameAs: sameAs(this.newPassword, 'New Password')
      }
    }
  }
})
export default class MyProfile extends Vue {
  @profile.Getter
  public userProfile!: UserItem
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form = { occupation: '', government: false, nickname: '' }
  password!: string
  newPassword!: string
  confirm!: string
  occupationsData!: string[]
  isOtherOccupation = false
  showVerify!: boolean
  modalShow!: boolean
  isLoadingAntiPhising = false
  antiphishingWord: string | null = null
  showTermsModal = false

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $v!: any

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkToken!: () => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateProfile!: (params: ResquestUpdateCustomerProfile) => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updatePassword!: (payload: any) => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getAntiphishingWord!: () => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateAntiphishingWord!: (antiphishingWord: string) => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public clearAntiphisingWord!: () => Promise<any>

  mounted() {
    this.$v.$touch()
    this.fillsOpccuoations()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.form.occupation = this.userProfile.occupation || ''
    this.form.government = this.userProfile.relationWithGovernment || false
    this.form.nickname = this.userProfile.nickname
    if (!this.occupationsData.includes(this.userProfile?.occupation)) {
      this.isOtherOccupation = true
    }
    this.getSecurityData()
  }

  openTerms() {
    this.showTermsModal = true
  }

  async getSecurityData() {
    try {
      this.isLoadingAntiPhising = true
      const data = await this.getAntiphishingWord()
      console.log(data)
      this.antiphishingWord = data
    } catch (error) {
      this.antiphishingWord = null
      console.log(error)
    } finally {
      this.isLoadingAntiPhising = false
    }
  }

  async updateAntiphishing() {
    if (this.isLoadingAntiPhising) return
    try {
      this.isLoadingAntiPhising = true
      const word = this.antiphishingWord + ''
      await this.updateAntiphishingWord(word)
      toast.success('Information saved!')
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      setTimeout(() => {
        this.isLoadingAntiPhising = false
      }, 2000)
    }
  }
  async removeAntiphishingWord() {
    if (this.isLoadingAntiPhising) return
    try {
      this.isLoadingAntiPhising = true
      await this.clearAntiphisingWord()
      this.antiphishingWord = ''
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      setTimeout(() => {
        this.isLoadingAntiPhising = false
      }, 2000)
    }
  }

  async updatedMyProfile() {
    try {
      customerStore.dispatch('loader/show')
      const params = new ResquestUpdateCustomerProfile()
      params.occupation = this.form.occupation
      params.relationWithGovernment = this.form.government
      params.nickname = this.form.nickname
      await this.updateProfile(params)
      toast.success('Information saved!')
    } catch (error) {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }
  changePassword() {
    if (!this.userProfile.isTwoFactorAuthenticationEnabled) {
      this.modalShow = true
    } else {
      this.showVerify = true
    }
  }
  close2fa() {
    this.showVerify = false
  }
  endChangePassword() {
    this.password = ''
    this.newPassword = ''
    this.confirm = ''
  }
  async success2fa(code: string) {
    this.$v.$touch()
    if (this.$v.$error) return
    try {
      customerStore.dispatch('loader/show')
      await this.updatePassword({
        password: this.password,
        newPassword: this.newPassword,
        code: code
      })
      this.endChangePassword()
      customerStore.dispatch('loader/hidden')
      toast.success('Change Success!')
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }
  noPasswords() {
    if (this.password != '' && this.newPassword != '' && this.confirm != '') {
      return false
    } else {
      return true
    }
  }
  fillsOpccuoations(): void {
    this.occupationsData = [
      'Legislators',
      'Senior government managers and officials',
      'Senior managers - health, education, social and community services and membership organizations',
      'Senior managers - trade, broadcasting and other services, n.e.c.',
      'Senior managers - construction, transportation, production and utilities',
      'Financial managers',
      'Human resources managers',
      'Purchasing managers',
      'Other administrative services managers',
      'Insurance, real estate and financial brokerage managers',
      'Banking, credit and other investment managers',
      'Advertising, marketing and public relations managers',
      'Other business services managers',
      'Telecommunication carriers managers',
      'Postal and courier services managers',
      'Engineering managers',
      'Architecture and science managers',
      'Computer and information systems managers',
      'Managers in health care',
      'Government managers - health and social policy development and program administration',
      'Government managers - economic analysis, policy development and program administration',
      'Government managers - education policy development and program administration',
      'Other managers in public administration',
      'Administrators - post-secondary education and vocational training',
      'School principals and administrators of elementary and secondary education',
      'Managers in social, community and correctional services',
      'Commissioned police officers',
      'Fire chiefs and senior firefighting officers',
      'Commissioned officers of the Canadian Forces',
      'Library, archive, museum and art gallery managers',
      'Managers - publishing, motion pictures, broadcasting and performing arts',
      'Recreation, sports and fitness program and service directors',
      'Corporate sales managers',
      'Retail and wholesale trade managers',
      'Restaurant and food service managers',
      'Accommodation service managers',
      'Managers in customer and personal services, n.e.c.',
      'Construction managers',
      'Home building and renovation managers',
      'Facility operation and maintenance managers',
      'Managers in transportation',
      'Managers in natural resources production and fishing',
      'Managers in agriculture',
      'Managers in horticulture',
      'Managers in aquaculture',
      'Manufacturing managers',
      'Utilities managers',
      'Financial auditors and accountants',
      'Financial and investment analysts',
      'Securities agents, investment dealers and brokers',
      'Other financial officers',
      'Human resources professionals',
      'Professional occupations in business management consulting',
      'Professional occupations in advertising, marketing and public relations',
      'Supervisors, general office and administrative support workers',
      'Supervisors, finance and insurance office workers',
      'Supervisors, library, correspondence and related information workers',
      'Supervisors, mail and message distribution occupations',
      'Supervisors, supply chain, tracking and scheduling co-ordination occupations',
      'Administrative officers',
      'Executive assistants',
      'Human resources and recruitment officers',
      'Property administrators',
      'Purchasing agents and officers',
      'Conference and event planners',
      'Court officers and justices of the peace',
      'Employment insurance, immigration, border services and revenue officers',
      'Administrative assistants',
      'Legal administrative assistants',
      'Medical administrative assistants',
      'Court reporters, medical transcriptionists and related occupations',
      'Health information management occupations',
      'Records management technicians',
      'Statistical officers and related research support occupations',
      'Accounting technicians and bookkeepers',
      'Insurance adjusters and claims examiners',
      'Insurance underwriters',
      'Assessors, valuators and appraisers',
      'Customs, ship and other brokers',
      'Physicists and astronomers',
      'Chemists',
      'Geoscientists and oceanographers',
      'Meteorologists and climatologists',
      'Other professional occupations in physical sciences',
      'Biologists and related scientists',
      'Forestry professionals',
      'Agricultural representatives, consultants and specialists',
      'Civil engineers',
      'Mechanical engineers',
      'Electrical and electronics engineers',
      'Chemical engineers',
      'Industrial and manufacturing engineers',
      'Metallurgical and materials engineers',
      'Mining engineers',
      'Geological engineers',
      'Petroleum engineers',
      'Aerospace engineers',
      'Computer engineers (except software engineers and designers)',
      'Other professional engineers, n.e.c.',
      'Architects',
      'Landscape architects',
      'Urban and land use planners',
      'Land surveyors',
      'Mathematicians, statisticians and actuaries',
      'Information systems analysts and consultants',
      'Database analysts and data administrators',
      'Software engineers and designers',
      'Computer programmers and interactive media developers',
      'Web designers and developers',
      'Chemical technologists and technicians',
      'Geological and mineral technologists and technicians'
    ]
  }
}
