import { required, maxLength, minLength, NumericValueType, numeric } from "@rxweb/reactive-forms";

export default class BankAccountForm {
    @required()
    currencyId!: string

    @required()
    customerId!: number;

    @required()
    @maxLength({ value: 50, message: 'Max length 50 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    recipientFullName!: string;

    @required()
    @maxLength({ value: 50, message: 'Max length 50 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    bankName!: string;

    @required()
    @maxLength({ value: 100, message: 'Max length 100 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    bankAddress!: string;

    @required()
    @maxLength({ value: 8, message: 'Max length 8 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    postalCode!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    city!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    province!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    country!: string;

    @required()
    @maxLength({ value: 11, message: 'Max length 11 characters.' })
    @minLength({ value: 8, message: 'Min length 8 characters.' })
    swiftOrBic!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    transitNumber!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    institutionNumber!: string;

    @required()
    @maxLength({ value: 10, message: 'Max length 10 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    phoneNumber!: string;

    @required()
    @maxLength({ value: 30, message: 'Max length 30 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    accountName!: string;

    @required()
    @maxLength({ value: 100, message: 'Max length 100 characters.' })
    @minLength({ value: 1, message: 'Min length 1 characters.' })
    legalAddressAccountHolder!: string;

    @required()
    @maxLength({ value: 18, message: 'Max length 18 characters.' })
    @minLength({ value: 18, message: 'Min length 18 characters.' })
    @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false, message: "Invalid value" })
    bankAccountNumber!: string;

    type!: number;

    isActive!: boolean;
}