import { RouteRecordRaw } from 'vue-router'
import Trade from '@/views/customer/Trade.vue'
import { ifUserAuthenticated } from '../ guards'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/trade',
    name: 'trade',
    props: true,
    component: Trade,
    beforeEnter: ifUserAuthenticated,
    meta: { layout: "default" },
  }
]
export default routes