
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import IconsCurrency from "@/components/customer/transaction/iconsCurrency.vue"
import CountDown from "@/components/customer/transaction/countDown.vue"
import amountInput from '@/components/common/inputs/amountInput.vue'
import TwoFactorAuthenticator from '@/components/customer/modals/twoFactorAuthenticator.vue'
import CopyToClipboard from '@/components/common/buttons/CopyToClipboard.vue'
import formatersMixin from '@/mixins/formatters'
import { useToast } from "vue-toastification";
import ParityItem from "@/types/pairs/ParityItem";
import CurrencyItem from '@/types/currencies/CurrencyItem'
const toast = useToast();
const transactions = namespace("transactions")
const balance = namespace('balance')
const prices = namespace('prices')
const profile = namespace('profile')
const assets = namespace('assets')
@Options({
    mixins: [formatersMixin],
    data() {
        return {
            total: 0,
            amount: 0,
            totalPercent: 0,
            price: 0,
            focus: 'total',
            success: false,
            tradeSuccess: false,
            tradeError: false,
            blockButton: false,
            blockButtonSubmit: false,
            modalShow: false,
            backup: {
                total: 0,
                amount: 0,
                totalPercent: 0,
            }
        }
    },
    props: {
        type: String,
        parity: Object,
        tradeType: String
    },
    watch: {
        type() {
            this.updatePrecisions()
            const pivot = this.backup
            this.backup = {
                total: this.total,
                amount: this.amount,
                totalPercent: this.totalPercent
            }
            this.total = pivot.total
            this.amount = pivot.amount
            this.totalPercent = pivot.totalPercent
        },
        parity() {
            this.updatePrecisions()
            this.keyQuoteInput ++
            this.backup = {
                total: 0,
                amount: 0,
                totalPercent: 0
            }
            this.total = this.backup.total
            this.amount = this.backup.amount
            this.totalPercent = this.backup.totalPercent
            this.update()
        },
        totalPercent() {
            if (this.focus == 'total_percent'){
                this.exchangeLevel()
                this.updatePrice()
            }
        },
        status(event){
            if (event == 'success') this.update()
        }
    },
    components: {
        IconsCurrency,
        CountDown,
        amountInput,
        TwoFactorAuthenticator,
        CopyToClipboard
    }
})
export default class Advancetransaction extends Vue {
    keyQuoteInput = 1
    BUFFER = 1.0004 
    isLoadingPrices = true
    type!: string
    tradeType!: string
    // eslint-disable-next-line
    total!: any
    // eslint-disable-next-line
    amount!: any
    price!: number
    totalPercent!: number
    amountBalance = 0
    totalBalance = 0
    parity!: ParityItem
    exchengeType = 'lower'
    focus!: string
    blockButton!: boolean;
    $isAdmin!: boolean
    customerId!: string
    rfqId!: string;
    success!: boolean;
    tradeSuccess!: boolean;
    tradeError!: boolean;
    quotePayload!: {amount: number; price: number; total: number}
    modalShow!: boolean
    requestAmount!: number
    requestFiatAmount!: number
    responseCurrency!: string
    requestCurrencyType!: string
    requestPrice!: number
    blockButtonSubmit!: boolean
    responseSuccess!: {side: string; amount: string; instrument: string; executedPrice: string; total: string}
    availableToTradePrecision = 2
    quotePrecision = 5
    amountPrecision = 4
    totalPrecision = 2
    basePrecision = 4

    @assets.Getter
    statusAssets!: string

    @assets.Getter
    // eslint-disable-next-line
    fiatsAssets!: any

    @assets.Getter
    // eslint-disable-next-line
    cryptosAssets!: any

    @balance.Getter
    // eslint-disable-next-line
    fiats!: any;

    @balance.Getter
    // eslint-disable-next-line
    cryptos!: any;

    @prices.Getter
    // eslint-disable-next-line
    prices!: any;

    @prices.Getter
    pricesStatus!: string;

    @profile.Getter
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public userProfile!: any

    @balance.State
    status!: string

    // @transactions.State43,659.45
    // status!: string;

    @transactions.State
    quote!: object;

    @transactions.State
    error!: object;


    //ACTIONS

    @transactions.Action
    public setTransaction!: (params: object) => {};

    @transactions.Action // eslint-disable-next-line
    public getQuote!: (params: object) => Promise<any>;

    @transactions.Action // eslint-disable-next-line
    public createQuote!: (params: object) => Promise<any>;

    @balance.Action
    // eslint-disable-next-line
    public getBalance!: (customerId?:number) => Promise<any>;

    @assets.Getter
    getAllAssets!: CurrencyItem[]


    get currentBaseCurrency() {
        return this.getAllAssets.find((currency) => currency.id === this.parity.pair.baseCurrencyId)
    }

    get currentQuoteCurrency() {
        return this.getAllAssets.find((currency) => currency.id === this.parity.pair.quoteCurrencyId)
    }

    // Formatters this.parity?.pair?.quoteCurrency
    public formatZeros!: (val: number, exact) => string
    balance() {
        if (this.type == 'BUY'){
            if (this.isFiat(this.parity?.pair?.quoteCurrencyId)){
                return this.formatZeros(this.fiats.find((currency) => currency.currencyId == this.parity?.pair?.quoteCurrencyId)?.balance, this.availableToTradePrecision)
            } else {
                return this.formatZeros(this.cryptos.find((currency) => currency.currencyId == this.parity?.pair?.quoteCurrencyId)?.balance, this.availableToTradePrecision)
            }
        } else {
            if (this.isFiat(this.parity?.pair?.baseCurrencyId)){
                return this.formatZeros(this.fiats.find((currency) => currency.currencyId == this.parity?.pair?.baseCurrencyId)?.balance, this.availableToTradePrecision)
            } else {
                return this.formatZeros(this.cryptos.find((currency) => currency.currencyId == this.parity?.pair?.baseCurrencyId)?.balance, this.availableToTradePrecision)
            }
        }
    }
    closeModal () {
        this.success = false
        this.amount = 0
        this.total = 0
        this.totalPercent = 0
        this.focus = 'total'
        this.blockButtonSubmit = false
        this.blockButton = false
        this.tradeSuccess = false
        this.tradeError = false
        this.requestAmount = 0
        this.requestFiatAmount = 0
        this.requestCurrencyType = ''
        this.requestPrice = 0
        this.rfqId = '';
    }
    updatePrice () {
        if(this.price == 0) return
        switch (this.focus) {
            case 'amount':
                if (this.type == 'BUY'){
                    if(this.totalBalance){
                        this.totalPercent = ((this.amount/this.totalBalance)*100)*this.price
                        this.total = (this.totalBalance*(this.totalPercent/100)).toFixed(this.quotePrecision)
                    }
                } else {
                    if(this.amountBalance){
                        this.totalPercent = (this.amount/this.amountBalance)*100
                        this.total = (this.amountBalance*(this.totalPercent/100))*this.price
                        this.total = this.total.toFixed(this.quotePrecision)
                    }
                }
                break;
            case 'total':
                if (this.type == 'BUY') {
                    if(this.totalBalance){
                        this.totalPercent = (this.total/this.totalBalance)*100
                        this.amount = (this.totalBalance*(this.totalPercent/100))/this.price
                        this.amount = this.amount.toFixed(this.basePrecision)
                    }  
                } else {
                    if(this.amountBalance){
                        this.totalPercent = ((this.total/this.amountBalance)*100)/this.price
                        this.amount = (this.amountBalance*(this.totalPercent/100)).toFixed(this.basePrecision)
                    }
                }
                break;
            case 'total_percent':
                if (this.type == 'BUY') {
                    if(this.totalBalance){
                        this.amount = (this.totalBalance*(this.totalPercent/100))/this.price
                        this.amount = this.amount.toFixed(this.basePrecision)
                        this.total = (this.totalBalance*(this.totalPercent/100)).toFixed(this.quotePrecision)
                    }  
                } else {
                    if(this.amountBalance){
                        this.total = (this.amountBalance*(this.totalPercent/100))*this.price
                        this.total = this.total.toFixed(this.quotePrecision)
                        this.amount = (this.amountBalance*(this.totalPercent/100)).toFixed(this.basePrecision)
                    }
                }
                break;
            default:
                break;
        }
    }
    isFiat(asset: string): boolean {
        if(this.statusAssets == "success") {
            return this.fiatsAssets.find((currency) => currency.id == asset) ? true : false
        } else {
            return true
        }
    }
    textForCopyToClipBoard(): string {
        let text = 'Satstreet©\nYou will get: '
        if (this.type == 'BUY'){
            text += `${this.formatZeros(this.requestAmount, this.amountPrecision)} ${this.parity?.pair?.baseCurrencyId}\nfor $${this.formatZeros(this.requestFiatAmount, this.totalPrecision)} ${this.parity?.pair?.quoteCurrencyId}\n`
        } else {
            text += `${this.formatZeros(this.requestFiatAmount, this.totalPrecision)} ${this.parity?.pair?.quoteCurrencyId}\nfor ${this.formatZeros(this.requestAmount, this.amountPrecision)} ${this.parity?.pair?.baseCurrencyId}\n`
        }
        text += `Market price: ${this.isFiat(this.parity?.pair?.quoteCurrencyId) ? '$': ''}${this.formatZeros(this.requestPrice, this.quotePrecision) } ${this.parity?.pair?.quoteCurrencyId}`
        return text
    }
    // eslint-disable-next-line
    formatCrypto(amount: any, decimalCount = 4, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            const j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            null
        }
    }
    formatPrice(value?: number) {
        if(value) {
            const val = (value/1).toFixed(2)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
    exchangeLevel() {
        try {
            if (this.amount > this.prices[this.parity.pairId].higher.level) {
                this.exchengeType = 'higher'
            } else {
                this.exchengeType = 'lower'
            }
        } catch (error) {
            error
        }
    }
    async newRequestQuote() {   
        this.requestQuote();
    }
    async requestQuote() {
        if (this.$isAdmin || this.userProfile.isTwoFactorAuthenticationEnabled) {
            const focus = this.focus
            this.blockButtonSubmit = true;
            const amount = this.amount;
            // const strAmount = amount.slice(0, (amount.indexOf(".")) + 4 + 1); 
            // console.log({strAmount, amount});
            
            const amountToTrade = Number(amount);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const payload: any = {
                pairId: this.parity?.pair?.baseCurrencyId+this.parity?.pair?.quoteCurrencyId,
                side: this.type == "BUY" ? 1 : 2,
                amountBase: amountToTrade,
                // customerId: this.$isAdmin ?  Number( this.$route.params.customerId ) : null,
                amountQuote:  parseFloat(this.total),
                currencyType:  this.type == "BUY" ? 
                    focus == 'amount' ? 'BASE' : 'QUOTE':
                    focus == 'total' ? 'QUOTE' : 'BASE'
            }
            if (this.$isAdmin) {
               payload.customerId = Number( this.$route.params.customerId )
            }
            try {
                const response = await this.getQuote(payload);
                this.requestAmount = response.data.amountBase
                this.requestFiatAmount = response.data.amountQuote
                this.customerId = response.data.customerId
                this.requestCurrencyType = response.data.currencyType
                this.requestPrice = response.data.price
                this.rfqId = response.data.rfqId
                if (this.type == "BUY") {
                    this.success = true
                } else {
                    this.success = true
                }
            } catch (error) {
                toast.error(await this.error['message'])
                this.blockButtonSubmit = false;
            }
        } else {
            this.modalShow = true
        }
    }
    async successQuote() {
        this.blockButton = true
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const payload: any = {
                rfqId: this.rfqId, 
                automaticExecution: true,
                timeZoneOffset : new Date().getTimezoneOffset(),
            }
            if (this.$isAdmin) {
               payload.customerId = Number( this.$route.params.customerId )
            }
            const result = await this.createQuote(payload)
            this.responseSuccess = {
                side: result.side == 1 ? 'BUY' : 'SELL',
                amount: result.amount,
                instrument: result.pairId,
                executedPrice: result.unitPrice,
                total: result.finalPrice,
            }
            this.tradeSuccess = true 
        } catch (e) {
            this.tradeError = true
            setTimeout(() => {this.getBalance(this.$isAdmin ?  Number(this.customerId) : undefined)}, 3000)
        }
    }
    mounted(){

        this.updatePrecisions()
        this.update()
        setInterval(()=>{
            this.price = this.prices[this.parity.pairId] ? this.prices[this.parity.pairId][this.exchengeType][this.type.toString().toLowerCase()] : 0
            if (this.amount != 0 || this.total != 0 || this.totalPercent != 0) this.updatePrice()
        },1000)
    }
    update(){
        if(this.status == 'success' && this.pricesStatus == 'success') {
            this.amountBalance = this.isFiat(this.parity?.pair?.baseCurrencyId) ?
                this.fiats.find((currency) => currency.currencyId == this.parity?.pair?.baseCurrencyId)?.balance : 
                this.cryptos.find((currency) => currency.currencyId == this.parity?.pair?.baseCurrencyId)?.balance

            this.totalBalance = this.isFiat(this.parity?.pair?.quoteCurrencyId) ?
                this.fiats.find((currency) => currency.currencyId == this.parity?.pair?.quoteCurrencyId)?.balance : 
                this.cryptos.find((currency) => currency.currencyId == this.parity?.pair?.quoteCurrencyId)?.balance
        } else {
            setTimeout(this.update,1000)
        }
    }
    updatePrecisions(): void{
        const isQuoteFiat = this.isFiat(this.parity?.pair?.quoteCurrencyId)
        const isBaseFiat = this.isFiat(this.parity?.pair?.baseCurrencyId)
        const isCryptoToCrypto = !isQuoteFiat && !isBaseFiat
        if (this.type == 'BUY') {
            this.availableToTradePrecision = this.isFiat(this.parity?.pair?.quoteCurrencyId) ? 2 : 4
        } else {
            if (isCryptoToCrypto) {
                this.availableToTradePrecision = 2
            } else {
                this.availableToTradePrecision = this.isFiat(this.parity?.pair?.baseCurrencyId) ? 2 : 4
            }
        }
        this.quotePrecision = isQuoteFiat ? 5 : 8
        this.amountPrecision = isCryptoToCrypto ? 2 : 4
        this.totalPrecision = isQuoteFiat ? 2 : 4
    }
}
