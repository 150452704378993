
import { Options, Vue } from 'vue-class-component'
import Balances from '@/components/customer/balances/balances.vue'
import OrderHistory from '@/components/customer/account-statement/OrderHistory.vue'

import AdvanceTransaction from '@/components/customer/transaction/advanceTransaction.vue'
import TradeChart from '@/components/common/charts/tradeChart.vue'
import Intervals from '@/components/customer/transaction/intervals.vue'
import CryptoBalances from '@/components/customer/balances/cryptoBalances.vue'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import tradingViewWidget from '@/components/common/charts/tradingViewWidget.vue'
import ChartPrice from '@/components/customer/price/chartPrice.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ParityItem from '@/types/pairs/ParityItem'
import { namespace } from 'vuex-class'
import { TransactionType } from '@/types'
import Message from 'primevue/message'
import UserItem from '@/types/users/UserItem'
import { WIDGET_TRADING } from '@/config/config'
import Dropdown from 'primevue/dropdown'
const transactions = namespace('transactions')
const currencies = namespace('currencies')
const prices = namespace('prices')
const assets = namespace('assets')
const pairs = namespace('pairs')
const balance = namespace('balance')
const profile = namespace('profile')
@Options({
  data() {
    return {
      widgetTrading: WIDGET_TRADING,
      parity: null,
      interval: '4HRS',
      minInterval: 240,
      type: 'BUY',
      showOverlay: false
    }
  },
  components: {
    Balances,
    TradeChart,
    Intervals,
    CryptoBalances,
    ChartPrice,
    OrderHistory,
    AdvanceTransaction,
    Message,
    TabView,
    TabPanel,
    Dropdown,
    IconsCurrency,
    tradingViewWidget
  },
  watch: {
    // parity(event){
    //   this.type = event.allowBuy ? 'BUY' : 'SELL'
    // },
    getStatus(event) {
      if (event == 'success') {
        if (!this.pairs || this.pairs.length == 0) {
          this.showOverlay = true
          return
        }
        const isIn = this.pairs.some(element => {
          return element.pairId == 'BTCUSD'
        })
        if (!isIn) {
          const firstPair = this.pairs.find(u => u.pair.allowBuy == true)
          this.parity = firstPair
        } else {
          const pair = this.pairs.find(u => u.pairId == 'BTCUSD')
          this.parity = pair
        }
      }
    }
  }
})
export default class Home extends Vue {
  /* eslint-disable */
  widgetTrading: string = WIDGET_TRADING
  paritySelect!: any
  parity!: any
  showOverlay = false
  interval!: string
  minInterval!: number
  get onBehalfOf() {
    return (this as any).$isAdmin ? Number(this.$route.params?.id) : 0
  }
  type!: string
  currencyBalance = localStorage.getItem('preferred-currency')
    ? localStorage.getItem('preferred-currency')
    : 'CAD'
  indexTab = 0

  @profile.Getter
  public userProfile!: UserItem

  @transactions.State
  transaction!: TransactionType

  @currencies.Action
  public getCurrencies!: (config: object) => Promise<any>

  @prices.Action
  // eslint-disable-next-line
  public getPricesOnBehalfOf!: (customerId?: number) => Promise<any>

  @prices.Action
  // eslint-disable-next-line
  public getPrices!: () => Promise<any>

  @prices.Action
  // eslint-disable-next-line
  public unmountConnection!: () => void

  @prices.State
  // eslint-disable-next-line
  connection!: any

  @assets.Action
  // eslint-disable-next-line
  public getAssets!: () => Promise<any>

  @assets.Getter
  statusAssets!: string

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  @balance.Action
  // eslint-disable-next-line
  public getBalance!: (customerId?: number) => Promise<any>

  @balance.Action
  // eslint-disable-next-line
  public getEvent!: () => void

  @pairs.Getter
  pairs!: ParityItem[]

  @pairs.Getter
  getStatus!: string

  @pairs.Action
  getPairs!: () => Promise<ParityItem[]>

  @pairs.Action
  getAvailablePairsForCustomer!: () => Promise<ParityItem[]>

  // onParityUpdate (parity: object) {
  //     this.parity = parity
  // }
  /* eslint-disable */
  switchTrans(type: string) {
    if (this.type != type) {
      this.type = type
    }
  }
  clearChart() {
    const pv = this.interval
    this.interval = '0mins'
    setTimeout(() => {
      this.interval = pv
    }, 50)
  }
  changeCurrency(currency: string) {
    this.currencyBalance = currency
  }
  beforeUnmount() {
    this.unmountConnection()
  }
  beforeMount() {
    this.getEvent()
    this.getBalance(this.onBehalfOf)
    this.getAssets()
    if ((this as any).$isAdmin) {
      this.getPricesOnBehalfOf(this.onBehalfOf)
      this.getPairs()
    } else {
      this.getPrices()
      this.getAvailablePairsForCustomer()
    }
  }
  isFiat(asset: string): boolean {
    if (this.statusAssets == 'success') {
      return this.fiatsAssets.find(currency => currency.id == asset)
        ? true
        : false
    } else {
      return true
    }
  }
  mounted() {
    if (this.$route.query.tab === 'order-history') this.indexTab = 1
  }
}
