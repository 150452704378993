
import { Options, Vue } from 'vue-class-component'
@Options({
    data() {
        return {
            timerEnabled: true,
            timerCount: 20,
            rows: 2
        }
    },
    watch: {
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },

        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else if (value == 0 && this.rows > 0) {
                    this.timerCount = 20
                    this.rows--
                    this.$emit('update', this.rows+1)
                } else {
                    this.$emit('update', this.rows)
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    props: {
        initialTimerCount: Number,
        initialrows: Number
    },
    emits: ['update'],
})
export default class CountDown extends Vue {
    initialTimerCount!: number
    initialrows!: number
    timerEnabled!: boolean
    timerCount!: number
    rows!: number
    play() {
        this.timerEnabled = true
    }
    pause() {
        this.timerEnabled = false
    }
    beforeMount() {
        this.rows = this.initialrows ? this.initialrows : 2
        this.timerCount = this.initialTimerCount ? this.initialTimerCount : 20
    }
}
