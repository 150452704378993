
import formatters from '@/mixins/formatters'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import FileUser from '@/types/users/FileUser'
import { useToast } from 'vue-toastification'
import Panel from 'primevue/panel'
import Password from 'primevue/password'
import { required, sameAs, minLength } from '@vuelidate/validators'
import Verify2fa from '@/components/customer/modals/verify2fa.vue'
import { customerStore } from '@/store/customer'
import TwoFactorAuthenticator from '@/components/customer/modals/twoFactorAuthenticator.vue'
import Dialog from 'primevue/dialog'
import Terms from '@/views/customer/Terms.vue'
const profile = namespace('profile')
const downloads = namespace('downloads')
const toast = useToast()

@Options({
  name: 'CompanyProfile',
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    Panel,
    Verify2fa,
    Password,
    TwoFactorAuthenticator,
    Terms,
    Dialog
  },
  data() {
    return {
      password: '',
      newPassword: '',
      confirm: ''
    }
  },

  validations() {
    return {
      password: {
        required,
        minLength: minLength(8)
      },
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: (value: string) => /[A-Z]/.test(value),
        containsLowercase: (value: string) => /[a-z]/.test(value),
        containsNumber: (value: string) => /[0-9]/.test(value),
        containsSpecial: (value: string) => /[#?!@$%^&*-.;':]/.test(value)
      },
      confirm: {
        sameAs: sameAs(this.newPassword, 'New Password')
      }
    }
  }
})
export default class CompanyProfile extends Vue {
  // Reset Password
  password = ''
  newPassword = ''
  confirm = ''
  showVerify = false
  modalShow = false
  showTermsModal = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $v!: any
  isLoadingAntiPhising = false
  antiphishingWord: string | null = null

  @profile.Getter
  public userProfile!: UserItem

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkToken!: () => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updatePassword!: (payload: any) => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getAntiphishingWord!: () => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateAntiphishingWord!: (antiphishingWord: string) => Promise<any>

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public clearAntiphisingWord!: () => Promise<any>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filterFiles(): any[] {
    if (!this.userProfile?.files) return []
    return this.userProfile?.files.filter(
      file =>
        !file.typeOfDocument.includes('signer') &&
        !file.typeOfDocument.includes('beneficial')
    )
  }

  // Resset Password
  noPasswords() {
    if (this.password != '' && this.newPassword != '' && this.confirm != '') {
      return false
    } else {
      return true
    }
  }
  changePassword() {
    if (!this.userProfile.isTwoFactorAuthenticationEnabled) {
      this.modalShow = true
    } else {
      this.showVerify = true
    }
  }
  close2fa() {
    this.showVerify = false
  }
  endChangePassword() {
    this.password = ''
    this.newPassword = ''
    this.confirm = ''
  }
  async success2fa(code: string) {
    this.$v.$touch()
    if (this.$v.$error) return
    try {
      customerStore.dispatch('loader/show')
      await this.updatePassword({
        password: this.password,
        newPassword: this.newPassword,
        code: code
      })
      this.endChangePassword()
      customerStore.dispatch('loader/hidden')
      toast.success('Change Success!')
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  //Antiphishing code
  async getSecurityData() {
    try {
      this.isLoadingAntiPhising = true
      const data = await this.getAntiphishingWord()
      console.log(data)
      this.antiphishingWord = data
    } catch (error) {
      this.antiphishingWord = null
      console.log(error)
    } finally {
      this.isLoadingAntiPhising = false
    }
  }

  async updateAntiphishing() {
    if (this.isLoadingAntiPhising) return
    try {
      this.isLoadingAntiPhising = true
      const word = this.antiphishingWord + ''
      await this.updateAntiphishingWord(word)
      toast.success('Information saved!')
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      setTimeout(() => {
        this.isLoadingAntiPhising = false
      }, 2000)
    }
  }
  async removeAntiphishingWord() {
    if (this.isLoadingAntiPhising) return
    try {
      this.isLoadingAntiPhising = true
      await this.clearAntiphisingWord()
      this.antiphishingWord = ''
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      setTimeout(() => {
        this.isLoadingAntiPhising = false
      }, 2000)
    }
  }

  mounted() {
    this.checkToken().then(() => {
      this.getSecurityData()
    })
  }
}
