
import TheBanner from './components/TheBanner.vue'
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { namespace } from 'vuex-class'
import Password from 'primevue/password'

const toast = useToast()
const profile = namespace('profile')

@Options({
  components: {
    TheBanner,
    Password
  },
  props: {
    token: String
  },
  data() {
    return {
      password: '',
      confirm: '',
      code: ''
    }
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value: string) => /[A-Z]/.test(value),
        containsLowercase: (value: string) => /[a-z]/.test(value),
        containsNumber: (value: string) => /[0-9]/.test(value),
        containsSpecial: (value: string) => /[#?!@$%^&*-.;':]/.test(value)
      },
      confirm: {
        sameAs: sameAs(this.password, 'password')
      }
    }
  }
})
export default class Resend extends Vue {
  token!: string
  password!: string
  code!: string
  comfirm!: string
  isCodeRequired = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $v!: any

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resetUserPassword!: (payload: any) => Promise<object>

  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const active = urlParams.get('active')
    if (active === 'true') this.isCodeRequired = true
  }

  async onSubmit() {
    this.$v.$touch()
    if (this.$v.$error) return
    try {
      await this.resetUserPassword({
        activationCode: this.token,
        password: this.password,
        code: this.code
      })
      toast.success('Change success, now login')
      this.$router.push({ name: 'auth.login' })
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }
}
