import { required, minNumber, minLength, maxLength } from "@rxweb/reactive-forms";

export default class WithdrawalsForm {
    @required()
    bankOrWalletId!: number | string;

    currencyId!: string

    @required()
    @minNumber({ value: 0 })
    amount!: number;

    @required()
    createdAt!: string

    customerId!: number | string;

    @minLength({ value: 1, message: 'Min length 1 characters.' })
    @maxLength({ value: 75, message: 'Max length 75 characters.' })
    transactionAuthCode!: string
}