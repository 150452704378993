
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'
import Column from 'primevue/column'
import { namespace } from 'vuex-class'
import OrderItem from '@/types/orders/OrderItem'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import formatters from '@/mixins/formatters'
import CurrencyItem from '@/types/currencies/CurrencyItem'
const orders = namespace('orders')
const currenciesSatStreet = namespace('currenciesSatStreet')
@Options({
  data() {
    return {
      filters: null
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    InputText,
    IconsCurrency
  }
})
export default class PendingOrders extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters!: any

  @orders.State
  status!: string

  @orders.Getter
  orders!: OrderItem[]

  @orders.Action
  public getPendingOrders!: () => Promise<OrderItem[]>

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem>

  @currenciesSatStreet.Getter
  getStatusCurrenciesSatstreet!: string

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  ifCrypto(currencyId: string) {
    if (this.getStatusCurrenciesSatstreet == 'success') {
      // eslint-disable-next-line
      const result = this.getCurrencies.find((element: any) => {
        return element.id == currencyId && element.currencyType == 0
      })
      return result ? currencyId : undefined
    }
  }
  ifLocal(currencyId: string) {
    // eslint-disable-next-line
    if (this.getStatusCurrenciesSatstreet == 'success') {
      // eslint-disable-next-line
      const result = this.getCurrencies.find((element: any) => {
        return element.id == currencyId && element.currencyType == 1
      })
      return result ? currencyId : undefined
    }
  }
  splitPair(pair: string) {
    const fiat = { first: '', second: '' }
    const crypto = { first: '', second: '' }
    let pairType = ''
    this.getCurrencies.forEach(element => {
      if (pair.search(element.id) != -1) {
        if (element.currencyType == 0) {
          crypto.first == ''
            ? (crypto.first = element.id)
            : (crypto.second = element.id)
        } else {
          fiat.first == ''
            ? (fiat.first = element.id)
            : (fiat.second = element.id)
        }
      }
    })
    if (fiat.first && fiat.second) {
      pairType = 'fiat'
      let pv: string
      if (pair != fiat.first + fiat.second) {
        pv = fiat.first
        fiat.first = fiat.second
        fiat.second = pv
      }
    }
    if (crypto.first && crypto.second) {
      pairType = 'crypto'
      let pv: string
      if (pair != crypto.first + crypto.second) {
        pv = crypto.first
        crypto.first = crypto.second
        crypto.second = pv
      }
    }
    if (pairType == '') {
      pairType = 'mix'
    }
    return { fiat: fiat, crypto: crypto, pairType: pairType }
  }

  mounted() {
    this.getPendingOrders()
    this.fetchCurrencies()
  }

  created() {
    this.initFilters()
  }

  clearFilter() {
    this.initFilters()
  }

  initFilters() {
    this.filters = {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS }
    }
  }

}
