
import ConfirmPopup from 'primevue/confirmpopup'
import { Options, Vue } from 'vue-class-component'
import Modal from '@/components/common/modal.vue'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'
import Column from 'primevue/column'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import { adminStore } from '@/store/admin'
import DepositItem from '@/types/account-movement/DepositItem'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import formatters from '@/mixins/formatters'
import FileUser from '@/types/users/FileUser'
const toast = useToast()
const deposits = namespace('deposits')
const downloads = namespace('downloads')
@Options({
  data() {
    return {
      filters: null,
      showModal: false,
      showDetailsModal: false,
      rejectionCode: 1
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    InputText,
    IconsCurrency,
    Modal,
    ConfirmPopup
  }
})
export default class PendingDeposits extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters!: any
  showModal!: boolean
  showDetailsModal!: boolean

  rejectDepositId!: number
  rejectionCode!: number

  @deposits.State
  status!: string
  @deposits.State
  manageDepositStatus!: string
  @deposits.State
  manageDepositId!: string

  @deposits.Getter
  deposits!: DepositItem[]
  @deposits.Getter
  depositDetails!: DepositItem

  @deposits.Action
  public getPendingDeposits!: () => Promise<DepositItem[]>
  @deposits.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public acceptDeposit!: (depositId) => Promise<any>
  @deposits.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public rejectDeposit!: ({ depositId, rejectionCode }) => Promise<any>
  @deposits.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSpecificDeposit!: (depositId) => Promise<any>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  modalOkCallback: Function = () => undefined

  async mounted() {
    try {
      //adminStore.dispatch("loader/show");
      await this.getPendingDeposits()
    } catch (error) {
      toast.error(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  executeAcceptDeposit(event: any, depositId: number) {
    this.$confirm.require({
      target: event.currentTarget,
      message: 'Are you sure?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.acceptDeposit(depositId).then(() => {
          const depositIndex = this.deposits.findIndex(e => e.id == depositId)
          this.deposits.splice(depositIndex, 1)
          adminStore.dispatch('dashboard/getCards')
          toast.success('Deposit accepted')
        })
      }
    })
  }

  showModalRejectDeposit(depositId: number) {
    this.rejectDepositId = depositId
    this.showModal = true
  }

  executeRejectDeposit() {
    const depositId = this.rejectDepositId
    this.showModal = false
    this.rejectDeposit({
      depositId: this.rejectDepositId,
      rejectionCode: this.rejectionCode
    }).then(() => {
      const depositIndex = this.deposits.findIndex(e => e.id == depositId)
      this.deposits.splice(depositIndex, 1)
      this.rejectionCode = 1
      adminStore.dispatch('dashboard/getCards')
      toast.success('Deposit rejected')
    })
  }

  getDepositDetails(depositId: number) {
    this.getSpecificDeposit(depositId).then(() => {
      this.showDetailsModal = true
    })
  }

  created() {
    this.initFilters()
  }

  clearFilter() {
    this.initFilters()
  }

  initFilters() {
    this.filters = {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS }
    }
  }

  public formatPrice!: (amount: number) => number
  public formatDate!: (isoDate: string) => string
}
