import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import http from "@/http-common-admin";
// import { useToast } from "vue-toastification";
import PairItem from "@/types/pairs/PairItem";

// const toast = useToast();
@Module({ namespaced: true, name: "" })
class Pairs extends VuexModule {
  public status!: string;
  public data!: PairItem[];
  public error!: object;

  @Mutation
  public onFetching(): void {
    this.status = "fetching";
    this.error = {};
    this.data = [];
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onSuccess(data: any): void {
    this.status = "success";
    this.error = {};
    this.data = data;
  }

  @Mutation
  public onError(err: object): void {
    this.status = "error";
    this.error = err;
  }

  @Action({ rawError: true })
  public getPairs() {
    return new Promise((resolve, reject) => {
      this.context.commit("onFetching");
      http
        .get("pair/admin/list-all")
        .then((response) => {
          this.context.commit("onSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getAvailablePairsForAdmin(customerId: number) {
    return new Promise((resolve, reject) => {
      this.context.commit("onFetching");
      http
        .get("plan-pair/admin/getAvailablePairsForAdmin", { params: { customerId } })
        .then((response) => {
          this.context.commit("onSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onError", error);
          reject(error);
        });
    });
  }


  @Action({ rawError: true })
  public getB2c2Instrument(providerName: string) {
    return new Promise((resolve, reject) => {
      http
        .get(`trading/admin/instruments/${providerName}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getValuesFromProvider(providerName: string) {
    return new Promise((resolve, reject) => {
      http
        .get(`trading/admin/currency/${providerName}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public updatePair(pair: PairItem) {
    return new Promise((resolve, reject) => {
      http
        .put("pair/admin/update", pair)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public addPair(pair: PairItem) {
    return new Promise((resolve, reject) => {
      http
        .post("pair/admin/create", pair)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public get pairs(): PairItem[] {
    return this.data;
  }

  public get getStatus(): string {
    return this.status;
  }
}
export default Pairs;
