import { createStore } from "vuex";
import profile from "@/store/customer/modules/profile";
import register from "@/store/customer/modules/register";
import verify from "@/store/customer/modules/verify";
import loader from "@/store/customer/modules/loader";
import users from "@/store/customer/modules/users";
import transactions from "@/store/customer/modules/transactions";
import currencies from "@/store/common/currencies";
import security from "@/store/common/security";
import balance from "@/store/customer/modules/balance";
import assets from "@/store/common/assets";
import prices from "@/store/common/prices";
import tradeChart from "@/store/common/tradeChart";
import accountStatement from "@/store/customer/modules/accountStatement";
import bankAccount from "@/store/customer/modules/bankAccount";
import depositWithdrawal from "@/store/customer/modules/deposits-withdrawal";
import pairs from "@/store/customer/modules/pairs";
import ownerSigner from "@/store/customer/modules/owners-signers";
import twofactorauthentication from "@/store/customer/modules/twoFactorAuthentication";
import downloads from "@/store/customer/modules/downloads";
const customerStore = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    security,
    downloads,
    ownerSigner,
    profile,
    register,
    verify,
    loader,
    users,
    transactions,
    currencies,
    balance,
    prices,
    tradeChart,
    assets,
    accountStatement,
    bankAccount,
    depositWithdrawal,
    pairs,
    twofactorauthentication
  },
});

export { customerStore };
