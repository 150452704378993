
import { Options, Vue } from 'vue-class-component'
import TradeChart from '@/components/common/charts/tradeChart.vue' 
import AdvanceTransaction from "@/components/customer/transaction/advanceTransaction.vue"
import TradeOptions from "@/components/customer/transaction/tradeOptions.vue"
import Intervals from "@/components/customer/transaction/intervals.vue"
import Parity from "@/components/customer/transaction/parity.vue"
import { namespace } from 'vuex-class'
const currencies = namespace('currencies')
@Options({
    data () {
        return {
            currencyValues: {},
            parity: {},
            tradeType: 'market',
            interval: '4HRS'
        }
    },
    props: {
        initialCriptoCurrency: String,
        initialLocalCurrency: String,
    },
    components: {
        TradeChart,
        TradeOptions,
        Parity,
        AdvanceTransaction,
        Intervals
    }
})
export default class Trade extends Vue {
    // eslint-disable-next-line
    currencyValues!: any
    // eslint-disable-next-line
    parity!: any
    initialCriptoCurrency!: string
    initialLocalCurrency!: string
    tradeType!: string
    interval!: string
    
    @currencies.Action
    // eslint-disable-next-line
    public getCurrencies!: (config: object) => Promise<any>

    onTradeOptionUpdate(tradeType: string) {
        this.tradeType = tradeType
    }
    onParityUpdate (parity: object) {
        this.parity = parity
    }
    async mounted() {
        this.parity = {
            local: this.initialLocalCurrency ? this.initialLocalCurrency: 'CAD',
            cripto: this.initialCriptoCurrency ? this.initialCriptoCurrency : 'BTC'
        }
        //esperando por back para traer la lista de localcurrencies y criptocurrencies
        const localCurrencies = ["usd", "cad"]
        const config = {
        "vs_currency": "",
        "order": "market_cap_desc",
        "per_page": 100,
        "page": 1,
        "sparkline": false,
        "ids": "bitcoin,ethereum,litecoin"
        }
        localCurrencies.forEach(async localCurrency => {
            config['vs_currency'] = localCurrency
            const response = await this.getCurrencies(config)
            // eslint-disable-next-line
            response.data.forEach((cripto: any) => {
                if (this.currencyValues[cripto.symbol]){
                this.currencyValues[cripto.symbol][localCurrency] = cripto.current_price
                }else {
                this.currencyValues[cripto.symbol] = new Object
                this.currencyValues[cripto.symbol][localCurrency] = cripto.current_price
                }
            })
        })
    }
    
}
