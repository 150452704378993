
import { Options, Vue } from 'vue-class-component'
import http from '@/http-common-coingecko'
import VueApexCharts from "vue3-apexcharts"

@Options({
    data() {
        return {
            series: [{
                data: [
                    
                ]
            }],
            chartOptions: {
                chart: {
                    height: 50,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true,
                    },
                    animations: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'straight'
                },
                tooltip: {
                    // eslint-disable-next-line
                    enabled: false,
                }
            },
            mntd: false,
        }
    },
    props: {
        localCurrency: String,
        criptoCurrency: String,
    },
    components: {
        apexchart: VueApexCharts,
    }
})
export default class CurrenciesChart extends Vue {
    // eslint-disable-next-line
    series!: any
    localCurrency!: string
    criptoCurrency!: string
    mntd!: boolean
    
    mounted () {
        this.getData()
        
    }
    async getData () {
        const response = await http.get(`coins/${this.criptoCurrency == "bitcoin cash updated" ? "bitcoin-cash" : this.criptoCurrency}/market_chart`, {params: {
            'vs_currency': this.localCurrency,
            days: 7,
            interval: 'hourly'
        }})
        if (response.status == 200 || response.status == 201 ){
             const responsePrices: [] = response.data.prices
            const prices: [] = []
            responsePrices.forEach(price => {
                prices.push(price[1])
            })
            this.series = [{data: prices}]
            this.mntd = true
        }else{
            setTimeout(this.getData,300000)
        }
       
    }
}
