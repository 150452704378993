
import CardBase from '@/components/common/layout/CardBase.vue'
import { Options, Vue } from 'vue-class-component'
import Modal from '@/components/common/modal.vue'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Column from 'primevue/column'
import { namespace } from 'vuex-class'
import CommercialGroup from '@/types/commercial-group/CommercialGroup'
import formatters from '@/mixins/formatters'
const commercialGroups = namespace('commercialGroups')
@Options({
  data() {
    return {
      showModal: false
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    InputText,
    Modal,
    CardBase
  }
})
export default class CommercialGroups extends Vue {
  showModal!: boolean

  selectedPlan = null

  @commercialGroups.State
  status!: string

  @commercialGroups.Getter
  commercialGroups!: CommercialGroup[]

  @commercialGroups.Action
  public list!: () => Promise<CommercialGroup[]>

  modalOkCallback: Function = () => undefined

  async mounted() {
    try {
      //adminStore.dispatch("loader/show");
      await this.list()
    } catch (error) {
      console.log(error)
    }
  }

  rowClass(data) {
    return data.id ? 'row-active' : 'row-inactive'
  }
}
