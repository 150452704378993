
import { Options, Vue } from 'vue-class-component'
import CardBase from '@/components/common/layout/CardBase.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import CurrencyItem from '@/types/currencies/CurrencyItem'
const adminReports = namespace('adminReports')
@Options({
  name: 'CurrencyReport',
  components: {
    CardBase,
    DataTable,
    Column
  },
  mixins: [formatters]
})
export default class CurrencyReport extends Vue {
  isLoading = true
  mainSearch = ''

  @adminReports.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAmountAllCurrencies!: () => Promise<any>
  @adminReports.Getter
  getAllBalances!: { amount: number; currency: CurrencyItem }[]

  @adminReports.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSinglePriceCoingecko!: ({ coingeckoIds, vs }) => Promise<any>

  @adminReports.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCryptoPrices!: any

  mounted() {
    this.fetchAssets()
  }

  async fetchAssets() {
    try {
      this.isLoading = true
      await this.getAmountAllCurrencies()
      const coingeckoIds: string[] = []
      for (const balance of this.getAllBalances) {
        coingeckoIds.push(balance.currency.coingeckoId)
      }
      await this.getSinglePriceCoingecko({
        coingeckoIds,
        vs: ['cad']
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  parseCadValue(coingeckoId): number {
    if (!this.getCryptoPrices) {
      return 0
    }
    return this.getCryptoPrices[coingeckoId].cad
  }

  get getFilteredItems(): { amount: number; currency: CurrencyItem }[] {
    const items = this.getAllBalances.filter(balance => balance.amount > 0)
    if (!this.mainSearch) {
      return items
    }
    return items.filter(p => {
      if (p.currency.id.toLowerCase().includes(this.mainSearch.toLowerCase())) {
        return p
      }
    })
  }
}
