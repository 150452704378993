import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (
      _ctx.pricesStatus == 'success' &&
        _ctx.prices[_ctx.parity.cripto + _ctx.parity.local] &&
        _ctx.balance > 0
    )
    ? (_openBlock(), _createBlock("span", _hoisted_1, " $ " + _toDisplayString(_ctx.formatPrice(
        _ctx.prices[_ctx.parity.cripto + _ctx.parity.local][_ctx.type][_ctx.side] *
          (_ctx.balance != null ? _ctx.balance : 1)
      )) + " " + _toDisplayString(_ctx.parity.local), 1))
    : (_ctx.balance <= 0)
      ? (_openBlock(), _createBlock("span", _hoisted_2, " $ " + _toDisplayString(_ctx.formatPrice(0)) + " " + _toDisplayString(_ctx.parity.local), 1))
      : (_openBlock(), _createBlock("span", _hoisted_3, " Price not available "))
}