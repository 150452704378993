
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'
const toast = useToast()
const profile = namespace('profile')
@Options({
  props: {
    code: String
  }
})
export default class Confirm extends Vue {
  code!: string

  @profile.State
  public status!: string

  @profile.Action
  public confirm!: (payload: object) => Promise<object>

  async mounted() {
    try {
      await this.confirm({ activationCode: this.code })
      this.$router.push({ name: 'Home' })
      return toast.success('Email confirmed!')
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  async submit() {
    this.$router.push({ name: 'auth.login' })
  }
}
