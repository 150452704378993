
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from "vuex-class";
import VueApexCharts from "vue3-apexcharts"

import PairItem from '@/types/pairs/PairItem'
import * as moment from "moment";
const pairs = namespace('pairs')
// import { namespace } from 'vuex-class'
//DECORATORS
// const example = namespace('example')

//OPTION API
@Options({
    data() {
      return {
        doughnut: {
          series: [0.2234,2.4564,1.0007, 0.5968, 0.0245, 0.0054, 0.1050, 0.9838],
          chartOptions: {
            labels: ['USD', 'CAD','BTC','ETH','LTC','BCH','ADA','XML'],
            chart: {
              width: 380,
              type: 'donut',
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 360,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10
                }, 
                donut: {
                  size: '65%',
                  background: 'transparent',
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontSize: '22px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      color: undefined,
                      offsetY: -10,
                      formatter: function (val) {
                        return val
                      }
                    },
                    value: {
                      show: true,
                      fontSize: '16px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 400,
                      color: undefined,
                      offsetY: 16,
                      formatter: function (val) {
                        return val
                      }
                    },
                    total: {
                      show: true,
                      showAlways: true,
                      label: 'Revenues',
                      fontSize: '22px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      color: '#373d3f',
                      formatter: function (w) {
                        return w.globals.seriesTotals.reduce((a, b) => {
                          return a + b
                        }, 0).toFixed(4)+' BTC'
                      }
                    }
                  }
                },      
              }
            },
            dataLabels: {
              enabled: true,
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  show: false
                }
              }
            }],
            legend: {
              position: 'right',
              offsetY: 0,
              height: 230,
            }
          }
        },
        bar: {
          series: [{
            name: 'USD',
            data: [1.1111, 1.2203, 1.2042, 1.3124, 1.4341, 1.1342, 1.1342,]
          }, {
            name: 'CAD',
            data: [2.3421, 2.2742, 3.5942, 3.5034, 3.7346, 3.2023, 3.1342,]
          }, {
            name: 'CRYPTOS',
            data: [1.2321, 1.2503, 2.3042, 0.8043, 1.5348, 1.0058, 2.1342,]
          },],

          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10
              },
            },
            fill: {
              opacity: 1
            }
          }
        },
        area: {
          series: [
            {
              name: 'TOTAL',
              data: [0.1023,0.2285,0.4947,0.9984,1.1049,1.2984,1.4874,1.9400,2.0430,3.1254,4,4.2054,4.8040,4.9085,5.1605,5.3960]
            },
          ],
          chartOptions: {
            chart: {
              type: 'area',
              height: 350,
              stacked: true,
              events: {
                selection: function (chart, e) {
                  console.log(new Date(e.xaxis.min))
                }
              },
            },
            colors: ['#008FFB'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            fill: {
              type: 'gradient',
              gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left'
            },
            // xaxis: {
            //   type: 'datetime'
            // },
          },
        }
      }
    },
    components: {
      apexchart: VueApexCharts
    },
    mixins: [
    ],
    watch: {
      // example() {
      //   //action
      // }
    }
})

export default class ComponentRenueve extends Vue {

  public from = moment.default().subtract(3, "months").format('yyyy-MM-DDThh:mm')
  public to = moment.default().format('yyyy-MM-DDThh:mm')
  public pairId = "";
  public period = '3M'


  @pairs.Getter
  pairs!: PairItem[]
  
  @pairs.Getter
  getStatus!: string

  @pairs.Action
  getPairs!: () => Promise<PairItem[]>

  interval(period: string){
    this.period = period
    switch (period) {
      case '1D':
        this.from = moment.default().subtract(1, 'days').format('yyyy-MM-DDThh:mm')
        break;
      case '1W':
        this.from = moment.default().subtract(7, 'days').format('yyyy-MM-DDThh:mm')
        break;
      case '1M':
        this.from = moment.default().subtract(1, 'months').format('yyyy-MM-DDThh:mm')
        break;
      case '3M':
        this.from = moment.default().subtract(3, 'months').format('yyyy-MM-DDThh:mm')
        break;
    }
    this.to = moment.default().format('yyyy-MM-DDThh:mm')
    //this.getRecords()
  }
  async mounted() {
    try {
    // eslint-disable-next-line
      if (this.$route.path == '/revenues/total'){
    // eslint-disable-next-line
        (this as any).$store.dispatch("loader/show");
        await this.getPairs()
      }
    } catch (error) {
      //toast.error(error);
    }
  }
//GETTERS
    //EXAMPLE STORE
//     @example.Getter
//     // eslint-disable-next-line
//     exampleGet!: any

// //STATES
//     @example.State
//     exampleState!: string

// //ACTIONS
//     @example.Action
//     // eslint-disable-next-line
//     public getExample!: () => Promise<any>


// //MIXINS
    
//     public mixinsExample!: (foo: string) => number

// //COMPONENT

//     CompVarExample!: number

//     //BEFORE MOUNT
//     async beforeMount() {
//         await this.getExample()
//     }
//     //BEFORE UNMOUNT
//     beforeUnmount() {
//         this.getExample()
//     }
//     //METHODS
//     methodExmaple (foo: string) {
//       return foo
//     }
}
