import axios from "axios"
import { COINGECKO_API_URL } from "./config/config"

const instance = axios.create({
  baseURL: COINGECKO_API_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
})
instance.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance