
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import OrderHistory from '@/components/customer/account-statement/OrderHistory.vue'
import Column from 'primevue/column'
import AccountStatementItem from '@/types/account-statement/AccountStatementItem'
import { namespace } from 'vuex-class'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import Calendar from 'primevue/calendar'
import Datepicker from 'vue3-datepicker'
import currenciesMixin from '@/mixins/currencies'
import formatters from '@/mixins/formatters'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import * as moment from 'moment'
import exportFromJSON from 'export-from-json'
import PairItem from '@/types/pairs/PairItem'
const accountStatement = namespace('accountStatement')
const transactions = namespace('transactions')
const assets = namespace('assets')
const currenciesSatStreet = namespace('currenciesSatStreet')
const pairs = namespace('pairs')
@Options({
  name: 'AccountStatementReport',
  mixins: [currenciesMixin, formatters],
  components: {
    DataTable,
    Column,
    IconsCurrency,
    Datepicker,
    OrderHistory,
    Calendar
  },
  watch: {
    from() {
      this.changeFilters()
    },
    to() {
      this.changeFilters()
    },
    currencyId() {
      this.changeFilters()
    },
    statusTransaction() {
      this.statusTransaction == 'success' ? this.getRecords() : null
    }
  }
})
export default class AccountStatementReport extends Vue {
  public from = moment
    .default()
    .subtract(7, 'days')
    .format('yyyy-MM-DDThh:mm')
  public to = moment.default().format('yyyy-MM-DDThh:mm')
  public max = moment.default().format('yyyy-MM-DDThh:mm')
  public currencyId = ''
  public period = '1W'
  public $isAdmin!: boolean

  @pairs.Action
  getPairs!: () => Promise<PairItem[]>

  @pairs.Getter
  pairs!: PairItem[]

  @pairs.State
  status!: string

  @accountStatement.Getter
  public getStatements!: AccountStatementItem[]

  @accountStatement.Getter
  public getStatus!: string

  @transactions.Getter
  public statusTransaction!: string

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  @currenciesSatStreet.Getter
  getStatusCurrenciesSatstreet!: string

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem>

  @accountStatement.Action
  public fetchStatements!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId?: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    fromDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    toDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    currencyId: string
  }) => Promise<void>

  @accountStatement.Action
  public fetchStatementsOrderReport!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId?: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    fromDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    toDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    currencyId: string
  }) => Promise<any>

  @accountStatement.Action
  public fetchStatementsDWReport!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    customerId?: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    fromDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    toDate: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    currencyId: string
  }) => Promise<any>
  // @accountStatement.Action
  // public adminFetchStatements!: (payload: {
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   customerId: number
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   fromDate: string
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   toDate: string
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   currencyId: string
  // }) => Promise<void>

  // @accountStatement.Action
  // public fetchStatementsOnBehalfOf!: (payload: {
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   customerId: number
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   fromDate: string
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   toDate: string
  //   // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  //   currencyId: string
  // }) => Promise<void>

  @assets.Action
  public getAssets!: () => Promise<void>

  //MIXINS
  public formatPrice!: (amount: number, decimals?: number) => number
  public formatZeros!: (amount: number, exact?: number) => string
  public format!: (amount: number | string, decimalCount?: number) => string
  public formatDate!: (date: string) => string

  changeFilters() {
    if (
      !moment.default(this.from).isValid() ||
      !moment.default(this.to).isValid()
    )
      return
    this.getRecords()
  }

  isFiat(currency: CurrencyItem | undefined): boolean {
    if (!currency) {
      return false
    }
    return currency.currencyType === 1
  }

  async downloadOrders(exportType: any){
    const Response = await this.fetchStatementsOrderReport({
      fromDate: moment.default(this.from).format('yyyy-MM-DDThh:mm'), //.format('yyyy-MM-DD hh:mm:ss'),
      toDate: moment.default(this.to).format('yyyy-MM-DDThh:mm'), //.format('yyyy-MM-DD hh:mm:ss'),
      currencyId: this.currencyId
    })
    const data: any[] = Response.data.map((order: any) => {
      const baseCurrency = this.getCurrencies.find(c => c.id === order.currency_CA)
      const quoteCurrency = this.getCurrencies.find(c => c.id === order.currency_CAQ)
      return {
        "ID": order.id,
        "Platform": order.platform,
        "M": order.M,
        "Trader": order.trader,
        "Date": order.date,
        "Timestamp": moment.default(order.timestamp).format('YYYY-MM-DD hh:mm:ss'),
        ...(exportType == "xls") && {"Accounting Hour": order.accounting_hour} || {"Accounting_Hour": order.accounting_hour},
        "Client": order.client,
        "Side": order.side,
        "Quantity": this.format(
          order.quantity
        ),
        ...(exportType == "xls") && {"Base Currency": order.base_currency} || {"Base_Currency": order.base_currency},
        ...(exportType == "xls") && {"Unit Cost": this.format(
            order.unit_cost
        )} || {"Unit_Cost": this.format(
            order.unit_cost
        )},
        ...(exportType == "xls") && {"Price Unit": order.price_unit} || {"Price_Unit": order.price_unit},
        ...(exportType == "xls") && {"Trade Amount": this.format(
          order.trade_amount
        )} || {"Trade_Amount": this.format(
          order.trade_amount
        )},
        ...(exportType == "xls") && {"Trade Unit": order.trade_unit} || {"Trade_Unit": order.trade_unit},
        ...(exportType == "xls") && {"Client Amount Base": this.format(
          order.client_amount_base
        )} || {"Client_Amount_Base": this.format(
          order.client_amount_base
        )},
        ...(exportType == "xls") && {"Currency CA": order.currency_CA} || {"Currency_CA": order.currency_CA},
        ...(exportType == "xls") && {"Client Price": this.format(
          order.client_price
        )} || {"Client_Price": this.format(
          order.client_price
        )},
        ...(exportType == "xls") && {"Final Client Price": this.isFiat(quoteCurrency)
        ? this.format(
          order.final_client_price
        )
        : this.format(
          order.final_client_price
        )} || {"Final_Client_Price": this.isFiat(quoteCurrency)
        ? this.format(
          order.final_client_price
        )
        : this.format(
          order.final_client_price
        )},
        ...(exportType == "xls") && {"Currency CAQ": order.currency_CAQ} || {"Currency_CAQ": order.currency_CAQ},
        ...(exportType == "xls") && {"Rev1 Amount": this.format(
          order.Rev1_amount
        )} || {"Rev1_Amount": this.format(
          order.Rev1_amount
        )},
        ...(exportType == "xls") && {"Rev1 Unit": order.Rev1_unit} || {"Rev1_Unit": order.Rev1_unit},
        ...(exportType == "xls") && {"Rev2 Amount": this.format(
          order.Rev2_amount
        )} || {"Rev2_Amount": this.format(
          order.Rev2_amount
        )},
        ...(exportType == "xls") && {"Rev2 Unit": order.Rev2_unit} || {"Rev2_Unit": order.Rev2_unit},
        "Notes": order.notes,
        ...(exportType == "xls") && {"CAD Value": this.format(
          order.CAD_value
        )} || {"CAD_Value": this.format(
          order.CAD_value
        )},
      }
    })
    const fileName = `TradeTracker-orders-${moment.default(new Date()).format('YYYY-MM-DD')}`
    exportFromJSON({ data, fileName, exportType })
  }
  async downloadDW(exportType: any){
    //1 pending, 2 authorized, 3 rejected, 4 Reversed, 5 sent to account (Withrawal)
    const status = ['pending', 'authorized', 'rejected', 'reversed', 'sent to account']
    //1 deposit, 2 withdrawal 
    const type = ['deposit', 'withdrawal']
    const Response = await this.fetchStatementsDWReport({
      fromDate: moment.default(this.from).format('yyyy-MM-DD'), //.format('yyyy-MM-DD hh:mm:ss'),
      toDate: moment.default(this.to).format('yyyy-MM-DD'), //.format('yyyy-MM-DD hh:mm:ss'),
      currencyId: this.currencyId
    })
    
    const data: any[] = Response.data.map((DorW: any) => {
      return {
        "Date": moment.default(DorW.createdAt).format('YYYY-MM-DD'),
        "Currency": DorW.currency.description,
        "Amount": this.format(DorW.amount, DorW.currency.exact),
        "Status": status[DorW.status],
        "Type": type[DorW.type],
        "Transaction_ID": DorW.transactionId,
        "Authorized/Rejected_At": moment.default(DorW.authorizedOrRejectedAt).format('YYYY-MM-DD HH:mm:ss'),
        "Authorized/Rejected_By": DorW.authorizedOrRejectedById,
        "Reversed_At": moment.default(DorW.reversedAt).format('YYYY-MM-DD HH:mm:ss'),
        "Reversed_By": DorW.reversedById,
        "Rejection_Code": DorW.rejectionCode,
        "Created_At": moment.default(DorW.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        "Transaction_Auth_Code": DorW.transactionAuthCode,
        "USD_Exchange_Value": this.format(DorW.usdExchangeValue),
        "CAD_Exchange_Value": this.format(DorW.cadExchangeValue),
        "Admin_ID": DorW.adminId,
        "Customer": DorW.customer.firstName + " " + DorW.customer.lastName,
      }
    })
    const fileName = `TradeTracker-deposit&withdrawals-${moment.default(new Date()).format('YYYY-MM-DD')}`
    exportFromJSON({ data, fileName, exportType })
  }

  getRecords(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      fromDate: moment.default(this.from).format('yyyy-MM-DDThh:mm'), //.format('yyyy-MM-DD hh:mm:ss'),
      toDate: moment.default(this.to).format('yyyy-MM-DDThh:mm'), //.format('yyyy-MM-DD hh:mm:ss'),
      currencyId: this.currencyId
    }
    this.fetchStatements(payload)
  }
  ifCrypto(currencyId: string) {
    if (this.getStatusCurrenciesSatstreet == 'success') {
      // eslint-disable-next-line
      const result = this.getCurrencies.find((element: any) => {
        return element.id == currencyId && element.currencyType == 0
      })
      return result ? currencyId : undefined
    }
  }
  ifLocal(currencyId: string) {
    // eslint-disable-next-line
    if (this.getStatusCurrenciesSatstreet == 'success') {
      // eslint-disable-next-line
      const result = this.getCurrencies.find((element: any) => {
        return element.id == currencyId && element.currencyType == 1
      })
      return result ? currencyId : undefined
    }
  }
  splitPair(pair: string) {
    const fiat = { first: '', second: '' }
    const crypto = { first: '', second: '' }
    let pairType = ''
    this.getCurrencies.forEach(element => {
      if (pair.search(element.id) != -1) {
        if (element.currencyType == 0) {
          crypto.first == ''
            ? (crypto.first = element.id)
            : (crypto.second = element.id)
        } else {
          fiat.first == ''
            ? (fiat.first = element.id)
            : (fiat.second = element.id)
        }
      }
    })
    if (fiat.first && fiat.second) {
      pairType = 'fiat'
      let pv: string
      if (pair != fiat.first + fiat.second) {
        pv = fiat.first
        fiat.first = fiat.second
        fiat.second = pv
      }
    }
    if (crypto.first && crypto.second) {
      pairType = 'crypto'
      let pv: string
      if (pair != crypto.first + crypto.second) {
        pv = crypto.first
        crypto.first = crypto.second
        crypto.second = pv
      }
    }
    if (pairType == '') {
      pairType = 'mix'
    }
    return { fiat: fiat, crypto: crypto, pairType: pairType }
  }
  interval(period: string) {
    this.period = period
    switch (period) {
      case '1D':
        this.from = moment
          .default()
          .subtract(1, 'days')
          .format('yyyy-MM-DDThh:mm')
        break
      case '1W':
        this.from = moment
          .default()
          .subtract(7, 'days')
          .format('yyyy-MM-DDThh:mm')
        break
      case '1M':
        this.from = moment
          .default()
          .subtract(1, 'months')
          .format('yyyy-MM-DDThh:mm')
        break
      case '3M':
        this.from = moment
          .default()
          .subtract(3, 'months')
          .format('yyyy-MM-DDThh:mm')
        break
    }
    this.to = moment.default().format('yyyy-MM-DDThh:mm')
  }
  async mounted() {
    this.getRecords()
    this.getAssets()
    this.getPairs()
    this.fetchCurrencies()
  }
}
