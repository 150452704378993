import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import http from '@/http-common-admin'
import coingeckoHttp from '@/http-common-coingecko'
import CurrencyItem from '@/types/currencies/CurrencyItem'

@Module({ namespaced: true, name: '' })
export default class AdminReports extends VuexModule {

    public allBalances: { amount: number; currency: CurrencyItem }[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public allCryptoRevenues: any[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any 
    public allFiatRevenues: any[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any 
    public cryptoPrices!: any
    public customerBalances: any[] = []

    @Action({ rawError: true })
    public getAmountAllCurrencies() {
        return new Promise((resolve, reject) => {
            http
                .get(`report/admin/allBalances`)
                .then(response => {
                    this.context.commit("onSuccessGetAmountAllCurrencies", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSuccessGetAmountAllCurrencies(data: any): void {
        this.allBalances = data
    }

    get getAllBalances(): { amount: number; currency: CurrencyItem }[] {
        return this.allBalances
    }


    @Action({ rawError: true })
    public getSinglePriceCoingecko({ coingeckoIds, vs }) {
        return new Promise((resolve, reject) => {
            coingeckoHttp
                .get("simple/price", {
                    params: {
                        ids: coingeckoIds.join(","),
                        "vs_currencies": vs.join(",")
                    }
                })
                .then((response) => {
                    this.context.commit("onSuccessGetSinglePriceCoingecko", response.data)
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSuccessGetSinglePriceCoingecko(data: any): void {
        this.cryptoPrices = data
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get getCryptoPrices(): any[] {
        return this.cryptoPrices
    }

    @Action({ rawError: true })
    public getRevenues(params) {
        return new Promise((resolve, reject) => {
            const timezoneOffset = new Date().getTimezoneOffset();
            http
                .get("customer-order/admin/revenuesByCurrency", { params: { ...params, timezoneOffset } })
                .then((response) => {
                    this.context.commit("onSuccessGetRevenues", response.data)
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSuccessGetRevenues(data: any): void {
        this.allCryptoRevenues = data['CRYPTO']
        this.allFiatRevenues = data['FIAT']
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get getAllCryptpRevenues(): any[] {
        return this.allCryptoRevenues
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get getAllFiatRevenues(): any[] {
        return this.allFiatRevenues
    }


    @Action({ rawError: true })
    public fetchAllCustomerBalances(params) {
        return new Promise((resolve, reject) => {
            const timezoneOffset = new Date().getTimezoneOffset();
            http
                .get("report/admin/allCustomerWithBalances", { params: { ...params, timezoneOffset } })
                .then((response) => {
                    this.context.commit("onSuccessAllCustomerBalances", response.data)
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSuccessAllCustomerBalances(data: any): void {
        this.customerBalances = [...data]
    }

    get getAllCustomerBalances(): any[] {
        return this.customerBalances
    }


}