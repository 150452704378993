
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import CopyToClipboard from '@/components/common/buttons/CopyToClipboard.vue'
const toast = useToast()
//DECORATORS
const twofactorauthentication = namespace('twofactorauthentication')
const profile = namespace('profile')

//OPTION API
@Options({
  data() {
    return {
      code: '',
      QR: '',
      key: '',
      active: 0
    }
  },
  components: {
    TabView,
    TabPanel,
    CopyToClipboard
  },
  props: {
    show: Boolean
  },
  watch: {
    active(val) {
      if (val == 0) {
        this.getQR()
      } else {
        this.getToken2fa()
      }
    },
    show(event) {
      event == true ? this.getQR() : (this.QR = '')
      this.active = 0
      this.key = ''
    }
  },
  emits: ['close']
})
export default class TwoFactorAuthenticator extends Vue {
  public show!: boolean
  public QR!: string
  public code!: string
  public key!: string
  public active!: number

  isLoading = false
  //GETTERS
  //EXAMPLE STORE
  @twofactorauthentication.Getter
  get2faStatus!: string

  //ACTIONS
  @twofactorauthentication.Action
  // eslint-disable-next-line
  public getQRCode!: () => Promise<any>

  @twofactorauthentication.Action
  // eslint-disable-next-line
  public token2fa!: () => Promise<any>

  @profile.Action
  // eslint-disable-next-line
  public active2fa!: (params: object) => Promise<any>

  async setTextFromClipBoard() {
    try {
      const text = await navigator.clipboard.readText()
      this.code = text
    } catch (error) {
      toast.info('Please enable site permission for Clipboard')
    }
  }

  textForCopyToClipBoard() {
    return this.key
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterKeyPress(event: any) {
    const keyCode = event.keyCode ? event.keyCode : event.which
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault()
    }
  }
  autoSend() {
    if (this.code.length === 6) {
      this.verifyQR()
    }
  }
  async getToken2fa() {
    const response = await this.token2fa()
    if (response.status === 200 || response.status === 201) {
      this.key = response.data.secret
    }
  }
  async getQR() {
    const responseQR = await this.getQRCode()
    this.QR = `data:${responseQR.headers['content-type']};base64,${btoa(
      String.fromCharCode(...new Uint8Array(responseQR.data))
    )}`
  }
  async verifyQR() {
    if (this.isLoading || !this.code || this.code.toString().length < 6) return
    this.isLoading = true
    try {
      await this.active2fa({
        twoFactorAuthenticationCode: this.code,
        method: 'enable'
      })
      toast.success('Two-factor authentication has been enabled')
      this.QR = ''
      this.$emit('close')
    } catch (error) {
      toast.error(
        !error.response.data.message
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error.response.data.errors.find((u: any) => u).message
          : error.response.data.message
      )
    } finally {
      this.isLoading = false
    }
    this.code = ''
  }
}
