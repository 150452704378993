
import formatters from '@/mixins/formatters'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { customerStore } from '@/store/customer'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useToast } from 'vue-toastification'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import Slider from 'primevue/slider'
import AuthorizedSignerForm from '@/types/auth/AuthorizedSignerForm'
import AuthorizedSigner from '@/types/users/AuthorizedSigner'
import ConfirmDialog from 'primevue/confirmdialog'
import { FILE_SIZE_LIMIT_MB } from '@/config/config'
import FileUser from '@/types/users/FileUser'
const downloads = namespace('downloads')
const profile = namespace('profile')
const ownerSigner = namespace('ownerSigner')
const toast = useToast()

@Options({
  name: 'CompanyProfile',
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    Slider,
    ConfirmDialog
  }
})
export default class SignerForm extends Vue {
  @profile.Getter
  public userProfile!: UserItem
  signerForm!: IFormGroup<AuthorizedSignerForm>
  isEditing = false

  currentSigner!: AuthorizedSigner | undefined

  @profile.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public checkToken!: () => Promise<any>

  @ownerSigner.Action
  public registerSigner!: (form: FormData) => Promise<void>

  @ownerSigner.Action
  public updateSigner!: (form: FormData) => Promise<void>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.signerForm = new RxFormBuilder().formGroup(
      AuthorizedSignerForm
    ) as IFormGroup<AuthorizedSignerForm>
  }

  mounted() {
    this.checkToken().then(() => {
      if (this.$route.name === 'edit.signer') {
        this.isEditing = true
        const signerId = Number(this.$route.params.signerId.toString())
        this.findSigner(signerId)
      }
    })
  }

  findSigner(id: number): void {
    this.currentSigner = this.userProfile.corporate.authorizedSigners.find(
      s => s.id === id
    )
    this.fillForm()
  }

  fillForm(): void {
    this.signerForm.props.name = this.currentSigner?.name
    this.signerForm.props.email = this.currentSigner?.email
    this.signerForm.props.withGovernment = this.currentSigner?.relatedGovernment
      ? true
      : false
    this.signerForm.props.identification = this.currentSigner?.identificationFile
    this.signerForm.props.identificationBack = this.currentSigner?.identificationBackFile
  }

  checkFileName(file: File): boolean {
    const extension = file.name.split('.').pop() || ''
    return ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(extension)
  }

  checkFileType(type) {
    return [
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'application/pdf'
    ].includes(type)
  }

  checkFileSize(file: File): boolean {
    const size = parseFloat((file.size / 1024 / 1024).toFixed(4))
    return size > FILE_SIZE_LIMIT_MB
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEntryFile(e: any, field: string): void {
    const file = e.target.files[0]
    if (!file) return

    if (!this.checkFileType(file.type)) {
      toast.warning('Invalid file type.')
      return
    }

    if (!this.checkFileName(file)) {
      toast.warning('Invalid file type.')
      return
    }

    if (this.checkFileSize(file)) {
      toast.warning('File oversize. Limit 10MB.')
      return
    }
    switch (field) {
      case 'idSigner':
        this.signerForm.props.identification = file
        break
      case 'idSignerBack':
        this.signerForm.props.identificationBack = file
        break
      default:
        break
    }
  }

  submitForm(): void {
    this.$confirm.require({
      message:
        'Are you sure?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.saveSigner()
      }
    })
  }

  async saveSigner(): Promise<void> {
    if (this.signerForm.invalid) {
      console.log(this.signerForm)
      return this.touchAllSignerFormControls()
    }
    try {
      customerStore.dispatch('loader/show')
      const form = new FormData()
      if (this.isEditing)
        form.set('authorizedSignerId', this.currentSigner?.id.toString() || '')
      form.set('name', this.signerForm.props.name)
      form.set('email', this.signerForm.props.email)
      form.set('customerId', this.userProfile.id.toString())
      form.set('signerId', this.signerForm.props.identification)
      form.set('relatedWithGovernment', this.signerForm.props.withGovernment)
      form.set('signerIdBack', this.signerForm.props.identificationBack)
      this.isEditing
        ? await this.updateSigner(form)
        : await this.registerSigner(form)
      toast.success('Signer Saved!')
      this.$router.push({ name: 'Home' })
      location.reload()
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  clearSignerForm(): void {
    this.signerForm = new RxFormBuilder().formGroup(
      AuthorizedSignerForm
    ) as IFormGroup<AuthorizedSignerForm>
  }

  touchAllSignerFormControls(): void {
    Object.keys(this.signerForm.controls).forEach(crl => {
      this.signerForm.get(crl).markAsDirty()
    })
  }
}
