import { Action, Module, VuexModule } from "vuex-module-decorators";
import http from "@/http-common-admin"
import FileUser from "@/types/users/FileUser";


@Module({ namespaced: true, name: '' })
export default class OwnerAndSigner extends VuexModule {

    @Action({ rawError: true })
    public downloadFile(file: FileUser) {
        return new Promise((resolve, reject) => {
            http
                .get('customer/admin/getSignedUrl/' + file.key)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
