<!-- eslint-disable no-undef -->
<template>
  <div class="tradingview-widget-container">
    <div class="-z-50" id="tradingview_0d296"></div>
  </div>
</template>

<script allowJs="true" setup>
import { onMounted, watch, defineProps } from 'vue'

// define props with default values
const props = defineProps({
  pairId: {
    type: String,
    default: 'INDEX:BTCUSD'
  }
})

function createTradingChart() {
  const symbol = props.pairId ? props.pairId : 'BTCUSD'
  // eslint-disable-next-line no-undef
  new TradingView.widget({
    width: '100%',
    height: '100%',
    autosize: false,
    symbol,
    interval: 'D',
    timezone: 'Etc/UTC',
    theme: 'light',
    style: '1',
    locale: 'en',
    // eslint-disable-next-line @typescript-eslint/camelcase
    toolbar_bg: '#f1f3f6',
    // eslint-disable-next-line @typescript-eslint/camelcase
    enable_publishing: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    hide_legend: false,
    withdateranges: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    allow_symbol_change: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    hide_top_toolbar: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    hide_side_toolbar: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    container_id: 'tradingview_0d296',
    range: 'YTD',
    // eslint-disable-next-line @typescript-eslint/camelcase
    show_popup_button: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    popup_width: '1000',
    // eslint-disable-next-line @typescript-eslint/camelcase
    popup_height: '650',
    studies: ['RSI@tv-basicstudies']
  })
}

watch(
  () => props.pairId,
  () => {
    createTradingChart()
  }
)

onMounted(() => {
  createTradingChart()
})
</script>
