
import { adminStore } from '@/store/admin'
import Listbox from 'primevue/listbox'
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'
import BankAccountItem from '@/types/bank-account/BankAccountItem'
import ConfirmPopup from 'primevue/confirmpopup'
import Dialog from 'primevue/dialog'
import formatersMixin from '@/mixins/formatters'
import BankForm from '@/types/bank-account/BankAccountForm'
import CryptoAccountForm from '@/types/bank-account/CryptoAccountForm'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import BankAccountForm from '@/types/bank-account/BankAccountForm'
const withdrawals = namespace('withdrawals')
const bankAccount = namespace('bankAccount')
const assets = namespace('assets')
const toast = useToast()
@Options({
  name: 'ValidateBankAccount',
  components: {
    Listbox,
    ConfirmPopup,
    Dialog
  },
  mixins: [formatersMixin],
  data() {
    return {
      selectedAccount: null
    }
  }
})
export default class ValidateBankAccount extends Vue {
  showModal = false
  public bankForm!: IFormGroup<BankForm>
  public cryptoForm!: IFormGroup<CryptoAccountForm>
  public isCrypto = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userAccounts: BankAccountItem[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedAccount!: BankAccountItem

  @withdrawals.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchAccountsByUser!: (customerId: string | number) => Promise<any>

  @bankAccount.Action
  public validateBankOrCryptoAccount!: (
    customerBankAccountId: number
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any>

  @bankAccount.Action
  public postBankAccount!: (account: BankAccountForm) => Promise<void>

  @bankAccount.Action
  public postCryptoAccount!: (account: CryptoAccountForm) => Promise<void>

  public formatCryptoAddress!: (address: string) => string
  public formatBankNumber!: (number: string) => string

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  @assets.Getter
  // eslint-disable-next-line
  cryptosAssets!: any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.bankForm = new RxFormBuilder().formGroup(BankForm) as IFormGroup<
      BankForm
    >
    this.cryptoForm = new RxFormBuilder().formGroup(
      CryptoAccountForm
    ) as IFormGroup<CryptoAccountForm>
  }

  mounted() {
    this.getAccounts()
  }

  addAccount(): void {
    this.bankForm.props.type = null
    this.bankForm.props.city = null
    this.bankForm.props.country = null
    this.bankForm.props.province = null
    this.bankForm.props.bankName = null
    this.bankForm.props.postalCode = null
    this.bankForm.props.currencyId = null
    this.bankForm.props.swiftOrBic = null
    this.bankForm.props.accountName = null
    this.bankForm.props.phoneNumber = null
    this.bankForm.props.bankAddress = null
    this.bankForm.props.transitNumber = null
    this.bankForm.props.institutionNumber = null
    this.bankForm.props.legalAddressAccountHolder = null
    this.bankForm.props.bankAccountNumber = null
    this.bankForm.props.recipientFullName = null

    this.cryptoForm.props.type = null
    this.cryptoForm.props.currencyId = null
    this.cryptoForm.props.cryptoAddress = null
    this.showModal = true
  }

  submitForm(): void {
    this.bankForm.props.customerId = Number(this.$route.params.customerId)
    this.cryptoForm.props.customerId = Number(this.$route.params.customerId)
    this.isCrypto ? this.handleCryptoForm() : this.handleBankForm()
  }

  async handleCryptoForm(): Promise<void> {
    if (this.cryptoForm.invalid) {
      this.touchAllFormControls()
      return
    }
    const crypto = new CryptoAccountForm()
    crypto.customerId = Number(this.$route.params.customerId)
    crypto.currencyId = this.cryptoForm.props.currencyId
    crypto.cryptoAddress = this.cryptoForm.props.cryptoAddress
    crypto.type = 2
    try {
      adminStore.dispatch('loader/show')
      await this.postCryptoAccount(crypto)
      toast.success('Information saved!')
      this.showModal = false
      this.getAccounts()
    } catch (error) {
      console.log(error)
      toast.error('An error has occurred \nPlease try again')
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async handleBankForm(): Promise<void> {
    if (this.bankForm.invalid) return
    const bankAccount = new BankForm()
    bankAccount.type = 1
    bankAccount.city = this.bankForm.props.city
    bankAccount.country = this.bankForm.props.country
    bankAccount.province = this.bankForm.props.province
    bankAccount.bankName = this.bankForm.props.bankName
    bankAccount.postalCode = this.bankForm.props.postalCode
    bankAccount.currencyId = this.bankForm.props.currencyId
    bankAccount.swiftOrBic = this.bankForm.props.swiftOrBic
    bankAccount.bankAddress = this.bankForm.props.bankAddress
    bankAccount.accountName = this.bankForm.props.accountName
    bankAccount.phoneNumber = this.bankForm.props.phoneNumber
    bankAccount.transitNumber = this.bankForm.props.transitNumber
    bankAccount.customerId = Number(this.$route.params.customerId)
    bankAccount.institutionNumber = this.bankForm.props.institutionNumber
    bankAccount.bankAccountNumber = this.bankForm.props.bankAccountNumber
    bankAccount.recipientFullName = this.bankForm.props.recipientFullName
    bankAccount.legalAddressAccountHolder = this.bankForm.props.legalAddressAccountHolder
    try {
      adminStore.dispatch('loader/show')
      await this.postBankAccount(bankAccount)
      toast.success('Information saved!')
      this.showModal = false
      this.getAccounts()
    } catch {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  formatAccountLabel(account: BankAccountItem) {
    if (account.type === 1) {
      return {
        ...account,
        label: `FIAT ${this.formatBankNumber(account.bankAccountNumber)} ${
          account.currencyId
        } ${!account.isActive ? '(Missing)' : ''}`
      }
    } else {
      return {
        ...account,
        label: `CRYPTO ${this.formatCryptoAddress(account.cryptoAddress)} ${
          account.currencyId
        } ${!account.isActive ? '(Missing)' : ''}`
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateAccount(event: any) {
    this.$confirm.require({
      target: event.currentTarget,
      message: 'Are you sure?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.confirmValidateAccount()
      }
    })
  }

  async confirmValidateAccount() {
    try {
      adminStore.dispatch('loader/show')
      await this.validateBankOrCryptoAccount(this.selectedAccount.id)
      toast.success('Account Validated!')
      this.selectedAccount = new BankAccountItem()
      this.getAccounts()
    } catch {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async getAccounts() {
    const userId = Number(this.$route.params.customerId)
    adminStore.dispatch('loader/show')
    try {
      const { data } = await this.fetchAccountsByUser(userId)
      this.userAccounts = data.map(this.formatAccountLabel)
    } catch {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  touchAllFormControls(): void {
    Object.keys(this.cryptoForm.controls).forEach(crl => {
      this.cryptoForm.get(crl).markAsDirty()
    })
    Object.keys(this.bankForm.controls).forEach(crl => {
      this.bankForm.get(crl).markAsDirty()
    })
  }
}
