import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "progress",
  style: {"height":"1px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: ["progress-bar", {'three': _ctx.rows == 2, 'two': _ctx.rows == 1, 'one': _ctx.rows==0}],
      role: "progressbar",
      style: {width: (_ctx.timerCount/_ctx.initialTimerCount)*100+'%'}
    }, null, 6)
  ]))
}