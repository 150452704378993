
import { Options, Vue } from 'vue-class-component'
import { ref } from 'vue'
import VueSelect from 'vue-next-select'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import UserItem from '@/types/users/UserItem'
import AutoComplete from 'primevue/autocomplete'
const users = namespace('users')
const onBehalfOf = namespace('onBehalfOfUser')

@Options({
  data() {
    return {
      selected: ref(null),
      foundUser: null
    }
  },
  mixins: [formatters],
  components: {
    VueSelect,
    AutoComplete
  }
})
export default class SearchClient extends Vue {
  /* eslint-disable */
  selected!: any
  searchInput: string = ''
  resultsSearch = []
  foundUser!: UserItem

  @users.Getter
  topBarUsers!: UserItem[]

  @users.Action
  public getTopBarUsersFilter!: (keyWord) => Promise<any>

  @users.Action
  public clearTopBarUsers!: () => {}

  @onBehalfOf.Action
  public setUser!: (payload) => {}

  async hanldeSearchInput(event) {
    await new Promise(resolve => setTimeout(resolve, 1))
    if (event.target.value.length < 3 && event.target.value.length != 0) {
      this.selected = null
      this.clearTopBarUsers()
    }
    if (this.selected) return
    this.searchInput = event.target.value
  }

  async getFilteredUsers() {
    await new Promise(resolve => setTimeout(resolve, 1))
    if (this.selected || this.searchInput.length < 3) return
    try {
      const keyWord = this.searchInput.split(' ')[0]
      const { data } = await this.getTopBarUsersFilter({ keyWord })
      this.resultsSearch = data
    } catch (error) {
      console.log(error)
    }
  }

  selectCustomer(e) {
    this.foundUser = e.value
  }

  goToOnBehalfOf() {
    if (!this.foundUser) return
    this.setUser(this.foundUser)
    this.$router.push({
      name: 'on-behalf-of.home.manual.order',
      params: { customerId: this.foundUser.id }
    })
  }

  beforeUnmount() {
    this.selected = null
    this.clearTopBarUsers()
  }
}
