
import { Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'

const verify = namespace('verify')

export default class Flinks extends Vue {
  @verify.Action
  public flinksVerification!: (params: object) => Promise<object>

  getParameterByName(name: string, url: string) {
    name = name.replace(/[[\]]/g, '\\$&')

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)

    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
  //eslint-disable-next-line
  async handleState(state: any) {
    const currStep = state.data.step
    if (currStep == 'REDIRECT') {
      const loginId = this.getParameterByName('loginId', state.data.url)
      if (loginId && loginId != '' && loginId.length > 1) {
        await this.flinksVerification({ loginId: loginId })
        this.$router.push({ name: 'Home' })
      }
    }
  }

  mounted() {
    window.addEventListener('message', this.handleState)
  }

  destroyed() {
    window.removeEventListener('message', this.handleState)
  }
}
