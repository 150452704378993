import moment from "moment";
import exactMath from 'exact-math'

const formattersMixin = {
  methods: {
    // eslint-disable-next-line
    format(amount: number | string | null, decimalCount: number = 12, decimal = ".", thousands = ","): string {
      const amountString = typeof amount === 'string' ? amount : amount?.toString() ?? '';
      const amountFraction = amountString.split('.')[1]
      if (amountString == '' || parseFloat(amountString) == 0 || amountFraction == '' || parseInt(amountFraction) == 0) {
        return amountString
      } else {
        try {
          const value = exactMath.floor(parseFloat(amountString.replace(/,/g, '')), -decimalCount).toString()
          const [integer, fraction] = value.split('.')
          const i = integer.toString()
          const j = i.length > 3 ? i.length % 3 : 0
          return (
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (value.split(".")[1] ? decimal + fraction : '')
          )
        } catch (error) {
          return ''
        }
      }
    },
    formatPrice(value: number, decimalCount = 2) {
      if (!value || value === 0) {
        return '0.00'
      }
      if (isNaN(value)) {
        return '0.00'
      }
      if (typeof value === 'string') {
        return '0.00'
      }
      let result = this.separator(exactMath.floor(value, -decimalCount))
      if (!result.includes('.')) {
        result += '.00'
      }
      return result
    },
    formatDate(isoDate: string, format = "YYYY-MM-DD HH:mm:ss") {
      // if (isoDate.split('').pop()?.toLocaleLowerCase() === 'z') {
      //   isoDate = isoDate.slice(0, -1);
      // }
      return moment(isoDate).format(format);
    },
    numberExponentToLarge(numIn) {
      numIn += '' // To cater to numric entries
      let sign = '' // To remember the number sign
      numIn.charAt(0) == '-' && ((numIn = numIn.substring(1)), (sign = '-')) // remove - sign & remember it
      let str = numIn.split(/[eE]/g) // Split numberic string at e or E
      if (str.length < 2) return sign + numIn // Not an Exponent Number? Exit with orginal Num back
      const power = str[1] // Get Exponent (Power) (could be + or -)

      const deciSp = (1.1).toLocaleString().substring(1, 2) // Get Deciaml Separator
      str = str[0].split(deciSp) // Split the Base Number into LH and RH at the decimal point
      let baseRH = str[1] || '', // RH Base part. Make sure we have a RH fraction else ""
        baseLH = str[0] // LH base part.

      if (power >= 0) {
        // ------- Positive Exponents (Process the RH Base Part)
        if (power > baseRH.length) baseRH += '0'.repeat(power - baseRH.length) // Pad with "0" at RH
        baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power) // Insert decSep at the correct place into RH base
        if (baseRH.charAt(baseRH.length - 1) == deciSp) baseRH = baseRH.slice(0, -1) // If decSep at RH end? => remove it
      } else {
        // ------- Negative exponents (Process the LH Base Part)
        const num = Math.abs(power) - baseLH.length // Delta necessary 0's
        if (num > 0) baseLH = '0'.repeat(num) + baseLH // Pad with "0" at LH
        baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power) // Insert "." at the correct place into LH base
        if (baseLH.charAt(0) == deciSp) baseLH = '0' + baseLH // If decSep at LH most? => add "0"
      }
      // Rremove leading and trailing 0's and Return the long number (with sign)
      return sign + (baseLH + baseRH).replace(/^0*(\d+|\d+\.\d+?)\.?0*$/, '$1')
    },
    formatZeros(value: number, exact = 8) {
      if ([null, 0, undefined].includes(exact)) {
        exact = 8
      }
      try {
        if (!value || value === 0) return '0'
        if (typeof value === 'string') {
          value = parseFloat(value + '')
        }
        let result = this.numberExponentToLarge(value)
        if (result.includes(".")) {
          const array = result.split('.')
          const decimals = array[1].slice(0, exact)
          const decimalsArray = decimals.split("")
          while (decimalsArray[decimalsArray.length - 1] === '0') {
            decimalsArray.pop()
          }
          result = array[0] + (decimalsArray.length === 0 ? '' : '.' + decimalsArray.join(""))
        }
        return this.separator(result)
      } catch (error) {
        return '0'
      }
    },
    separator(numb): string {
      try {
        const str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
      } catch (error) {
        return '0';
      }
    },
    hideEmail(email: string) {
      return email
    },
    titleize(value): string {
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    },
    separeteByCapital(value: string): string {
      const formatedString = this.titleize(value)
      if (!formatedString)
        return ""
      return formatedString.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") || "";
    },
    formatCryptoAddress(address: string): string {
      try {
        // const showText = address.substr(address.length - 4)
        // return `***********${showText}`
        return address
      } catch (error) {
        return 'Invalid crypto address'
      }
    },
    formatBankNumber(number: string): string {
      try {
        // const showText = number.substr(number.length - 4)
        // return `**************${showText}`
        return number
      } catch (error) {
        return 'Invalid bank number'
      }
    },
    showWithOrNotDollarSing(currencyId: string, value: number): string {
      if (['USD', 'CAD', 'MXN', 'EUR'].includes(currencyId.toUpperCase()))
        return `$ ${this.formatPrice(value)}`

      return this.formatZeros(value) + ''
    }
  },
};
export default formattersMixin;
