import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
// import httpCA from '@/http-common-coinapi'
import httpProxy from '@/http-common-proxy'
import { API_KEY_COINAPI } from '@/config/api-keys'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ohlcvParser = function (data: any) {
  // eslint-disable-next-line
  let ohlc: any = []
  // eslint-disable-next-line
  let vol: any = []

  let end!: Date
  // eslint-disable-next-line
  data.forEach((element: any) => {
    ohlc.push({
      x: new Date(element['time_period_start']),
      y: [element['price_open'], element['price_high'], element['price_low'], element['price_close']],
    })
    vol.push({
      x: new Date(element['time_period_start']),
      y: element['volume_traded'],
    })
  })
  return { ohlc: ohlc, vol: vol, end: end }
}
@Module({ namespaced: true, name: '' })
class TradeChart extends VuexModule {
  /* eslint-disable */
  public connection!: any
  public status!: string
  public wsStatus!: string
  public series!: object
  public seriesBar!: object
  public error!: object
  public errorBar!: object
  public interval!: string
  public minInterval!: number
  public lastMinute!: number
  public lastVol!: number
  public seriesUpdate: number = 0

  //rest history
  @Mutation
  public onTradeChart(): void {
    this.status = "fetching"
    this.error = {}
    this.series = [{
      data: [

      ]
    }]
    this.seriesBar = [{
      data: [

      ]
    }]
  }
  @Mutation
  public onTradeChartSuccess(data: any): void {
    const { ohlc, vol } = ohlcvParser(data)
    this.error = {}
    this.series = [{ data: ohlc }]
    this.seriesBar = [{ data: vol }]
    this.lastMinute = new Date(this.series[0].data[this.series[0].data.length - 1].x).getMinutes()
    this.status = "success"
  }
  @Mutation
  public onTradeChartSetInterval(interval: any): void {
    switch (interval) {
      case '15MIN':
        this.minInterval = 15
        break
      case '1HRS':
        this.minInterval = 60
        break
      case '4HRS':
        this.minInterval = 240
        break
      case '8HRS':
        this.minInterval = 480
        break
      case '1DAY':
        this.minInterval = 1440
        break
      case '7DAY':
        this.minInterval = 10080
        break
      default:
        this.minInterval = 240
        break
    }
  }
  @Mutation
  public onTradeChartError(err: object): void {
    this.status = "error"
    this.error = err
  }
  @Action({ rawError: true })
  public getTradeChart(params: any) {
    this.context.commit("onTradeChartSetInterval", params.period)
    return new Promise((resolve, reject) => {
      this.context.commit("onTradeChart")
      httpProxy.get(`ohlcv/history`, { params })
        .then(response => {
          this.context.commit("onTradeChartSuccess", response.data.reverse())
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onTradeChartError", error)
          setTimeout(() => { this.context.dispatch("getTradeChart", params) }, 20000)
          reject(error)
        })
    })
  }
  //websocket
  @Mutation
  public onWs(): void {
    this.status = "fetching"
    this.error = {}
  }
  @Mutation
  public mountedWs(connection: object): void {
    this.connection = connection
  }
  @Mutation
  public onWsSuccess(event: any): void {
    this.seriesUpdate += 1
    this.error = {}
    //parsing ohlc and vol of event from websocket
    const { ohlc, vol } = ohlcvParser([JSON.parse(event.data)])
    //length of series
    const lengthData = this.series[0].data.length

    if (lengthData && lengthData > 0) {
      // last date from series
      const lastDate = new Date(this.series[0].data[lengthData - 1].x)
      // the new date from event
      const newDate = new Date(ohlc[0].x)
      //end date of serie
      const end = lastDate.setMinutes(lastDate.getMinutes() + this.minInterval)
      //new series from event
      const newSerie = ohlc[0].y
      // if the series needs to be updated
      if (newDate.getTime() <= end) {
        let serieBar = 0
        // last serie
        const serie = this.series[0].data[lengthData - 1].y
        //if the volumen needs to be updated
        if (this.lastMinute != newDate.getMinutes()) {
          //assign lastMinute
          this.lastMinute = newDate.getMinutes()
          serieBar = this.seriesBar[0].data[lengthData - 1].y + vol[0].y
        } else {
          serieBar = this.seriesBar[0].data[lengthData - 1].y + vol[0].y - this.lastVol
        }
        this.lastVol = vol[0].y
        //if is high or low
        this.series[0].data[lengthData - 1].y[3] = newSerie[3]
        if (newSerie[3] > serie[1]) {
          this.series[0].data[lengthData - 1].y[1] = newSerie[3]
        } else if (newSerie[3] < serie[2]) {
          this.series[0].data[lengthData - 1].y[2] = newSerie[3]
        }
        this.seriesBar[0].data[lengthData - 1].y = serieBar
      } else {
        ohlc[0].x = end
        vol[0].x = end
        this.series[0].data.push(ohlc[0])
        this.seriesBar[0].data.push(vol[0])
        this.lastVol = vol[0].y
      }
      this.wsStatus = "success"
    }
  }
  @Mutation
  public onWsError(err: object): void {
    this.wsStatus = "error"
    this.errorBar = err
  }
  @Mutation
  public unmountedWs(): void {
    try {
      this.connection.close()
    } catch (error) {
      console.warn(error)
    }
  }
  @Action({ rawError: true })
  public connectWs(params: { crypto: string, fiat: string }) {
    const connection = new WebSocket('wss://ws.coinapi.io/v1/')
    this.context.commit("mountedWs", connection)
    connection.addEventListener('open', (event: any) => {
      connection.send(JSON.stringify({
        "type": "hello",
        "apikey": API_KEY_COINAPI,
        "heartbeat": false,
        "subscribe_data_type": [
          "ohlcv"
        ],
        "subscribe_filter_symbol_id": [
          `${params.fiat == 'USD' ? 'COINBASE' : 'KRAKEN'}_SPOT_${params.crypto}_${params.fiat}$`
        ],
        "subscribe_filter_period_id": [
          "1MIN"
        ]
      }))
      this.context.commit("onWs")
    })

    connection.addEventListener('message', (event: any) => {
      if (this.TradeChartStatus == 'success') {
        this.context.commit("onWsSuccess", event)
      }
    })
    // eslint-disable-next-line
    connection.addEventListener('error', (event: any) => {
      this.context.commit("onWsError", event)
      // setTimeout(() => { this.context.dispatch("connectWs", params)}, 500);
    })
  }

  @Action({ rawError: true })
  public unmountConnection() {
    this.context.commit("unmountedWs")
  }
  public get getSeriesUpdate(): number {
    return this.seriesUpdate

  }
  public get getSeries(): object {
    return this.series
  }
  public get getSeriesBar(): object {
    return this.seriesBar
  }
  public get TradeChartStatus(): string {
    switch (this.status) {
      case "success":
        return 'success'
      case "fetching":
        return 'fetching'
      case "error":
        return 'error'
      default:
        return 'fetching'
    }
  }
}
export default TradeChart
