import { vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("input", {
    class: "form-control text-right",
    style: {"padding-right":"1rem"},
    ref: "amountInput",
    type: "text",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
    onKeypress: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.filterKeyPress && _ctx.filterKeyPress(...args))),
    onKeyup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.filterKeyUp && _ctx.filterKeyUp(...args))),
    onClick: _cache[4] || (_cache[4] = 
      () => {
        _ctx.value = _ctx.value == 0 ? _ctx.value = null : _ctx.formatAmount(_ctx.value)
      }
    ),
    onBlur: _cache[5] || (_cache[5] = ()=>{
      _ctx.value = _ctx.formatAmount(_ctx.value)
    })
  }, null, 544)), [
    [_vModelText, _ctx.value]
  ])
}