import axios from "axios"
import { useToast } from "vue-toastification";
import { BACKEND_URL } from "./config/config";
import app from "./main"
import { customerStore } from "./store/customer";



const handleHttpError = (code) => {
  switch (code) {
    case 401:
    case 418:
      customerStore.dispatch("profile/logout")
      break;
    default:
  }
}


const instance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true,
})
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("access-token")
    const csrf = app?.config?.globalProperties?.$store?.getters["security/getCSRFToken"]
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (config.method && ['post', 'put', 'patch'].includes(config.method) && csrf) {
      config.headers['X-CSRF-TOKEN'] = csrf
    }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => {
    app.config.globalProperties.$store.dispatch("loader/hidden")
    if (response.headers['xsrf-token']) {
      app.config.globalProperties.$store.commit("security/setCSRFToken", response.headers['xsrf-token'])
    }
    return response
  },
  async error => {
    const originalConfig = error.config
    app.config.globalProperties.$store.dispatch("loader/hidden")
    if (error.config.url !== 'auth/refreshToken' && error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        await app.config.globalProperties.$store.dispatch("profile/postRefreshJwtToken")
        return instance(originalConfig);
      } catch (error) {
        console.log(error);
        await customerStore.dispatch("profile/logout")
        const toast = useToast()
        toast.clear()
        return instance(originalConfig);
      }
    }
    handleHttpError(error.response.status)
    return Promise.reject(error)
  }
)

export default instance