
import Skeleton from 'primevue/skeleton'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import CardBase from '@/components/common/layout/CardBase.vue'
import VueApexCharts from 'vue3-apexcharts'
import formatters from '@/mixins/formatters'
import * as moment from 'moment'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
const adminReports = namespace('adminReports')

@Options({
  name: 'CurrencyReport',
  components: {
    CardBase,
    apexchart: VueApexCharts,
    Skeleton,
    DataTable,
    Column
  },
  mixins: [formatters]
})
export default class CurrencyReport extends Vue {
  DATE_FORMAT = 'yyyy-MM-DD'
  isLoadingPieChart = true
  isChangingIntervals = false
  mainSearch = ''
  charKey = 0
  currentCurrencyConvert = 'CAD'
  public from = moment
    .default()
    .subtract(3, 'months')
    .format(this.DATE_FORMAT)
  public to = moment.default().format(this.DATE_FORMAT)
  public pairId = ''
  public period = '3M'
  pieSeries: number[] = []
  pieLabels: string[] = []
  pieChartOptions = {
    labels: this.pieLabels
  }
  barSeries: { y: number; x: string }[] = []
  barChartOptions = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#000']
      },
      formatter: this.formatTextLabel,
      offsetX: 0
    }
  }

  skipedAssets: CurrencyItem[] = []

  @adminReports.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRevenues!: (any) => Promise<any>
  @adminReports.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAllCryptpRevenues!: any[]
  @adminReports.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAllFiatRevenues!: any[]

  @adminReports.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSinglePriceCoingecko!: (any) => Promise<any>
  @adminReports.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCryptoPrices!: any

  formatTextLabel(value) {
    if (value === 0) return '$ 0.00'
    if (typeof value === 'string') value = parseFloat(value + '')
    let result = value.toFixed(6)
    result = result.slice(0, -4)
    const str = result.toString().split('.')
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return '$ ' + str.join('.')
  }

  mounted() {
    this.getRevenues({ fromDate: this.from, toDate: this.to }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const coingeckoIds: any[] = []
      const allRevues = [
        ...this.getAllCryptpRevenues,
        ...this.getAllFiatRevenues
      ]
      for (const revenue of allRevues) {
        coingeckoIds.push(revenue.currency.coingeckoId)
      }
      this.getSinglePriceCoingecko({
        coingeckoIds,
        vs: ['cad']
      }).then(() => this.processDataForCharts())
    })
  }

  changeInterval(period: string) {
    if (this.isChangingIntervals) return
    this.isChangingIntervals = true
    if (!period) {
      this.getRevenues({ fromDate: this.from, toDate: this.to }).then(() => {
        this.changeCurrentConvert()
        this.isChangingIntervals = false
      })
      return
    }
    this.period = period
    switch (period) {
      case '1D':
        this.from = moment
          .default()
          .subtract(1, 'days')
          .format(this.DATE_FORMAT)
        break
      case '1W':
        this.from = moment
          .default()
          .subtract(7, 'days')
          .format(this.DATE_FORMAT)
        break
      case '1M':
        this.from = moment
          .default()
          .subtract(1, 'months')
          .format(this.DATE_FORMAT)
        break
      case '3M':
        this.from = moment
          .default()
          .subtract(3, 'months')
          .format(this.DATE_FORMAT)
        break
    }
    this.to = moment.default().format(this.DATE_FORMAT)
    this.getRevenues({ fromDate: this.from, toDate: this.to }).then(() => {
      this.changeCurrentConvert()
      this.isChangingIntervals = false
    })
  }

  processDataForCharts() {
    this.charKey++
    this.isLoadingPieChart = true
    const allRevues = [...this.getAllCryptpRevenues, ...this.getAllFiatRevenues]
    for (const revenue of allRevues) {
      const prices = this.getCryptoPrices[revenue.currency.coingeckoId]
      let revenueAmount = 0
      if (this.currentCurrencyConvert === revenue.currency.id) {
        revenueAmount = revenue.totalRevenues
      } else {
        try {
          revenueAmount =
            revenue.totalRevenues *
            prices[this.currentCurrencyConvert.toLowerCase()]
        } catch {
          this.skipedAssets.push(revenue.currency)
          continue
        }
      }
      const label = revenue.currency.id
      if (revenueAmount > 0) {
        this.pieLabels.push(label)
        this.pieSeries.push(revenueAmount)
        this.barSeries.push({ y: revenueAmount, x: label })
      }
    }

    this.isLoadingPieChart = false
  }

  changeCurrentConvert() {
    this.pieLabels = []
    this.pieSeries = []
    // Bar car
    this.barSeries = []
    this.charKey++
    this.skipedAssets = []
    setTimeout(() => {
      this.processDataForCharts()
    }, 700)
  }

  get getFiatRevenue(): number {
    let fiatRevenue = 0
    for (const revenue of this.getAllFiatRevenues) {
      try {
        if (revenue.currency.id === this.currentCurrencyConvert) {
          fiatRevenue += revenue.totalRevenues
          continue
        } else {
          const price = this.getCryptoPrices[revenue.currency.coingeckoId].cad
          fiatRevenue += price * revenue.totalRevenues
        }
      } catch (error) {
        continue
      }
    }
    return fiatRevenue
  }

  get getCryptoRevenue(): number {
    let cryptoRevenue = 0
    for (const revenue of this.getAllCryptpRevenues) {
      try {
        const price = this.getCryptoPrices[revenue.currency.coingeckoId].cad
        const total = revenue.totalRevenues * price
        cryptoRevenue += total
      } catch (error) {
        continue
      }
    }
    return cryptoRevenue
  }

  get getFilteredRevenues() {
    if (!this.mainSearch) {
      return [...this.getAllFiatRevenues, ...this.getAllCryptpRevenues]
    }
    return [
      ...this.getAllFiatRevenues,
      ...this.getAllCryptpRevenues
    ].filter(rev => JSON.stringify(rev).includes(this.mainSearch))
  }
}
