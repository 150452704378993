const periodsMixin = {
    data() {
      return {
        periods: [//15 mins 1 hr 4 hr 8hr 24hr/1d 1 semana
            {
                id: '15MIN',
                name: '15 minutes',
                symbol: '15M',
                minutes: 15
            },
            {
                id: '1HRS',
                name: '1 hour',
                symbol: '1H',
                minutes: 60
            },
            {
                id: '4HRS',
                name: '4 hours',
                symbol: '4H',
                minutes: 240
            },
            {
                id: '8HRS',
                name: '8 hours',
                symbol: '8H',
                minutes: 480
            },
            {
                id: '1DAY',
                name: '1 day',
                symbol: '1D',
                minutes: 1440
            },
            {
                id: '7DAY',
                name: '1 week',
                symbol: '1W',
                minutes: 10080
            },
        ],
      }
    }
}
export default periodsMixin