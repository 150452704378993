
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    localCurrency: String,
    cryptoCurrency: String,
    reverse: Boolean,
    width: String,
    height: String,
    color: Boolean,
    pairType: String,
  }
})
export default class IconsCurrency extends Vue {
  localCurrency!: string
  cryptoCurrency!: string
  reverse!: boolean
  width!: string
  height!: string
  pairType!: string
  getImgUrl(img: string) {
    try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(`@/assets/currencies/${img}.svg`).toString()
    } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(`@/assets/logo.png`).toString()
    }
    
  }
}
