
const defaultLayout = 'default'
const defaultAdminLayout = 'default-admin'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
const loader = namespace('loader')
@Options({
  computed: {
    layout() {
      return (
        (this.$route.meta.layout ||
          (this.$isAdmin ? defaultAdminLayout : defaultLayout)) + '-layout'
      )
    }
  }
})
export default class App extends Vue {
  @loader.Getter
  public loader!: boolean
  mounted() {
    console.log('%cStop!', 'color: red; font-size: 30px; font-weight: bold;')
    console.log(
      'This is a browser feature intended for developers. If someone told you to copy-paste here to eneable a new feature or "hack" something, it is a scam and will give access to your account.'
    )
  }
}
