
import { Options, Vue } from 'vue-class-component'
import { adminStore } from '@/store/admin'
import { namespace } from 'vuex-class'
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
const profile = namespace('profile')
const onBehalfOf = namespace('onBehalfOfUser')
@Options({})
export default class OnBehalfOfNavbar extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public links!: any[]

  @profile.State
  public status!: object

  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: any

  @profile.Getter
  public emailProfile!: string

  @onBehalfOf.Action
  public getUser!: (userId: number) => Promise<object>

  @onBehalfOf.State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user!: any

  @profile.Action
  public logout!: () => void

  async signOut() {
    await this.logout()
  }

  async mounted() {
    try {
      adminStore.dispatch('loader/show')
      await this.getUser(Number(this.$route.params.customerId))
    } catch (error) {
      console.log(error)
    }
  }
}
