
import { ENVIRONMENT } from '@/config/config'
import Sidebar from 'primevue/sidebar'
import { Options, Vue } from 'vue-class-component'
@Options({
  name: 'SiteFooter',
  components: {
    Sidebar
  }
})
export default class SiteFooter extends Vue {
  environment = ENVIRONMENT
  visibleBottom = false
  isOnline = true
  message = ''
  mounted() {
    window.addEventListener('online', this.onOnline)
    window.addEventListener('offline', this.onOffline)
    setInterval(() => {
      this.checkOnline()
    }, 7000)
  }

  checkOnline() {
    navigator.onLine ? this.onOnline() : this.onOffline()
  }

  onOnline() {
    this.isOnline = true
    this.message = 'Back to online'
    setTimeout(() => {
      this.visibleBottom = false
    }, 1500)
  }
  onOffline() {
    this.isOnline = false
    this.visibleBottom = true
    this.message = 'No connection'
  }
}
