
import InputSwitch from 'primevue/inputswitch'
import { Options, Vue } from 'vue-class-component'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import Column from 'primevue/column'
import { useToast } from 'vue-toastification'
import Divider from 'primevue/divider'
import DataTable from 'primevue/datatable'
import LoginLogs from '@/types/users/LoginLog'
import CardBase from '@/components/common/layout/CardBase.vue'
import CopyToClipboard from '@/components/common/buttons/CopyToClipboard.vue'
const toast = useToast()
const users = namespace('users')

@Options({
  name: 'GrandPermissionsToAdmins',
  components: {
    InputSwitch,
    Divider,
    DataTable,
    Column,
    CopyToClipboard,
    CardBase
  },
  mixins: [formatters]
})
export default class SecurityOptions extends Vue {
  mainSearch = ''
  mantenice = false
  configs: { concept: string; id: number; isActive: boolean }[] = []
  loginLogs: LoginLogs[] = []

  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listConfig!: () => Promise<any[]>

  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listAdminLoginLogs!: () => Promise<any[]>

  @users.Action
  updateConfig!: (config: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    configurationId: number
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    isActive: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any[]>

  async getListConfig() {
    const data = await this.listConfig()
    this.configs = data
  }

  async getListCustomerLogins() {
    const data = await this.listAdminLoginLogs()
    this.loginLogs = data
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async changeConfig(config: any): Promise<void> {
    try {
      await this.updateConfig({
        configurationId: config.id,
        isActive: config.isActive
      })
      toast.success('Configuration saved!')
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    }
  }

  mounted() {
    this.getListConfig()
    this.getListCustomerLogins()
  }

  formatDate!: (address: string) => string

  get filteredItems(): LoginLogs[] {
    if (!this.mainSearch) {
      return this.loginLogs
    }
    return this.loginLogs.filter(log => {
      if (
        log.id.toString().includes(this.mainSearch.toLowerCase()) ||
        this.formatDate(log.loginDate.toString()).includes(
          this.mainSearch.toLowerCase()
        ) ||
        log.ip.toLowerCase().includes(this.mainSearch.toLowerCase()) ||
        log.userId
          .toString()
          .toLowerCase()
          .includes(this.mainSearch.toLowerCase())
      ) {
        return log
      }
    })
  }
}
