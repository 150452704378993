import { email, minLength, required, password, prop, compare, maxLength, url } from "@rxweb/reactive-forms";


export default class BusinessRegisterForm {

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    fullLegalName!: string;

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    lastName!: string;

    @email({ message: "Please enter a valid email address" })
    @maxLength({ value: 65, message: "Max length 65 caracters" })
    @required()
    email!: string;

    @prop()
    @required()
    @password(
        {
            validation: { maxLength: 36, minLength: 6, digit: true, specialCharacter: true, upperCase: true },
            message: "Invalid password. It must be min. 6 characters, also it must contains at least a number and one uppercase character and special characters (, . @ () {} * | ? ! # $ % ^ &) "
        }
    )
    password!: string;

    @required()
    @compare({ fieldName: 'password', message: "Passwords do not match" })
    confirmPassword!: string;

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    entityName!: string;

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    entityType!: string;

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 70, message: 'Max value 70 caracaters' })
    @required()
    corporateAdress!: string;

    @url({ message: "Please enter a valid url" })
    @maxLength({ value: 150, message: "Max length 150 caracters" })
    companyWebsite!: string;

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    natureBusiness!: string;

    @required()
    miningBitcoin!: boolean;

    @required()
    hasFintracOrMsb!: boolean;

    @required()
    articlesIncorporation!: File;

    @required()
    addressProof!: File;

    @required()
    hasIndividualEntity!: boolean;

    hasBeneficialOwner!: boolean;

    energyBill!: File;

    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    walletAddress!: string;

    partnerAssociate: string[] = [];
}