import { Action, Module, VuexModule } from "vuex-module-decorators";
import http from "@/http-common-customer"
import WithdrawalsForm from "@/types/pending-deposit/WithdrawalsForm";
import DepositForm from "@/types/pending-deposit/DepositForm";

@Module({ namespaced: true, name: '' })
export default class DepositWithdrawal extends VuexModule {
    @Action({ rawError: true })
    public registerWithdrawal(withdrawal: WithdrawalsForm) {
        return new Promise((resolve, reject) => {
            http
                .post('customer-withdrawal/register', withdrawal)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public registerDeposit(deposit: DepositForm): Promise<void> {
        return new Promise((resolve, reject) => {
            const form = new FormData()
            if (deposit.file) {
                form.append("file", deposit.file)
            }
            if (deposit.transactionAuthCode) {
                form.set("transactionAuthCode", deposit.transactionAuthCode)
            }
            form.set("currencyId", deposit.currencyId)
            form.set("amount", deposit.amount.toString())
            http
                .post(`customer-deposit/register/`, form)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}