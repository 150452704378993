import { maxLength, minLength, required, maxNumber, minNumber, numeric, NumericValueType } from "@rxweb/reactive-forms";
import CoingeckoItem from "./CoingeckoItem";
export default class CurrencyForm {
    @required()
    @maxLength({ value: 12, message: "Max length 12" })
    @minLength({ value: 2, message: "Min length 2" })
    id!: string;

    @required()
    @maxLength({ value: 20, message: "Max length 20" })
    description!: string;

    @required()
    currencyType!: number;

    @required()
    coingeckoId!: string;

    @required()
    @minNumber({ value: 2, message: "Min value 2" })
    @maxNumber({ value: 15, message: "Max value 15" })
    @numeric({ allowDecimal: false, acceptValue: NumericValueType.PositiveNumber, message: "Invalid value" })
    exact!: number

    coingeckoObject!: CoingeckoItem

    vault!: boolean;
    stake!: boolean;
    lend!: boolean;
}