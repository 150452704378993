import { RouteRecordRaw } from 'vue-router'
import AuthBase from '@/views/admin/Auth.vue'
import AdminLogin from '@/components/admin/auth/AdminLogin.vue'
import ForgotPassword from '@/components/customer/auth/ForgotPassword.vue'
import ResetPassword from '@/components/customer/auth/ResetPassword.vue'
// import Register from '@/components/customer/auth/Register.vue'
// import Confirm from '@/components/customer/auth/Confirm.vue'
import ReSend from '@/components/customer/auth/ReSend.vue'
import { ifAdminNotAuthenticated } from '../guards'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthBase,
    beforeEnter: ifAdminNotAuthenticated,
    children: [
      {
        path: "login",
        component: AdminLogin,
        name: "auth.login",
        meta: { layout: "auth" }
      },
      {
        path: "forgot",
        component: ForgotPassword,
        name: "auth.forgot",
        meta: { layout: "auth" }
      },
      {
        path: "reset/:token",
        component: ResetPassword,
        name: "auth.reset",
        props: true,
        meta: { layout: "auth" }
      },
      {
        path: "resend/:email",
        component: ReSend,
        props: true,
        name: "auth.resend",
        meta: { layout: "auth" }
      },
    ]
  }
]
export default routes