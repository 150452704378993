import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-admin'
import { useToast } from "vue-toastification"
import WithdrawalItem from '@/types/account-movement/WithdrawalItem'
import WithdrawalsForm from '@/types/pending-deposit/WithdrawalsForm'
const toast = useToast()
@Module({ namespaced: true, name: '' })
class Withdrawals extends VuexModule {
  public status!: string
  public data!: object
  public error!: object
  public withdrawal!: WithdrawalItem

  public manageWithdrawalId!: number
  public manageWithdrawalResult!: object
  public manageWithdrawalError!: object
  public manageWithdrawalStatus!: string

  @Mutation
  public setWithdrawal(withdrawal: WithdrawalItem): void {
    this.withdrawal = withdrawal;
  }

  @Mutation
  public onWithdrawals(): void {
    this.status = "fetching"
    this.error = {}
    this.data = []
  }

  @Mutation
  public onWithdrawalsSuccess(data: object): void {
    this.status = "success"
    this.error = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any[]).forEach(e => {
      e.customer.fullName = e.customer.firstName + ' ' + e.customer.lastName;
    });
    this.data = data
  }

  @Mutation
  public onWithdrawalsError(err: object): void {
    this.status = "error"
    this.error = err
  }

  @Mutation
  public onManageWithdrawal({ withdrawalId }): void {
    this.manageWithdrawalStatus = "fetching"
    this.manageWithdrawalId = withdrawalId
    this.manageWithdrawalError = {}
    this.manageWithdrawalResult = {}
  }

  @Mutation
  public onManageWithdrawalSuccess(data: object): void {
    this.manageWithdrawalStatus = "success"
    this.manageWithdrawalId = 0
    this.manageWithdrawalError = {};
    this.manageWithdrawalResult = data
  }

  @Mutation
  public onManageWithdrawalError(err: object): void {
    this.manageWithdrawalStatus = "error"
    this.manageWithdrawalId = 0
    this.manageWithdrawalError = err
  }

  @Action({ rawError: true })
  public getPendingWithdrawals() {
    return new Promise((resolve, reject) => {
      this.context.commit("onWithdrawals")
      http
        .get('customer-withdrawal/internal/list?status=1')
        .then(response => {
          this.context.commit("onWithdrawalsSuccess", response.data)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onWithdrawalsError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public withdrawalDone(payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onManageWithdrawal", { withdrawalId: payload.withdrawalId })
      http
        .post(`customer-withdrawal/internal/authorize`, payload)
        .then(response => {
          this.context.commit("onManageWithdrawalSuccess", { ...response.data, actionType: "accept" })
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onManageWithdrawalError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public cancelWithdrawal(withdrawalId) {
    return new Promise((resolve, reject) => {
      this.context.commit("onManageWithdrawal", { withdrawalId })
      http
        .get(`customer-withdrawal/internal/revert/${withdrawalId}`)
        .then(response => {
          this.context.commit("onManageWithdrawalSuccess", { ...response.data, actionType: "reject" })
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onManageWithdrawalError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public fetchAccountsByUser(customerId: string | number) {
    return new Promise((resolve, reject) => {
      http
        .get(`customer-bank-accounts/internal/list/${customerId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public postWithdrawal(withdrawal: WithdrawalsForm) {
    const data = {
      ...withdrawal
    }
    return new Promise((resolve, reject) => {
      http
        .post(`customer-withdrawal/internal/register`, { ...data })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  public get withdrawals(): object {
    return this.data
  }

  public get selectedWithdrawal(): WithdrawalItem {
    return this.withdrawal
  }

  public get withdrawalDetails() {
    return this.manageWithdrawalResult
  }

  @Action({ rawError: true })
  public getSpecificWithdrawal(withdrawalId: number | string): Promise<WithdrawalItem> {
    return new Promise((resolve, reject) => {
      this.context.commit("onManageWithdrawal", { withdrawalId })
      http
        .get(`customer-withdrawal/internal/details/${withdrawalId}`)
        .then(response => {
          resolve(response.data)
          this.context.commit("onManageWithdrawalSuccess", { ...response.data, actionType: "details" })
        })
        .catch(error => {
          this.context.commit("onManageWithdrawalError", error)
          reject(error)
        })
    })
  }
}
export default Withdrawals