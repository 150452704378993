
import TheBanner from '@/components/customer/auth/components/TheBanner.vue'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Payload } from '@/types'
import { useToast } from 'vue-toastification'
import { required, email, minLength } from '@vuelidate/validators'
import Password from 'primevue/password'
import Verify2fa from '@/components/customer/modals/verify2fa.vue'
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
const toast = useToast()
const profile = namespace('profile')
const twofactorauthentication = namespace('twofactorauthentication')
@Options({
  components: {
    TheBanner,
    Password,
    Verify2fa
  },
  data() {
    return {
      showVerify: false,
      email: null,
      password: null
    }
  },
  validations() {
    return {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
        // containsUppercase: (value: string) => /[A-Z]/.test(value) ,
        // containsLowercase: (value: string) => /[a-z]/.test(value),
        // containsNumber: (value: string) => /[0-9]/.test(value),
        // containsSpecial: (value: string) => /[#?!@$%^&*-]/.test(value),
      }
    }
  }
})
export default class AdminLogin extends Vue {
  email!: string
  password!: string
  // eslint-disable-next-line
  $v!: any
  showVerify!: boolean
  @profile.State
  public status!: string

  @profile.Action
  public login!: (payload: Payload) => Promise<object>
  
  @twofactorauthentication.Action
  public setingTemporalToken!: (temporalToken: string) => void

  // eslint-disable-next-line
  beforeRouteEnter(to: any, from: any, next: Function) {
    // eslint-disable-next-line
    next((vm: any) => {
      vm.$v.$reset()
    })
  }

  handleLoginError(statusCode: number): void {
    switch (statusCode) {
      case 301:
        toast.warning('You have not yet confirmed your email!')
        this.$router.push({
          name: `auth.resend`,
          params: { email: this.email }
        })
        break
      case 500:
        toast.warning('Internal server error')
        break
      default:
        toast.warning('Somthing went wrong, try later')
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSuccessResponse(response: any): void {
    if (response.status == 201) {
      this.setingTemporalToken(response.data.tokenTwoFactor)
      if (response.data.twoFactorAuthenticationEnabled) {
        this.showVerify = true
      }else {
        toast.clear()
        toast.success('Welcome to Satstreet!')
        this.$router.push({ name: 'dashboard' })
      }
    }
  }

  close2fa() {
    this.showVerify = false
  }
  success2fa(): void {
    toast.success('Welcome to Satstreet!')
    this.$router.push({ name: 'dashboard' })
  }
  async submit() {
    this.$v.$touch()
    if (this.$v.$error) {
      return toast.error('Check all fields!')
    }

    try {
      // eslint-disable-next-line
      const response: any = await this.login({
        email: this.email,
        password: this.password
      })
      this.handleSuccessResponse(response)
    } catch (error) {
      console.log(error)
      return toast.error('Invalid credentials!')
    }
  }
}
