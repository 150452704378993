import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Terms from "../../views/admin/Terms.vue";
import { ifAdminAuthenticated } from "./guards";
import Auth from "./modules/auth";
import Empty from "@/components/admin/dashboard/empty.vue";
import Dashboard from "./modules/dashboard";
import OnBehalfOf from "./modules/on-behalf-of";
import CommercialGroups from "./modules/commercial-groups";
import Permissions from "./modules/permissions";
import Currencies from "./modules/currencies";
import Pairs from "./modules/pairs";
import Revenues from "./modules/revenues";
import Providers from "./modules/providers";
import ReportsAdmin from "./modules/reports-admin";
import { adminStore } from "@/store/admin";

const routes: Array<RouteRecordRaw> = [
  ...Currencies,
  ...Auth,
  ...Dashboard,
  ...OnBehalfOf,
  ...CommercialGroups,
  ...Permissions,
  ...Pairs,
  ...Revenues,
  ...Providers,
  ...ReportsAdmin,
  {
    path: "/",
    name: "root",
    component: Empty,
    beforeEnter: ifAdminAuthenticated,
    redirect: "dashboard",
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: { layout: "auth" },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const adminRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

adminRouter.afterEach(() => {
  adminStore.dispatch("loader/hidden");
});

export { adminRouter };
