import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import { useToast } from "vue-toastification"
const toast = useToast()
@Module({ namespaced: true, name: '' })
class TwoFactorAuthentication extends VuexModule {
  public status!: string
  public data!: object
  public error!: object
  public temporalToken!: string

  @Mutation
  public setTemporalToken(temporalToken: string): void {
    this.temporalToken = temporalToken;
  }
  @Mutation
  public onFetching(): void {
    this.status = "fetching";
    this.error = {};
    this.data = {};
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onSuccess(data: any): void {
    this.status = "success";
    this.error = {};
    this.data = data;
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onError(err: any): void {
    this.status = "error";
    this.error = err;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast.error(!err.response.data.message ? err.response.data.errors.find((u: any) => u).message : err.response.data.message);
  }
  @Action({ rawError: true })
  public getQRCode() {
    return new Promise((resolve, reject) => {
      this.context.commit("onFetching")
      http
        .post('2fa/generate', {}, { responseType: 'arraybuffer' })
        .then(response => {
          this.context.commit("onSuccess", response.data)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onError", error)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public token2fa() {
    return new Promise((resolve, reject) => {
      this.context.commit("onFetching")
      http
        .post('2fa/generateToken2fa', {})
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onError", error)
          reject(error)
        })
    })
  }
  public get get2faStatus(): string {
    return this.status;
  }
  public get get2faData(): object {
    return this.data
  }

  public get getTemporalToken(): string {
    return this.temporalToken
  }
}
export default TwoFactorAuthentication