
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    disable: {
      type: Boolean,
      default: false
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    rightHeaderBadge: String,
    size: String,
    okText: String,
    okButtonClass: String,
  },
})
export default class Modal extends Vue {
  show!: boolean;
  okText!: string;
  title!: string;
  disable!: boolean
  rightHeaderBadge!: string
  size!: string
  showCancelButton!: boolean
  okButtonClass!: string;

  close() {
    this.$emit("close");
  }

  ok() {
    this.$emit("ok");
  }
}
