
import { adminStore } from '@/store/admin'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import { Options, Vue } from 'vue-class-component'
import Modal from '@/components/common/modal.vue'
import { useToast } from 'vue-toastification'
import CommercialGroup from '@/types/commercial-group/CommercialGroup'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
const commercialGroups = namespace('commercialGroups')
const toast = useToast()
Vue.registerHooks(['beforeRouteEnter'])
@Options({
  data() {
    return {
      showModal: false,
      editingPairId: '',
      newSpread: 0
    }
  },
  mixins: [formatters],
  components: {
    IconsCurrency,
    DataTable,
    Column,
    Modal
  }
})
export default class EditCommercialGroup extends Vue {
  showModal!: boolean
  editingPairId!: string
  newSpread!: number

  @commercialGroups.Getter
  public selectedCommercialGroup!: CommercialGroup
  @commercialGroups.State
  public saveCommercialGroupStatus!: string

  /*@commercialGroups.Getter
  public currentStatus!: string;*/

  @commercialGroups.Action
  public getSpecificCommercialGroup!: (id: number) => Promise<CommercialGroup>
  @commercialGroups.Action
  public saveCommercialGroup!: () => Promise<CommercialGroup>
  @commercialGroups.Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addPlanPairToCommercialGroup!: (pairId: string) => any
  @commercialGroups.Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removePlanPairFromCommercialGroup!: (pairId: string) => any
  @commercialGroups.Mutation
  public editPlanPairSpreadForCommercialGroup!: ({
    pairId: string,
    spread: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => any

  async mounted() {
    adminStore.dispatch('loader/show')
    document.addEventListener('contextmenu', e => e.preventDefault())
    try {
      await this.getSpecificCommercialGroup(Number(this.$route.params.id))
    } catch (e) {
      console.log(e)
    } finally {
      adminStore.dispatch('loader/hidden')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(document.getElementById('main-scroll') as any).scrollTop = 0
    }
  }

  addPair(pairId: string) {
    this.addPlanPairToCommercialGroup(pairId)
  }

  removePair(pairId: string) {
    this.removePlanPairFromCommercialGroup(pairId)
  }

  editSpread(pairId: string, spread: number) {
    this.editingPairId = pairId
    this.newSpread = spread
    this.showModal = true
  }

  saveEditSpread() {
    this.editPlanPairSpreadForCommercialGroup({
      pairId: this.editingPairId,
      spread: this.newSpread
    })
    this.showModal = false
  }

  async saveChanges() {
    try {
      await this.saveCommercialGroup()
      toast.success('Changes saved successfully')
      this.$router.back()
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    }
  }

  rowClass(data) {
    return data.id ? 'row-active' : 'row-inactive'
  }

  get allReady() {
    return !!this.selectedCommercialGroup?.name
  }
}
