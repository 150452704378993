import { email, maxLength, minLength, required } from "@rxweb/reactive-forms";

export default class AuthorizedSignerForm {
    @minLength({ value: 2, message: 'Min value 2 caracaters' })
    @maxLength({ value: 50, message: 'Max value 50 caracaters' })
    @required()
    name!: string;

    @email({ message: 'Invalid email' })
    @required()
    @maxLength({ value: 65, message: "Max length 65 caracters" })
    email!: string;

    @required()
    withGovernment!: boolean;

    @required()
    identification!: File;

    identificationBack!: File;
}