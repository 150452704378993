
import RadioButton from 'primevue/radiobutton'
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import BankForm from '@/types/bank-account/BankAccountForm'
import CryptoAccountForm from '@/types/bank-account/CryptoAccountForm'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import { useToast } from 'vue-toastification'
import { customerStore } from '@/store/customer'
import BankAccountItem from '@/types/bank-account/BankAccountItem'
const bankAccount = namespace('bankAccount')
const profile = namespace('profile')
const assets = namespace('assets')
const toast = useToast()

@Options({
  components: {
    RadioButton
  }
})
export default class BankAccountForm extends Vue {
  public isLoading = true
  public bankForm!: IFormGroup<BankForm>
  public cryptoForm!: IFormGroup<CryptoAccountForm>
  public isCrypto = false
  public isOnlyRead = false
  public isActive = false

  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: any

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  @assets.Getter
  // eslint-disable-next-line
  cryptosAssets!: any

  @assets.Action
  // eslint-disable-next-line
  public getAssets!: () => Promise<any>

  @assets.State
  status!: string

  @bankAccount.Action
  public postBankAccount!: (account: BankForm) => Promise<void>

  @bankAccount.Action
  public postCryptoAccount!: (account: CryptoAccountForm) => Promise<void>

  @bankAccount.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchSpecificAccount!: (id: number | string) => Promise<any>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.bankForm = new RxFormBuilder().formGroup(BankForm) as IFormGroup<
      BankForm
    >
    this.cryptoForm = new RxFormBuilder().formGroup(
      CryptoAccountForm
    ) as IFormGroup<CryptoAccountForm>
  }

  mounted() {
    customerStore.dispatch('loader/show')
    this.getAssets().finally(() => {
      if (this.$route.name === 'bank.account.detail') {
        this.isOnlyRead = true
        const id = this.$route.params.id as string
        this.fetchSpecificAccount(id).then(({ data }) => {
          const item = data
          item.type === 1 ? this.fillFiatData(item) : this.fillCryptoData(item)
          this.isLoading = false
        })
      }
      this.isLoading = false
      customerStore.dispatch('loader/hidden')
    })
  }

  fillCryptoData(item: BankAccountItem) {
    this.isCrypto = true
    this.isActive = item.isActive
    this.cryptoForm.props.currencyId = item.currencyId
    this.cryptoForm.props.accountName = item.accountName
    this.cryptoForm.props.cryptoAddress = item.cryptoAddress
  }

  fillFiatData(item: BankAccountItem) {
    this.isCrypto = false
    this.isActive = item.isActive
    this.bankForm.props.bankName = item.bankName
    this.bankForm.props.currencyId = item.currencyId
    this.bankForm.props.bankAddress = item.bankAddress
    this.bankForm.props.recipientFullName = item.recipientFullName
    this.bankForm.props.bankAccountNumber = item.bankAccountNumber
    this.bankForm.props.postalCode = item.postalCode
    this.bankForm.props.city = item.city
    this.bankForm.props.province = item.province
    this.bankForm.props.country = item.country
    this.bankForm.props.swiftOrBic = item.swiftOrBic
    this.bankForm.props.transitNumber = item.transitNumber
    this.bankForm.props.institutionNumber = item.institutionNumber
    this.bankForm.props.phoneNumber = item.phoneNumber
    this.bankForm.props.accountName = item.accountName
    this.bankForm.props.legalAddressAccountHolder =
      item.legalAddressAccountHolder
  }

  submitForm(): void {
    this.isCrypto ? this.handleCryptoForm() : this.handleBankForm()
  }

  async handleCryptoForm(): Promise<void> {
    this.cryptoForm.props.customerId = this.userProfile.id
    if (this.cryptoForm.invalid) {
      this.touchAllFormControls()
      return
    }
    const crypto = new CryptoAccountForm()
    crypto.currencyId = this.cryptoForm.props.currencyId
    crypto.accountName = this.cryptoForm.props.accountName
    crypto.cryptoAddress = this.cryptoForm.props.cryptoAddress
    crypto.type = 2
    try {
      customerStore.dispatch('loader/show')
      await this.postCryptoAccount(crypto)
      toast.success('Information saved!')
      this.$router.push({ name: 'bank.account.list' })
    } catch {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  async handleBankForm(): Promise<void> {
    this.bankForm.props.customerId = this.userProfile.id
    if (this.bankForm.invalid) return
    const bankAccount = new BankForm()
    bankAccount.type = 1
    bankAccount.city = this.bankForm.props.city
    bankAccount.country = this.bankForm.props.country
    bankAccount.province = this.bankForm.props.province
    bankAccount.bankName = this.bankForm.props.bankName
    bankAccount.postalCode = this.bankForm.props.postalCode
    bankAccount.currencyId = this.bankForm.props.currencyId
    bankAccount.swiftOrBic = this.bankForm.props.swiftOrBic
    bankAccount.bankAddress = this.bankForm.props.bankAddress
    bankAccount.accountName = this.bankForm.props.accountName
    bankAccount.phoneNumber = this.bankForm.props.phoneNumber
    bankAccount.transitNumber = this.bankForm.props.transitNumber
    bankAccount.institutionNumber = this.bankForm.props.institutionNumber
    bankAccount.bankAccountNumber = this.bankForm.props.bankAccountNumber
    bankAccount.recipientFullName = this.bankForm.props.recipientFullName
    bankAccount.legalAddressAccountHolder = this.bankForm.props.legalAddressAccountHolder
    try {
      customerStore.dispatch('loader/show')
      await this.postBankAccount(bankAccount)
      toast.success('Information saved!')
      this.$router.push({ name: 'bank.account.list' })
    } catch {
      toast.error('An error has occurred \nPlease try again')
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  touchAllFormControls(): void {
    Object.keys(this.cryptoForm.controls).forEach(crl => {
      this.cryptoForm.get(crl).markAsDirty()
    })
    Object.keys(this.bankForm.controls).forEach(crl => {
      this.bankForm.get(crl).markAsDirty()
    })
  }
}
