import PermissionItem from "./PermissionItem";
import { email, minLength, required, password } from "@rxweb/reactive-forms";

export default class AdminItem {
    id!: number;

    @required()
    @minLength({ value: 2, message: 'Min length 2' })
    firstName!: string;

    @required()
    @minLength({ value: 2, message: 'Min length 2' })
    lastName!: string;

    @required()
    @email({ message: 'Invalid email' })
    email!: string;

    @password(
        {
            validation: { maxLength: 36, minLength: 5, digit: true, specialCharacter: true, upperCase: true },
            message: "Invalid password. It must be min. 5 characters, also it must contains at least a number and one uppercase character"
        }
    )
    password!: string;

    isActive!: boolean;
    createdAt!: string;
    permissions!: PermissionItem[]
    permissionsCount!: number;
}