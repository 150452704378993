import { createStore } from "vuex";
import profile from "@/store/admin/modules/profile";
import loader from "@/store/admin/modules/loader";
import users from "@/store/admin/modules/users";
import orders from "@/store/admin/modules/orders";
import deposits from "@/store/admin/modules/deposits";
import withdrawals from "@/store/admin/modules/withdrawals";
import dashboard from "@/store/admin/modules/dashboard";
import onBehalfOfUser from "@/store/admin/modules/on-behalf-of-user";
import transactions from "@/store/admin/modules/transactions";
import pairs from "@/store/admin/modules/pairs";
import providers from "@/store/admin/modules/providers";
import balance from "@/store/admin/modules/balance";
import commercialGroups from "@/store/admin/modules/commercial-groups";
import currencies from "@/store/common/currencies";
import prices from "@/store/common/prices";
import assets from "@/store/common/assets";
import security from "@/store/common/security";
import tradeChart from "@/store/common/tradeChart";
import accountStatement from "@/store/admin/modules/accountStatement";
import bankAccount from "@/store/admin/modules/bank-account";
import currenciesSatStreet from "@/store/admin/modules/currencies-satstreet";
import downloads from "@/store/admin/modules/downloads";
import adminReports from "@/store/admin/modules/admin-reports";
import verify from "@/store/admin/modules/verify";
import twofactorauthentication from "@/store/admin/modules/twoFactorAuthentication";
const adminStore = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    security,
    downloads,
    currenciesSatStreet,
    profile,
    loader,
    users,
    orders,
    deposits,
    withdrawals,
    dashboard,
    onBehalfOfUser,
    currencies,
    prices,
    transactions,
    balance,
    assets,
    accountStatement,
    pairs,
    providers,
    commercialGroups,
    tradeChart,
    bankAccount,
    adminReports,
    verify,
    twofactorauthentication
  },
});

export { adminStore };
