/* eslint-disable */
import { adminStore } from "@/store/admin"
import AdminItem from "@/types/users/AdminItem"
import { RouteLocation } from "vue-router"
import { useToast } from "vue-toastification"
const toast = useToast()

export async function ifAdminAuthenticated(to: RouteLocation, from: RouteLocation, next: Function) {
  adminStore.dispatch("loader/show")
  const accessToken = localStorage.getItem("access-token")
  if (!accessToken) {
    next({ name: "auth.login" })
  } else {
    try {
      await adminStore.dispatch("profile/checkToken")
      next();
    } catch (error) {
      adminStore.dispatch("profile/logout")
      next({ name: "auth.login", query: { redirect: to.fullPath } })
    }
  }
}

export function checkPermission(to: RouteLocation, from: RouteLocation, next: Function) {
  if (to.meta.permission) {
    const permission: any = to.meta.permission
    const admin = adminStore.state.profile.data.user as AdminItem
    const permissionFound = admin.permissions.find(p => p.concept === permission.name)
    if (permissionFound) {
      next()
    } else {
      toast.warning("Your account is not allowed to continue")
      next({ name: from.name })
    }
  } else {
    next()
  }
}

export async function ifAdminNotAuthenticated(to: RouteLocation, from: RouteLocation, next: Function) {
  adminStore.dispatch("loader/show")
  const accessToken = localStorage.getItem("access-token")
  if (!accessToken) {
    next()
  } else {
    try {
      await adminStore.dispatch("profile/checkToken")
      next({ name: "dashboard" });
    } catch (error) {
      adminStore.dispatch("profile/logout")
      next()
    }
  }
}