
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import SearchClient from '@/components/admin/dashboard/searchClient.vue'
const profile = namespace('profile')

//OPTION API
@Options({
  components: { SearchClient }
})
export default class AdminSubNavbar extends Vue {
  @profile.State
  public status!: object

  @profile.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userProfile!: any
}
