
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import { Options, Vue } from 'vue-class-component'
import OrderItem from '@/types/orders/OrderItem'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import { adminStore } from '@/store/admin'
const orders = namespace('orders')
Vue.registerHooks(['beforeRouteEnter'])
@Options({
  mixins: [formatters],
  components: {
    IconsCurrency,
    DataTable,
    Column
  }
})
export default class OrderDetails extends Vue {
  @orders.Getter
  public selectedOrder!: OrderItem

  @orders.Getter
  public currentStatus!: string

  @orders.Action
  public getSpecificOrder!: (id: string) => Promise<OrderItem>

  mounted() {
    adminStore.dispatch('loader/show')
    const orderId = this.$route.params.id.toString()
    this.getSpecificOrder(orderId).finally(() =>
      adminStore.dispatch('loader/hidden')
    )
    document.addEventListener('contextmenu', e => e.preventDefault())
    // eslint-disable-next-line
    ;(document.getElementById('main-scroll') as any).scrollTop = 0
  }
}
