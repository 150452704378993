
import ConfirmPopup from 'primevue/confirmpopup'
import { adminStore } from '@/store/admin'
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import Column from 'primevue/column'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import CurrencyForm from '@/types/currencies/CurrencyForm'
import { useToast } from 'vue-toastification'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import CoingeckoItem from '@/types/currencies/CoingeckoItem'
import InputNumber from 'primevue/inputnumber'
import AutoComplete from 'primevue/autocomplete'
import CardBase from '@/components/common/layout/CardBase.vue'
const toast = useToast()
const currenciesSatStreet = namespace('currenciesSatStreet')

@Options({
  name: 'ListCurrencies',
  components: {
    CardBase,
    DataTable,
    Column,
    ConfirmPopup,
    Dialog,
    Dropdown,
    AutoComplete,
    InputNumber
  },
  mixins: [formatters],
  data() {
    return {
      currencyUpdate: null
    }
  }
})
export default class ListCurrencies extends Vue {
  errorCoingecko = ''
  searchInput = ''
  mainSearch = ''
  isLoadingCoingecko = false
  isEditingCoingecko = false
  coingeckoData = []
  isEditing = false
  showModal = false
  curencyForm!: IFormGroup<CurrencyForm>
  currencyUpdate!: CurrencyItem
  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  @currenciesSatStreet.Getter
  getStatus!: string

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem>

  @currenciesSatStreet.Action
  fetchCoingeckoCurrencies!: (id: string) => Promise<CoingeckoItem[]>

  @currenciesSatStreet.Action
  addCurrencies!: (currency: CurrencyItem) => Promise<void>

  @currenciesSatStreet.Action
  updateCurrencies!: (currency: CurrencyItem) => Promise<void>

  @currenciesSatStreet.Action
  deleteCurrency!: (id: string) => Promise<void>

  @currenciesSatStreet.Getter
  coingeckoCurrencies!: CoingeckoItem[]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.curencyForm = new RxFormBuilder().formGroup(
      CurrencyForm
    ) as IFormGroup<CurrencyForm>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectCoingeckoId(item: any): void {
    this.curencyForm.props.coingeckoId = item.value.id
  }

  async getData(): Promise<void> {
    try {
      adminStore.dispatch('loader/show')
      await this.fetchCurrencies()
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async inputAsset() {
    if (!this.curencyForm.props.id) return
    this.curencyForm.props.id = this.curencyForm.props.id.toUpperCase()
    try {
      this.isLoadingCoingecko = true
      this.errorCoingecko = ''
    } catch (error) {
      console.log(error)
      this.errorCoingecko = error.response.data.message
    } finally {
      this.isLoadingCoingecko = false
    }
  }

  addNewAsset(): void {
    this.isEditingCoingecko = true
    this.isEditing = false
    this.curencyForm.props.id = null
    this.curencyForm.props.description = null
    this.curencyForm.props.currencyType = null
    this.curencyForm.props.vault = false
    this.curencyForm.props.lend = false
    this.curencyForm.props.coingeckoId = null
    this.curencyForm.props.stake = false
    this.curencyForm.props.exact = null
    this.searchInput = ''
    this.showModal = true
  }

  edit(item: CurrencyItem): void {
    this.isEditingCoingecko = false
    this.curencyForm.props.id = item.id
    this.curencyForm.props.description = item.description
    this.curencyForm.props.currencyType = item.currencyType
    this.curencyForm.props.stake = item.stake
    this.curencyForm.props.vault = item.vault
    this.curencyForm.props.lend = item.lend
    this.curencyForm.props.coingeckoObject = item.coingeckoObject
    this.curencyForm.props.exact = item.exact
    this.isEditing = true
    this.showModal = true
    this.isLoadingCoingecko = true
    this.searchInput = item.coingeckoObject.name
    this.inputAsset().then(() => {
      this.curencyForm.props.coingeckoId = item.coingeckoId
      this.isLoadingCoingecko = false
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(event: any, item: CurrencyItem): void {
    this.$confirm.require({
      target: event.currentTarget,
      message: 'Are you sure?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        adminStore.dispatch('loader/show')
        try {
          await this.deleteCurrency(item.id)
          toast.success('Asset deleted!')
          setTimeout(() => {
            this.getData()
          }, 2000)
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('An error has occurred \nPlease try again')
          }
        } finally {
          adminStore.dispatch('loader/hidden')
        }
      }
    })
  }

  async saveCurrency(): Promise<void> {
    try {
      if (this.curencyForm.invalid) return
      adminStore.dispatch('loader/show')
      const currency = new CurrencyItem()
      currency.id = this.curencyForm.props.id
      currency.description = this.curencyForm.props.description
      currency.currencyType = this.curencyForm.props.currencyType
      currency.vault = this.curencyForm.props.vault
      currency.stake = this.curencyForm.props.stake
      currency.lend = this.curencyForm.props.lend
      currency.coingeckoId = this.curencyForm.props.coingeckoId
      currency.exact = Number(this.curencyForm.props.exact)
      if (this.isEditing) {
        await this.updateCurrencies(currency)
      } else {
        await this.addCurrencies(currency)
      }
      this.showModal = false
      toast.success('Asset saved!')
      this.getData()
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  mounted() {
    this.getData()
  }

  get filteredItems(): CurrencyItem[] {
    if (!this.mainSearch) {
      return this.getCurrencies
    }
    return this.getCurrencies.filter(c => {
      if (
        c.id.toLowerCase().includes(this.mainSearch.toLowerCase()) ||
        c.description.toLowerCase().includes(this.mainSearch.toLowerCase()) ||
        c.coingeckoId.toLowerCase().includes(this.mainSearch.toLowerCase())
      ) {
        return c
      }
    })
  }
}
