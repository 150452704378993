import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import { ProfileAuth, Payload } from '@/types'
import { useToast } from "vue-toastification"
import ResquestUpdateCustomerProfile from '@/types/auth/ResquestUpdateCustomerProfile'
import { customerRouter } from '@/router/customer'
import { customerStore } from '..'
const toast = useToast()
@Module({ namespaced: true, name: '' })
class Profile extends VuexModule {
  public status!: string
  public data!: ProfileAuth
  public error!: object
  public config = {
    maintenance: false,
  }

  @Mutation
  public onLogout(): void {
    this.status = "success"
    this.error = {}
    this.data = {} as ProfileAuth;
  }

  @Mutation
  public onLogin(): void {
    this.status = "fetching"
    this.error = {}
    this.data = {
      user: {
        email: '',
        lastName: '',
        firstName: '',
        role: '',
        token: '',
        isActive: false,
        isTwoFactorAuthenticationEnabled: false
      }
    }
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onLoginSuccess(payload: any): void {
    if (payload.data.token) {
      localStorage.setItem("access-token", payload.data.token)
    }
    if (payload.data.refreshToken) {
      localStorage.setItem("refresh-token", payload.data.refreshToken)
    }
    this.status = "success"
    this.error = {}
    this.data.user = payload.data.customer;
    if (this.data.user) this.data.user.role = payload.type;
    // Save Config
    if (payload.data.configuration) {
      for (const config of payload.data.configuration) {
        if (config.concept === "maintenance")
          this.config.maintenance = config.isActive
      }
    }

  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onVerifySuccess(): void {
    if (this.data.user) this.data.user.isTwoFactorAuthenticationEnabled = true
  }
  @Mutation
  public onLoginError(err: object): void {
    this.status = "error"
    this.error = err
  }

  @Action({ rawError: true })
  public login(params: Payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onLogin")
      http
        .post(`auth/customer/login`, params)
        .then(response => {
          if (response.data.twoFactorAuthenticationEnabled) {
            // console.log(response.data)
            resolve(response)
          } else {
            this.context.commit("onLoginSuccess", { data: response.data, type: 'customer' })
            resolve(response)
          }
        })
        .catch(error => {
          this.context.commit("onLoginError", error)
          reject(error)
        })
    })
  }


  @Action({ rawError: true })
  public confirm(params: object) {
    return new Promise((resolve, reject) => {
      this.context.commit("onLogin")
      http
        .post(`auth/customer/confirm`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onLoginError", error)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public resend(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/customer/resendConfirmCode`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          toast.error("Invalid account!")
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public checkToken() {
    return new Promise((resolve, reject) => {
      this.context.commit("onLogin")
      http
        .get(`customer`)
        .then(response => {
          this.context.commit("onLoginSuccess", { data: response.data, type: response.data.type })
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onLoginError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.context.commit("onLogin")
      const refreshToken = localStorage.getItem('refresh-token')
      http
        .post(`auth/logout`, { refreshToken })
        .then(() => {
          localStorage.clear();
          customerRouter.push({ name: 'auth.login' })
          customerStore.commit('security/removeCSRFToken')
          resolve()
        })
        .catch(error => {
          localStorage.clear();
          customerRouter.push({ name: 'auth.login' })
          customerStore.commit('security/removeCSRFToken')
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public sendEmailForgotPassword(params: { email: string }) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/customer/requestPasswordReset`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public resetUserPassword(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/customer/resetPassword`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public updateProfile(params: ResquestUpdateCustomerProfile) {
    return new Promise((resolve, reject) => {
      http
        .put(`customer`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public verify(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post('2fa/check', params)
        .then(response => {
          this.context.commit("onLoginSuccess", { data: response.data, type: 'customer' })
          this.context.commit("onVerifySuccess", response.data)
          localStorage.setItem("2faSuccess", response.data.customer.updatedAt)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public active2fa(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post('2fa/enable', params)
        .then(response => {
          this.context.commit("onVerifySuccess", response.data)
          localStorage.setItem("2faSuccess", response.data.updatedAt)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public updateAntiphishingWord(antiphishingWord: string) {
    return new Promise((resolve, reject) => {
      http
        .put('customer/updateAntiphishingWord', { antiphishingWord })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public getAntiphishingWord() {
    return new Promise((resolve, reject) => {
      http
        .get('customer/getAntiphishingWord')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public updatePassword(params: object) {
    return new Promise((resolve, reject) => {
      http
        .post(`auth/customer/updatePassword`, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public clearAntiphisingWord() {
    return new Promise((resolve, reject) => {
      http
        .delete('customer/deleteAntiphishingWord')
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public postRefreshJwtToken() {
    return new Promise((resolve, reject) => {
      const refreshToken = localStorage.getItem('refresh-token')
      http
        .post('auth/refreshToken', { refreshToken })
        .then(response => {
          this.context.commit('onRefreshTokenSuccess', response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit('onRefreshTokenError', error)
          reject(error)
        })
    })
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onRefreshTokenSuccess(payload: any): void {
    const { token, refreshToken } = payload.data
    localStorage.setItem("access-token", token)
    localStorage.setItem("refresh-token", refreshToken)
    this.error = {}
  }
  @Mutation
  public onRefreshTokenError(err: object): void {
    // this.context.dispatch('logout')
    this.error = err
  }
  get emailProfile() {
    return this.data?.user?.email
  }

  get userProfile() {
    return this.data?.user
  }
}
export default Profile