import { RouteRecordRaw } from "vue-router";
import CommercialGroups from "@/components/admin/commercial-groups/CommercialGroups.vue";
import EditCommercialGroup from "@/components/admin/commercial-groups/EditCommercialGroup.vue";
import CreateCommercialGroup from "@/components/admin/commercial-groups/CreateCommercialGroup.vue";
import MangeCustomers from "@/components/admin/commercial-groups/MangeCustomers.vue";
import CommercialGroupsBase from "@/views/admin/CommercialGroupsBase.vue";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import SitePermissionsEnum from "@/types/users/SitePermissionsEnum";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/commercial-groups",
    name: "CommercialGroups",
    component: CommercialGroupsBase,
    beforeEnter: [ifAdminAuthenticated, checkPermission],
    meta: {
      permission: SitePermissionsEnum.commercialGroups
    },
    redirect: "",
    children: [
      {
        path: "",
        name: "CommercialGroups.list",
        component: CommercialGroups,
        beforeEnter: [checkPermission],
        meta: {
          layout: "default-admin",
          permission: SitePermissionsEnum.commercialGroups
        },
      },
      {
        path: "edit/:id",
        name: "CommercialGroups.edit",
        component: EditCommercialGroup,
        beforeEnter: [checkPermission],
        meta: {
          layout: "default-admin",
          permission: SitePermissionsEnum.commercialGroups
        },
      },
      {
        path: "create",
        name: "CommercialGroups.create",
        component: CreateCommercialGroup,
        beforeEnter: [checkPermission],
        meta: {
          layout: "default-admin",
          permission: SitePermissionsEnum.commercialGroups
        },
      },
      {
        path: "customers/:id",
        name: "CommercialGroups.customers",
        component: MangeCustomers,
        beforeEnter: [checkPermission],
        meta: {
          layout: "default-admin",
          permission: SitePermissionsEnum.customerManagement
        },
      },
    ],
  },
];
export default routes;
