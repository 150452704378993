
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
const assets = namespace('assets')
@Options({})
export default class AccountStatement extends Vue {
  @assets.Action
  // eslint-disable-next-line
  public getAssets!: () => Promise<any>
  beforeMount() {
    this.getAssets()
  }

}
