
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
const profile = namespace('profile')
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
@Options({})
/* eslint-disable */
export default class VerifyBase extends Vue {
  step = 0

  @profile.Getter
  public userProfile!: any

  beforeRouteUpdate(to: any, from: object, next: Function) {
    this.toVerify(to.name)
    next()
  }

  beforeRouteEnter(to: any, from: object, next: Function) {
    next((vm: any) => {
      vm.toVerify(to.name)
    })
  }

  get showMessage() {
    return (
      this.userProfile.manualVerificationStep === 3 &&
      this.$route.name !== 'verify.zero'
    )
  }

  toVerify(name: string) {
    if (name == 'verify.zero') {
      this.step = 1
    } else if (name == 'verify.one') {
      this.step = 2
    } else if (name == 'verify.two') {
      this.step = 3
    } else if (name == 'verify.three') {
      this.step = 4
    }
  }
}
