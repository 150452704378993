
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import CardBase from '@/components/common/layout/CardBase.vue'
import { adminStore } from '@/store/admin'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'
const users = namespace('users')
const toast = useToast()
const downloads = namespace('downloads')
import formatters from '@/mixins/formatters'
import FileUser from '@/types/users/FileUser'
@Options({
  name: 'FileHistoryForCustomers',
  mixins: [formatters],
  components: {
    DataTable,
    CardBase,
    Column
  }
})
export default class FileHistoryForCustomers extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileList: any[] = []
  @users.Action
  public getSpecificUser!: (id: number) => Promise<UserItem>
  @users.Getter
  public selectedUser!: UserItem
  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFileHistoryForCustomer!: (customerId: number) => Promise<any[]>
  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async getInformation() {
    try {
      adminStore.dispatch('loader/show')
      const customerId = Number(this.$route.params.id)
      await this.getSpecificUser(customerId)
      this.fileList = await this.getFileHistoryForCustomer(customerId)
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }
  get getTotalFiles() {
    let total = 0
    this.fileList.forEach(list => (total += list.length))
    return total
  }
  mounted() {
    this.getInformation()
  }
}
