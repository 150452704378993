
//IMPORTS
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import http from '@/http-common-coingecko'
import formatters from '@/mixins/formatters'
//DECORATORS
const balance = namespace('balance')
const prices = namespace('prices')
const assets = namespace('assets')

//OPTION API
@Options({
  data() {
    return {
      total: 0
    }
  },
  components: {
    IconsCurrency
  },
  emits: ['update'],
  mixins: [formatters],
  watch: {
    currencyId(event) {
      this.$emit('update', event)
      localStorage.setItem('preferred-currency', event)
    },
    status(event) {
      if (event == 'success') {
        this.getCryptoPrices()
      }
    }
  }
})
export default class Balances extends Vue {
  //GETTERS
  //BALANCE
  @balance.Getter
  // eslint-disable-next-line
  fiats!: any

  @balance.Getter
  // eslint-disable-next-line
  cryptos!: any

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  @assets.Getter
  // eslint-disable-next-line
  cryptosAssets!: any

  @prices.Getter
  // eslint-disable-next-line
  prices!: any

  @prices.Getter
  pricesStatus!: string

  //STATES
  @balance.State
  status!: string

  @balance.State
  error!: object

  @balance.Action
  // eslint-disable-next-line
  public getEvent!: () => void

  @prices.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getPricesCoingecko!: (object: any) => void

  @balance.Action
  // eslint-disable-next-line
  public unmountConnection!: () => {}

  @assets.Action
  public getAssets!: () => {}

  //COMPONENT

  total = 0
  currencyId = localStorage.getItem('preferred-currency')
    ? localStorage.getItem('preferred-currency')
    : 'CAD'
  usdcad = 0
  cadusd = 0
  cryptoPrice = Object({})
  getCryptoPricesIntervalId!: number
  totalValueIntervalId!: number
  //BEFORE MOUNT

  //BEFORE UNMOUNT

  //METHODS
  beforeMount() {
    this.getFiatPrices()
    this.getCryptoPrices()
  }
  mounted() {
    this.totalValue()
    this.getCryptoPricesIntervalId = setInterval(this.getCryptoPrices, 1200000)
    this.totalValueIntervalId = setInterval(this.totalValue, 1000)
  }
  beforeUnmount() {
    clearInterval(this.getCryptoPricesIntervalId)
    clearInterval(this.totalValueIntervalId)
    this.unmountConnection()
  }
  getCryptoPrices() {
    if (this.fiatsAssets) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fiats: any[] = []
      this.fiatsAssets.forEach(element => {
        fiats.push({ currencyId: element.id, coingeckoId: element.coingeckoId })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cryptos: any[] = this.cryptos
      this.cryptosAssets.forEach(element => {
        if (!this.cryptos.find(u => u.currencyId == element.id)) {
          cryptos.push({
            currencyId: element.id,
            coingeckoId: element.coingeckoId
          })
        }
      })

      this.getPricesCoingecko({ crypto: cryptos, fiat: fiats })
    }
  }
  getFiatPrices() {
    http
      .get('simple/price', {
        params: {
          ids: 'cad-coin',
          // eslint-disable-next-line @typescript-eslint/camelcase
          vs_currencies: 'usd'
        }
      })
      .then(res => {
        this.cadusd = res.data['cad-coin'].usd
      })
    http
      .get('simple/price', {
        params: {
          ids: 'usd-coin',
          // eslint-disable-next-line @typescript-eslint/camelcase
          vs_currencies: 'cad'
        }
      })
      .then(res => {
        this.usdcad = res.data['usd-coin'].cad
      })
  }
  convertfiat(amount: number, fromCurrency: string, toCurrency: string) {
    return this[fromCurrency.toLowerCase() + toCurrency.toLowerCase()] * amount
  }
  totalValue() {
    let totalProfolio = 0
    // eslint-disable-next-line
    this.fiats.forEach((fiat: any) => {
      if (this.currencyId == 'CAD') {
        if (fiat.currencyId == 'USD') {
          const res = this.convertfiat(fiat.balance, 'USD', 'CAD')
          totalProfolio += res
        } else {
          totalProfolio += fiat.balance
        }
      } else {
        if (fiat.currencyId == 'USD') {
          totalProfolio += fiat.balance
        } else {
          const res = this.convertfiat(fiat.balance, 'CAD', 'USD')
          totalProfolio += res
        }
      }
    })
    if (this.pricesStatus == 'success') {
      // eslint-disable-next-line
      this.cryptos.forEach((crypto: any) => {
        if (this.prices[crypto.currencyId + this.currencyId]) {
          totalProfolio += !isNaN(
            this.prices[crypto.currencyId + this.currencyId]['higher'].buy *
              crypto.balance
          )
            ? Number(
                this.prices[crypto.currencyId + this.currencyId]['higher'].buy *
                  crypto.balance
              )
            : 0
        }
      })
    }
    // eslint-disable-next-line
    this.total = totalProfolio
  }
}
