
import { Options, Vue } from 'vue-class-component'
import AdvanceTransaction from "@/components/customer/transaction/advanceTransaction.vue"
import IconsCurrency from "@/components/customer/transaction/iconsCurrency.vue"
import Dropdown from 'primevue/dropdown';
import PairItem from '@/types/pairs/PairItem'
import { namespace } from 'vuex-class'
const pairs = namespace('pairs')
const assets = namespace('assets')
@Options({
  data () {
    return {
      parity: null,
      interval: '4HRS',
      minInterval: 240,
      type: 'BUY'
    }
  },
  components: {
    AdvanceTransaction,
    Dropdown,
    IconsCurrency
  },
  watch: {
    // parity(event){
    //   this.type = event.allowBuy ? 'BUY' : 'SELL'
    // },
    getStatus(event){
      if (event == 'success'){
        const isIn = this.pairs.find(u=>u.pairId == 'BTCUSD')
        const firstPair = isIn ? isIn : this.pairs.find(u=> u == u)
        this.parity = firstPair
      }
    }
  }
})
export default class Home extends Vue {
 /* eslint-disable */
  parity!: any
  interval!: string
  minInterval!: number
  get onBehalfOf(){return (this as any).$isAdmin ? Number( this.$route.params?.id ) : 0}
  type!: string
  @pairs.Getter
  pairs!: PairItem[]

  @pairs.Getter
  getStatus!: string

  @pairs.Action
  getAvailablePairsForAdmin!: (customerId: number) => Promise<PairItem[]>

  @assets.Getter
  statusAssets!: string

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  // onParityUpdate (parity: object) {
  //     this.parity = parity
  // }
  /* eslint-disable */
  switchTrans(type: string) {
    if (this.type != type) {
      this.type = type;
    }
  }
  beforeMount () {
    const customerId = Number(this.$route.params?.customerId)
    this.getAvailablePairsForAdmin(customerId)
  }
  isFiat(asset: string): boolean {
      if(this.statusAssets == "success") {
          return this.fiatsAssets.find((currency) => currency.id == asset) ? true : false
      } else {
          return true
      }
  }
}
