export default class RequestStepOne {
    dateOfBirth!: string;
    occupation!: string;
    relationWithGovernment!: boolean;
    postalCode!: string;
    addressLine1!: string;
    addressLine2!: string;
    city!: string;
    phoneNumber!: string;
    phoneNumberCode!: string;
    phoneCountry!: string;
    streetNumber!: string;
    street!: string;
    interiorNumber!: string;
    province!: string;
    nickname!: string;
    firstName!: string;
    lastName!: string;
    email!: string;
    customerId!: number;
}