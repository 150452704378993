
import ConfirmPopup from 'primevue/confirmpopup'
import { Options, Vue } from 'vue-class-component'
import Modal from '@/components/common/modal.vue'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'
import Column from 'primevue/column'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import DepositItem from '@/types/account-movement/DepositItem'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import amountInput from '@/components/common/inputs/amountInput.vue'
import formatters from '@/mixins/formatters'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import FileUser from '@/types/users/FileUser'
const toast = useToast()
const deposits = namespace('deposits')
const prices = namespace('prices')
const downloads = namespace('downloads')
const currenciesSatStreet = namespace('currenciesSatStreet')
@Options({
  data() {
    return {
      filters: null,
      showModal: false,
      showDetailsModal: false,
      rejectionCode: 1
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    InputText,
    IconsCurrency,
    Modal,
    ConfirmPopup,
    amountInput
  }
})
export default class DepositsMissingValues extends Vue {
  isLoading = false
  isLoadingRetry = false
  isLoadingRetryAll = false
  indexFilling!: number | undefined
  maxRows = 10
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters!: any
  showModal!: boolean
  showDetailsModal!: boolean

  rejectDepositId!: number
  rejectionCode!: number

  @deposits.State
  status!: string
  @deposits.State
  manageDepositStatus!: string
  @deposits.State
  manageDepositId!: string

  @deposits.Getter
  getMissingDeposits!: DepositItem[]
  @deposits.Getter
  depositDetails!: DepositItem

  @deposits.Action
  public getMissingValuesDeposits!: () => Promise<void>

  @deposits.Action
  public putDeposit!: (deposit: DepositItem) => Promise<void>

  @deposits.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSpecificDeposit!: (depositId) => Promise<any>

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem[]>

  @prices.Action
  getAssetPriceByDate!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    asset: string
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    date: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  async downloadDocument(file: FileUser): Promise<void> {
    try {
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch {
      toast.error('Download error, please try later.')
    }
  }

  mounted() {
    this.getData()
    this.fetchCurrencies()
  }

  async getData() {
    try {
      await this.getMissingValuesDeposits()
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveDeposit(event: any, deposit: DepositItem): void {
    this.$confirm.require({
      target: event.currentTarget,
      message: 'Are you sure?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          this.isLoading = true
          this.putDeposit(deposit)
          const depositIndex = this.getMissingDeposits.findIndex(
            e => e.id == deposit.id
          )
          this.getMissingDeposits.splice(depositIndex, 1)
          toast.success('Deposit updated!')
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('An error has occurred \nPlease try again')
          }
        } finally {
          this.isLoading = false
        }
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async retryFillReferenceValues(index: number, deposit: DepositItem) {
    try {
      this.isLoadingRetry = true
      this.indexFilling = index
      const currency = this.getCurrencies.find(c => c.id === deposit.currencyId)
      const { data } = await this.getAssetPriceByDate({
        date: deposit.createdAt,
        asset: '' + currency?.coingeckoId
      })
      if (data?.market_data?.current_price) {
        deposit['usdExchangeValue'] =
          data.market_data.current_price['usd'] * deposit.amount
        deposit['cadExchangeValue'] =
          data.market_data.current_price['cad'] * deposit.amount
      }
    } catch (error) {
      deposit['usdExchangeValue'] = 0
      deposit['cadExchangeValue'] = 0
    } finally {
      this.isLoadingRetry = false
      this.indexFilling = undefined
    }
  }

  async retryFillAllReferencesValues() {
    try {
      this.isLoadingRetryAll = true

      let count = 0
      for (const deposit of this.getMissingDeposits) {
        if (count === this.maxRows) return

        const currency = this.getCurrencies.find(
          c => c.id === deposit.currencyId
        )
        const { data } = await this.getAssetPriceByDate({
          date: deposit.createdAt,
          asset: '' + currency?.coingeckoId
        })
        if (data?.market_data?.current_price) {
          deposit['usdExchangeValue'] =
            data.market_data.current_price['usd'] * deposit.amount
          deposit['cadExchangeValue'] =
            data.market_data.current_price['cad'] * deposit.amount
        }

        count++
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingRetryAll = false
    }
  }

  showModalRejectDeposit(depositId: number) {
    this.rejectDepositId = depositId
    this.showModal = true
  }

  getDepositDetails(depositId: number) {
    this.getSpecificDeposit(depositId).then(() => {
      this.showDetailsModal = true
    })
  }

  created() {
    this.initFilters()
  }

  clearFilter() {
    this.initFilters()
  }

  initFilters() {
    this.filters = {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS }
    }
  }

  public formatPrice!: (amount: number) => number
  public formatDate!: (isoDate: string) => string
}
