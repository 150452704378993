import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-admin'
import { useToast } from "vue-toastification"
import DepositItem from '@/types/account-movement/DepositItem'
import DespositForm from '@/types/pending-deposit/DepositForm'
const toast = useToast()
@Module({ namespaced: true, name: '' })
class Deposits extends VuexModule {
  public status!: string
  public data!: object
  public error!: object
  public deposit!: DepositItem
  public missingValuesDeposits!: DepositItem[]

  public manageDepositId!: number
  public manageDepositResult!: object
  public manageDepositError!: object
  public manageDepositStatus!: string

  @Mutation
  public setDeposit(deposit: DepositItem): void {
    this.deposit = deposit;
  }

  @Mutation
  public onDeposits(): void {
    this.status = "fetching"
    this.error = {}
    this.data = []
  }

  @Mutation
  public onDepositsSuccess(data: object): void {
    this.status = "success"
    this.error = {};
    // eslint-disable-next-line 
    (data as any[]).forEach(e => {
      e.customer.fullName = e.customer.firstName + ' ' + e.customer.lastName;
    });
    this.data = data
  }

  @Mutation
  public onDepositsError(err: object): void {
    this.status = "error"
    this.error = err
  }

  @Mutation
  public onManageDeposit({ depositId }): void {
    this.manageDepositStatus = "fetching"
    this.manageDepositId = depositId
    this.manageDepositError = {}
    this.manageDepositResult = {}
  }

  @Mutation
  public onManageDepositSuccess(data: object): void {
    this.manageDepositStatus = "success"
    console.log("this.manageDepositStatus", this.manageDepositStatus)
    this.manageDepositId = 0
    this.manageDepositError = {};
    this.manageDepositResult = data
  }

  @Mutation
  public onManageDepositError(err: object): void {
    this.manageDepositStatus = "error"
    this.manageDepositId = 0
    this.manageDepositError = err
  }

  @Mutation
  public onDepositsMissingSuccess(data: DepositItem[]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any[]).forEach(e => {
      e.customer.fullName = e.customer.firstName + ' ' + e.customer.lastName;
    });
    this.missingValuesDeposits = data;
  }

  @Mutation
  public onDepositsMissingError(error: object) {
    this.missingValuesDeposits = [];
    this.error = error;
  }

  @Action({ rawError: true })
  public getPendingDeposits() {
    return new Promise((resolve, reject) => {
      this.context.commit("onDeposits")
      http
        .get('customer-deposit/internal/list?status=1')
        .then(response => {
          this.context.commit("onDepositsSuccess", response.data)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onDepositsError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public getMissingValuesDeposits() {
    return new Promise((resolve, reject) => {
      http
        .get('customer-deposit/internal/list-pending-exchange-values')
        .then(response => {
          this.context.commit("onDepositsMissingSuccess", response.data)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onDepositsMissingError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public putDeposit(deposit: DepositItem) {
    return new Promise((resolve, reject) => {
      http
        .put('customer-deposit/internal/update-deposit-exchange-values', {
          depositId: deposit.id,
          usdExchangeValue: deposit.usdExchangeValue,
          cadExchangeValue: deposit.cadExchangeValue
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public acceptDeposit(depositId: number) {
    return new Promise((resolve, reject) => {
      this.context.commit("onManageDeposit", { depositId })
      http
        .get(`customer-deposit/internal/authorize/${depositId}`)
        .then(response => {
          this.context.commit("onManageDepositSuccess", { ...response.data, actionType: "accept" })
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onManageDepositError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public rejectDeposit({ depositId, rejectionCode }) {
    return new Promise((resolve, reject) => {
      this.context.commit("onManageDeposit", { depositId })
      http
        .get(`customer-deposit/internal/reject/${depositId}/${rejectionCode}`)
        .then(response => {
          this.context.commit("onManageDepositSuccess", { ...response.data, actionType: "reject" })
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onManageDepositError", error)
          reject(error)
        })
    })
  }

  public get deposits(): object {
    return this.data
  }

  public get selectedDeposit(): DepositItem {
    return this.deposit
  }

  public get depositDetails() {
    return this.manageDepositResult
  }

  public get getMissingDeposits(): DepositItem[] {
    return this.missingValuesDeposits
  }

  @Action({ rawError: true })
  public getSpecificDeposit(depositId: number | string): Promise<DepositItem> {
    return new Promise((resolve, reject) => {
      this.context.commit("onManageDeposit", { depositId })
      http
        .get(`customer-deposit/internal/details/${depositId}`)
        .then(response => {
          resolve(response.data)
          this.context.commit("onManageDepositSuccess", { ...response.data, actionType: "details" })
        })
        .catch(error => {
          this.context.commit("onManageDepositError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public postDeposit(deposit: DespositForm): Promise<void> {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      if (deposit.file)
        form.append("file", deposit.file)

      if (deposit.transactionAuthCode)
        form.set("transactionAuthCode", deposit.transactionAuthCode)

      if (deposit.cadExchangeValue)
        form.set("cadExchangeValue", deposit.cadExchangeValue.toString())

      if (deposit.usdExchangeValue)
        form.set("usdExchangeValue", deposit.usdExchangeValue.toString())

      form.set("createdAt", deposit.createdAt)
      form.set("currencyId", deposit.currencyId)
      form.set("amount", deposit.amount.toString())
      form.set("customerId", deposit.customerId.toString())
      http
        .post(`customer-deposit/internal/register/`, form)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
export default Deposits