
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import Dialog from 'primevue/dialog'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import RegistrationForm from '@/types/auth/RegistrationForm'
import { customerStore } from '@/store/customer'
import Password from 'primevue/password'
import { GOOGLE_RECAPTCHA_SITE_KEY } from '@/config/api-keys'
const toast = useToast()
const register = namespace('register')

@Options({
  components: {
    Dialog,
    Password
  },
  data() {
    return {
      terms: false
    }
  }
})
export default class Register extends Vue {
  showModal = false
  terms!: boolean
  registerForm!: IFormGroup<RegistrationForm>

  @register.Action
  public register!: (register: object) => Promise<object>

  openTerms() {
    this.showModal = true
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.registerForm = new RxFormBuilder().formGroup(
      RegistrationForm
    ) as IFormGroup<RegistrationForm>
  }

  generateCaptcha() {
    //eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
      const key = GOOGLE_RECAPTCHA_SITE_KEY
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute(key, {
          action: 'register'
        })
        .then((token: string) => {
          this.registerForm.props.token = token
          this.submit()
        })
    })
  }

  async submit() {
    try {
      customerStore.dispatch('loader/show')
      await this.register({
        email: this.registerForm.props.email.toLowerCase(),
        firstName: this.registerForm.props.firstName,
        lastName: this.registerForm.props.lastName,
        password: this.registerForm.props.password,
        token: this.registerForm.props.token,
        tosAcceptance: this.terms
      })
      toast.success('Congratulation! \nPlease check your email to continue')
      this.$router.push({
        name: `auth.resend`,
        params: { email: this.registerForm.props.email }
      })
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      customerStore.dispatch('loader/hidden')
    }
  }

  readQueryParams() {
    this.registerForm.props.email = this.$route.query.email
    this.registerForm.props.firstName = this.$route.query.name
    this.registerForm.props.lastName = this.$route.query.lastname
  }

  created(): void {
    this.readQueryParams()
  }
}
