import { RouteRecordRaw } from "vue-router";
import OnBehalfOfBase from "@/views/admin/OnBehalfOfBase.vue";
import Home from "@/views/admin/on-behalf-of/Home.vue";
import CreateAutomaticOrder from "@/components/admin/dashboard/CreateAutomaticOrder.vue"
import CreateOrder from "@/components/admin/dashboard/createOrder.vue"
import EditOrder from "@/components/admin/dashboard/editOrder.vue"
import PendingDepositsForm from "@/components/admin/dashboard/PendingDepositsForm.vue"
import PendingWithdrawalsForm from "@/components/admin/dashboard/PendingWithdrawalsForm.vue"
import ValidateBankAccount from "@/components/admin/dashboard/ValidateBankAccount.vue"
import OrderHistoryDetail from '@/components/customer/account-statement/OrderHistoryDetail.vue'
import AccountStatementReport from '@/components/customer/account-statement/AccountStatementReport.vue'
import AccountStatement from "@/views/admin/on-behalf-of/AccountStatement.vue";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import SitePermissionsEnum from "@/types/users/SitePermissionsEnum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/on-behalf-of",
    name: "on-behalf-of",
    component: OnBehalfOfBase,
    redirect: "/dashboard",
    children: [
      {
        path: ":customerId/home",
        name: "on-behalf-of.home",
        component: Home,
        meta: { layout: "admin-on-behalf-of" },
        beforeEnter: ifAdminAuthenticated,
        children: [
          {
            path: 'automatic-order',
            name: "on-behalf-of.home.automatic.order",
            component: CreateAutomaticOrder,
            beforeEnter: checkPermission,
            meta: { permission: SitePermissionsEnum.OBOAutomaticOrder }
          },
          {
            path: 'register-deposit',
            name: "on-behalf-of.home.register.deposit",
            component: PendingDepositsForm,
            beforeEnter: checkPermission,
            meta: { permission: SitePermissionsEnum.OBORegisterDeposit }
          },
          {
            path: 'register-withdrawal',
            name: "on-behalf-of.home.register.withdrawal",
            component: PendingWithdrawalsForm,
            beforeEnter: checkPermission,
            meta: { permission: SitePermissionsEnum.OBORegisterWithdrawal }
          },
          {
            path: 'manual-order',
            name: "on-behalf-of.home.manual.order",
            component: CreateOrder,
            beforeEnter: checkPermission,
            meta: { permission: SitePermissionsEnum.OBOManualOrder }
          },
          {
            path: 'edit-manual-order/:orderId',
            name: "on-behalf-of.home.edit-manual.order",
            component: EditOrder,
            beforeEnter: checkPermission,
            meta: { permission: SitePermissionsEnum.OBOManualOrder }
          },
          {
            path: 'validate-account',
            name: "on-behalf-of.home.validate.account",
            component: ValidateBankAccount,
            beforeEnter: checkPermission,
            meta: { permission: SitePermissionsEnum.OBORegisterBankWallets }
          },
        ]
      },
      {
        path: ":customerId/account-ledger",
        name: "on-behalf-of.account.statement",
        component: AccountStatement,
        meta: { layout: "admin-on-behalf-of" },
        beforeEnter: ifAdminAuthenticated,
        children: [
          {
            name: 'on-behalf-of.account.statement.report',
            path: 'report',
            component: AccountStatementReport
          },
          {
            path: 'order/:id',
            name: 'on-behalf-of.account.statement.order.detail',
            component: OrderHistoryDetail
          },
          {
            path: 'order-history/:id',
            name: 'on-behalf-of.account.statement.order.history.detail',
            component: OrderHistoryDetail
          },
        ]
      },
      // {
      //   path: ":id/trade",
      //   name: "on-behalf-of.trade",
      //   component: Trade,
      //   meta: { layout: "admin-on-behalf-of" },
      //   beforeEnter: ifAdminAuthenticated,
      // },
    ],
  },
];
export default routes;
