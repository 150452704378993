import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import http from "@/http-common-admin";
@Module({ namespaced: true, name: "" })
class OnBehalfOfUser extends VuexModule {
  public fetching = false;
  public user!: object;

  @Mutation
  public onClear(): void {
    this.fetching = false;
    this.user = {} as object;
  }

  @Mutation
  // eslint-disable-next-line
  public onSet(payload: any): void {
    this.fetching = false;
    this.user = payload;
  }

  @Mutation
  // eslint-disable-next-line
  public onFetching(payload: any): void {
    this.fetching = true;
  }

  @Mutation
  public onError(): void {
    this.fetching = false;
  }

  @Action({ rawError: true })
  public setUser(params) {
    this.context.commit("onSet", params);
  }

  @Action({ rawError: true })
  public getUser(userId: number) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      if ((this.context.state as any).fetching) return resolve(undefined);
      // eslint-disable-next-line
      if ((this.context.state as any).user?.id) {
        // eslint-disable-next-line
        return resolve((this.context.state as any).user);
      }
      this.context.commit("onFetching");
      http
        .get(`customer/admin/get/${userId}`)
        .then((response) => {
          this.context.commit("onSet", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onError", error);
          reject(error);
        });
    });
  }

  public get getUserOnBehalfOf(): object  {
    return this.user;
  }
}
export default OnBehalfOfUser;
