
import { Options, Vue } from 'vue-class-component'
import currencies from '@/mixins/currencies'
@Options({
    data () {
        return {
            parity: Object,
            parities: Array
        }
    },
    props: {
        initialParity: Object
    },
    mixins: [
        currencies
    ],
    emits: ["update"]
})
export default class Parity extends Vue {
    parity!: object
    parities: object[] = []
    initialParity!: object
    criptoCurrencies!: {id: string; name: string; symbol: string}[]
    localCurrencies!: {id: string; name: string; symbol: string}[]
    changeParity(parity: object){
        this.parity = this.parity != parity ? parity : this.parity
        this.$emit('update', this.parity)
    }
    beforeMount(){
        this.parity = this.initialParity ? this.parity : {local: 'CAD', cripto: 'BTC'}
        this.localCurrencies.forEach(localCurrency => {
            this.criptoCurrencies.forEach(criptoCurrency => {
                this.parities.push({local: localCurrency.symbol, cripto: criptoCurrency.symbol})
            })
        });
    }
}
