import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import httpCountries from '@/http-common-countries'
import RequestStepOne from '@/types/verify/RequestStepOne'
@Module({ namespaced: true, name: '' })
class Verify extends VuexModule {
  public status!: string
  public step!: number
  public data!: object
  public error!: object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public countries!: any[]

  @Mutation
  public onVerify(step: number): void {
    this.status = "fetching"
    this.step = step
  }
  @Mutation
  public onVerifySuccess(data: object): void {
    this.status = "success"
    this.error = {}
    this.data = data
  }
  @Mutation
  public onVerifyError(err: object): void {
    this.status = "error"
    this.error = err
  }
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onGetCountriesSuccess(response: any): void {
    this.countries = response.data;
  }
  @Action({ rawError: true })
  public stepOne(params: RequestStepOne) {
    return new Promise((resolve, reject) => {
      this.context.commit("onVerify", 2)
      http
        .put(`customer`, params)
        .then(response => {
          this.context.commit("onVerifySuccess", response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onVerifyError", error)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public stepTwo(file: File) {
    return new Promise((resolve, reject) => {
      this.context.commit("onVerify", 3)
      const params = new FormData()
      params.append("file", file)
      params.append("typeOfDocument", "documentId")
      http
        .post(`customer/uploadFile`, params)
        .then(response => {
          this.context.commit("onVerifySuccess", response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onVerifyError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public stepTwoBack(file: File) {
    return new Promise((resolve, reject) => {
      const params = new FormData()
      params.append("file", file)
      params.append("typeOfDocument", "documentIdBack")
      http
        .post(`customer/uploadFile`, params)
        .then(response => {
          this.context.commit("onVerifySuccess", response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onVerifyError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public stepThree(file: File) {
    return new Promise((resolve, reject) => {
      this.context.commit("onVerify", 3)
      const params = new FormData()
      params.append("file", file)
      params.append("typeOfDocument", "documentAddress")
      http
        .post(`customer/uploadFile`, params)
        .then(response => {
          this.context.commit("onVerifySuccess", response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onVerifyError", error)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public flinksVerification(params: object) {
    return new Promise((resolve, reject) => {
      this.context.commit("onVerify", 0)
      http
        .post(`customer/activateAccountFlinks`, params)
        .then(response => {
          this.context.commit("onVerifySuccess", response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onVerifyError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public getCountries() {
    return new Promise((resolve, reject) => {
      httpCountries
        .get(`all?fields=name;flag;callingCodes;alpha3Code`)
        .then(response => {
          this.context.commit("onGetCountriesSuccess", response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
export default Verify