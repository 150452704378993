
import { Options, Vue } from 'vue-class-component'
@Options({
    data () {
        return {
            type: 'market'
        }
    },
    emits: ['update']
})
export default class TradeOptions extends Vue {
    type!: string 

    changeType (type: string) {
        this.type = type != this.type ? type : this.type
        this.$emit('update', this.type)
    }
}
