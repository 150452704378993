
import {Vue, Options} from 'vue-class-component'
import exactMath from 'exact-math'
@Options({
    props: {
      amount: Number,
      precision: Number,
      select: Boolean,
    },
    emits: ['update:amount'],
    watch: {
      amount(event){
        this.value = isNaN(event) ? null : this.format(event)
      },
      select(event){
        if (event)this.$refs.amountInput.focus()
      }
    }
})

export default class BaseNameClass extends Vue {
  value: string|number = 0
  amount!: number
  precision!: number
  select!: boolean

  reductor (string: string) {
    let n = 1
    while (string[string.length - n] == '0') {
      n++
    }
    return n - 1
  }
  mounted () {
    if(this.amount){
      this.value = isNaN(this.amount) ? 0 : this.amount
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs['amounInput'] as any)?.focus();
  }
  filterKeyUp = (event) => {
    if(event.key != '.' &&  event.key != '0' && event.key != 'Backspace'){
      if (this.value){
        this.value = this.formatAmount(this.value.toString())
      }
    } else if(event.key == '0') {
      this.value.toString().indexOf('.') == -1 ? this.value = this.formatAmount(this.value.toString()) : null
    } else if (event.key =='Backspace') {
      try {
        this.value.toString()[this.value.toString().length-1] == '.' || this.value.toString()[this.value.toString().length-1] == '0' ?
        null : this.value = this.formatAmount(this.value.toString())
      } catch (error) {
        null
      }
    }
  }
  filterKeyPress = (event) => {
    const keyCode = (event.keyCode ? event.keyCode : event.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        event.preventDefault();
    }
    if (this.value){
      if (
        this.value.toString().indexOf('.') != -1 && 
        this.value.toString().split('.').length > 0 && 
        this.value.toString().split('.')[1].length > this.precision
        ){
        event.preventDefault()
      }
      if(event.key == '.' && this.value.toString().split('.').length-1 > 0 || event.key == '0' && this.value.toString() == '0'){
        event.preventDefault()
      }
    }
  }
  format(amount: number | string, precision = this.precision ? this.precision : 12, decimal = ".", thousands = ",") {
    if (!amount || !precision) {
      return '0'
    }
    const value = exactMath.floor(amount, -precision).toString()
    const i = parseInt(value).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    return (
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (parseFloat(value).toString().split(".")[1] ? decimal +  parseFloat(value).toString().split(".")[1] : '')
    );
  }
  formatAmount(number: string) {
    if (number) {
      number = parseFloat((number).toString().replace(/,/g, '')).toString()
      this.$emit('update:amount', number)
      return this.format(number)
    } else {
      this.$emit('update:amount', 0)
      return 0
    }
  }
  updateAmount(event): void {
    if(!event) return
    this.value = this.format(event)
    this.$emit('update:amount', parseFloat((event).toString().replace(/,/g, '')).toString())
  }
  myRound (num: number, dec: number) {
    const exp = Math.pow(10, dec || 2);
    return parseInt((num * exp).toString(), 10) / exp;
  }
}
