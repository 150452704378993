export const FILE_SIZE_LIMIT_MB = 10
export const TERMINTATE_SESSION_MIN = 10
export const ENVIRONMENT = 'Production' // Development || Sandbox || Production

// URLS
export const BACKEND_INTERNAL_URL = 'https://opsapi.dev.satstreet.com/api/'
export const BACKEND_URL = 'https://customer-v2-api.satstreet.com/api/'
export const BACKEND_PROXY_URL = 'https://proxy-v2-api.satstreet.com/api/'

export const WIDGET_TRADING = 'https://proxy-v2-api.satstreet.com/api/'
export const BACKEND_WS_URL = 'wss://proxy-v2-api.satstreet.com'
export const BACKEND_WS_INTERNAL_URL = 'wss://otcapi.dev.satstreet.com'
export const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3/'
