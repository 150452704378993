import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/http-common-customer'
import { Register } from '@/types'
@Module({ namespaced: true, name: '' })
class Regist extends VuexModule {
  public status!: string
  public data!: object
  public error!: object

  @Mutation
  public onRegister(): void {
    this.status = "fetching"
  }
  @Mutation
  public onRegisterSuccess(data: object): void {
    this.status = "success"
    this.error = {}
    this.data = data
  }

  @Mutation
  public onRegisterError(err: object): void {
    this.status = "error"
    this.error = err
  }

  @Action({ rawError: true })
  public register(params: Register) {
    return new Promise((resolve, reject) => {
      this.context.commit("onRegister")
      http
        .post(`auth/customer/register`, params)
        .then(response => {
          this.context.commit("onRegisterSuccess", response)
          resolve(response)
        })
        .catch(error => {
          this.context.commit("onRegisterError", error)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public registerCorporate(data: FormData) {
    return new Promise((resolve, reject) => {
      this.context.commit("onRegister")
      http
        .post(`corporate`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public updateCorporate(data: FormData) {
    return new Promise((resolve, reject) => {
      this.context.commit("onRegister")
      http
        .put(`corporate`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
export default Regist