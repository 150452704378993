
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import Calendar from 'primevue/calendar'
import Dialog from 'primevue/dialog'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ProgressBar from 'primevue/progressbar'
import CustomerSelect from './customerSelect.vue'
import formatters from '@/mixins/formatters'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import amountInput from '@/components/common/inputs/amountInput.vue'
import { useToast } from 'vue-toastification'
import OrderItem from '@/types/account-statement/OrderItem'
import { Money3Component } from 'v-money3'
import { adminStore } from '@/store/admin'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import exactMath from 'exact-math'
import InputNumber from 'primevue/inputnumber'
import moment from 'moment'
import PairItem from '@/types/pairs/PairItem'
const toast = useToast()
const pairs = namespace('pairs')
const orders = namespace('orders')
const providers = namespace('providers')
const assets = namespace('assets')
@Options({
  data() {
    return {
      providerOrders: [],
      currentBaseCurrency: null,
      currentQuoteCurrency: null,
      fillingOrder: {
        providerId: null,
        amount: null,
        unitCost: null,
        externalProviderOrderId: '',
        settlementDatetime: moment(new Date()).format('yyyy-MM-DDTHH:mm'),
        totalCost: null
      }
    }
  },
  mixins: [formatters],
  components: {
    CustomerSelect,
    Calendar,
    DataTable,
    Column,
    IconsCurrency,
    ProgressBar,
    Dialog,
    money3: Money3Component,
    amountInput,
    InputNumber
  }
})
export default class EditOrder extends Vue {
  hasFillingOrderDateError = false
  showModalcloseOrder = false
  config = {
    decimal: '.',
    thousands: ',',
    precision: 12,
    disableNegative: true,
    prefix: ''
  }

  currentBaseCurrency!: CurrencyItem
  currentQuoteCurrency!: CurrencyItem

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providerOrders!: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fillingOrder: any

  @pairs.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pairs!: any[]
  @pairs.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPairs!: () => Promise<any[]>

  @orders.Getter
  public selectedOrder!: OrderItem
  @orders.Getter
  public currentCreateOrderStatus!: string
  @orders.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveOrder!: (payload) => any
  @orders.Action
  public getSpecificOrder!: (id: string) => Promise<OrderItem>

  @orders.Action
  closerManualOrder!: (orderId: number) => Promise<void>

  @providers.Getter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providers!: any[]
  @providers.Action
  @assets.Getter
  statusAssets!: string

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  @assets.Getter
  // eslint-disable-next-line
  cryptosAssets!: any
  @providers.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getProvidersForPairId!: (pairId: string) => Promise<any[]>

  isFiat(asset: string): boolean {
    if (this.statusAssets == 'success') {
      return this.fiatsAssets.find(currency => currency.id == asset)
        ? true
        : false
    } else {
      return true
    }
  }
  //is Crypto To Crypto
  isCTC(pair: PairItem): boolean {
    return (
      !this.isFiat(pair.baseCurrencyId) && !this.isFiat(pair.quoteCurrencyId)
    )
  }
  mounted() {
    this.getData()
  }

  get getUnitPrice(): number {
    try {
      if (
        !this.fillingOrder.totalCost &&
        !this.fillingOrder.amount &&
        this.fillingOrder.amount > 0 &&
        this.fillingOrder.totalCost > 0
      ) {
        return 0
      }
      const total = Number.parseFloat(this.fillingOrder.totalCost + '').toFixed(
        15
      )
      const amount = Number.parseFloat(this.fillingOrder.amount + '').toFixed(
        15
      )
      const result = exactMath.div(total, amount).toFixed(15)
      this.fillingOrder.unitCost = result
      return result
    } catch {
      this.fillingOrder.unitCost = 0
      return 0
    }
  }

  changeUnitPrice() {
    this.fillingOrder.totalCost =
      this.fillingOrder.unitCost * this.fillingOrder.amount
  }

  // eslint-disable-next-line
  changeTotalCost(e: any) {
    // this.fillingOrder.totalCost = parseFloat(e.target.value.replace(/,/g, ''))
    // this.fillingOrder.unitCost = exactMath.div(
    //   this.fillingOrder.totalCost,
    //   this.fillingOrder.amount
    // )
    //this.fillingOrder.totalCost / this.fillingOrder.amount
  }

  // eslint-disable-next-line
  changeAmount(e: any) {
    // this.fillingOrder.amount = parseFloat(e.target.value.replace(/,/g, ''))
    // this.fillingOrder.totalCost =
    //   this.fillingOrder.amount * this.fillingOrder.unitCost
  }

  async retryGetFillingOrders() {
    try {
      if (this.providerOrders.length === 0) {
        await this.getSpecificOrder(this.$route.params.orderId.toString())
      }
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    }
  }

  async getData(): Promise<void> {
    try {
      adminStore.dispatch('loader/show')
      await this.getSpecificOrder(this.$route.params.orderId.toString())
      await this.getPairs()
      this.providerOrders = [...this.selectedOrder.providerOrders]
      this.currentBaseCurrency = this.selectedOrder.pair.baseCurrency
      this.currentQuoteCurrency = this.selectedOrder.pair.quoteCurrency
      await this.getProvidersForPairId(this.selectedOrder.pairId)
      setTimeout(() => {
        this.retryGetFillingOrders()
      }, 3000)
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  addProviderOrder(): void {
    if (!this.validateDate()) return
    this.providerOrders.push({ ...this.fillingOrder })
    this.fillingOrder = {
      providerId: null,
      amount: null,
      unitCost: null,
      externalProviderOrderId: '',
      settlementDatetime: ''
    }
  }
  validateDate(): boolean {
    const fillingOrderDate = moment(
      this.fillingOrder.settlementDatetime,
      'yyyy-MM-DDThh:mm:ss'
    ).valueOf()
    const currentDate = moment(new Date(), 'yyyy-MM-DDThh:mm:ss').valueOf()
    if (fillingOrderDate > currentDate) {
      this.hasFillingOrderDateError = true
      return false
    }
    this.hasFillingOrderDateError = false
    return true
  }

  openModalcloseOrder(): void {
    this.showModalcloseOrder = true
  }

  async closeOrder(): Promise<void> {
    if (this.selectedOrder.providerOrders.length === 0) return
    try {
      adminStore.dispatch('loader/show')
      await this.closerManualOrder(this.selectedOrder.id)
      toast.success('Order successfully closed')
      this.$router.push({
        name: 'on-behalf-of.home.manual.order'
      })
    } catch (e) {
      toast.error(e.response.data.message)
      console.log(e)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async executeSaveOrder(): Promise<void> {
    try {
      adminStore.dispatch('loader/show')
      await this.saveOrder({
        orderId: this.selectedOrder.id,
        providerOrdersData: this.providerOrders
          .filter(providerOrder => !providerOrder.id)
          .map(providerOrder => ({
            providerId: Number(providerOrder.providerId),
            amount: Number(providerOrder.amount),
            totalCost: Number(providerOrder.totalCost),
            unitCost: Number(providerOrder.unitCost),
            externalProviderOrderId: providerOrder.externalProviderOrderId,
            settlementDatetime: moment(providerOrder.settlementDatetime).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          }))
      })
      toast.success('Order successfully created')
      this.$router.push({
        name: 'on-behalf-of.home.manual.order'
      })
    } catch (e) {
      toast.error(e.response.data.message)
      console.log(e)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  removeRow(index): void {
    this.providerOrders.splice(index, 1)
  }

  toMaximum(): void {
    if (this.progress >= 100) return
    this.fillingOrder.amount = this.selectedOrder?.amount - this.totalAmount
    try {
      this.fillingOrder.totalCost =
        this.fillingOrder.amount * this.fillingOrder.unitCost
    } catch (error) {
      this.fillingOrder.totalCost = 0
    }
  }

  getProviderNameById(providerId): void {
    return this.providers.find(e => e.id == providerId).name
  }

  get currentPair() {
    if (!this.selectedOrder || !this.pairs) return null
    return this.pairs.find(e => e.id == this.selectedOrder.pairId)
  }

  get canAddProviderOrder() {
    return (
      this.fillingOrder.providerId &&
      this.fillingOrder.amount &&
      this.fillingOrder.unitCost &&
      this.fillingOrder.totalCost &&
      this.fillingOrder.settlementDatetime
    )
  }

  get totalAmount() {
    let total = 0
    for (const order of this.providerOrders) {
      total += order.amount
    }
    return total
  }

  get totalCost() {
    let total = 0
    for (const order of this.providerOrders) {
      total += order.totalCost
    }
    return total
  }

  get progress() {
    if (!this.providerOrders.length) return 0
    if (this.selectedOrder?.side == 1)
      return Math.floor((this.totalAmount / this.selectedOrder.amount) * 100)
    if (this.selectedOrder?.side == 1)
      return Math.floor(
        (this.totalCost /
          (this.selectedOrder.amount * this.selectedOrder.unitPrice)) *
          100
      )
    return 0
  }

  get baseCurrencyRevenue() {
    if (this.selectedOrder?.side == 1)
      // Buy
      return exactMath.sub(this.totalAmount, this.selectedOrder.amount) // this.totalAmount - this.selectedOrder.amount
    if (this.selectedOrder?.side == 2)
      // Sell
      return exactMath.sub(this.selectedOrder.amount, this.totalAmount) //this.selectedOrder.amount - this.totalAmount
    return 0
  }

  get quoteCurrencyRevenue() {
    const total = parseFloat(this.selectedOrder.finalPrice.toString())
    if (!total || !this.totalCost) return 0
    if (this.selectedOrder?.side == 1) {
      return exactMath.sub(total, this.totalCost)
    }
    if (this.selectedOrder?.side == 2) {
      return exactMath.sub(this.totalCost, total)
    }
    return 0
  }

  get revenueBalance() {
    const baseRevenuePrice =
      this.baseCurrencyRevenue * this.baseCurrencyAveragePrice
    const totalRevenuePrice = baseRevenuePrice + this.quoteCurrencyRevenue
    const basePercent =
      Math.round((baseRevenuePrice * 1000) / totalRevenuePrice) / 10
    const quotePercent = Math.round((100 - basePercent) * 10) / 10
    return [basePercent, quotePercent]
  }

  get revenueBalanceForBar() {
    if (this.revenueBalance == null) return null
    let value = this.revenueBalance[0]
    if (value < 0) value = 0
    if (value > 100) value = 100
    return value
  }

  get baseCurrencyAveragePrice() {
    return this.totalCost / this.totalAmount
  }
}
