import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import http from "@/http-common-admin";
import { useToast } from "vue-toastification";
const toast = useToast();
@Module({ namespaced: true, name: "" })
class Dashboard extends VuexModule {
  public status!: string;
  public cardsData!: object;
  public error!: object;

  @Mutation
  public onCards(): void {
    this.status = "fetching";
    this.error = {};
  }

  @Mutation
  public onCardsSuccess(data: object): void {
    this.status = "success";
    this.error = {};
    this.cardsData = data;
  }

  @Mutation
  public onCardsError(err: object): void {
    this.status = "error";
    this.error = err;
    this.cardsData = {};
  }

  @Action({ rawError: true })
  public getCards() {
    return new Promise((resolve, reject) => {
      this.context.commit("onCards");
      http
        .get("admin/get-cards")
        .then((response) => {
          this.context.commit("onCardsSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onCardsError", error);
          reject(error);
        });
    });
  }

  public get cards(): object {
    return this.cardsData;
  }
}
export default Dashboard;
