<template>
  <div class="row justify-content-center">
    <div class="col">
      <img
        src="@/assets/svg/logo-white.svg"
        class="img-fluid"
        alt="Satstreet"
      />
    </div>
  </div>
</template>
