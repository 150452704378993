
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
// eslint-disable-next-line
import { adminStore } from '@/store/admin'
import UserItem from '@/types/users/UserItem'
import OrderItem from '@/types/orders/OrderItem'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import formatters from '@/mixins/formatters'
const toast = useToast()
const orders = namespace('orders')
@Options({
  data() {
    return {
      usersState: 'disabled',
      filters: {
        enabled: false,
        keyWord: ''
      },
      show: true
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    IconsCurrency
  }
})
export default class PendingOrders extends Vue {
  show!: boolean
  usersState!: string
  filters = {
    enabled: false,
    keyWord: ''
  }

  @orders.State
  status!: string

  @orders.Getter
  lastTrades!: OrderItem[]

  @orders.Action
  public getLastTrades!: () => Promise<UserItem[]>

  async mounted() {
    try {
      //adminStore.dispatch("loader/show");
      this.show = !!localStorage.getItem('lastTradesShow')
      await this.getLastTrades()
    } catch (error) {
      toast.error(error)
    }
  }

  showToggle() {
    this.show = !this.show
    localStorage.setItem('lastTradesShow', this.show ? 'true' : '')
  }

  public format!: (amount: number, decimals: number) => number

  formatPrice(value: number) {
    return this.format(value, 2)
  }
  formatAmount(value: number) {
    return this.format(value, 4)
  }
}
