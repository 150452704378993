
import CardBase from '@/components/common/layout/CardBase.vue'
import ConfirmPopup from 'primevue/confirmpopup'
import { adminStore } from '@/store/admin'
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Column from 'primevue/column'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import PairItem from '@/types/pairs/PairItem'
import ProviderItem from '@/types/providers/ProviderItem'
import Checkbox from 'primevue/checkbox'
import CurrencyItem from '@/types/currencies/CurrencyItem'
import Dropdown from 'primevue/dropdown'
const toast = useToast()
const pairs = namespace('pairs')
const currenciesSatStreet = namespace('currenciesSatStreet')
const providers = namespace('providers')

@Options({
  name: 'ListCurrencies',
  components: {
    DataTable,
    Column,
    ConfirmPopup,
    Dialog,
    Checkbox,
    Dropdown,
    CardBase
  },
  mixins: [formatters],
  data() {
    return {
      currencyUpdate: null
    }
  }
})
export default class ListPairs extends Vue {
  mainSearch = ''
  minBaseAmount = 0
  minQuoteAmount = 0
  showModal = false
  pairForm!: IFormGroup<PairItem>
  pairUpdate!: PairItem
  @pairs.Getter
  pairs!: PairItem[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  b2b2Instruments: any = []

  @pairs.Getter
  getStatus!: string

  @pairs.Action
  getPairs!: () => Promise<PairItem[]>

  @pairs.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getB2c2Instrument!: (providerName: string) => Promise<any>

  @pairs.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValuesFromProvider!: (providerName: string) => Promise<any>

  @pairs.Action
  updatePair!: (pair: PairItem) => Promise<void>

  @pairs.Action
  addPair!: (pair: PairItem) => Promise<void>

  @currenciesSatStreet.Getter
  getCurrencies!: CurrencyItem[]

  @currenciesSatStreet.Action
  fetchCurrencies!: () => Promise<CurrencyItem[]>

  @providers.Action
  getProviders!: () => Promise<void>

  @providers.Getter
  providers!: ProviderItem[]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.pairForm = new RxFormBuilder().formGroup(PairItem) as IFormGroup<
      PairItem
    >
  }

  async getData(): Promise<void> {
    try {
      adminStore.dispatch('loader/show')
      await this.fetchCurrencies()
      await this.getPairs()
      await this.getProviders()
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async getInstrumentByProvider(callback?: Function) {
    adminStore.dispatch('loader/show')
    try {
      const { data } = await this.getB2c2Instrument(
        this.pairForm.props.automaticExecutionProviderName
      )
      this.b2b2Instruments = data
      if (callback) callback()
    } catch (error) {
      console.log({ error: error })
      this.b2b2Instruments = []
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  async getMinimumTradeSize(callback?: Function) {
    adminStore.dispatch('loader/show')
    try {
      if (!this.pairForm.props.automaticExecutionProviderName) return
      const isCreating = this.pairForm.props.id === null

      const { data } = await this.getValuesFromProvider(
        this.pairForm.props.automaticExecutionProviderName
      )
      if (data[this.pairForm.props.baseCurrencyId]) {
        const minBase =
          data[this.pairForm.props.baseCurrencyId].minimum_trade_size
        this.minBaseAmount = minBase
        if (isCreating) {
          this.pairForm.props.minBase = minBase
          this.pairForm.props.levelLower = minBase
        }
      }
      if (data[this.pairForm.props.quoteCurrencyId]) {
        const minQuote =
          data[this.pairForm.props.quoteCurrencyId].minimum_trade_size
        this.minQuoteAmount = minQuote
        if (isCreating) {
          this.pairForm.props.minQuote = minQuote
        }
      }
      if (callback) callback()
    } catch (error) {
      console.log({ error: error })
      this.b2b2Instruments = []
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  addNewPair(): void {
    this.showModal = true
    this.pairForm.props.id = null
    this.pairForm.props.baseCurrencyId = null
    this.pairForm.props.quoteCurrencyId = null
    this.pairForm.props.baseCurrencyRevenuePercentage = null
    this.pairForm.props.quoteCurrencyRevenuePercentage = null
    this.pairForm.props.allowSell = false
    this.pairForm.props.allowBuy = false
    this.pairForm.props.isActive = false
    this.pairForm.props.allowAutomaticTrade = false
    this.pairForm.props.automaticExecutionProviderName = null
    this.pairForm.props.instrumentId = null
    this.pairForm.props.minBase = null
    this.pairForm.props.minQuote = null
    this.pairForm.props.levelLower = null
    this.pairForm.props.levelHigher = null
    this.minBaseAmount = 0
    this.minQuoteAmount = 0
  }

  edit(pair: PairItem): void {
    adminStore.dispatch('loader/show')
    // this.pairForm.props.automaticExecutionProviderName =
    //   pair.automaticExecutionProviderName
    this.pairForm.props.id = pair.id
    this.pairForm.props.baseCurrencyId = pair.baseCurrencyId
    this.pairForm.props.quoteCurrencyId = pair.quoteCurrencyId
    this.pairForm.props.baseCurrencyRevenuePercentage =
      pair.baseCurrencyRevenuePercentage
    this.pairForm.props.quoteCurrencyRevenuePercentage =
      pair.quoteCurrencyRevenuePercentage
    this.pairForm.props.allowSell = pair.allowSell
    this.pairForm.props.allowBuy = pair.allowBuy
    this.pairForm.props.isActive = pair.isActive
    this.pairForm.props.minBase = pair.minBase
    this.pairForm.props.minQuote = pair.minQuote
    this.pairForm.props.levelLower = pair.levelLower
    this.pairForm.props.levelHigher = pair.levelHigher
    this.pairForm.props.allowAutomaticTrade = pair.allowAutomaticTrade
    if (this.pairForm.props.allowAutomaticTrade) {
      this.pairForm.props.automaticExecutionProviderName =
        pair.automaticExecutionProviderName
      this.getInstrumentByProvider(() => {
        this.pairForm.props.instrumentId = pair.instrumentId
      })
      this.getMinimumTradeSize(() => {
        this.showModal = true
      })
    } else {
      adminStore.dispatch('loader/hidden')
      this.pairForm.props.automaticExecutionProviderName = null
      this.pairForm.props.instrumentId = null
      this.showModal = true
    }
  }

  get validRevenue(): boolean {
    return (
      Number(this.pairForm.props.baseCurrencyRevenuePercentage) +
        Number(this.pairForm.props.quoteCurrencyRevenuePercentage) ===
      1
    )
  }

  async savePair(): Promise<void> {
    if (this.pairForm.invalid) return
    try {
      adminStore.dispatch('loader/show')
      const pair = new PairItem()
      pair.id = this.pairForm.props.id
      pair.minBase = parseFloat(this.pairForm.props.minBase)
      pair.minQuote = parseFloat(this.pairForm.props.minQuote)
      pair.allowAutomaticTrade =
        this.pairForm.props.allowAutomaticTrade === true
      pair.baseCurrencyRevenuePercentage = Number(
        this.pairForm.props.baseCurrencyRevenuePercentage
      )
      pair.quoteCurrencyRevenuePercentage = Number(
        this.pairForm.props.quoteCurrencyRevenuePercentage
      )
      pair.instrumentId = this.pairForm.props.instrumentId
      pair.allowBuy = this.pairForm.props.allowBuy === true
      pair.isActive = this.pairForm.props.isActive === true
      pair.allowSell = this.pairForm.props.allowSell === true
      pair.baseCurrencyId = this.pairForm.props.baseCurrencyId
      pair.quoteCurrencyId = this.pairForm.props.quoteCurrencyId
      pair.levelLower = parseFloat(this.pairForm.props.levelLower)
      pair.levelHigher = parseFloat(this.pairForm.props.levelHigher)
      pair.automaticExecutionProviderName = this.pairForm.props.automaticExecutionProviderName
      if (pair.id) {
        await this.updatePair(pair)
      } else {
        await this.addPair(pair)
      }
      this.getData()
      toast.success('Pair saved!')
      this.showModal = false
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      adminStore.dispatch('loader/hidden')
    }
  }

  get getFilteredPairs(): PairItem[] {
    if (!this.mainSearch) {
      return this.pairs
    }
    return this.pairs.filter(p => {
      if (p.id.toLowerCase().includes(this.mainSearch.toLowerCase())) {
        return p
      }
    })
  }

  get isValidateFormWithAllowAutomaticTrade(): boolean {
    if (!this.pairForm.props.allowAutomaticTrade) {
      return true
    }
    return (
      this.pairForm.props.automaticExecutionProviderName &&
      this.pairForm.props.allowAutomaticTrade &&
      this.pairForm.props.levelLower &&
      this.pairForm.props.minQuote &&
      this.pairForm.props.minBase &&
      this.pairForm.props.levelHigher
    )
  }

  mounted() {
    this.getData()
  }
}
