import AccountStatementItem from "@/types/account-statement/AccountStatementItem";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import http from "@/http-common-customer"
import OrderItem from "@/types/account-statement/OrderItem";

@Module({ namespaced: true, name: '' })
export default class AccountStatement extends VuexModule {
    public status!: string
    public accountStatements!: AccountStatementItem[]
    public orders!: OrderItem[]
    public statusOrder!: string
    public error!: object
    public selectedOrder!: OrderItem


    @Mutation
    public onStatementFetching(): void {
        this.status = 'fetching'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onStatementSuccess(payload: any): void {
        this.accountStatements = payload.data;
        this.error = {}
        this.status = 'success'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onStatementError(payload: any): void {
        this.accountStatements = [];
        this.error = payload
        this.status = 'error'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onOrderSuccess(payload: any): void {
        this.orders = payload.data;
        this.error = {}
        this.statusOrder = 'success'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onOrderError(payload: any): void {
        this.orders = [];
        this.error = payload
        this.statusOrder = 'error'
    }

    @Mutation
    public onOrderFetching(): void {
        this.statusOrder = 'fetching'
    }

    @Mutation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSelectedOrderSuccess(payload: any): void {
        this.selectedOrder = payload.data;
        this.statusOrder = 'success';
    }


    @Action({ rawError: true })
    public fetchStatements(payload: { fromDate: string; toDate: string; currencyId: string }) {
        return new Promise((resolve, reject) => {
            this.context.commit("onStatementFetching")
            const offset = new Date().getTimezoneOffset();
            http
                .get('customer-transaction/account-statement', { params: { ...payload, timezoneOffset: offset } })
                .then(response => {
                    this.context.commit("onStatementSuccess", response)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onStatementError", error)
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public fetchStatementsOnBehalfOf(payload: { customerId: number; fromDate: string; toDate: string; currencyId: string }) {
        return new Promise((resolve, reject) => {
            this.context.commit("onStatementFetching")
            const offset = new Date().getTimezoneOffset();
            http
                .get('customer-transaction/internal/account-statement', { params: { ...payload, timezoneOffset: offset } })
                .then(response => {
                    this.context.commit("onStatementSuccess", response)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onStatementError", error)
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public fetchOrders(payload: { fromDate?: string; toDate?: string; pairId?: string }) {
        return new Promise((resolve, reject) => {
            this.context.commit("onOrderFetching")
            const offset = new Date().getTimezoneOffset();
            http
                .get('customer-order/list', { params: { ...payload, timezoneOffset: offset } })
                .then(response => {
                    this.context.commit("onOrderSuccess", response)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onOrderError", error)
                    reject(error)
                })
        })
    }


    @Action({ rawError: true })
    public fetchSpecificOrder(orderId: string) {
        return new Promise((resolve, reject) => {
            const offset = new Date().getTimezoneOffset();
            this.context.commit("onOrderFetching")
            http
                .get(`customer-order/get-details/${orderId}`, { params: { timezoneOffset: offset } })
                .then(response => {
                    this.context.commit("onSelectedOrderSuccess", response)
                    resolve(response)
                })
                .catch(error => {
                    this.context.commit("onOrderError", error)
                    reject(error)
                })
        })
    }

    public get getStatements(): AccountStatementItem[] {
        return this.accountStatements
    }
    public get getStatus(): string {
        return this.status;
    }

    public get getOrders(): OrderItem[] {
        return this.orders
    }
    public get getStatusOrders(): string {
        return this.statusOrder;
    }

    public get getSelectedOrder(): OrderItem {
        return this.selectedOrder;
    }
}