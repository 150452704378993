import { createApp } from "vue";
import { VuelidatePlugin } from "@vuelidate/core";
import Toast from "vue-toastification";
import FileInput from "../node_modules/vue3-simple-file-input/src/components/FileInput.vue";
import "bootstrap";
import App from "./App.vue";
import { customerRouter } from "./router/customer";
import { adminRouter } from "./router/admin";
import { customerStore } from "./store/customer";
import { adminStore } from "./store/admin";
import DefaultLayout from "@/layouts/default.vue";
import DefaultAdminLayout from "@/layouts/default-admin.vue";
import AdminOnBehalfOfLayout from "@/layouts/admin-on-behalf-of.vue";
import Auth from "@/layouts/auth.vue";
import VueHighcharts from "vue3-highcharts";
import VuePlaceAutocomplete from "vue-place-autocomplete";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';

import "@/sass/main.scss";
import "vue-toastification/dist/index.css";
import { ReactiveFormConfig, ClientLibrary } from "@rxweb/reactive-forms";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

//Font awesome global
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import "vue-next-select/dist/index.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import ConfirmationService from 'primevue/confirmationservice';
import CsrfToken from '@/components/common/security/CsrfToken.vue'
import './registerServiceWorker'

library.add(faUserSecret);

ReactiveFormConfig.clientLib = ClientLibrary.Vue;
ReactiveFormConfig.set({
  validationMessage: {
    required: "This field is required",
  },
});

const isAdmin = location.hostname.includes("admin."); // TODO: exact match
const router = isAdmin ? adminRouter : customerRouter;
const store = isAdmin ? adminStore : customerStore;

const app = createApp(App)
  .component("default-layout", DefaultLayout)
  .component("default-admin-layout", DefaultAdminLayout)
  .component("admin-on-behalf-of-layout", AdminOnBehalfOfLayout)
  .component("auth-layout", Auth)
  .component("file-input", FileInput)
  .component("high-charts", VueHighcharts)
  .component("csrf-token", CsrfToken)
  .directive("tooltip", Tooltip)
  .use(store)
  .use(router)
  .use(VuelidatePlugin)
  .use(VueTelInput)
  .use(Toast)
  .use(VuePlaceAutocomplete)
  .use(PrimeVue)
  .use(ConfirmationService)

app.config.globalProperties.$isAdmin = isAdmin;
app.config.globalProperties.$permissions = {
  'role': 'main_admin',
  'generals': {
    'pending_deposit': true,
    'pending_withdrawals': true,
    'pending_manual_orders': true,
    'pending_customer_verification': true,
    'customer_management': true,
    'comercial_groups': true,
  },
  'on_behalf_of': {
    'create_automatic_order': true,
    'create_manual_order': true,
    'register_deposit': true,
    'register_withdrawal': true,
    'bank_and_wallets': true
  },
}
app.mount("#app");

export default app;
