import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "btn",
  style: {"pointer-events":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (
      _ctx.parity != null &&
      _ctx.pricesStatus == 'success' &&
      _ctx.prices[_ctx.parity.pair.baseCurrencyId + _ctx.parity.pair.quoteCurrencyId]
    )
    ? (_openBlock(), _createBlock("button", _hoisted_1, [
        _createVNode("span", {
          class: {
        'text-success': _ctx.lastPrice > _ctx.beforePrice,
        'text-danger': _ctx.lastPrice < _ctx.beforePrice
      }
        }, [
          (_ctx.isQuoteFiat)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" $ " + _toDisplayString(_ctx.formatPrice(_ctx.lastPrice * 1)) + " " + _toDisplayString(_ctx.parity.pair.quoteCurrencyId), 1)
              ], 64))
            : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatZeros(_ctx.lastPrice * 1, _ctx.getQuoteCurrency?.exact)) + " " + _toDisplayString(_ctx.parity.pair.quoteCurrencyId), 1)
              ], 64))
        ], 2)
      ]))
    : _createCommentVNode("", true)
}