
import ForgotPasswordForm from '@/types/auth/ForgotPasswordForm'
import TheBanner from './components/TheBanner.vue'
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-forms'
import { customerStore } from '@/store/customer'

const toast = useToast()
const profile = namespace('profile')

@Options({
  components: {
    TheBanner
  }
})
export default class ForgotPassword extends Vue {
  forgotPasswordForm!: IFormGroup<ForgotPasswordForm>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $v!: any

  @profile.Action
  public sendEmailForgotPassword!: (payload: {
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style
    email: string
  }) => Promise<object>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.forgotPasswordForm = new RxFormBuilder().formGroup(
      ForgotPasswordForm
    ) as IFormGroup<ForgotPasswordForm>
  }

  async onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      return this.touchAllFormControls()
    }
    try {
      customerStore.dispatch('loader/show')
      await this.sendEmailForgotPassword({
        email: this.forgotPasswordForm.props.email.toLowerCase()
      })
      toast.info('Check your email!', { timeout: false })
      this.$router.push({ name: 'auth.login' })
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    }
  }

  touchAllFormControls(): void {
    Object.keys(this.forgotPasswordForm.controls).forEach(crl => {
      this.forgotPasswordForm.get(crl).markAsDirty()
    })
  }
}
