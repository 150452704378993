
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import { Options, Vue } from 'vue-class-component'
import formatersMixin from '@/mixins/formatters'
@Options({
  props: {
    item: Object
  },
  components: {
    IconsCurrency
  },
  mixins: [formatersMixin]
})
export default class ListBackAccountsItem extends Vue {}
