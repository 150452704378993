import { RouteRecordRaw } from "vue-router";
import UserList from "@/components/admin/dashboard/UserList.vue";
import PendingDeposits from "@/components/admin/dashboard/PendingDeposits.vue";
import Deposits from "@/components/admin/dashboard/Deposits.vue";
import PendingWithdrawals from "@/components/admin/dashboard/PendingWithdrawals.vue";
import PendingOrders from "@/components/admin/dashboard/PendingOrders.vue";
import OrderDetails from "@/components/admin/dashboard/OrderDetails.vue";
import DashboardBase from "@/views/admin/DashboardBase.vue";
import User from "@/components/admin/dashboard/user.vue";
import CreateNewCustomer from "@/components/admin/dashboard/CreateNewCustomer.vue";
import ValidateCompany from "@/components/admin/dashboard/ValidateCompany.vue";
import FileHistoryForCustomers from "@/components/admin/dashboard/FileHistoryForCustomers.vue";
import { ifAdminAuthenticated, checkPermission } from "../guards";
import SitePermissions from "@/types/users/SitePermissionsEnum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardBase,
    beforeEnter: ifAdminAuthenticated,
    children: [
      {
        path: "pending-users",
        component: UserList,
        name: "dashboard.pending-users",
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingCustomerVerification
        }
      },
      {
        path: "user-verify/:id",
        name: "dashboard.user-verify",
        component: User,
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingCustomerVerification
        },
      },
      {
        path: "add-user/:customerId",
        name: "dashboard.add.user",
        component: CreateNewCustomer,
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingCustomerVerification
        },
      },
      {
        path: "company-verify/:id",
        name: "dashboard.company-verify",
        component: ValidateCompany,
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingCustomerVerification
        },
      },
      {
        path: "file-history/:id",
        name: "dashboard.file.history",
        component: FileHistoryForCustomers,
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingCustomerVerification
        },
      },
      {
        path: "deposits",
        component: Deposits,
        name: "dashboard.deposits",
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingDeposit
        },
      },
      {
        path: "pending-deposits",
        component: PendingDeposits,
        name: "dashboard.pending-deposits",
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingDeposit
        },
      },
      {
        path: "pending-withdrawals",
        component: PendingWithdrawals,
        name: "dashboard.pending-withdrawals",
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingWithdrawals
        },
      },
      {
        path: "order-details/:id",
        name: "dashboard.order-details",
        component: OrderDetails,
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingManualOrders
        }
      },
      {
        path: "unfilled-orders",
        component: PendingOrders,
        name: "dashboard.unfilled-client-orders",
        beforeEnter: checkPermission,
        meta: {
          layout: "default-admin",
          permission: SitePermissions.pendingManualOrders
        },
      },
    ],
  },
];
export default routes;
