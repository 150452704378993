
import { Options, Vue } from 'vue-class-component'
import Balances from '@/components/customer/balances/balances.vue'
import OrderHistory from '@/components/customer/account-statement/OrderHistory.vue'
import OnBehalfOfOptions from '@/views/admin/on-behalf-of/OnBehalfOfOptions.vue'
import TradeChart from '@/components/common/charts/tradeChart.vue'
import Intervals from '@/components/customer/transaction/intervals.vue'
import CryptoBalances from '@/components/customer/balances/cryptoBalances.vue'
import SinglePrice from '@/components/customer/price/singlePrice.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import { namespace } from 'vuex-class'
import { TransactionType } from '@/types'
const transactions = namespace('transactions')
const prices = namespace('prices')
const assets = namespace('assets')
const balance = namespace('balance')
@Options({
  data() {
    return {
      parity: {},
      interval: '4HRS',
      minInterval: 240
    }
  },
  components: {
    Balances,
    TradeChart,
    Intervals,
    CryptoBalances,
    SinglePrice,
    OrderHistory,
    OnBehalfOfOptions,
    TabView,
    TabPanel
  }
})
export default class Home extends Vue {
  /* eslint-disable */
  parity!: any
  interval!: string
  minInterval!: number
  currencyBalance = localStorage.getItem('preferred-currency')
    ? localStorage.getItem('preferred-currency')
    : 'CAD'
  get onBehalfOf() {
    return (this as any).$isAdmin ? Number(this.$route.params?.customerId) : 0
  }

  indexTab = 0

  @transactions.State
  transaction!: TransactionType

  @transactions.Action
  public setTransaction!: (params: object) => {}

  @prices.Action
  // eslint-disable-next-line
  public getPricesOnBehalfOf!: (customerId?: number) => Promise<any>

  @prices.Action
  // eslint-disable-next-line
  public getPrices!: () => Promise<any>

  @prices.Action
  // eslint-disable-next-line
  public unmountConnection!: () => void

  @prices.State
  // eslint-disable-next-line
  connection!: any

  @assets.Action
  // eslint-disable-next-line
  public getAssets!: () => Promise<any>

  @balance.Action
  // eslint-disable-next-line
  public getBalance!: (customerId?: number) => Promise<any>

  // onParityUpdate (parity: object) {
  //     this.parity = parity
  // }
  /* eslint-disable */
  candlestickChange() {
    if (this.transaction.type == 'BUY') {
      this.parity.cripto = this.transaction.toCurrency
      this.parity.local = this.transaction.fromCurrency
    } else {
      this.parity.cripto = this.transaction.fromCurrency
      this.parity.local = this.transaction.toCurrency
    }
  }
  beforeUnmount() {
    this.unmountConnection()
  }
  beforeMount() {
    this.getBalance(this.onBehalfOf)
    this.getAssets()
    if ((this as any).$isAdmin) {
      this.getPricesOnBehalfOf(this.onBehalfOf)
    } else {
      this.getPrices()
    }
    this.parity = {
      local: 'CAD',
      cripto: 'BTC'
    }
    this.setTransaction({
      modal: false,
      type: 'BUY',
      fromCurrency: 'CAD',
      toCurrency: 'BTC',
      from: 0,
      to: 0
    })
  }
  changeCurrency(currency: string) {
    this.currencyBalance = currency
  }
  mounted() {
    if (this.$route.query.tab === 'order-history') this.indexTab = 1
  }
}
