
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import { API_KEY_GOOGLE } from '@/config/api-keys'
import StepOneForm from '@/types/verify/StepOneForm'
import RequestStepOne from '@/types/verify/RequestStepOne'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import { customerStore } from '@/store/customer'
import UserItem from '@/types/users/UserItem'
import moment from 'moment'
const toast = useToast()
const verify = namespace('verify')
const profile = namespace('profile')

@Options({
  name: 'StepOne',
  data() {
    return {
      phoneNumber: '',
      phoneNumberCode: null,
      addressLine1: '',
      apiKeyGoogle: API_KEY_GOOGLE,
      autocomplete: null,
      isResultAddres: false,
      daysData: [],
      monthsData: [],
      yearsData: [],
      occupationsData: [],
      stepOneForm: undefined,
      isDisabledSubmitButton: false,
      isResultAddress: false
    }
  }
})
export default class StepOne extends Vue {
  // eslint-disable-next-line
  autocomplete: any
  daysData!: string[]
  occupation!: string
  yearsData!: string[]
  phoneNumber!: string
  monthsData!: string[]
  addressLine1!: string
  phoneNumberCode!: string
  phoneNumberCountry!: string
  isResultAddress!: boolean
  occupationsData!: string[]
  isDisabledSubmitButton = false
  stepOneForm!: IFormGroup<StepOneForm>
  isOtherOccupation = false
  isLoading = true

  @profile.State
  public status!: object

  @profile.Getter
  public userProfile!: object

  @verify.State
  public step!: number

  @verify.Action
  public stepOne!: (params: RequestStepOne) => Promise<object>

  @profile.Action
  public checkToken!: () => Promise<object>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(pros: any) {
    super(pros)
    this.stepOneForm = new RxFormBuilder().formGroup(StepOneForm) as IFormGroup<
      StepOneForm
    >
  }

  mounted() {
    this.isLoading = true
    this.checkToken().then(() => {
      this.fillForm()
    })
  }

  fillForm(): void {
    const user: UserItem = { ...this.userProfile } as UserItem
    this.isResultAddress = user?.addressLine1 ? true : false
    this.autocomplete = user?.addressLine1
    this.stepOneForm.props.city = user?.city
    this.stepOneForm.props.email = user?.email
    this.stepOneForm.props.lastName = user?.lastName
    this.stepOneForm.props.firstName = user?.firstName
    this.stepOneForm.props.province = user?.province
    this.stepOneForm.props.postalCode = user?.postalCode
    this.stepOneForm.props.occupation = user?.occupation
    this.stepOneForm.props.phoneNumber = user?.phoneNumber
    this.stepOneForm.props.addressLine2 = user?.addressLine2
    this.stepOneForm.props.street = user?.street
    this.stepOneForm.props.streetNumber = user?.streetNumber
    this.stepOneForm.props.interiorNumber = user?.interiorNumber
    this.stepOneForm.props.birthdate = user?.dateOfBirth
    this.stepOneForm.props.government = user?.relationWithGovernment
    this.stepOneForm.props.nickname = user?.nickname
    this.stepOneForm.props.phoneNumberCode = user?.phoneNumberCode
    this.stepOneForm.props.phoneCountry = user?.phoneCountry
    if (!this.occupationsData.includes(user?.occupation)) {
      this.isOtherOccupation = true
    }
    this.isLoading = false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAddress(place: any, response: any): void {
    this.isResultAddress = true
    this.autocomplete = response.formatted_address
    this.setAddressComponents(response.address_components)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAddressComponents(components: any[]): void {
    components.forEach(c => {
      if (c.types.includes('street_number')) {
        this.stepOneForm.props.streetNumber = c.long_name
      } else if (
        c.types.includes('locality') &&
        c.types.includes('political')
      ) {
        this.stepOneForm.props.city = c.long_name
      } else if (
        c.types.includes('sublocality') ||
        c.types.includes('neighborhood') ||
        c.types.includes('administrative_area_level_1')
      ) {
        this.stepOneForm.props.province = c.long_name
      } else if (c.types.includes('postal_code')) {
        this.stepOneForm.props.postalCode = c.long_name
      } else if (c.types.includes('route')) {
        this.stepOneForm.props.street = c.long_name
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changePhone(val: any): void {
    this.phoneNumber = val?.target?.value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeCountryInputTel(val: any): void {
    this.stepOneForm.props.phoneNumberCode = val.dialCode
    this.stepOneForm.props.phoneCountry = val.iso2
  }

  resetAutocomplete(): void {
    this.isResultAddress = false
    this.autocomplete = ''
  }

  handleFormWithErrors(): void {
    this.isDisabledSubmitButton = true
    this.touchAllFormControls()
    toast.error('Check all fields!')
    setTimeout(() => (this.isDisabledSubmitButton = false), 3000)
  }

  async submit(): Promise<void> {
    if (this.stepOneForm.invalid) {
      return this.handleFormWithErrors()
    }
    try {
      customerStore.dispatch('loader/show')
      const req = new RequestStepOne()
      req.dateOfBirth = moment(this.stepOneForm.props.birthdate).format(
        'yyyy-MM-DD hh:mm:ss'
      )
      req.phoneCountry = this.stepOneForm.props.phoneCountry
      req.occupation = this.stepOneForm.props.occupation
      req.street = this.stepOneForm.props.street
      req.streetNumber = this.stepOneForm.props.streetNumber
      req.relationWithGovernment = this.stepOneForm.props.government
      req.postalCode = this.stepOneForm.props.postalCode
      req.addressLine2 = this.stepOneForm.props.addressLine2
      req.addressLine1 = this.autocomplete
      req.city = this.stepOneForm.props.city
      req.interiorNumber = this.stepOneForm.props.interiorNumber
      req.province = this.stepOneForm.props.province
      req.phoneNumber = this.phoneNumber
      req.phoneNumberCode = this.stepOneForm.props.phoneNumberCode
      // req.nickname = this.stepOneForm.props.nickname
      await this.stepOne(req)
      toast.success('Information saved!')
      this.$router.push({ name: 'verify.two' })
    } catch (error) {
      console.log(error)

      toast.error('An error has occurred \nPlease try again')
    }
  }

  touchAllFormControls(): void {
    Object.keys(this.stepOneForm.controls).forEach(crl => {
      this.stepOneForm.get(crl).markAsDirty()
    })
  }
}
