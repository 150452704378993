
	import { Options, Vue } from 'vue-class-component'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Calendar from 'primevue/calendar'
	import { namespace } from 'vuex-class'
	import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
	import Datepicker from 'vue3-datepicker'
	import * as moment from 'moment'
	import OrderItem from '@/types/account-statement/OrderItem'
	import formatters from '@/mixins/formatters'
	import exportFromJSON from 'export-from-json'
	import OrderHistoryCSV from '@/types/account-statement/OderHistoryCSV'
	import UserItem from '@/types/users/UserItem'
	const accountStatement = namespace('accountStatement')
	const transactions = namespace('transactions')
	const balance = namespace('balance')
	const onBehalfOf = namespace('onBehalfOfUser')
	const assets = namespace('assets')

	@Options({
		name: 'AccountStatementReport',
		mixins: [formatters],
		components: {
			DataTable,
			Column,
			IconsCurrency,
			Datepicker,
			Calendar,
		},
		watch: {
			from() {
				this.changeFilters()
			},
			to() {
				this.changeFilters()
			},
			pairId() {
				this.changeFilters()
			},
			statusTransaction() {
				this.statusTransaction == 'success' ? this.getRecords() : null
			},
		},
	})
	export default class OrderHistory extends Vue {
		public from = moment
			.default()
			.subtract(3, 'months')
			.format('yyyy-MM-DD')
		public to = moment.default().format('yyyy-MM-DD')
		public pairId = ''
		public period = '3M'
		public pairs = ['']
		get onBehalfOf() {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (this as any).$isAdmin ? Number(this.$route.params?.customerId) : 0
		}

		@onBehalfOf.Getter
		public getUserOnBehalfOf!: UserItem

		@assets.Getter
		// eslint-disable-next-line
		fiatsAssets!: any

		@assets.Getter
		// eslint-disable-next-line
		cryptosAssets!: any

		@assets.Getter
		statusAssets!: string

		@balance.State
		status!: string

		@balance.Getter
		// eslint-disable-next-line
		fiats!: any

		@balance.Getter
		// eslint-disable-next-line
		cryptos!: any

		@transactions.Getter
		public statusTransaction!: string

		@accountStatement.Getter
		public getOrders!: OrderItem[]

		@accountStatement.Getter
		public getStatusOrders!: string

		@accountStatement.Action
		public fetchOrders!: (payload: {
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			customerId?: number
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			fromDate: string
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			toDate: string
			// eslint-disable-next-line @typescript-eslint/member-delimiter-style
			currencyId: string
		}) => Promise<void>

		@assets.Action
		public getAssets!: () => Promise<void>
		//MIXINS

		public formatPrice!: (amount: number, decimals: number) => number

		public formatDate!: (date: string) => string

		public formatZeros!: (amount: number, exact?: number) => string

		public format!: (amount: number | string, decimalCount?: number) => string

		public $isAdmin!: boolean

		keypressFilter(event: KeyboardEvent, model: string): void {
			if (!(parseInt(event.key) >= 0 && parseInt(event.key) <= 9)) {
				event.preventDefault()
			} else {
				if (this[model].length == 4 || this[model].length == 7) {
					this[model] = this[model] + '-' + event.key
					event.preventDefault()
				}
			}
		}
		changeFilters() {
			if (
				!moment.default(this.from).isValid() ||
				!moment.default(this.to).isValid()
			)
				return
			this.getRecords()
		}

		getRecords(): void {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const payload: any = {
				fromDate: moment.default(this.from).format('yyyy-MM-DD'),
				toDate: moment.default(this.to).format('yyyy-MM-DD'),
				pairId: this.pairId,
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if ((this as any).$isAdmin) payload.customerId = Number(this.onBehalfOf)
			this.fetchOrders(payload)
		}

		interval(period: string) {
			const year = moment.default().format('YYYY')
			this.period = period
			switch (period) {
				case '1D':
					this.from = moment
						.default()
						.subtract(1, 'days')
						.format('yyyy-MM-DDThh:mm')
					this.to = moment.default().format('yyyy-MM-DDThh:mm')
					break
				case '1W':
					this.from = moment
						.default()
						.subtract(7, 'days')
						.format('yyyy-MM-DDThh:mm')
					this.to = moment.default().format('yyyy-MM-DDThh:mm')
					break
				case '1M':
					this.from = moment
						.default()
						.subtract(1, 'months')
						.format('yyyy-MM-DDThh:mm')
					this.to = moment.default().format('yyyy-MM-DDThh:mm')
					break
				case '3M':
					this.from = moment
						.default()
						.subtract(3, 'months')
						.format('yyyy-MM-DDThh:mm')
					this.to = moment.default().format('yyyy-MM-DDThh:mm')
					break
				case 'Q1':
					this.from = moment
						.default(`${year}-01-01T00:00`)
						.format('yyyy-MM-DDThh:mm')

					this.to = moment
						.default(`${year}-03-31T00:00`)
						.format('yyyy-MM-DDThh:mm')
					break
				case 'Q2':
					this.from = moment
						.default(`${year}-04-01T00:00`)
						.format('yyyy-MM-DDThh:mm')

					this.to = moment
						.default(`${year}-06-30T00:00`)
						.format('yyyy-MM-DDThh:mm')
					break
				case 'Q3':
					this.from = moment
						.default(`${year}-07-01T00:00`)
						.format('yyyy-MM-DDThh:mm')

					this.to = moment
						.default(`${year}-09-30T00:00`)
						.format('yyyy-MM-DDThh:mm')
					break
				case 'Q4':
					this.from = moment
						.default(`${year}-10-01T00:00`)
						.format('yyyy-MM-DDThh:mm')

					this.to = moment
						.default(`${year}-12-31T00:00`)
						.format('yyyy-MM-DDThh:mm')
					break
			}
			this.changeFilters()
		}
		createPairs() {
			if (this.status == 'success') {
				this.pairs.pop()
				this.cryptos.forEach((crypto) => {
					this.fiats.forEach((fiat) => {
						this.pairs.push(crypto.currencyId + fiat.currencyId)
					})
				})
			} else {
				setTimeout(this.createPairs, 2000)
			}
		}
		pairType(pair): string {
			if (
				this.isFiat(pair.baseCurrencyId) &&
				this.isFiat(pair.quoteCurrencyId)
			) {
				return 'fiat'
			}
			if (
				this.isCrypto(pair.baseCurrencyId) &&
				this.isCrypto(pair.quoteCurrencyId)
			) {
				return 'crypto'
			}
			return 'mix'
		}
		isFiat(id: string) {
			return this.fiatsAssets.some((element: any) => element.id == id)
		}
		isCrypto(id: string) {
			return this.cryptosAssets.some((element: any) => element.id == id)
		}
		downloadXls() {
			const data: OrderHistoryCSV[] = []
			this.getOrders.forEach((element) => {
				data.push({
					Date: moment.default(element.createdAt).format('MM/DD/YYYY'),
					Pair: element.pair.id,
					Side: element.side == 1 ? 'Buy' : 'Sell',
					Amount: this.format(element.amount),
					Price: this.format(element.unitPrice),
					Total: this.format(element.finalPrice),
					...(this.$isAdmin && {
						'Reversed-Id': element.reversedOrderId ?? 'N/A',
					}),
				})
			})
			let fileName = `Satstreet-Report-${new Date()}`
			if (this.$isAdmin) {
				fileName += `-${this.getUserOnBehalfOf.nickname}`
			}
			const exportType = 'csv'
			exportFromJSON({ data, fileName, exportType })
		}
		mounted() {
			if (this.statusAssets != 'success') {
				this.getAssets()
			}
			this.getRecords()
			this.createPairs()
		}
	}
