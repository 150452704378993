
import SiteContent from '@/components/common/layout/siteContent.vue'
import AuthNavbar from '@/components/common/layout/authNavbar.vue'
import SiteFooter from '@/components/common/layout/siteFooter.vue'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: {
    SiteContent,
    AuthNavbar,
    SiteFooter
  }
})
export default class AuthLayout extends Vue {
  isSmall = false
  mounted() {
    this.checkIsMobile()
  }

  checkIsMobile() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    if (vw < 500) {
      this.isSmall = true
    }
  }

  get showNavbar(): boolean {
    return !this.isSmall || this.$route.name !== 'auth.login'
    // return this.$route.name !== 'auth.login'
  }
}
