
import { Options, Vue } from 'vue-class-component'
import { namespace } from 'vuex-class'
import ListBankAccountItem from './ListBankAccountItem.vue'
import BankAccountItem from '@/types/bank-account/BankAccountItem'
const bankAccount = namespace('bankAccount')

@Options({
  components: { ListBankAccountItem }
})
export default class ListBackAccounts extends Vue {
  @bankAccount.Getter
  public getAccounts!: BankAccountItem[]

  @bankAccount.Getter
  public getAccountStatus!: string

  @bankAccount.Action
  public fetchBankAccounts!: () => Promise<void>

  mounted() {
    this.fetchBankAccounts()
  }
}
