import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.periods, (prd) => {
    return (_openBlock(), _createBlock("button", {
      key: prd.id,
      class: ["btn btn-sm btn-interval", {'btn-primary': _ctx.period.id == prd.id}],
      onClick: ($event: any) => (_ctx.changePeriod(prd))
    }, _toDisplayString(prd.symbol), 11, ["onClick"]))
  }), 128))
}