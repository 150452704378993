import { RouteRecordRaw } from "vue-router";
import { checkPermission, ifAdminAuthenticated } from "../guards";
import PairsBaseView from "@/views/admin/PairsBaseView.vue";
import ListPairs from "@/components/admin/pairs/ListPairs.vue"
import SitePermissionsEnum from "@/types/users/SitePermissionsEnum";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/pairs",
        name: "pairs",
        component: PairsBaseView,
        beforeEnter: ifAdminAuthenticated,
        redirect: "",
        children: [
            {
                path: "list",
                name: "pairs.list",
                component: ListPairs,
                beforeEnter: checkPermission,
                meta: { layout: "default-admin" },
            }
        ],
    },
];
export default routes;
