
import { customerStore } from '@/store/customer'
import UserItem from '@/types/users/UserItem'
import { Options, Vue } from 'vue-class-component'
import { useToast } from 'vue-toastification'
import { namespace } from 'vuex-class'
import $ from 'jquery'
import { FILE_SIZE_LIMIT_MB } from '@/config/config'
import FileUser from '@/types/users/FileUser'
// import { adminStore } from '@/store/admin'
Vue.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter'])
const verify = namespace('verify')
const profile = namespace('profile')
const downloads = namespace('downloads')
const users = namespace('users')
const toast = useToast()

@Options({
  data() {
    return {
      identification: null,
      identificationKey: '',
      identificationSuccess: false,
      identificationBack: null,
      identificationBackKey: '',
      identificationBackSuccess: false,
      address: null,
      addressKey: '',
      addressSuccess: false
    }
  }
})
export default class StepTwo extends Vue {
  identification!: File
  identificationKey!: string
  identificationLoading = false
  identificationSuccess!: boolean

  identificationBack!: File
  identificationBackKey!: string
  identificationBackLoading = false
  identificationBackSuccess!: boolean

  address!: File
  addressKey!: string
  addressLoading = false
  addressSuccess!: boolean

  @verify.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stepTwo!: (identification: File) => Promise<any>

  @verify.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stepTwoBack!: (identificationBack: File) => Promise<any>

  @verify.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public stepThree!: (address: File) => Promise<any>

  @profile.Getter
  public userProfile!: UserItem

  @profile.Action
  public checkToken!: () => Promise<object>

  @downloads.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile!: (file: FileUser) => Promise<any>

  $isAdmin!: boolean

  customerId!: number

  @users.Action
  getSpecificUser!: (id: number) => Promise<UserItem>

  async downloadDocument(key: string): Promise<void> {
    try {
      this.showLoader()
      const file = new FileUser()
      file.key = key
      const { data } = await this.downloadFile(file)
      window.location.href = data
    } catch (error) {
      console.log(error)
      toast.error('Download error, please try later.')
    } finally {
      this.hideLoader()
    }
  }

  mounted() {
    this.processUserInfo()
  }

  async processUserInfo() {
    let user = new UserItem()
    try {
      this.showLoader()
      if (this.$isAdmin) {
        const customerId = Number(this.$route.params['customerId'])
        this.customerId = customerId
        user = await this.getSpecificUser(customerId)
      } else {
        await this.checkToken()
        user = this.userProfile as UserItem
      }
      this.checkIfUserHasFiles(user.files)
    } catch (error) {
      console.log(error)
    } finally {
      this.hideLoader()
    }
  }

  showLoader(file?: string): void {
    switch (file) {
      case 'identification':
        this.identificationLoading = true
        break
      case 'identificationBack':
        this.identificationBackLoading = true
        break
      case 'address':
        this.addressLoading = true
        break
    }
    customerStore.dispatch('loader/show')
  }

  hideLoader(): void {
    customerStore.dispatch('loader/hidden')
    this.addressLoading = false
    this.identificationLoading = false
    this.identificationBackLoading = false
  }

  checkIfUserHasFiles(files) {
    const identification = files?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (file: any) => file.typeOfDocument === 'documentId'
    )
    if (identification) {
      this.identificationSuccess = true
      this.identificationKey = identification.key
    }

    const identificationBack = files?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (file: any) => file.typeOfDocument === 'documentIdBack'
    )
    if (identificationBack) {
      this.identificationBackSuccess = true
      this.identificationBackKey = identificationBack.key
    }

    const address = files?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (file: any) => file.typeOfDocument === 'documentAddress'
    )
    if (address) {
      this.addressSuccess = true
      this.addressKey = address.key
    }
  }

  get allFilesSuccess(): boolean {
    return (
      this.identificationSuccess &&
      this.identificationSuccess &&
      this.addressSuccess
    )
  }

  uploadFile(inputId: string): void {
    document.getElementById(inputId)?.click()
  }

  resetInputFile(inputId: string): void {
    $(`#${inputId}`).val(null)
  }

  checkFileName(file: File): boolean {
    const extension = file.name.split('.').pop() || ''
    return ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'].includes(extension)
  }

  checkFileType(type) {
    return [
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'application/pdf'
    ].includes(type)
  }

  checkFileSize(file: File): boolean {
    const size = parseFloat((file.size / 1024 / 1024).toFixed(4))
    return size > FILE_SIZE_LIMIT_MB
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOpenFile(e: any, type: string) {
    const files = e.target.files || e.dataTransfer.files
    if (!files.length) return

    if (!this.checkFileType(files[0].type)) {
      toast.warning('Invalid file type.')
      return
    }

    if (!this.checkFileName(files[0])) {
      toast.warning('Invalid file type.')
      return
    }

    if (this.checkFileSize(files[0])) {
      toast.warning('File oversize. Limit 10MB.')
      return
    }
    if (type === 'address') {
      this.address = files[0]
      this.submitStepThree()
    } else if (type === 'identification') {
      this.identification = files[0]
      this.submitStepTwo()
    } else {
      this.identificationBack = files[0]
      this.submitStepTwoBack()
    }
  }

  async submitStepTwo() {
    try {
      this.showLoader('identification')
      if (this.$isAdmin) {
        this.identification['customerId'] = this.customerId
      }
      const res = await this.stepTwo(this.identification)
      this.identificationKey = res.data.key
      this.identificationSuccess = true
      this.onSuccessResponseUploadFile()
    } catch (e) {
      console.log(e)
      this.resetInputFile('file-identification')
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      this.hideLoader()
    }
  }

  async submitStepTwoBack() {
    try {
      this.showLoader('identificationBack')
      if (this.$isAdmin) {
        this.identificationBack['customerId'] = this.customerId
      }
      const res = await this.stepTwoBack(this.identificationBack)
      this.identificationBackKey = res.data.key
      this.identificationBackSuccess = true
      this.onSuccessResponseUploadFile()
    } catch (e) {
      console.log(e)
      this.resetInputFile('file-identification-back')
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      this.hideLoader()
    }
  }

  async submitStepThree() {
    try {
      this.showLoader('address')
      if (this.$isAdmin) {
        this.address['customerId'] = this.customerId
      }
      const res = await this.stepThree(this.address)
      this.addressKey = res.data.key
      this.addressSuccess = true
      this.onSuccessResponseUploadFile()
    } catch (e) {
      this.resetInputFile('file-address')
      console.log(e)
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      this.hideLoader()
    }
  }

  onSuccessResponseUploadFile(): void {
    toast.success('Success!')
  }
}
