
import { Options, Vue } from 'vue-class-component'
import periods from '@/mixins/periods'
@Options({
    data(){
        return {
            period: Object,
            startDate: String,
            endDate: String
        }
    },
    mixins: [
        periods
    ],
    emits: ["update"]
})
export default class Intervals extends Vue {
    period!: object
    periods!: object[]
    startDate!: string
    endDate!: string
    changePeriod(period: object){
        this.period = period
        this.$emit("update", this.period)
    }
    beforeMount(){
        this.period = this.periods[2] 
    }
}
