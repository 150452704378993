
import { ref } from 'vue'
import { Options, Vue } from 'vue-class-component'
import VueSelect from 'vue-next-select'
import formatters from '@/mixins/formatters'
import { namespace } from 'vuex-class'
import UserItem from '@/types/users/UserItem'
const users = namespace('users')
@Options({
  props: {
    label: String
  },
  data() {
    return {
      selectedCustomer: ref(null)
    }
  },
  mixins: [formatters],
  components: {
    VueSelect
  },
  watch: {
    selectedCustomer: function(value, oldValue) {
      console.log('changed')
      this.$emit('customerChanged', this.selectedCustomer)
    }
  }
})
export default class CustomerSelect extends Vue {
  selectedCustomer!: any
  searchInput = ''

  @users.Getter
  users!: UserItem[]

  @users.Action
  public getUsersFilter!: (keyWord) => Promise<UserItem[]>
  @users.Action
  public clearUsers!: () => {}

  clear() {
    this.selectedCustomer = null
    this.clearUsers()
  }

  async hanldeSearchInput(event) {
    await new Promise(resolve => setTimeout(resolve, 1))
    if (event.target.value.length < 3 && event.target.value.length != 0) {
      this.selectedCustomer = null
      this.clearUsers()
    }
    if (this.selectedCustomer) return
    this.searchInput = event.target.value
  }

  async getFilteredUsers() {
    console.log('getFilteredUsers')
    await new Promise(resolve => setTimeout(resolve, 1))
    if (this.selectedCustomer || this.searchInput.length < 3) return
    try {
      const keyWord = this.searchInput.split(' ')[0]
      await this.getUsersFilter({ keyWord })
    } catch (error) {
      console.log(error)
    }
  }

  beforeUnmount() {
    this.clearUsers()
  }
}
