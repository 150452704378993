import { Action, Module, VuexModule } from "vuex-module-decorators";
import http from "@/http-common-customer"


@Module({ namespaced: true, name: '' })
export default class OwnerAndSigner extends VuexModule {

    @Action({ rawError: true })
    public registerSigner(signer: FormData) {
        return new Promise((resolve, reject) => {
            http
                .post('corporate/signer', signer)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public registerOwner(owner: FormData) {
        return new Promise((resolve, reject) => {
            http
                .post('corporate/owner', owner)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public updateOwner(owner: FormData) {
        return new Promise((resolve, reject) => {
            http
                .put('corporate/owner', owner)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    @Action({ rawError: true })
    public updateSigner(signer: FormData) {
        return new Promise((resolve, reject) => {
            http
                .put('corporate/signer', signer)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

}