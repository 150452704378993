
import { Vue, Options } from 'vue-class-component'
import Button from 'primevue/button'
import useClipboard from 'vue-clipboard3'

@Options({
  name: 'CopyToClipboard',
  props: {
    text: String
  },
  components: {
    Button
  }
})
export default class CopyToClipboard extends Vue {
  text!: string
  message = 'Copy to Clipboard'

  async onClick() {
    const { toClipboard } = useClipboard()
    try {
      await toClipboard(this.text)
      this.message = 'Copied!'
      setTimeout(() => {
        this.message = 'Copy to Clipboard'
      }, 2000)
    } catch (e) {
      console.error(e)
    }
  }
}
