export default {
    pendingDeposit: { name: 'pendingDeposit', label: 'Pending Deposits' },
    pendingWithdrawals: { name: 'pendingWithdrawals', label: 'Pending Withdrawals' },
    pendingManualOrders: { name: 'pendingManualOrders', label: 'Pending Manual Orders' },
    pendingCustomerVerification: { name: 'pendingCustomerVerification', label: 'Pending Customers' },
    customerManagement: { name: 'customerManagement', label: 'Customer Management' },
    commercialGroups: { name: 'commercialGroups', label: 'Commercial Groups' },
    manageAdmins: { name: 'manageAdmins', label: 'Manage Admins' },
    managePairs: { name: 'managePairs', label: 'Manage Pairs' },
    manageCurrencies: { name: 'manageCurrencies', label: 'Manage Currencies' },
    OBOAutomaticOrder: { name: 'OBOAutomaticOrder', label: 'OBO Automatic Order' },
    OBOManualOrder: { name: 'OBOManualOrder', label: 'OBO Manual Order' },
    OBORegisterDeposit: { name: 'OBORegisterDeposit', label: 'OBO Register Deposits' },
    OBORegisterWithdrawal: { name: 'OBORegisterWithdrawal', label: 'OBO Register Withdrawal' },
    OBORegisterBankWallets: { name: 'OBORegisterBankWallets', label: 'OBO REgister Bank Accounts' },
}
