
import ConfirmPopup from 'primevue/confirmpopup'
import { Options, Vue } from 'vue-class-component'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Modal from '@/components/common/modal.vue'
import { FilterMatchMode } from 'primevue/api'
import Column from 'primevue/column'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import { adminStore } from '@/store/admin'
import WithdrawalItem from '@/types/account-movement/WithdrawalItem'
import IconsCurrency from '@/components/customer/transaction/iconsCurrency.vue'
import formatters from '@/mixins/formatters'
const toast = useToast()
const withdrawals = namespace('withdrawals')
const assets = namespace('assets')
@Options({
  data() {
    return {
      filters: null,
      showModal: false,
      showDetailsModal: false,
      transactionId: ''
    }
  },
  mixins: [formatters],
  components: {
    DataTable,
    Column,
    InputText,
    IconsCurrency,
    Modal,
    ConfirmPopup
  }
})
export default class PendingWithdrawals extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters!: any
  showModal!: boolean
  showDetailsModal!: boolean

  doneWithdrawalId!: number
  transactionId!: string

  @withdrawals.State
  status!: string
  @withdrawals.State
  manageWithdrawalStatus!: string
  @withdrawals.State
  manageWithdrawal!: string

  @withdrawals.Getter
  withdrawals!: WithdrawalItem[]
  @withdrawals.Getter
  withdrawalDetails!: WithdrawalItem

  @withdrawals.Action
  public getPendingWithdrawals!: () => Promise<WithdrawalItem[]>
  @withdrawals.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public withdrawalDone!: ({ withdrawalId, transactionId }) => Promise<any>
  @withdrawals.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public cancelWithdrawal!: (withdrawalId) => Promise<any>
  @withdrawals.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSpecificWithdrawal!: (withdrawalId) => Promise<any>

  @assets.Getter
  // eslint-disable-next-line
  cryptosAssets!: any

  @assets.Getter
  // eslint-disable-next-line
  fiatsAssets!: any

  modalOkCallback: Function = () => undefined

  async mounted() {
    try {
      //adminStore.dispatch("loader/show");
      await this.getPendingWithdrawals()
    } catch (error) {
      toast.error(error)
    }
  }

  executeWithdrawalDone() {
    const withdrawalId = this.doneWithdrawalId
    this.showModal = false
    this.withdrawalDone({
      withdrawalId,
      transactionId: this.transactionId
    }).then(() => {
      const withdrawalIndex = this.withdrawals.findIndex(
        e => e.id == withdrawalId
      )
      this.withdrawals.splice(withdrawalIndex, 1)
      adminStore.dispatch('dashboard/getCards')
      this.transactionId = ''
      toast.success('Withdrawal done')
    })
  }

  showModalWithdrawalDone(withdrawalId: number) {
    this.doneWithdrawalId = withdrawalId
    this.showModal = true
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  executeCancelWithdrawal(event: any, withdrawalId: number) {
    this.$confirm.require({
      target: event.currentTarget,
      message: 'Are you sure?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelWithdrawal(withdrawalId).then(() => {
          const withdrawalIndex = this.withdrawals.findIndex(
            e => e.id == withdrawalId
          )
          this.withdrawals.splice(withdrawalIndex, 1)
          adminStore.dispatch('dashboard/getCards')
          toast.success('Withdrawal canceled')
        })
      }
    })
  }

  getWithdrawalDetails(withdrawalId: number) {
    this.getSpecificWithdrawal(withdrawalId).then(() => {
      this.showDetailsModal = true
    })
  }

  created() {
    this.initFilters()
  }

  clearFilter() {
    this.initFilters()
  }

  initFilters() {
    this.filters = {
      global: { value: '', matchMode: FilterMatchMode.CONTAINS }
    }
  }

  ifCrypto(currencyId: string) {
    if (this.status == 'success') {
      // eslint-disable-next-line
      const result = this.cryptosAssets.find((element: any) => {
        return element.id == currencyId
      })
      return result ? currencyId : undefined
    }
  }
  ifLocal(currencyId: string) {
    // eslint-disable-next-line
    if (this.status == 'success') {
      // eslint-disable-next-line
      const result = this.fiatsAssets.find((element: any) => {
        return element.id == currencyId
      })
      return result ? currencyId : undefined
    }
  }

  public formatPrice!: (amount: number) => number
  public formatDate!: (isoDate: string) => string
}
