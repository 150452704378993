import AuthorizedSigner from "./AuthorizedSigner";
import BeneficialOwner from "./BeneficialOwner";
import Corporate from "./Corporate";
import FileUser from "./FileUser";

export default class UserItem {
    id!: number;
    firstName!: string;
    lastName!: string;
    email!: string;
    isActive!: boolean;
    createdAt!: string;
    activationCode!: string;
    comments!: string;
    dateOfBirth!: string;
    emailConfirmed!: boolean;
    flinksLoginId!: string;
    manualVerificationStep!: number;
    planId!: number;
    referenceCode!: string;
    relationWithGovernment!: boolean;
    updatedAt!: string;
    phoneNumber!: string;
    phoneNumberCode!: string;
    phoneCountry!: string;
    occupation!: string;
    postalCode!: string;
    addressLine1!: string;
    city!: string;
    addressLine2!: string;
    street!: string;
    streetNumber!: string;
    interiorNumber!: string;
    province!: string;
    nickname!: string;
    files!: FileUser[];
    isSuspended!: boolean;
    suspensionReason!: string;
    isTwoFactorAuthenticationEnabled!: boolean;
    corporate!: Corporate;
    isApiTradingEnabled!: boolean;
}
