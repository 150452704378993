export default class BankAccountItem {
    id!: number;
    currencyId!: string;
    type!: number;
    recipientFullName!: string;
    bankName!: string;
    bankAddress!: string;
    postalCode!: string;
    city!: string;
    province!: string;
    country!: string;
    swiftOrBic!: string;
    transitNumber!: string;
    institutionNumber!: string;
    bankAccountNumber!: string;
    phoneNumber!: string;
    accountName!: string;
    legalAddressAccountHolder!: string;
    cryptoAddress!: string;
    isActive!: boolean;
}