import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-mask"
}
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  style: {"padding-right":"60px"}
}
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = {
  key: 0,
  class: "badge badge-light",
  style: {"margin-top":"3px","float":"right","font-size":"17px"}
}
const _hoisted_7 = { style: {"color":"#3a3b45"} }
const _hoisted_8 = {
  type: "button",
  class: "close",
  "data-dismiss": "modal",
  "aria-label": "Close",
  style: {"position":"absolute","right":"25px","top":"20px"}
}
const _hoisted_9 = { class: "modal-body" }
const _hoisted_10 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              _createVNode("div", {
                class: 'modal-dialog popup' + ( _ctx.size ? ' '+_ctx.size : '' ),
                role: "document"
              }, [
                _createVNode("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, [
                    _createVNode("h5", _hoisted_5, _toDisplayString(_ctx.title), 1),
                    (_ctx.rightHeaderBadge)
                      ? (_openBlock(), _createBlock("span", _hoisted_6, [
                          _createVNode("code", _hoisted_7, _toDisplayString(_ctx.rightHeaderBadge), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode("button", _hoisted_8, [
                      _createVNode("span", {
                        "aria-hidden": "true",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCancelButton == true ? _ctx.close() : _ctx.ok()))
                      }, "×")
                    ])
                  ]),
                  _createVNode("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "default")
                  ]),
                  _createVNode("div", _hoisted_10, [
                    (_ctx.showCancelButton)
                      ? (_openBlock(), _createBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn btn-secondary",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
                        }, " Close "))
                      : _createCommentVNode("", true),
                    _createVNode("button", {
                      type: "button",
                      disabled: _ctx.disable,
                      class: 'btn ' + _ctx.okButtonClass,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ok()))
                    }, _toDisplayString(_ctx.okText), 11, ["disabled"])
                  ])
                ])
              ], 2)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}