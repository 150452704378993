
import InputSwitch from 'primevue/inputswitch'
import { Options, Vue } from 'vue-class-component'
import CardBase from '@/components/common/layout/CardBase.vue'
import formattersMixin from '@/mixins/formatters'
import SitePermissionsEnum from '@/types/users/SitePermissionsEnum'
import AdminItem from '@/types/users/AdminItem'
import { namespace } from 'vuex-class'
import { useToast } from 'vue-toastification'
import { adminStore } from '@/store/admin'
import ConfirmPopup from 'primevue/confirmpopup'
const users = namespace('users')
const toast = useToast()
@Options({
  components: {
    CardBase,
    InputSwitch,
    ConfirmPopup
  },
  mixins: [formattersMixin]
})
export default class AdminDetails extends Vue {
  isLoading = false
  currentAdmin = new AdminItem()

  @users.Action
  getAdminById!: (id: number) => Promise<AdminItem>

  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assingPermissionToAdmin!: (payload: any) => Promise<void>

  @users.Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset2FA!: (adminId: number) => Promise<void>

  mounted() {
    this.getInformation()
  }

  async getInformation() {
    const id = Number(this.$route.params['id'])
    try {
      adminStore.dispatch('loader/show')
      this.isLoading = true
      this.currentAdmin = await this.getAdminById(id)
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      this.isLoading = false
      adminStore.dispatch('loader/hidden')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeConfig(permission: any) {
    this.assingPermission(this.currentAdmin.id, permission.name)
  }

  get getPermissionList() {
    if (!this.currentAdmin.id) {
      return []
    }
    return Object.entries(SitePermissionsEnum).map(key => {
      const permission = key[1]
      this.currentAdmin.permissions.some(p => p.concept === permission.name)
        ? (permission['active'] = true)
        : (permission['active'] = false)
      return { ...permission }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetTwoFactorAuth(e: any) {
    this.$confirm.require({
      target: e.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          await this.reset2FA(this.currentAdmin.id)
          await this.getInformation()
          toast.success('Account updated')
        } catch (error) {
          toast.error('An error has occurred \nPlease try again later')
        }
      }
    })
  }
  async assingPermission(adminId: number, concept: string): Promise<void> {
    try {
      adminStore.dispatch('loader/show')
      await this.assingPermissionToAdmin({ adminId, concept })
      toast.clear()
      toast.success(`Permission grandted`)
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error has occurred \nPlease try again')
      }
    } finally {
      await this.getInformation()
      adminStore.dispatch('loader/hidden')
    }
  }
}
