import { email, maxLength, required, password, prop, compare } from "@rxweb/reactive-forms";

export default class RegistrationForm {
    @required()
    @email({ message: "Invalid email" })
    @maxLength({ value: 65, message: "Max length 65 caracters" })
    email!: string;

    @required()
    @maxLength({ value: 50, message: "Max length 50 caracters" })
    firstName!: string

    @required()
    @maxLength({ value: 50, message: "Max length 50 caracters" })
    lastName!: string

    @prop()
    @required()
    @password(
        {
            validation: { maxLength: 36, minLength: 6, digit: true, specialCharacter: true, upperCase: true },
            message: "Invalid password. It must be between 6 and 36 characters, also it must contains at least a number and one uppercase character and special characters (, . @ () {} * | ? ! # $ % ^ &) "
        }
    )
    password!: string;

    @compare({ fieldName: 'password', message: "Passwords do not match" })
    rpassword!: string;

    token!: string;
}