import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import http from "@/http-common-admin";
import UserItem from "@/types/users/UserItem";

@Module({ namespaced: true, name: "" })
class Orders extends VuexModule {
  public status!: string;
  public lastTradesData!: any;
  public data!: any;
  public error!: object;
  public order!: any;

  public createOrderData!: any;
  public createOrderError!: object;
  public createOrderStatus!: string;

  public createFillingOrderData!: any;
  public createFillingOrderError!: object;
  public createFillingOrderStatus!: string;

  @Mutation
  public onCreatingFillingOrder(): void {
    this.createFillingOrderStatus = "fetching";
    this.createFillingOrderError = {};
    this.createFillingOrderData = [];
  }

  @Mutation
  public onCreatingFillingOrderSuccess(data: object): void {
    this.createFillingOrderStatus = "success";
    this.createFillingOrderError = {};
    this.createFillingOrderData = data;
  }

  @Mutation
  public onCreatingFillingOrderError(err: object): void {
    this.createFillingOrderStatus = "error";
    this.createFillingOrderError = err;
  }

  @Mutation
  public onCreatingOrder(): void {
    this.createOrderStatus = "fetching";
    this.createOrderError = {};
    this.createOrderData = [];
  }

  @Mutation
  public onCreatingOrderSuccess(data: object): void {
    this.createOrderStatus = "success";
    this.createOrderError = {};
    this.createOrderData = data;
  }

  @Mutation
  public onCreatingOrderError(err: object): void {
    this.createOrderStatus = "error";
    this.createOrderError = err;
  }

  @Mutation
  public onLastTrades(): void {
    this.status = "fetching";
    this.error = {};
    this.lastTradesData = [];
  }

  @Mutation
  public onLastTradesSuccess(data: object): void {
    this.status = "success";
    this.error = {};
    this.lastTradesData = data;
  }

  @Mutation
  public onLastTradesError(err: object): void {
    this.status = "error";
    this.error = err;
  }

  @Mutation
  public onOrders(): void {
    this.status = "fetching";
    this.error = {};
    this.data = [];
  }

  @Mutation
  public onOrdersSuccess(data: object): void {
    this.status = "success";
    this.error = {};
    (data as any[]).forEach((e) => {
      e.customer.fullName = e.customer.firstName + " " + e.customer.lastName;
    });
    this.data = data;
  }

  @Mutation
  public onOrdersError(err: object): void {
    this.status = "error";
    this.error = err;
  }

  @Action({ rawError: true })
  public createOrder(payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onCreatingOrder");
      const offset = new Date().getTimezoneOffset();
      http
        .post("customer-order/internal/register", { ...payload, timezoneOffset: offset })
        .then((response) => {
          this.context.commit("onCreatingOrderSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onCreatingOrderError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public saveOrder(payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onCreatingOrder");
      const offset = new Date().getTimezoneOffset();
      http
        .post("customer-order/internal/update", { ...payload, timezoneOffset: offset })
        .then((response) => {
          this.context.commit("onCreatingOrderSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onCreatingOrderError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public closerManualOrder(orderId: number) {
    return new Promise((resolve, reject) => {
      http
        .put("customer-order/internal/close", { orderId })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public createFillingOrder(payload) {
    return new Promise((resolve, reject) => {
      this.context.commit("onCreatingFillingOrder");
      const offset = new Date().getTimezoneOffset();
      http
        .post("provider-order/admin/register", { ...payload, timezoneOffset: offset })
        .then((response) => {
          this.context.commit("onCreatingFillingOrderSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onCreatingFillingOrderError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getLastTrades() {
    return new Promise((resolve, reject) => {
      this.context.commit("onLastTrades");
      const offset = new Date().getTimezoneOffset();
      http
        .get("customer-order/internal/list?orderBy=createdAt&sort=DESC&limit=100", { params: { timezoneOffset: offset } })
        .then((response) => {
          this.context.commit("onLastTradesSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onLastTradesError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public getPendingOrders() {
    return new Promise((resolve, reject) => {
      this.context.commit("onOrders");
      const offset = new Date().getTimezoneOffset();
      http
        .get("customer-order/internal/list?automaticExecution=false&status=1&status=2", { params: { timezoneOffset: offset } })
        .then((response) => {
          this.context.commit("onOrdersSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          this.context.commit("onOrdersError", error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  public reverseOrder(payload: { customerId: number; orderUuid: string }) {
    return new Promise((resolve, reject) => {
      const timeZoneOffset = new Date().getTimezoneOffset();
      http
        .post("customer-order/internal/reverse", { ...payload, timeZoneOffset })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public get lastTrades(): object {
    return this.lastTradesData;
  }

  public get orders(): object {
    return this.data;
  }

  @Mutation
  public setOrder(order: UserItem): void {
    this.order = order;
  }

  public get selectedOrder(): UserItem {
    return this.order;
  }

  public get currentStatus(): string {
    return this.status;
  }

  public get currentCreateOrderStatus(): string {
    return this.createOrderStatus;
  }

  public get currentCreateFillingOrderStatus(): string {
    return this.createFillingOrderStatus;
  }

  @Action({ rawError: true })
  public getSpecificOrder(id: string): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      const offset = new Date().getTimezoneOffset();
      http
        .get(`customer-order/internal/get-details/${id}`, { params: { timezoneOffset: offset } })
        .then((response) => {
          resolve(response.data);
          this.context.commit("setOrder", response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default Orders;
