import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
@Module({ namespaced: true, name: '' })
class Loader extends VuexModule {
  public status!: boolean

  @Mutation
  public toShow(): void {
      this.status = true
  }
  @Mutation
  public toHidden (): void {
    this.status = false
  }
  @Action({rawError: true})
  public show(){
    this.context.commit("toShow")
  }
  @Action({rawError: true})
  public hidden(){
    this.context.commit("toHidden")
  }
  
  get loader () {
    return this.status
  }
}
export default Loader